import { ICourt } from 'usecases/types/court';
import { IPricingRule } from 'usecases/types/price';
import { CHDateTime } from 'helpers/DateTime/CHDateTime';
import { CHTime } from 'helpers/Time/CHTime';
import { BusinessHoursInput } from '@fullcalendar/common';
import { PlayerRole } from 'types';

export const calculateBusinessHours = (
    court: ICourt | undefined,
    pricingRules:IPricingRule[],
    date: CHDateTime,
    role: PlayerRole,
): BusinessHoursInput => {
    const noBusinessHours = [{
        daysOfWeek: [1, 2, 3, 4, 5, 6, 0], // Sunday is 0 instead of 7
        startTime: '00:00',
        endTime: '00:00',
    }];

    if (!court) {
        return noBusinessHours;
    }

    const pricingRulesOfCourt = pricingRules.filter(rule => rule.courts.includes(court.id));

    let pricingRulesOfCourtInSeason = pricingRulesOfCourt.filter(pricingRule => {
        const seasonStart = new CHDateTime(pricingRule.season.startDateTime);
        const seasonEnd = new CHDateTime(pricingRule.season.endDateTime);
        return seasonStart.isBeforeOrEqual(date) && date.isBefore(seasonEnd);
    });

    if (role !== PlayerRole.ADMIN) {
        pricingRulesOfCourtInSeason = pricingRulesOfCourtInSeason.filter(pricingRule => pricingRule.role === role);
    }

    if (pricingRulesOfCourtInSeason.length === 0) {
        // No applicable pricing rules means it is completely closed, so no business hours.
        return noBusinessHours;
    }

    return pricingRulesOfCourtInSeason.map(pricingRule => ({
        daysOfWeek: pricingRule.weekdays.map(weekday => weekday % 7), // Sunday is 0 instead of 7
        startTime: CHTime.fromStringWithTimezone(pricingRule.startTime).toLocalStringWithoutTimezone(),
        endTime: CHTime.fromStringWithTimezone(pricingRule.endTime).toLocalStringWithoutTimezone(),
    }));
};
