import React from 'react';
import { translate } from 'translations/translate';
import { LegalAgreement } from '../LegalAgreement/LegalAgreement';

export const TermsOfService = ({ route } : {route: string}) => (
    <LegalAgreement
        text1={translate('user_register-terms_and_conditions_agreement')}
        linkText={translate('terms_and_conditions')}
        linkRoute={route}
        text2={translate('user_register-terms_and_conditions_agreement2')}
    />
);
