import { CHDateTime } from 'helpers/DateTime/CHDateTime';
import { Invoice, InvoiceDTO } from 'types';

export const adaptInvoiceResponse = (invoiceDataFromAPI: InvoiceDTO[]): Invoice[] => invoiceDataFromAPI
    .map((invoice: InvoiceDTO): Invoice => ({
        id: invoice.id,
        invoiceNumber: invoice.invoiceNumber,
        status: invoice.status,
        name: invoice.name,
        surname: invoice.surname,
        user: invoice.user,
        amount: invoice.amount,
        bookings: invoice.bookings,
        createdAt: new CHDateTime(invoice.createdAt),
        isSubscription: invoice.isSubscription,
        paymentMethod: invoice.paymentMethod,
    }));
