import { arrayToMapByAttribute } from 'adapters/arrayToMapByAttribute';
import { Dict, IUser } from 'types';
import { IReservation } from 'usecases/types/reservations';
import { calculateDiffBetweenTwoMaps } from './calculateDiffBetweenTwoMaps';

export const calculatePlayers = (userDictionary: Dict<IUser>, reservation: IReservation): Dict<IUser> => {
    const { owner, companions } = reservation;

    let restuserDictionary;
    if (owner) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [owner.id]: currentUser, ...restUsers } = userDictionary;
        restuserDictionary = restUsers;
    } else {
        restuserDictionary = userDictionary;
    }

    const possiblePlayers = calculateDiffBetweenTwoMaps(restuserDictionary, arrayToMapByAttribute(companions));
    return possiblePlayers;
};
