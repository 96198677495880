import { arrayToMapByAttribute } from 'adapters/arrayToMapByAttribute';
import { IUser, PlayerRole } from 'types';

export const changeUserRoleInClub = (user: IUser, newRole: PlayerRole, clubId: string): IUser => {
    const mappedMemberships = arrayToMapByAttribute(user.memberships, 'clubId');
    const editedMappedMemberships = {
        ...mappedMemberships,
        [clubId]: {
            ...mappedMemberships[clubId],
            role: newRole,
        },
    };
    const editedMemberships = Object.values(editedMappedMemberships);
    const editedUser = { ...user, memberships: editedMemberships };

    return editedUser;
};
