import { COURTS, PLACEHOLDERS } from 'config/endpoints';
import { getWrapper } from 'services/httpService/httpServiceWrapper';
import { ICourt } from '../types/court';
import { IClub } from '../types/club';
import { getCourtsAdapter } from './getCourtsAdapter';

export const getCourts = async (club: IClub): Promise<ICourt[]> => {
    const endpoint = COURTS.LIST.replace(PLACEHOLDERS.CLUB_ID, club.id);

    const { data: courts }: { data: ICourt[] } = await getWrapper(endpoint);

    return getCourtsAdapter(courts);
};
