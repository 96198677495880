import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.min.css';
import '@fullcalendar/common/main.css';
import '@fullcalendar/timegrid/main.css';
import './styles/_fonts.scss';
import './styles/_variables.scss';
import './index.scss';
import { AppProvider } from 'context/AppContext';
import { Provider } from 'react-redux';
import { appStore } from 'redux/store';
import TagManager from 'react-gtm-module';
import { ModalProvider } from 'context/ModalContext';
import { FeatureFlagProvider } from 'context/FeatureFlagContext/FeatureFlagContext';
import { isLocalServer } from 'helpers/isLocalServer';
import reportWebVitals from './reportWebVitals';
import { App } from './App';

let optimizelyKey = '';

if (!isLocalServer()) {
    optimizelyKey = process.env.REACT_APP_OPTIMIZELY_SDK_KEY!;

    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID as string,
    };

    TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={appStore}>
            <AppProvider>
                <FeatureFlagProvider sdkKey={optimizelyKey}>
                    <ModalProvider>
                        <App />
                    </ModalProvider>
                </FeatureFlagProvider>
            </AppProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
