import { IReservation } from 'usecases/types/reservations';
import { EventSourceInput } from '@fullcalendar/react';
import { CalendarViewEnum } from 'types';
import { ICourt } from 'usecases/types/court';
import { IPricingRule } from 'usecases/types/price';
import { ISeason } from 'usecases/types/season';
import { CHDateTime } from 'helpers/DateTime/CHDateTime';
import { getBackgroundEvents } from './getBackgroundEvents';
import { getCalendarEvents } from './getCalendarEvents';
import { getMappedPricingRules } from './getMappedPricingRules';

interface Params {
    seasons: ISeason[],
    pricingRules: IPricingRule[],
    bookings: IReservation[],
    courts: ICourt[],
    selectedCourt: ICourt | null,
    initialDate: CHDateTime,
    view: CalendarViewEnum,
}

export const loadEvents = ({
    seasons,
    pricingRules,
    bookings,
    courts,
    selectedCourt,
    initialDate,
    view,
}: Params): EventSourceInput => [
    ...getCalendarEvents(courts, bookings),
    ...getBackgroundEvents(seasons),
    ...getMappedPricingRules(seasons, pricingRules, selectedCourt, initialDate, view),
];
