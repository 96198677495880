import { customHistory } from 'routing/customHistory';

export const getBookingIdFromUrl = (urlParams: URLSearchParams) => {
    const bookingId = urlParams.get('bookingId');
    urlParams.delete('bookingId');
    urlParams.delete('session_id');
    customHistory.originalReplace({ search: decodeURIComponent(urlParams.toString()) });

    return bookingId;
};
