import { Input, Modal, notification } from 'antd';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from 'react';
import { translate } from 'translations/translate';
import { useAppContext } from 'context/AppContext';
import {
    getClubFromStorage,
    getCurrentUserRole,
    getUserFromStorage,
    isLoggedUserAdmin,
} from 'services/functionalStorage';
import { createReservation } from 'usecases/reservation/createReservation';
import {
    CalendarViewEnum, Dict, IGuest,
    IUser, PLAYERROLECLIENT,
} from 'types';
import { ICourt } from 'usecases/types/court';
import { IReservation } from 'usecases/types/reservations';
import { getClubMembers } from 'usecases/club/getClubMembers';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import {
    getBackToCalendarAfterSuccessDataLayer,
    getBookingFailureDatalayer,
    getBookingSubmitDatalayer,
    getBookingSuccessDatalayer, getCloseBookingModalDataLayer,
    getShowBookingAfterSuccessDataLayer,
} from 'services/gtm/gtmDatalayers';
import { formatNumberAsLocaleCurrency } from 'adapters/formatNumberAsLocaleCurrency';
import { AlertModalContent } from 'components/AlertModalContent/AlertModalContent';
import { useModalContext } from 'context/ModalContext';
import {
    AddGuestModalContent,
} from 'components/Reservations/BookingDialog/components/AddGuestModalContent/AddGuestModalContent';
import { useMobileMediaQuery } from 'hooks/useMobileMediaQuery';
import { Interval } from 'usecases/types/enums/booking_interval.enum';
import { createRecurringBooking } from 'usecases/reservation/createRecurringBooking';
import { ISeason } from 'usecases/types/season';
import { PaymentTypeEnum } from 'usecases/types/enums/payment_type.enum';
import { BookingType } from 'usecases/types/enums/booking_type.enum';
import { useBookingPriceFromApi } from 'hooks/useBookingPriceFromApi';
import { debounce } from 'adapters/debounce';
import { useApi } from 'hooks/useApi';
import { getUserMembership } from 'helpers/getUserMembership';
import { ModalBookButton } from './components/ModalBookButton/ModalBookButton';
import { Players } from './components/Players/Players';
import { Guests } from './components/Guests/Guests';
import { Footer } from './components/Footer/Footer';
import { DateTimePicker } from './components/DateTimePicker/DateTimePicker';
import { Owner } from './components/Owner/Owner';
import { ModalHeader } from './components/ModalHeader/ModalHeader';
import { calculateBookingDurationMinutes } from './calculateBookingDurationMinutes';
import { calculatePlayers } from './calculatePlayers';
import { calculateEndTimeFromStartTimeAndDuration } from './calculateEndTimeFromStartTimeAndDuration';
import { getActivePlayersDictionary } from './getActivePlayersDictionary';
import { DurationRow } from './components/DurationRow/DurationRow';
import { DeleteReservationModalContent }
    from './components/DeleteReservationModalContent/DeleteReservationModalContent';
import { getDeleteSuccessModalContent } from './getDeleteSuccessModalContent';
import { HeaderIcon } from './components/HeaderIcon/HeaderIcon';
import { FormDivider } from './FormDivider/FormDivider';
import { getSeasonFromBooking } from './getSeasonFromBooking';
import { enableReservationsCalendarScrollbar } from '../ReservationsCalendar/enableReservationsCalendarScrollbar';
import { disableReservationsCalendarScrollbar } from '../ReservationsCalendar/disableReservationsCalendarScrollbar';
import { PaymentTypeSelector } from './components/PaymentTypeSelector/PaymentTypeSelector';
import { BookingTypeSelector } from './BookingTypeSelector/BookingTypeSelector';
import { isNotNull } from './components/ModalBookButton/isNotNull';
import './BookingDialog.scss';

interface IProps {
    setVisible: (visible: boolean) => void;
    onCloseActionsDialog: () => void;
    setReservation: (booking: IReservation) => void;
    booking: IReservation;
    courts: ICourt[];
    selectedView: CalendarViewEnum;
    bookableSeasons: ISeason[]
}

export function BookingDialog({
    setVisible,
    onCloseActionsDialog,
    setReservation,
    booking,
    courts,
    selectedView,
    bookableSeasons,
}: IProps): JSX.Element | null {
    const { setIsSpinnerVisible } = useAppContext();
    const { setModalContentProps, setModalContent } = useModalContext();
    const { isMobile } = useMobileMediaQuery();
    const [usersFromAPI, setUsersFromAPI] = useState<Dict<IUser>>({});
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [guestFeeAmount, setGuestFeeAmount] = useState<number | null>(null);
    const isNewBooking = !booking.id;
    const isNotSingleBooking = booking.bookingType !== BookingType.SINGLE;
    const isSubscriptionBooking = booking.bookingType === BookingType.SUBSCRIPTION;

    const defaultInterval = Interval.WEEKLY;
    const [interval, setInterval] = useState<Interval>(defaultInterval);
    const showBookingTypeSelector = isNewBooking ? isLoggedUserAdmin() : true;
    const callApi = useApi();

    const bookingSeason = getSeasonFromBooking(bookableSeasons, booking);
    const [endingOn, setEndingOn] = useState<string>(bookingSeason!.endDateTime);

    const { onlinePaymentsEnabled, cashPaymentsEnabled } = getClubFromStorage();

    const user = getUserFromStorage();
    const currentMembership = getUserMembership(user, booking.club)!;

    const redirectOnlinePayment = (link: string) => {
        window.location.assign(link);
    };

    const addSingleBooking = async (desiredPrice: number): Promise<void> => {
        setErrors({});

        if (!booking.owner) {
            setErrors({ api: translate('calendar_no-owner-selected-error') });
            return;
        }
        if (booking.description && booking.description.length >= 200) {
            setErrors({ api: translate('calendar-reservation_dialog-description-error') });
            return;
        }

        try {
            pushGtmDatalayer(getBookingSubmitDatalayer({
                courtId: booking.court.id,
                courtName: booking.court.name,
                list: selectedView,
                reservationDate: moment(booking.startTime).format('YYYY-MM-DD'),
                reservationTime: moment(booking.startTime).format('HH:mm'),
            }));

            const { reservation, checkoutUrl } = await callApi(
                createReservation(
                    {
                        ...booking,
                        price: desiredPrice,
                    },
                ),
            );
            setReservation(reservation);

            if (booking.paymentType === PaymentTypeEnum.CASH) {
                pushGtmDatalayer(getBookingSuccessDatalayer(
                    [reservation],
                    user.id,
                    booking.club.id,
                    currentMembership.role,
                    desiredPrice,
                ));
            }

            setModalContentProps({
                closable: false,
            });

            if (booking.paymentType === PaymentTypeEnum.ONLINE) {
                setIsSpinnerVisible(true);
                redirectOnlinePayment(checkoutUrl);
            } else {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                setModalContent(getCreateSuccessModalContent());
            }
        } catch (error: any) {
            pushGtmDatalayer(getBookingFailureDatalayer(
                user.id,
                booking.club.id,
                currentMembership.role,
                desiredPrice,
                booking.paymentType,
                booking.bookingType,
                error.message,
            ));
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    const addRecurringBooking = async (desiredPrice: number): Promise<void> => {
        setErrors({});

        if (!booking.owner) {
            setErrors({ api: translate('calendar_no-owner-selected-error') });
            return;
        }
        if (booking.description && booking.description.length >= 200) {
            setErrors({ api: translate('calendar-reservation_dialog-description-error') });
            return;
        }

        try {
            setIsSpinnerVisible(true);
            const newRecurringReservation = await createRecurringBooking({
                ...booking,
                price: desiredPrice,
                interval,
                endingOn,
            });

            setReservation(newRecurringReservation[0]);

            if (booking.paymentType === PaymentTypeEnum.CASH) {
                pushGtmDatalayer(getBookingSuccessDatalayer(
                    newRecurringReservation,
                    user.id,
                    booking.club.id,
                    currentMembership.role,
                    desiredPrice,
                ));
            }

            setModalContentProps({
                closable: false,
            });
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            setModalContent(getCreateSuccessModalContent());
        } catch (error: any) {
            setErrors({ api: error.message });
            pushGtmDatalayer(getBookingFailureDatalayer(
                user.id,
                booking.club.id,
                currentMembership.role,
                desiredPrice,
                booking.paymentType,
                booking.bookingType,
                error.message,
            ));
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    const handleDurationChange = (duration: number): void => {
        setReservation({
            ...booking,
            endTime: calculateEndTimeFromStartTimeAndDuration(booking, duration),
        });
    };

    const closeActionsDialog = (_reservation?: IReservation) => {
        setModalContent(null);
        setErrors({});
        onCloseActionsDialog();
        if (!_reservation) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            closeModal();
            setReservation({
                ...booking,
                companions: [],
                guests: [],
            });
        }
    };

    useEffect(() => {
        if (getCurrentUserRole() !== PLAYERROLECLIENT) {
            const { id } = getClubFromStorage();
            getClubMembers(id).then((users: IUser[]) => {
                const usersSortedMapped = getActivePlayersDictionary(users, getUserFromStorage());
                setUsersFromAPI(usersSortedMapped);
            });
        }
    }, []);

    useEffect(() => {
        disableReservationsCalendarScrollbar();

        return () => {
            enableReservationsCalendarScrollbar();
        };
    }, []);

    const removeOption = (id: string) => {
        const newCompanions = [...booking.companions, usersFromAPI[id]];

        setReservation({
            ...booking,
            companions: newCompanions,
        });
    };

    const addOption = (id: string) => {
        const newCompanions = booking.companions.filter(companion => companion.id !== id);

        setReservation({
            ...booking,
            companions: newCompanions,
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const removeOwner = (id: string) => {
        setReservation({
            ...booking,
            owner: undefined,
            price: null,
        });
        setGuestFeeAmount(null);
    };
    const bookingInterval = isNotSingleBooking ? interval : null;
    const reservationTime = calculateBookingDurationMinutes(booking);

    const getReservationPrice = async (
        bookingFromApi: IReservation,
        reservationTiming: number,
        bookingDuration: Interval | null,
        bookingEnding: string | null,
    ) => {
        try {
            const { amount, guestFee } = await useBookingPriceFromApi(
                bookingFromApi,
                reservationTiming,
                bookingDuration,
                bookingEnding,
            );

            if (isNotNull(guestFee)) {
                setGuestFeeAmount(guestFee);
            }

            if (isNotNull(amount)) {
                setReservation({
                    ...bookingFromApi,
                    price: amount,
                });
            }
        } catch (error: any) {
            setReservation({
                ...bookingFromApi,
                price: null,
            });
            notification.error({
                message: translate('error'),
                description: error.message,
            });
        }
    };

    const handlePrice = (
        bookingFromApi: IReservation,
        reservationTiming: number,
        bookingDuration: Interval | null,
        bookingEnding: string | null,
    ): void => {
        getReservationPrice(
            bookingFromApi,
            reservationTiming,
            bookingDuration,
            bookingEnding,
        );
    };

    const debouncedCallback = useCallback(
        debounce(handlePrice, 500),
        [],
    );

    useEffect(
        () => {
            if (isNewBooking && booking.owner) {
                debouncedCallback(
                    booking,
                    reservationTime,
                    bookingInterval,
                    endingOn,
                );
            }
        },
        [
            booking.owner, booking.guests,
            booking.bookingType, booking.court,
            booking.startTime, booking.endTime,
            bookingInterval, endingOn,
        ],
    );

    const addOwner = (id: string) => {
        setReservation({
            ...booking,
            owner: usersFromAPI[id],
        });
    };

    const addGuest = (guest: IGuest) => {
        setReservation({
            ...booking,
            guests: [...booking.guests, guest],
        });
        setModalContent(null);
    };

    const removeGuest = (chosenGuest: IGuest) => {
        const newGuests = booking.guests.filter(guest => guest.name !== chosenGuest.name);

        setReservation({
            ...booking,
            guests: newGuests,
        });
        setGuestFeeAmount(null);
    };

    const setCourt = (_court: ICourt) => {
        setReservation({
            ...booking,
            court: _court,
        });
    };

    const setDate = (_date: string) => {
        const duration = calculateBookingDurationMinutes(booking);
        const newReservation = JSON.parse(JSON.stringify({ ...booking, startTime: _date }));
        const calculatedEndTime = calculateEndTimeFromStartTimeAndDuration(
            newReservation,
            duration,
        );
        setReservation({
            ...booking,
            startTime: _date,
            endTime: calculatedEndTime,
        });
    };

    const setDescription = (description: string): void => {
        setReservation({
            ...booking,
            description,
        });
    };

    const setPaymentType = (paymentType: PaymentTypeEnum) => {
        setReservation({
            ...booking,
            paymentType,
        });
    };

    const setBookingType = (bookingType: BookingType) => {
        setReservation({
            ...booking,
            bookingType,
        });
    };

    const closeModal = () => {
        setErrors({});
        setVisible(false);
        pushGtmDatalayer(getCloseBookingModalDataLayer(getUserFromStorage().id, getClubFromStorage().id, booking.id));
        setBookingType(BookingType.SINGLE);
    };

    const getCreateSuccessModalContent = (): JSX.Element => {
        const texts = {
            header: translate('calendar-reservation_dialog-booking_successful'),
            subHeader: translate('calendar-reservation_dialog-cancellation_policy'),
            firstButton: translate('calendar_reservation-confirmed-show-booking'),
            secondButton: translate('calendar_reservation-confirmed-back-to-calendar'),
        };
        const {
            header, subHeader, firstButton, secondButton,
        } = texts;

        const firstButtonSuccesModalOnClick = () => {
            closeActionsDialog(booking);
            pushGtmDatalayer(getShowBookingAfterSuccessDataLayer(
                user.id,
                booking.club.id,
            ));
        };

        const secondButtonSuccesModalOnClick = () => {
            closeActionsDialog();
            pushGtmDatalayer(getBackToCalendarAfterSuccessDataLayer(
                user.id,
                booking.club.id,
            ));
        };

        return (
            <AlertModalContent
                type="success"
                header={header}
                subHeader={isLoggedUserAdmin() ? '' : subHeader}
                seeDetailsText={firstButton}
                goBackText={secondButton}
                onSeeDetails={firstButtonSuccesModalOnClick}
                onGoBack={secondButtonSuccesModalOnClick}
            />
        );
    };

    return (
        <Modal
            getContainer={false}
            centered={!isMobile}
            visible
            footer={null}
            closable={false}
            style={{ top: isMobile ? 20 : 0 }}
            wrapClassName="booking-modal"
        >
            <div className={`
                    booking-dialog__container
                    ${isMobile ? 'booking-dialog__container--mobile-height' : ''}
                `}
            >
                <div
                    className={`
                            booking-dialog__card 
                            ${isNewBooking ? 'booking-dialog__card--mobile-height' : ''}
                        `}
                >
                    <ModalHeader
                        date={moment(booking.startTime)}
                        reservation={booking}
                        icon={<HeaderIcon onClickIcon={closeModal} />}
                    />
                    <FormDivider />
                    <Owner
                        owner={booking.owner as IUser}
                        userOptions={calculatePlayers(usersFromAPI, booking)}
                        removeOption={addOwner}
                        addOption={removeOwner}
                        isNewReservation={isNewBooking}
                    />
                    {getCurrentUserRole() !== PLAYERROLECLIENT && (
                        <>
                            <FormDivider />
                            <Players
                                userOptions={calculatePlayers(usersFromAPI, booking)}
                                companionList={Object.values(booking.companions)}
                                removeOption={removeOption}
                                addOption={addOption}
                                isNewReservation={isNewBooking}
                                loggedUserId={user.id}
                                facilityId={booking.club.id}
                            />
                        </>
                    )}
                    <FormDivider />
                    <Guests
                        removeGuest={removeGuest}
                        guestList={booking.guests}
                        onAddGuestClick={() => {
                            setModalContentProps({
                                closable: true,
                                onCancel: () => setModalContent(null),
                            });
                            setModalContent(<AddGuestModalContent
                                addGuest={addGuest}
                                onError={() => {
                                    setErrors({ form: translate('calendar-reservation_dialog-guest-error') });
                                    setModalContent(null);
                                }}
                                guests={booking.guests}
                                loggedUserId={user.id}
                                facilityId={booking.club.id}
                            />);
                        }}
                        isNewReservation={isNewBooking}
                        loggedUserId={user.id}
                        facilityId={booking.club.id}
                    />

                    {booking && isLoggedUserAdmin() && (
                        <>
                            <FormDivider />
                            <DateTimePicker
                                reservation={booking}
                                dateChange={setDate}
                                isNewReservation={isNewBooking}
                            />
                        </>
                    )}
                    <FormDivider />
                    <DurationRow
                        onDurationChange={handleDurationChange}
                        reservationTime={calculateBookingDurationMinutes(booking)}
                        court={booking.court}
                        courts={courts}
                        reservation={booking}
                        onCourtChange={setCourt}
                        isNewReservation={isNewBooking}
                    />

                    {showBookingTypeSelector && (
                        <BookingTypeSelector
                            onIntervalChange={setInterval}
                            onEndingOnChange={setEndingOn}
                            bookingSeason={bookingSeason!}
                            onChangeBookingType={setBookingType}
                            booking={booking}
                        />
                    )}

                    {((!isNewBooking && booking.description) || isLoggedUserAdmin()) && (
                        <>
                            <FormDivider />
                            <div>
                                <p className="booking-dialog__label">
                                    {translate('calendar-reservation_dialog-description')}
                                </p>
                                <Input
                                    className="booking-dialog__description"
                                    placeholder={translate('calendar-reservation_dialog-description-placeholder')}
                                    type="text"
                                    disabled={!isNewBooking}
                                    onChange={e => setDescription(e.target.value)}
                                    defaultValue={booking?.description}
                                />
                            </div>
                        </>
                    )}

                    {!isNewBooking && (!!booking.price || booking.price === 0) && (
                        <>
                            <FormDivider />
                            <p className="booking-dialog__label">
                                {translate('calendar-reservation_dialog-total-amount')}
                            </p>
                            <p className="booking-dialog__total">
                                {formatNumberAsLocaleCurrency(booking.price)}
                            </p>
                        </>
                    )}

                    {!isNewBooking && (

                        <Footer
                            onShowDeleteReservationModal={() => {
                                setModalContentProps({
                                    closable: false,
                                });
                                setModalContent(<DeleteReservationModalContent
                                    isAdmin={isLoggedUserAdmin()}
                                    reservation={booking}
                                    onReservationDeleted={() => {
                                        setModalContentProps({
                                            closable: false,
                                        });
                                        setModalContent(getDeleteSuccessModalContent(
                                            closeActionsDialog,
                                            isSubscriptionBooking
                                                ? 'calendar-reservation_dialog-subscription_successful-delete'
                                                : 'calendar-reservation_dialog-booking_successful-delete',
                                        ));
                                    }}
                                    onCancel={() => setModalContent(null)}
                                />);
                            }}
                        />
                    )}
                </div>

                {isNewBooking && (
                    <ModalBookButton
                        onBookingProceed={isNotSingleBooking ? addRecurringBooking : addSingleBooking}
                        reservationFormErrors={errors}
                        reservation={booking}
                        setErrors={setErrors}
                        guestFeeAmount={guestFeeAmount}
                        paymentTypeSelector={(
                            <PaymentTypeSelector
                                disabled={isNewBooking}
                                onChange={setPaymentType}
                                cash={cashPaymentsEnabled}
                                online={onlinePaymentsEnabled}
                                booking={booking}
                            />
                        )}
                    />
                )}
            </div>
        </Modal>
    );
}
