import React from 'react';
import moment from 'moment';
import { translate } from 'translations/translate';
import { IUser, IGuest } from 'types';
import { DEFAULT_AVATAR_BLACK } from 'config/constants';
import { IReservation } from 'usecases/types/reservations';
import { formatReservationRoles } from './formatReservationRoles';
import styles from './Reservation.module.scss';

const UserRow = ({ user }: { user: IUser | IGuest }) => (
    <div className={styles['user-row__event-row']}>
        <img
            src={(user as IUser).avatar || DEFAULT_AVATAR_BLACK}
            alt={`${user.name} ${user.surname}`}
        />
        <span>{`${user.name} ${user.surname}`}</span>
    </div>
);

export const Reservation = ({ reservation }: { reservation: IReservation }) => {
    const {
        owner,
        companions,
        startTime,
        endTime,
        guests,
    } = reservation;

    return (
        <div className={`${styles.reservation} ${owner ? styles['reservation--owner'] : ''}`}>
            <div className={styles.reservation__title}>
                <b>{reservation.description ?? translate('calendar_court-reserved')}</b>
            </div>
            {!owner && (
                <>
                    <div className={styles['user-row__event-row']}>
                        {formatReservationRoles(reservation.bookedBy)}
                    </div>
                </>
            )}
            {owner && (
                <>
                    <UserRow user={owner} />
                    {companions
                        && companions.map(companion => <UserRow user={companion} key={companion.id} />)}
                    {guests && guests.map(guest => <UserRow user={guest} key={guest.name} />)}
                </>
            )}
            <div className={styles['reservation__time-row']}>
                <img src="/assets/icons/clock-time.svg" alt="Hours" />
                <span>
                    {moment(startTime).format('HH:mm')}
                    {' '}
                    -
                    {moment(endTime).format('HH:mm')}
                </span>
            </div>
        </div>
    );
};
