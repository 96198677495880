import React, {
    useRef, useEffect, useState,
} from 'react';
import {
    Button, Checkbox, Form, Input, Select,
} from 'antd';

import {
    IUser,
    Address, IMemberForm, PLAYERROLECLIENT, PLAYERROLEMEMBER, PLAYERROLEADMIN,
} from 'types';
import { translate } from 'translations/translate';
import { GenderEnum } from 'usecases/types/enums/gender.enum';
import { LanguageEnum } from 'usecases/types/enums/language.enum';
import { useAppContext } from 'context/AppContext';
import { getCountryMap } from 'pages/common/AddressCard/getCountryMap';
import { getClubFromStorage } from 'services/functionalStorage';
import { getUserMembership } from 'helpers/getUserMembership';
import './MemberModalContent.scss';

interface IProps {
    title: string;
    onCancel: Function;
    onProceed: (values: IMemberForm) => void;
    member?: IUser;
}

export const MemberModalContent = ({
    title,
    onCancel,
    onProceed,
    member,
}: IProps) => {
    const { isSpinnerVisible } = useAppContext();
    const [mandatoryFields, setMandatoryFields] = useState<boolean>(false);
    const [isEmailRequired, setIsEmailRequired] = useState<boolean>(!member || Boolean(member.email));
    const [initialForm] = Form.useForm();
    const containerRef = useRef<HTMLElement>();
    const getInitialFormValues = (memberToChange?: IUser) => ({
        name: memberToChange?.name,
        surname: memberToChange?.surname,
        gender: memberToChange?.gender,
        language: memberToChange?.language,
        role: memberToChange ? getUserMembership(memberToChange, getClubFromStorage())?.role : undefined,
        street: memberToChange?.address?.street,
        number: memberToChange?.address?.number,
        zipCode: memberToChange?.address?.zipCode,
        city: memberToChange?.address?.city,
        country: memberToChange?.address?.country,
        email: memberToChange?.email ?? null,
    });

    const disableAllFieldsButRole = Boolean(member);

    const isAddressFilled = (): boolean => {
        const values: IMemberForm = initialForm.getFieldsValue();
        const {
            country,
            city,
            number,
            street,
            zipCode,
        } = values;
        const address: Address = {
            country,
            city,
            number,
            street,
            zipCode,
        };
        const isFilled = Object.values(address).some(addressProp => !!addressProp);
        setMandatoryFields(isFilled);
        return isFilled;
    };

    useEffect(() => {
        initialForm.setFieldsValue(getInitialFormValues(member));
    }, [member]);

    return (

        <div
            className="member-modal-content"
            ref={containerRef as any}
        >
            <h1 className="member-modal-content__title">
                {title}
            </h1>
            <Form
                className="member-modal-content__form-container"
                requiredMark="optional"
                layout="vertical"
                name="basic"
                form={initialForm}
                onFinish={onProceed}
                onFieldsChange={isAddressFilled}
            >
                <Form.Item
                    label={(
                        <label className="member-modal-content__form-label">
                            {translate('club_members_modal_name')}
                        </label>
                    )}
                    name="name"
                    rules={[{
                        required: true,
                        message: translate('club_members_modal_name-required'),
                    }]}
                >
                    <Input
                        className="member-modal-content__form-item"
                        disabled={disableAllFieldsButRole}
                    />
                </Form.Item>
                <Form.Item
                    label={(
                        <label className="member-modal-content__form-label">
                            {translate('club_members_modal_surname')}
                        </label>
                    )}
                    name="surname"
                    rules={[{
                        required: true,
                        message: translate('club_members_modal_surname-required'),
                    }]}
                >
                    <Input
                        className="member-modal-content__form-item"
                        disabled={disableAllFieldsButRole}
                    />
                </Form.Item>
                <Form.Item
                    name="gender"
                    label={(
                        <label htmlFor="gender" className="member-modal-content__form-label">
                            {translate('club_members_modal_gender')}
                        </label>
                    )}
                    rules={[{ required: true, message: translate('gender-is-required') }]}
                >
                    <Select
                        size="large"
                        className="member-modal-content__form-item-select"
                        getPopupContainer={() => containerRef.current as any}
                        disabled={disableAllFieldsButRole}
                    >
                        <Select.Option value={GenderEnum.MALE}>{translate('male')}</Select.Option>
                        <Select.Option value={GenderEnum.FEMALE}>{translate('female')}</Select.Option>
                        <Select.Option value={GenderEnum.OTHER}>{translate('other')}</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="language"
                    label={(
                        <label htmlFor="language" className="member-modal-content__form-label">
                            {translate('club_members_modal_language')}
                        </label>
                    )}
                    rules={[{ required: true, message: translate('language-is-required') }]}
                >
                    <Select
                        size="large"
                        className="member-modal-content__form-item-select"
                        getPopupContainer={() => containerRef.current as any}
                        disabled={disableAllFieldsButRole}
                    >
                        <Select.Option value={LanguageEnum.ENGLISH}>English</Select.Option>
                        <Select.Option value={LanguageEnum.GERMAN}>Deutsch</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label={(
                        <label className="member-modal-content__form-label">
                            {translate('club_members_modal_email')}
                        </label>
                    )}
                    name="email"
                    normalize={value => value ?? null}
                    rules={[{
                        required: isEmailRequired,
                        message: translate('club_members_modal_email-required'),
                    }]}
                    help={!member && (
                        <Checkbox checked={!isEmailRequired} onChange={e => setIsEmailRequired(!e.target.checked)}>
                            {translate('club-members-modal_no-email-required')}
                        </Checkbox>
                    )}
                >
                    <Input
                        disabled={Boolean(member)}
                        className="member-modal-content__form-item"
                    />
                </Form.Item>
                <Form.Item
                    name="role"
                    label={(
                        <label htmlFor="role" className="member-modal-content__form-label">
                            {translate('club_members_modal_role')}
                        </label>
                    )}
                    rules={[{ required: true, message: translate('role-is-required') }]}
                >
                    <Select
                        size="large"
                        className="member-modal-content__form-item-select"
                        getPopupContainer={() => containerRef.current as any}
                    >
                        <Select.Option value={PLAYERROLEMEMBER}>{translate(`role_${PLAYERROLEMEMBER}`)}</Select.Option>
                        <Select.Option value={PLAYERROLECLIENT}>{translate(`role_${PLAYERROLECLIENT}`)}</Select.Option>
                        <Select.Option value={PLAYERROLEADMIN}>{translate(`role_${PLAYERROLEADMIN}`)}</Select.Option>
                    </Select>
                </Form.Item>
                <div className="member-modal-content__address-container">
                    <h2 className="member-modal-content__form-label member-modal-content__subtitle">
                        {translate('club_members_modal_subtitle')}
                    </h2>
                    <Form.Item
                        label={(
                            <label className="member-modal-content__form-label">
                                {translate('club_members_modal_street')}
                            </label>
                        )}
                        name="street"
                        rules={[{
                            required: mandatoryFields,
                            message: translate('club_members_modal_street-required'),
                        }]}
                    >
                        <Input
                            className="member-modal-content__form-item"
                            disabled={disableAllFieldsButRole}
                        />
                    </Form.Item>
                    <Form.Item
                        label={(
                            <label className="member-modal-content__form-label">
                                {translate('club_members_modal_number')}
                            </label>
                        )}
                        name="number"
                        rules={[{
                            required: mandatoryFields,
                            message: translate('club_members_modal_number-required'),
                        }]}
                    >
                        <Input
                            className="member-modal-content__form-item"
                            disabled={disableAllFieldsButRole}
                        />
                    </Form.Item>
                    <Form.Item
                        label={(
                            <label className="member-modal-content__form-label">
                                {translate('club_members_modal_zip-code')}
                            </label>
                        )}
                        name="zipCode"
                        rules={[{
                            required: mandatoryFields,
                            message: translate('club_members_modal_zip-code-required'),
                        }]}
                    >
                        <Input
                            className="member-modal-content__form-item"
                            disabled={disableAllFieldsButRole}
                        />
                    </Form.Item>
                    <Form.Item
                        label={(
                            <label className="member-modal-content__form-label">
                                {translate('club_members_modal_city')}
                            </label>
                        )}
                        name="city"
                        rules={[{
                            required: mandatoryFields,
                            message: translate('club_members_modal_city-required'),
                        }]}
                    >
                        <Input
                            className="member-modal-content__form-item"
                            disabled={disableAllFieldsButRole}
                        />
                    </Form.Item>
                    <Form.Item
                        label={(
                            <label className="member-modal-content__form-label">
                                {translate('club_members_modal_country')}
                            </label>
                        )}
                        name="country"
                        rules={[{
                            required: mandatoryFields,
                            message: translate('club_members_modal_country-required'),
                        }]}
                    >
                        <Select
                            className="member-modal-content__form-item-select"
                            size="large"
                            getPopupContainer={() => containerRef.current as any}
                            disabled={disableAllFieldsButRole}
                        >
                            {Object.entries(getCountryMap())
                                .map(([countryCode, countryName]) => (
                                    <Select.Option
                                        key={countryCode}
                                        value={countryCode}
                                    >
                                        {countryName}
                                    </Select.Option>
                                ))}

                        </Select>
                    </Form.Item>
                </div>
                <div className="member-modal-content__buttons-container">
                    <Button
                        type="default"
                        className="member-modal-content__button member-modal-content__button--gray"
                        onClick={() => onCancel(false)}
                    >
                        {translate('club_members_modal_cancel-button')}
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="member-modal-content__button"
                        disabled={isSpinnerVisible}
                    >
                        {(member && member.id)
                            ? translate('club_members_modal_edit-button')
                            : translate('club_members_modal_create-button')}
                    </Button>
                </div>
            </Form>
        </div>
    );
};
