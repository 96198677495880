import { DataLayerArgs } from 'react-gtm-module';
import { getClub } from 'usecases/club/getClub';
import { IUser } from 'types';
import { getSuccessfulUserRegistrationDatalayer } from './gtmDatalayers';

export const getAsyncSuccessfulUserRegistrationDatalayer = async (userCreatedInfo: IUser, clubId: string):
 Promise<DataLayerArgs['dataLayer']> => {
    const {
        gender, language, id,
    } = userCreatedInfo;

    const { name } = await getClub(clubId);

    const successfulUserRegistrationDatalayer = getSuccessfulUserRegistrationDatalayer({
        customerClubName: name,
        customerGender: gender,
        customerLanguage: language,
        customerUserId: id,
    });

    return successfulUserRegistrationDatalayer;
};
