import { IPricingRule } from 'usecases/types/price';
import { ISeason } from 'usecases/types/season';
import { getWeekDayDatesBetweenTwoDates } from './getWeekDayDatesBetweenTwoDates';

export const pickDatesFromPricingRule = (pricingRule: IPricingRule, activeSeason: ISeason)
: string[] => {
    const { startDateTime, endDateTime } = activeSeason;

    const dayDatesOfPricingRule = pricingRule.weekdays.reduce((acc, currWeekday) => [...acc,
        ...getWeekDayDatesBetweenTwoDates(startDateTime, endDateTime, currWeekday)], [] as any[]);

    return dayDatesOfPricingRule;
};
