import React, { useEffect, useState } from 'react';
import { Button, notification } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useAppContext } from 'context/AppContext';
import { getClubFromStorage } from 'services/functionalStorage';
import { ListTable } from 'components/ListTable/ListTable';
import { translate } from 'translations/translate';
import {
    IUser,
    IListTableHeaderField,
    IMemberForm, IModalTexts, ListAPIAction, PlayerRole,
} from 'types';
import { MEMBERSHIP_STATUS } from 'usecases/types/enums/membership_status.enum';
import { getClubMembers } from 'usecases/club/getClubMembers';
import { FACILITY_ICON_MEMBERS } from 'config/constants';
import { ActionElements } from 'components/ActionElements/ActionElements';
import { getSuccessModalTexts } from 'adapters/getSuccessModalTexts';
import { createClubPlayer } from 'usecases/club/createClubPlayer';
import { getAddressFromParameters } from 'usecases/users/getAddressFromParameters';
import { updateClubPlayer } from 'usecases/club/updateClubPlayer';
import { dateFormatter } from 'adapters/dateFormatter';
import { ClubMembersFilters } from 'components/ClubMembersFilters/ClubMembersFilters';
import { MobileFooter } from 'components/MobileFooter/MobileFooter';
import { ListPageHeader } from 'components/ListPageHeader/ListPageHeader';
import { ListPageButton } from 'components/ListPageButton/ListPageButton';
import { ImportPlayers } from 'components/ImportPlayers/ImportPlayers';
import { InfoModalContent } from 'components/InfoModalContent/InfoModalContent';
import { arrayToMapByAttribute } from 'adapters/arrayToMapByAttribute';
import { AlertModalContent } from 'components/AlertModalContent/AlertModalContent';
import { MemberModalContent } from 'components/MemberModalContent/MemberModalContent';
import { useModalContext } from 'context/ModalContext';
import { useApi } from 'hooks/useApi';
import { useMobileMediaQuery } from 'hooks/useMobileMediaQuery';
import { calculateStatusClassName } from 'helpers/calculateStatusClassName';
import { getUserMembership } from 'helpers/getUserMembership';
import { changeMemberStatusInClub } from 'usecases/club/changeMemberStatusInClub';
import { ClubVerification } from 'usecases/types/enums/club_verification.enum';
import { filterMembers } from './filterMembers';
import { sortMembersDescByDate } from './sortMembersDescByDate';
import { ClubMembersExplanation } from './ClubMembersExplanation';
import { changeUserRoleInClub } from './changeUserRoleInClub';
import './ClubMembers.scss';

export const ClubMembers = () => {
    const {
        isSpinnerVisible, antdLocaleObject,
    } = useAppContext();
    const { locale: antdLocale } = antdLocaleObject;
    const { setModalContentProps, setModalContent } = useModalContext();
    const callApi = useApi();
    const { isMobile } = useMobileMediaQuery();

    const [members, setMembers] = useState<IUser[]>([]);
    const [nameFilterValue, setNameFilterValue] = useState<string>('');
    const [roleFilterValue, setRoleFilterValue] = useState<PlayerRole | ''>('');
    const [statusFilterValue, setStatusFilterValue] = useState<string>('');
    const club = getClubFromStorage();

    const successModalTexts: IModalTexts = {
        successCreateHeader: translate('club_members_success-modal_title-create'),
        successEditHeader: translate('club_members_success-modal_title-edit'),
        successDeleteHeader: translate('club_members_success-delete-modal_header'),
        firstButtonSuccess: translate('club_members_success-modal_first-button'),
        secondButtonSuccess: translate('club_members_success-modal_second-button'),
        secondButtonDeleteSuccess: translate('club_members_success-delete-second-button'),
    };
    const headers: IListTableHeaderField[] = [
        {
            field: 'avatar',
            value: translate('club_members-table_header-avatar'),
        },
        {
            field: 'name',
            value: translate('club_members-table_header-name'),
            sortable: true,
        },
        {
            field: 'surname',
            value: translate('club_members-table_header-surname'),
            sortable: true,
        },
        {
            field: 'email',
            value: translate('club_members-table_header-email'),
            isLarge: true,
        },
        {
            field: 'role',
            value: translate('club_members-table_header-role'),
        },
        {
            field: 'registerDate',
            value: translate('club_members-table_header-register-date'),
            sortable: true,
        },
        {
            field: 'status',
            value: translate('club_members-table_header-status'),
        },
        {
            field: '',
            value: '',
        },
    ];

    const getFirstButtonSuccesModalOnClickActions = (action: ListAPIAction, member?: IUser): () => void => {
        if (action === ListAPIAction.create || action === ListAPIAction.edit) {
            return () => {
                setModalContentProps({
                    closable: false,
                });
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                setModalContent(getMemberModalContent(ListAPIAction.edit, member));
            };
        }

        return () => { };
    };

    const getSuccessModalContent = (apiAction: ListAPIAction, member?: IUser): JSX.Element => {
        const { header, firstButton, secondButton } = getSuccessModalTexts(apiAction, successModalTexts);

        return (
            <AlertModalContent
                type="success"
                header={header}
                seeDetailsText={firstButton}
                goBackText={secondButton}
                onSeeDetails={getFirstButtonSuccesModalOnClickActions(apiAction, member)}
                onGoBack={() => setModalContent(null)}
            />
        );
    };

    const updateModifiedMemberState = (memberId: string, status: MEMBERSHIP_STATUS) => {
        const modifiedMemberIndex = members.findIndex(member => member.id === memberId);
        const memberToModify = members[modifiedMemberIndex];
        const currentMembership = getUserMembership(memberToModify, club)!;
        const newMembership = {
            ...currentMembership,
            status,
        };
        const otherMemberships = memberToModify.memberships.filter(
            membership => membership.clubId !== currentMembership.clubId,
        );

        setMembers([
            ...members.slice(0, modifiedMemberIndex),
            {
                ...members[modifiedMemberIndex],
                memberships: [
                    ...otherMemberships,
                    newMembership,
                ],
            },
            ...members.slice(modifiedMemberIndex + 1),
        ]);
    };

    const modifyMemberStatus = async (memberId: string, status: MEMBERSHIP_STATUS, role: PlayerRole) => {
        await callApi(changeMemberStatusInClub(memberId, club.id, status, role));

        notification.success({
            message: translate('success'),
            description: translate('club_members-user-changed-succesfully'),
        });

        updateModifiedMemberState(memberId, status);
    };

    const getButtonsElement = (member: IUser) => {
        const memberStatus = getUserMembership(member, club)!.status;
        const { role } = getUserMembership(member, club)!;
        return (
            <div className="club-members__item-actions">
                <Button
                    onClick={() => modifyMemberStatus(member.id, MEMBERSHIP_STATUS.ACTIVE, role)}
                    className="club-members__button club-members__button--activate"
                    disabled={memberStatus === MEMBERSHIP_STATUS.ACTIVE || isSpinnerVisible}
                    size="large"
                >
                    <CheckOutlined />
                    {translate('club_members-actions-activate')}
                </Button>

                <Button
                    onClick={() => modifyMemberStatus(member.id, MEMBERSHIP_STATUS.INACTIVE, role)}
                    className="club-members__button club-members__button--deactivate"
                    disabled={memberStatus === MEMBERSHIP_STATUS.INACTIVE || isSpinnerVisible}
                    size="large"
                >
                    <CloseOutlined />
                </Button>
            </div>
        );
    };

    const getStatusElement = (member: IUser) => {
        const memberStatus = getUserMembership(member, club)!.status;
        return (
            <div className={`club-members__item-status ${calculateStatusClassName(memberStatus)}`}>
                {translate(`user-status_${memberStatus.toLowerCase()}`)}
            </div>
        );
    };

    const changeStatusFilterValue = (value: string): void => {
        setStatusFilterValue(value);
    };

    const getNameFilterOption = (inputValue: string, option: any): any => option.value
        .toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

    const onChangeNameFilter = (value: string): void => {
        setNameFilterValue(value);
    };

    const sortBy = (field: string, order?: 'asc' | 'desc'): void => {
        const descSortedMembers = members.sort((current, next) => {
            if (field === 'name') {
                return current.name.localeCompare(
                    next.name,
                    undefined,
                    {
                        numeric: true,
                        sensitivity: 'base',
                    },
                );
            }
            if (field === 'surname') {
                return current.surname.localeCompare(
                    next.surname,
                    undefined,
                    {
                        numeric: true,
                        sensitivity: 'base',
                    },
                );
            }
            if (field === 'registerDate') {
                return new Date(current.registerDate).getTime() - new Date(next.registerDate).getTime();
            }
            return 0;
        });
        const ascSortedMembers = JSON.parse(JSON.stringify(descSortedMembers)).reverse();
        const sortedMembers = order === 'asc' ? ascSortedMembers : descSortedMembers;
        setMembers([...sortedMembers]);
    };

    const getMembersDataFromAPI = async (): Promise<void> => {
        const { id } = club;
        const memberList = await callApi(getClubMembers(id));
        const sortedMembers = sortMembersDescByDate(memberList);
        setMembers(sortedMembers);
    };

    const onCreateMember = async ({
        name,
        surname,
        gender,
        language,
        role,
        street,
        number,
        zipCode,
        city,
        country,
        email,
    }: IMemberForm): Promise<void> => {
        const address = getAddressFromParameters(country, city, street, number, zipCode);
        const newMember = await callApi(createClubPlayer({
            clubId: club.id,
            name,
            surname,
            gender,
            language,
            role,
            email,
            address,
        }));

        setMembers(sortMembersDescByDate([...members, newMember]));
        setModalContentProps({
            closable: false,
        });
        setModalContent(getSuccessModalContent(ListAPIAction.create, newMember));
    };

    const onEditMember = async ({
        name,
        surname,
        gender,
        language,
        role,
        street,
        number,
        zipCode,
        city,
        country,
    }: IMemberForm, member: IUser): Promise<void> => {
        const address = getAddressFromParameters(country, city, street, number, zipCode);
        const currentRole = getUserMembership(member, club)!.role;
        const userToModify = {
            clubId: club.id,
            name,
            surname,
            gender,
            language,
            role,
            address,
        };

        await callApi(updateClubPlayer({ ...userToModify, playerId: member.id, oldRole: currentRole })).then(() => {
            const mappedState = arrayToMapByAttribute(members);
            const editedMember = changeUserRoleInClub({ ...member, ...userToModify }, role, club.id);

            const mappedStateEdited = {
                ...mappedState,
                [member.id]: editedMember,
            };
            setMembers(Object.values(mappedStateEdited));
            setModalContentProps({
                closable: false,
            });
            setModalContent(getSuccessModalContent(ListAPIAction.edit, mappedStateEdited[member.id]));
        });
    };

    const getMemberModalContent = (apiAction: ListAPIAction, member?: IUser): JSX.Element => (
        <MemberModalContent
            title={apiAction === ListAPIAction.edit
                ? translate('club_members_modal_edit-title')
                : translate('club_members_modal_create-title')}
            onProceed={apiAction === ListAPIAction.edit
                ? (memberForms: IMemberForm) => onEditMember(memberForms, member!) : onCreateMember}
            onCancel={() => {
                setModalContent(null);
            }}
            member={member}
        />
    );

    const getListRows = (_members: IUser[]): any[][] => {
        const filteredMembers = filterMembers(
            _members,
            statusFilterValue,
            nameFilterValue,
            roleFilterValue,
            club,
        );

        return filteredMembers.map(member => [
            member.avatar as string,
            member.name,
            member.surname,
            {
                value: member.email,
                isLarge: true,
            },
            translate(`role_${getUserMembership(member, club)!.role}`),
            dateFormatter(new Date(member.registerDate), antdLocale),
            getStatusElement(member),
            getButtonsElement(member),
            <ActionElements
                firstButtonOnClick={() => {
                    setModalContentProps({
                        closable: !!isMobile,
                        onCancel: () => setModalContent(null),
                    });
                    setModalContent(getMemberModalContent(ListAPIAction.edit, member));
                }}
            />,
        ]);
    };

    const onImportSuccessful = () => {
        getMembersDataFromAPI();
    };

    useEffect(() => {
        getMembersDataFromAPI();
    }, []);

    const clubMembersFilters = (
        <ClubMembersFilters
            nameFilterValue={nameFilterValue}
            nameFilterOptions={members.map(member => ({
                label: `${member.name} ${member.surname}`,
                value: `${member.name} ${member.surname}`,
            }))}
            getNameFilterOption={getNameFilterOption}
            onChangeNameFilter={onChangeNameFilter}
            setRoleFilterValue={setRoleFilterValue}
            changeStatusFilterValue={changeStatusFilterValue}
        />
    );

    return (
        <>
            <ListPageHeader header={translate('club_members_title')} iconUrl={FACILITY_ICON_MEMBERS}>
                <ListPageButton
                    onClick={() => {
                        setModalContentProps({
                            closable: true,
                            onCancel: () => setModalContent(null),
                        });
                        setModalContent(
                            <InfoModalContent>
                                <ClubMembersExplanation />
                            </InfoModalContent>,
                        );
                    }}
                    grey
                    icon="?"
                    roundedIcon
                >
                    {translate('club_members_header-first-button')}
                </ListPageButton>
                <ListPageButton
                    icon="+"
                    disabled={club.verified === ClubVerification.REVOKED}
                    onClick={() => {
                        setModalContentProps({
                            closable: !!isMobile,
                            onCancel: () => setModalContent(null),
                        });
                        setModalContent(getMemberModalContent(ListAPIAction.create, undefined));
                    }}
                >
                    {translate('club_members_header-second-button')}
                </ListPageButton>
                <ListPageButton
                    icon="+"
                    disabled={club.verified === ClubVerification.REVOKED}
                    onClick={() => {
                        setModalContentProps({
                            closable: true,
                            onCancel: () => setModalContent(null),
                        });
                        setModalContent(<ImportPlayers onImportSuccessful={onImportSuccessful} />);
                    }}
                >
                    {translate('club_members_header-import-button')}
                </ListPageButton>
            </ListPageHeader>
            <div className="club-members__container">
                {!isMobile && clubMembersFilters}

                {members.length > 0 && (
                    <ListTable
                        rows={getListRows(members)}
                        sortBy={sortBy}
                        headers={headers}
                    />
                )}
            </div>

            {isMobile && <MobileFooter content={clubMembersFilters} />}
        </>
    );
};
