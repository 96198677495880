import React, {
    useEffect, useState,
} from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import { translate } from 'translations/translate';
import Icon from '@ant-design/icons';
import { CALENDAR_ICON_GRAY } from 'config/constants';
import { dateFormatter } from 'adapters/dateFormatter';
import { useAppContext } from 'context/AppContext';
import { CHDateTime } from 'helpers/DateTime/CHDateTime';
import { CHAutoComplete, CHSelect } from 'components/TopRowFilters';
import {
    InvoiceStatus, PlayerRole, PLAYERROLECLIENT, PLAYERROLEMEMBER,
} from 'types';
import './InvoicesFilters.scss';

interface IProps {
    onDateRangeChange: (from: CHDateTime, to: CHDateTime) => void;
    onNameFilterChange: (value: string) => void;
    onStatusFilterChange: (value: InvoiceStatus | '') => void;
    onRoleFilterChange: (value: PlayerRole | '') => void;
    onInvoiceNumberChange: (value: string) => void;
    onPaymentFilterChange: (value: string | '') => void;
    nameFilterOptions: string[];
    invoiceNumberOptions: string[];
}

export const InvoicesFilters = ({
    onDateRangeChange, onNameFilterChange, onStatusFilterChange, onRoleFilterChange,
    onInvoiceNumberChange, onPaymentFilterChange, nameFilterOptions, invoiceNumberOptions,
}: IProps): JSX.Element => {
    const { antdLocaleObject: { locale: antdLocale } } = useAppContext();
    const today = new CHDateTime();
    const todayStartOfDay = today.startOfDay();
    const todayEndOfDay = today.endOfDay();
    const [from, setFrom] = useState<CHDateTime | null>(todayStartOfDay);
    const [to, setTo] = useState<CHDateTime | null>(todayEndOfDay);

    useEffect(
        () => {
            if (from !== null && to !== null) {
                onDateRangeChange(from, to);
            }
        },
        [from, to],
    );

    const MemoizedIcon = React.useCallback(() => (
        <img
            className="season-modal__calendar-icon"
            src={CALENDAR_ICON_GRAY}
            alt="close_icon_white"
        />
    ), []);

    return (
        <div className="invoices-filters">
            <div className="invoices-filters__filter">
                <DatePicker
                    value={moment(from?.toNativeDate())}
                    inputReadOnly
                    bordered={false}
                    placeholder={translate('invoices-page_filter-start')}
                    format={date => dateFormatter(date.toDate(), antdLocale)}
                    onChange={value => setFrom(value !== null ? CHDateTime.fromNativeDate((value.toDate())) : null)}
                    className="invoices-filters__picker-input"
                    suffixIcon={<Icon component={MemoizedIcon} />}
                    allowClear={false}
                    disabledDate={value => to !== null && value.valueOf() > to.getTimestamp()}
                />
            </div>
            <div className="invoices-filters__filter">
                <DatePicker
                    value={moment(to?.toNativeDate())}
                    inputReadOnly
                    bordered={false}
                    placeholder={translate('invoices-page_filter-end')}
                    format={date => dateFormatter(date.toDate(), antdLocale)}
                    onChange={value => setTo(value !== null ? CHDateTime.fromNativeDate((value.toDate())) : null)}
                    className="invoices-filters__picker-input"
                    suffixIcon={<Icon component={MemoizedIcon} />}
                    allowClear={false}
                    disabledDate={value => from !== null && value.valueOf() < from.getTimestamp()}
                />
            </div>
            <CHSelect
                onChange={value => {
                    onRoleFilterChange(value === '' ? '' : parseInt(value, 10) as PlayerRole);
                }}
                placeholder={translate('invoices-page_filter-role')}
                options={[
                    { value: PLAYERROLEMEMBER, label: translate(`role_${PLAYERROLEMEMBER}`) },
                    { value: PLAYERROLECLIENT, label: translate(`role_${PLAYERROLECLIENT}`) },
                    { value: '', label: translate('role_all') },
                ]}
            />
            <CHSelect
                onChange={value => onStatusFilterChange(value as InvoiceStatus | '')}
                placeholder={translate('invoices-page_filter-status')}
                options={[
                    { value: InvoiceStatus.UNPAID, label: translate('invoice_UNPAID') },
                    { value: InvoiceStatus.PAID, label: translate('invoice_PAID') },
                    { value: InvoiceStatus.CANCELLED, label: translate('invoice_CANCELLED') },
                    { value: '', label: translate('invoices-page_filter-status_all') },
                ]}
            />

            <CHSelect
                onChange={value => onPaymentFilterChange(value as 'cash' | '')}
                placeholder={translate('invoices-page_filter-payment-method_placeholder')}
                options={[
                    { value: 'cash', label: translate('invoice_CASH') },
                    { value: 'card', label: translate('invoice_ONLINE') },
                    { value: '', label: translate('invoices-page_filter-status_all') },
                ]}
            />

            <CHAutoComplete
                options={nameFilterOptions}
                onChange={onNameFilterChange}
                placeholder={translate('invoices-page_filter-name')}
            />
            <CHAutoComplete
                options={invoiceNumberOptions}
                onChange={onInvoiceNumberChange}
                placeholder={translate('invoices-page_filter-invoice-number')}
            />

        </div>
    );
};
