import React from 'react';
import { translate } from 'translations/translate';
import { LegalAgreement } from '../LegalAgreement/LegalAgreement';

export const PrivacyPolicy = ({ route } : {route: string}) => (
    <LegalAgreement
        text1={translate('user_register-privacy-policy-agreement')}
        linkText={translate('privacy_policy')}
        linkRoute={route}
        text2={translate('user_register-privacy-policy-agreement2')}
    />
);
