import React from 'react';
import { translate } from 'translations/translate';
import {
    PlayerRole, PLAYERROLEADMIN, PLAYERROLEMEMBER, PLAYERROLECLIENT,
} from 'types';
import './ListRoleItem.scss';

interface IProps {
    role: PlayerRole;
}
const roleClassModifiers = {
    [PLAYERROLEADMIN]: '--admin',
    [PLAYERROLEMEMBER]: '--member',
    [PLAYERROLECLIENT]: '--client',
};

export const ListRoleItem = ({ role }: IProps): JSX.Element => (
    <div className={`list-role-item list-role-item${roleClassModifiers[role]}`}>
        <div className="list-role-item__circle" />
        <div className="list-role-item__text">
            {translate(`role_${role}`)}
        </div>
    </div>
);
