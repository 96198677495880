export const DEFAULT_AVATAR = `${process.env.PUBLIC_URL}/assets/user-default.svg`;
export const DEFAULT_AVATAR_BLACK = `${process.env.PUBLIC_URL}/assets/user-default_BLACK.svg`;
export const PLUS_ICON = './assets/icons/plus.png';
export const PLUS_ICON_GRAY = './assets/icons/plus-icon-gray.svg';
export const MINUS_ICON = './assets/icons/minus.png';
export const LOGO = `${process.env.PUBLIC_URL}/assets/default-club-logo.svg`;
export const LOGO_WHITE = `${process.env.PUBLIC_URL}/assets/logo_WHITE.svg`;
export const LOGO_BLACK = `${process.env.PUBLIC_URL}/assets/logo_BLACK.svg`;
export const LOGO_TEXTLESS = `${process.env.PUBLIC_URL}/assets/logo_TEXTLESS.svg`;
export const LOGO_RED = `${process.env.PUBLIC_URL}/assets/logo_RGB.png`;
export const CLOSE_ICON = `${process.env.PUBLIC_URL}/assets/icons/close.png`;
export const CLOSE_ICON_GRAY = `${process.env.PUBLIC_URL}/assets/icons/close_GRAY.png`;
export const CLOSE_ICON_WHITE = `${process.env.PUBLIC_URL}/assets/icons/close_WHITE.png`;
export const ACCOUNT_ICON = `${process.env.PUBLIC_URL}/assets/icons/menu-icon_account_GRAY.svg`;
export const ACCOUNT_ICON_WHITE = `${process.env.PUBLIC_URL}/assets/icons/menu-icon_account_WHITE.svg`;
export const ACCOUNT_ICON_DARK_GRAY = `${process.env.PUBLIC_URL}/assets/icons/menu-icon_account.svg`;
export const ADD_PLAYER = `${process.env.PUBLIC_URL}/assets/icons/add-player.png`;
export const ADD_PLAYER_GRAY = `${process.env.PUBLIC_URL}/assets/icons/add-player_GRAY.png`;
export const ADD_PLAYER_GRAY2 = `${process.env.PUBLIC_URL}/assets/icons/add-player_GRAY2.png`;
export const MONEY_ICON_GRAY = `${process.env.PUBLIC_URL}/assets/icons/menu-icon_book-keeping_GRAY.svg`;
export const MONEY_ICON = `${process.env.PUBLIC_URL}/assets/icons/menu-icon_book-keeping.svg`;
export const MONEY_ICON_WHITE = `${process.env.PUBLIC_URL}/assets/icons/menu-icon_book-keeping_WHITE.svg`;
export const CHECK_ICON = `${process.env.PUBLIC_URL}/assets/icons/check.svg`;
export const WARNING_ICON = `${process.env.PUBLIC_URL}/assets/icons/warning.svg`;
export const DASHBOARD_ICON = `${process.env.PUBLIC_URL}/assets/icons/menu-icon_dashboard.svg`;
export const DASHBOARD_ICON_GRAY = `${process.env.PUBLIC_URL}/assets/icons/menu-icon_dashboard_GRAY.svg`;
export const DASHBOARD_ICON_WHITE = `${process.env.PUBLIC_URL}/assets/icons/menu-icon_dashboard_WHITE.svg`;
export const RESERVATION_ICON = `${process.env.PUBLIC_URL}/assets/icons/menu-icon_reservation.svg`;
export const RESERVATION_ICON_GRAY = `${process.env.PUBLIC_URL}/assets/icons/menu-icon_reservation_GRAY.svg`;
export const RESERVATION_ICON_WHITE = `${process.env.PUBLIC_URL}/assets/icons/menu-icon_reservation_WHITE.svg`;
export const FACILITY_ICON = `${process.env.PUBLIC_URL}/assets/icons/menu-icon_my-facility.svg`;
export const FACILITY_ICON_GRAY = `${process.env.PUBLIC_URL}/assets/icons/menu-icon_my-facility_GRAY.svg`;
export const FACILITY_ICON_WHITE = `${process.env.PUBLIC_URL}/assets/icons/menu-icon_my-facility_WHITE.svg`;
export const FACILITY_ICON_COURTS = `${process.env.PUBLIC_URL}/assets/icons/menu-icon_my-facility_COURTS.svg`;
export const FACILITY_ICON_MEMBERS = `${process.env.PUBLIC_URL}/assets/icons/menu-icon_my-facility_MEMBERS.svg`;
export const FACILITY_ICON_PRICE = `${process.env.PUBLIC_URL}/assets/icons/menu-icon_my-facility_PRICE.svg`;
export const FACILITY_ICON_SEASONS = `${process.env.PUBLIC_URL}/assets/icons/menu-icon_my-facility_SEASONS.svg`;
export const COURTS_ICON_BLACK = `${process.env.PUBLIC_URL}/assets/icons/icon_club_BLACK.svg`;
export const COURTS_ICON_GRAY = `${process.env.PUBLIC_URL}/assets/icons/icon_club_GRAY.svg`;
export const CALENDAR_ICON = `${process.env.PUBLIC_URL}/assets/icons/icon-calendar.svg`;
export const CALENDAR_ICON_GRAY = `${process.env.PUBLIC_URL}/assets/icons/icon-calendar.svg`;
export const COURT_CARPET_ICON = `${process.env.PUBLIC_URL}/assets/icons/court-carpet.svg`;
export const COURT_CLAY_ICON = `${process.env.PUBLIC_URL}/assets/icons/court-clay.svg`;
export const COURT_GRASS_ICON = `${process.env.PUBLIC_URL}/assets/icons/court-grass.svg`;
export const COURT_HARD_ICON = `${process.env.PUBLIC_URL}/assets/icons/court-hard.svg`;
export const ALL_VENUES_ICON = `${process.env.PUBLIC_URL}/assets/icons/all-venues.svg`;
export const COURT_DEFAULT_ICON = `${process.env.PUBLIC_URL}/assets/icons/court-default.svg`;
export const DELETE_ICON = `${process.env.PUBLIC_URL}/assets/icons/icon_delete.svg`;
export const DELETE_ICON_GRAY = `${process.env.PUBLIC_URL}/assets/icons/icon_delete_GRAY.svg`;
export const DELETE_ICON_WHITE = `${process.env.PUBLIC_URL}/assets/icons/icon_delete_WHITE.svg`;
export const EDIT_ICON = `${process.env.PUBLIC_URL}/assets/icons/icon_edit.svg`;
export const EDIT_ICON_GRAY = `${process.env.PUBLIC_URL}/assets/icons/icon_edit_GRAY.svg`;
export const EDIT_ICON_WHITE = `${process.env.PUBLIC_URL}/assets/icons/icon_edit_WHITE.svg`;
export const CSV_EXAMPLE_FILE = `${process.env.PUBLIC_URL}/assets/files/players_import_example.csv`;
export const BOOKKEEPING_ICON_INVOICES = `${process.env.PUBLIC_URL}/assets/icons/bookkeeping-icon_invoices.svg`;
export const BOOKKEEPING_ICON_SETTLEMENTS = `${process.env.PUBLIC_URL}/assets/icons/bookkeping-icon_settlements.svg`;
export const EUR_CURRENCY = 'EUR';
export const OUT_SEASON_BG_COLOR = '#444444';
export const FULLCALLENDAR_LICENSE_KEY = '0541873899-fcs-1647944809';
export const MIDDLE_DOT = '\u2022';
export const DEFAULT_BOOOKING_DURATION = 60;
export const MOBILE_MEDIAQUERY = '(max-width: 1024px)';
