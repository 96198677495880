/* eslint-disable max-len */
export const deDE: { [key: string]: string } = {
    success: 'Erfolg',
    error: 'Fehler',
    x_hours: '__hours__ Stunden',
    x_hour: '__hours__ Stunde',
    x_minutes: '__minutes__ Minuten',
    x_hours_and_y_minutes: '__hours__ Stunden und __minutes__ Minuten',
    x_hour_and_y_minutes: '__hours__ Stunde und __minutes__ Minuten',
    privacy_policy: 'Datenschutzerklärung',
    terms_and_conditions: 'Nutzungsbedingungen',
    imprint: 'Impressum',

    // Error messages
    // Global - 000
    'error-000001': 'Ungültige Anfrage: __message__',
    // User - 001
    'error-001001': 'Das Passwort sollte mindestens __minLength__ Zeichen lang sein.',
    'error-001002': 'Der Nutzer mit der ID __id__ wurde im System nicht gefunden.',
    'error-001003': 'Die E-Mail-Adresse __email__ wurde nicht gefunden.',
    'error-001004': 'Ungültige E-Mail-Adresse: __email__',
    'error-001005': 'Das Geschlecht __gender__ ist nicht zulässig.',
    'error-001006': 'Die Sprache __language__ wird nicht unterstützt.',
    'error-001007': 'Falsches Passwort',
    'error-001008': 'Altes und neues Passwort stimmen nicht überein.',
    'error-001009': 'Der angegebene Kontotyp wird nicht unterstützt.',
    'error-001010': 'Ungültiges Google-Token',
    'error-001011': 'Das Aktualisierungs-Token stimmt nicht mit dem angegebenen Nutzer überein.',
    'error-001012': 'Ungültige Anmeldeinformationen. Bitte achte auf die korrekte Schreibweise.',
    'error-001013': 'Der Nutzer existiert bereits.',
    'error-001014': 'Bitte alle Addressfelder ausfüllen. Fehlendes Feld: __missingField__',
    'error-001015': 'Diese Rolle __role__ wird nicht unterstützt.',
    'error-001016': 'Dieses Land __country__ wird nicht unterstützt.',
    'error-001017': 'Du musst Deine Registrierung zuerst bestätigen, um Dich anmelden zu können. Bitte klicke dazu auf den “Bitte Bestätigen” Button in der Willkommens-E-Mail.',
    // Facility - 002
    'error-002001': 'Der Verein mit der ID __id__ wurde im System nicht gefunden.',
    'error-002002': 'Der Vereinsname darf nicht leer sein.',
    'error-002003': 'Der angemeldete Nutzer ist nicht der Admin des Vereins __club__',
    'error-002004': 'Die Datei muss weniger als __limit__ Spieler haben.',
    'error-002005': 'Die Zeile __row__ hat kein gültiges Format.',
    'error-002006': 'Um fortzufahren, hinterlege bitte zunächst die Abrechnungsdaten.',
    'error-002008': 'Es gibt bereits eine Anlage mit diesem Namen.',
    'error-002009': 'Du hast nicht die erforderliche Berechtigung, um die Rolle des Spielers \'__user__\' zu ändern.',
    'error-002010': 'Token __token__ is no longer valid.',
    'error-002011': 'Die Online-Zahlung kann nicht aktiviert werden, da der Einrichtungsprozess noch nicht abgeschlossen wurde.',
    'error-002012': 'Um fortzufahren, hinterlege bitte zunächst die Abrechnungsdaten.',
    'error-002013': 'Die Anlage kann nicht deaktiviert werden, da sie zukünftige Buchungen hat. Bitte gehe zum Kalender und storniere alle zukünftigen Buchungen, bevor Du die Anlage deaktivierst.',
    'error-002015': 'Mindestens eine der beiden Zahlungsmethoden muss aktiviert sein.',
    'error-002016': 'Um fortzufahren, hinterlege bitte zunächst die Abrechnungsdaten.',
    'error-002017': 'Du kannst die Abrechnungsdaten nicht entfernen, solange Online-Zahlungen aktiviert sind.',
    'error-002018': 'Du kannst dieser Anlage nicht beitreten, da diese nicht verifiziert ist.',
    'error-002019': 'Deine Anlage ist nicht verifiziert. Somit kannst Du keine Spieler erstellen oder importieren. Bitte kontatkiere courthero@tennis-point.de um Deine Anlage zu verifizieren.',
    // Court - 003
    'error-003001': 'Der Platz mit der ID __id__ wurde im System nicht gefunden.',
    'error-003002': '__type__ ist kein gültiger Platzbelag.',
    'error-003003': '__type__ ist kein gültiger Spielort.',
    // Reservation - 004
    'error-004001': 'Die Buchung mit der ID __id__ wurde im System nicht gefunden.',
    'error-004002': 'Der Nutzer hat keine Berechtigung, diese Reservierung zu ändern.',
    'error-004003': 'Diese Reservierung gehört nicht zum Verein __club__.',
    'error-004004': 'Frühere Reservierungen können nicht mehr geändert werden.',
    'error-004005': 'Nur der Admin des Vereins kann den Hautspieler einer Buchung ändern.',
    'error-004006': 'Es sind keine Buchungen außerhalb der Öffnungszeiten des Platzes möglich.',
    'error-004007': 'Der Platz ist in der ausgewählten Zeit bereits gebucht.',
    'error-004008': 'Der Platz kann nicht mehr als __days__ Tage im Voraus gebucht werden.',
    'error-004009': 'Eine Buchung in der Vergangenheit ist nicht möglich.',
    'error-004010': 'Die maximale Buchungsdauer für 3 oder mehr Spieler beträgt __minutes__ Minuten.',
    'error-004011': 'Die maximale Buchungsdauer für 2 Spieler beträgt __minutes__ Minuten.',
    'error-004012': 'Du kannst nicht mehr als __maximumActiveBookings__ langfristige Buchungen gleichzeitig haben.',
    'error-004013': 'Du kannst nicht mehr als __maximumActiveBookings__ kurzfristige Buchung in den nächsten __minutes__ Minuten haben.',
    'error-004014': 'Du kannst noch keinen Platz buchen, da dein Konto noch nicht vom Vereinsadministrator freigeschaltet wurde.',
    'error-004015': 'Ungültiges Zeitintervall für den Reservierungsfilter',
    'error-004016': 'Ungültiges Zeitintervall für die Reservierung.',
    'error-004017': 'Der Nutzer __adminName__ hat keine Berechtigung die Buchung im Namen von __userName__ durchzuführen.',
    'error-004018-admin': 'Dieser Buchungszeitraum kann nicht gebucht werden, da noch kein Preis festgelegt worden ist.',
    'error-004018-player': 'Dieser Buchungszeitraum kann nicht gebucht werden, da vom Vereinsadmin noch kein Preis festgelegt worden ist.',
    'error-004019': 'Du kannst keinen Platz außerhalb der aktiven Saison buchen.',
    'error-004020': 'Deine Buchung konnte nicht durchgeführt werden, da sich der Preis in der Zwischenzeit geändert hat. Bitte lade die Seite neu.',
    'error-004021': 'Du musst mindestens einen Mitspieler (entweder Mitglied oder Gastspieler) hinzufügen, um eine Buchung vorzunehmen.',
    'error-004022': 'Du kannst keinen Platz mit einem Spieler buchen, der bereits in den letzten __minimumAmountOfMinutesBetweenBookings__ Minuten einen Platz gebucht hat.',
    'error-004023': 'Zwischen zwei Buchungen müssen minds. __minimumAmountOfMinutesBetweenBookings__ Minuten liegen. Jedoch kannst du ab 5 Minuten vor dem Ende deiner aktiven Buchung weitere 30 Minuten buchen.',
    'error-004025': 'Mindestens einer der Termine der Buchung ist nicht verfügbar.',
    'error-004027': 'Bitte wende Dich an den Admin dieser Anlage, wenn Du das Abo stornieren möchtest.',
    'error-004028': 'Du hast für Deine Buchung nicht bezahlt. Bitte storniere sie im Kalender. Andernfalls wird die Buchung nach 30 Minuten automatisch storniert.',
    'error-004029': 'Die Buchung konnte nicht storniert werden, weil die Stornierungsfrist von 6 Stunden abgelaufen ist.',
    'error-004033': 'Du kannst diese Buchung nicht online bezahlen, da der Gesamtbetrag minds. 0,50 € betragen muss.',
    'error-004034': 'Die Anlage wurde deaktiviert und Du kannst keine Buchungen mehr vornehmen.',
    'error-004035': 'Deine Anlage ist nicht verifiziert und Buchungen sind somit nicht möglich. Bitte kontaktiere courthero@tennis-point.de um Deine Anlage zu verifizieren.',
    'error-004036': 'Eine Buchung ist nicht möglich, da die Barzahlung als Zahlungsmittel durch den Anlagenbetreiber dekaktiviert wurde.',
    'error-004037': 'Eine Buchung ist nicht möglich, da die Online-Zahlung als Zahlungsmittel durch den Anlagenbetreiber entfernt wurde.',

    // Time - 005
    'error-005001': '__time__ ist keine gültige Zeitangabe.',
    // Season - 006
    'error-006001': 'Die Saison mit der ID __id__ wurde nicht gefunden.',
    'error-006002': 'Startdatum __startDate__ oder Enddatum __endDate__ haben kein gültiges Format.',
    'error-006003': 'Ungültiges Zeitintervall für die Saison.',
    'error-006004': 'Die Saisons dürfen sich zeitlich nicht überschneiden.',
    // Pricing Rule - 007
    'error-007001': 'Der Platz __courtSelected__ ist in der __season__ nicht verfügbar. Die verfügbaren Plätze sind __courtsAvailable__ ',
    'error-007002': 'Die Preisregel mit der ID __id__ wurde nicht gefunden.',
    'error-007003': 'Die Preisregel __pricingRule__ hat eine zeitliche Überschneidung mit dieser Preisregel. Bitte passe eine der beiden Preisregeln an um fortzufahren.',
    // Invoice - 008
    'error-008004': 'Eine Abo-Rechnung kann hier nicht storniert werden. Du musst das gesamte Abo über den Kalender stornieren, um die Rechnung zu stornieren.',
    // External service - 010
    'error-010001': 'Die Datei konnte nicht hochgeladen werden.',
    'error-010002': 'Die Datei überschreitet die maximale Größe von 2MB.',
    'error-010003': 'Die Datei konnte nicht gelöscht werden.',
    'error-010004': 'Die E-Mail konnte nicht gesendet werden.',
    'error-010006': 'Der Prozess der Zahlungseinrichtung wurde unerwartet geschlossen. Du kannst ihn im Abschnitt “Online-Bezahlung” auf der Seite der Anlageneinstellungen wieder aufnehmen.',
    'error-010999': 'Ein Fehler ist aufgetreten: __message__',

    'menu-profile': 'Konto',
    'menu-settings': 'Einstellungen',
    'menu-logout': 'Abmelden',
    'menu-facilities': 'Spielorte',

    // User status
    'user-status_pending': 'AUSSTEHEND',
    'user-status_active': 'AKTIV',
    'user-status_inactive': 'INAKTIV',
    'user-status_all': 'ALLE',

    or: 'oder',
    'sign-up': 'Konto erstellen',
    'e-mail': 'E-Mail',
    password: 'Passwort',
    'remember-me': 'Angemeldet bleiben',
    'forgot-your-password': 'Passwort vergessen?',

    'password-changed-successfully': 'Passwort erfolgreich geändert',
    'your-password-has-been-successfully-changed': 'Dein Passwort wurde erfolgreich geändert.',
    'from-now-on-you-can-log-in-to-court-hero-with-your-new-password': 'Du kannst Dich ab sofort mit Deinem neuen Passwort bei Court Hero anmelden.',
    'go-to-login': 'Zur Anmeldung',
    'enter-your-new-password': 'Gib Dein neues Passwort ein.',
    'confirm-password': 'Passwort bestätigen',
    'change-password': 'Passwort ändern',
    'passwords-do-not-match': 'Passwörter stimmen nicht überein',

    'we-sent-you-an-email': 'Wir haben Dir eine E-Mail geschickt.',
    'you-have-successfully-registered-in-court-hero': 'Du hast erfolgreich ein Konto bei Court Hero erstellt.',
    'check-your-inbox-for-verification-link': 'Überprüfe Deinen Posteingang für den Bestätigungslink. Bitte überprüfe auch Deinen Spam-Ordner, solltest du die E-Mail zur Bestätigung nicht erhalten haben.',

    'email-verified-successfully-title': 'Vielen Dank für Deine Bestätigung.',
    'email-verified-successfully-text': 'Ab sofort kannst du dich bei Court Hero anmelden.',
    'the-email-could-not-be-confirmed': 'Die E-Mail konnte nicht bestätigt werden. Wenn Du den Link kopiert hast, überprüfe bitte, ob er vollständig kopiert wurde.',

    'page-not-found': 'Seite nicht gefunden',
    'this-page-does-not-exist': 'Diese Seite existiert nicht',
    'go-back': 'Zurück',

    'forgot-password': 'PASSWORT VERGESSEN?',
    'enter-your-email-to-recover-password': 'Bitte gib Deine E-Mail-Adresse ein, um Dein Passwort zurückzusetzen',
    'an-email-will-be-sent-to-reset-your-password': 'Es wird eine E-Mail mit einem Link an Dich versendet, mit welchem Du dein Passwort zurücksetzen und ein neues vergeben kannst',
    send: 'Senden',

    'account-settings': 'EINSTELLUNGEN',
    'user-profile-updated': 'Dein Profil wurde aktualisiert.',
    'profile-picture': 'Profilbild',
    'your-profile-picture-is-visible-to-everyone': 'Dein Profilbild ist für alle angemeldeten Nutzer sichtbar.',
    'edit-profile-picture': 'Profilbild bearbeiten',
    'delete-profile-picture': 'Profilbild löschen',
    'first-name': 'Vorname',
    'profile_card-first_name': 'VORNAME',
    'first-name-is-required': 'Bitte Vornamen eingeben.',
    'last-name': 'Nachname',
    'profile_card-last_name': 'NACHNAME',
    'last-name-is-required': 'Bitte Nachnamen eingeben.',
    'email-is-required': 'Bitte E-Mail-Adresse eingeben.',
    'club-name': 'Vereinsname',
    'club-name-is-required': 'Bitte Vereinsnamen eingeben.',
    'profile_card-gender': 'GESCHLECHT',
    'profile_card-language': 'SPRACHE',
    gender: 'Geschlecht',
    'gender-is-required': 'Bitte Geschlecht auswählen.',
    male: 'Männlich',
    female: 'Weiblich',
    other: 'Divers',
    language: 'Sprache',
    'language-is-required': 'Bitte Sprache auswählen.',
    role: 'Rolle',
    'role-is-required': 'Bitte Rolle auswählen.',
    role_0: 'Mitglied',
    role_1: 'Gast',
    role_2: 'Admin',
    role_all: 'Alle',
    'save-changes': 'Änderungen speichern',
    save: 'Speichern',
    delete: 'Löschen',
    'select-time': 'Zeit auswählen',
    'current-password': 'AKTUELLES PASSWORT',
    'password-is-required': 'Bitte Passwort eingeben.',
    'new-password': 'NEUES PASSWORT',
    'new-password-is-required': 'Bitte neues Passwort eingeben.',
    'repeat-new-password': 'BITTE NEUES PASSWORT ERNEUT EINGEBEN.',
    'repeat-password-is-required': 'Bitte neues Passwort erneut eingeben.',
    'terms-is-required': 'Bitte Nutzungsbedingungen akzeptieren.',
    'privacy-is-required': 'Bitte Datenschutzerklärung akzeptieren.',
    'reset-password': 'Passwort ändern',
    carpet: 'Teppich',
    clay: 'Sand',
    grass: 'Rasen',
    hard: 'Hart',
    indoor: 'Hallenplatz',
    outdoor: 'Außenplatz',
    'not-specified': 'keine Angabe',

    // Invoice Status
    invoice_UNPAID: 'unbezahlt',
    invoice_PAID: 'bezahlt',
    invoice_CANCELLED: 'storniert',

    // Invoice Payment Method
    invoice_CASH: 'Bar',
    invoice_ONLINE: 'Online-Zahlung',

    // User side menu links
    'user_menu-calendar': 'Kalender',
    'user_menu-my_facility': 'Meine Anlage',
    'user_menu-club': 'Anlage',

    // Register page
    'register-club_name': 'Name',
    'register-page_title': 'KONTO ERSTELLEN',
    'register-page_title_link': 'Anmeldung',
    'register-page_bottom-link': 'Anmeldung',

    // User register page
    'user-register_title': 'KONTO ERSTELLEN',
    'user-register_link': 'Anmeldung',
    'user-register_bottom-link': 'Anmeldung',
    'user_register-terms_and_conditions_agreement': 'Ja, ich stimme den',
    'user_register-terms_and_conditions_agreement2': 'zu.',
    'user_register-already-a-member': 'Konto bereits vorhanden?',
    'user_register-privacy-policy-agreement': 'Ich habe die', // Ich habe die Datenschutzerklärung gelesen und akzeptiere diese.
    'user_register-privacy-policy-agreement2': 'gelesen und akzeptiere diese.',
    'user_register-sign_up': 'Konto erstellen',

    // Calendar page
    'calendar_club-without-courts-notification-title': 'Dein Verein hat noch keine Plätze.',
    'calendar_club-without-courts-notification-body': 'Bitte den Vereinsadministrator Plätze zu erstellen, damit diese gebucht werden können.',
    calendar_header: 'Platzbuchung',
    'calendar_filter-today': 'heute',
    'calendar_filter-surface': 'Belag',
    'calendar_filter-floodlights': 'Flutlicht',
    'calendar_filter-inside-outside': 'Außenplatz / Hallenplatz',
    'calendar_display-selector-week': 'Woche',
    'calendar_display-selector-day': 'Tag',
    'calendar_court-reserved': 'Platz belegt',
    'calendar_no-owner-selected-error': 'Ein Hauptspieler muss ausgewählt werden.',
    calendar_from: 'ab',
    'calendar_court-feature-carpet': 'Teppich',
    'calendar_court-feature-roofed': 'überdacht',
    'calendar_court-feature-floodlight': 'Flutlicht',
    'calendar_reservation-confirmed-show-booking': 'Buchung anzeigen',
    'calendar_reservation-confirmed-back-to-calendar': 'Zurück zum Kalender',
    'calendar_info-modal_header': 'Das ausgewählte Zeitfenster liegt außerhalb einer Saison. Bitte wähle ein Zeitfenster mit einem weißen Hintergrund im Kalender aus.',
    'calendar_info-modal_second-button': 'Zurück zum Kalender',
    'calendar_info-no-courts-available': 'Das ausgewählte Datum liegt außerhalb einer erstellten Saison.',

    'calendar-reservation_dialog-add_player_placeholder': 'Spieler hinzufügen',
    'calendar-reservation_dialog-date_selector_label': 'Datum',
    'calendar-reservation_dialog-time_selector_label': 'Uhrzeit',
    'calendar-reservation_dialog-duration_selector_label': 'Dauer',
    'calendar-reservation_dialog-court_selector_label': 'Platz',
    'calendar-reservation_dialog-guests_list_title': 'Gäste',
    'calendar-reservation_dialog-add_guest_player': 'Gastspieler hinzufügen',
    'calendar-reservation_dialog-add_guest_modal_title': 'Gastspieler',
    'calendar-reservation_dialog-add_guest_modal_button': 'Änderungen speichern',
    'calendar-reservation_dialog-book_now': 'Jetzt kostenpflichtig buchen',
    'calendar-reservation_dialog-book_now_free_booking': 'Jetzt buchen',
    'calendar-reservation_dialog-email_notification_notice': 'Du erhältst eine Buchungsbestätigung per E-Mail.',
    'calendar-delete_reservation_admin_modal-footer': 'Bist du Dir sicher, dass Du Deine Buchung stornieren möchtest? Dadurch werden ggf. Rechungen storniert.',
    'calendar-delete_reservation_user_modal-footer': 'Bist du Dir sicher, dass Du Deine Buchung stornieren möchtest?',
    'calendar-delete_reservation_user_modal-cancellation_policy': 'Bitte beachte, dass kostenpflichtige Buchungen nur bis zu 6 Stunden vor Beginn storniert werden können.',
    'calendar-cancel_subscription_booking_modal-footer': 'Bist du Dir sicher, dass Du das Abo stornieren möchtest? Dadurch wird auch die Rechnung storniert.',
    'calendar-reservation_dialog-owner': 'Hauptspieler',
    'calendar-reservation_dialog-choose_an_owner': 'Wähle einen Hauptspieler',
    'calendar-reservation_dialog-you': 'Du',
    'calendar-reservation_dialog-players': 'Mitspieler',
    'calendar-reservation_dialog-choose_a_player': 'Wähle einen Mitspieler aus',
    'calendar-reservation_dialog-booking_successful': 'Buchung erfolgreich!',
    'calendar-reservation_dialog-booking_successful-delete': 'Buchung erfolgreich storniert!',
    'calendar-reservation_dialog-subscription_successful-delete': 'Abo erfolgreich storniert!',
    'calendar-reservation_dialog-book-modal_total': 'GESAMTBETRAG',
    'calendar-reservation_dialog-book-modal_fee-text': 'Mir ist bekannt, dass eine Gastgebühr von __fee__ anfällt.',
    'calendar-reservation_dialog-book-modal_fee-text2': 'Diese wird direkt mit dem Verein abgerechnet (z.B. Zahlung vor Ort oder Lastschriftverfahren).',
    'calendar-reservation_dialog-book-modal_fee-notice': 'In dem Gesamtbetrag ist eine Gastgebühr in Höhe von __feeAmount__ inkludiert.',
    'calendar-reservation_dialog-delete_booking': 'Buchung stornieren',
    'calendar-booking_dialog-cancel_subscription': 'Abo stornieren',
    'calendar-reservation_dialog-back': 'Zurück',
    'calendar-reservation_dialog-player-alt-image': 'Player image', // Translation missing
    'calendar-reservation_mobile-filters_open': 'Filter öffnen',
    'calendar-reservation_mobile-filters_close': 'Filter schließen',
    'calendar-reservation_admin_no-courts-no-active-season-step-one': 'Erstelle bitte im ersten Schritt deine Plätze, die für Spieler buchbar sein sollen.',
    'calendar-reservation_admin_no-courts-no-active-season-step-two': 'Im zweiten Schritt erstelle bitte eine Saison und ordne die gewünschten Plätze dieser Saison zu.',
    'calendar-reservation_admin_no-courts-no-active-season-step-three': 'Im dritten Schritt erstelle bitte deine Preisregeln für die zuvor erstellte Saison.',
    'calendar-reservation_admin_no-courts-no-active-season-step-four': 'Im letzten Schritt bearbeite bitte deine zuvor erstellte Saison und gebe die Saison zur Buchung durch Spieler frei.',
    'calendar-reservation_admin_no-courts-in-active-season': 'Bitte wähle die gewünschten Plätze für die aktive Saison aus.',
    'calendar-reservation_player_no-active-season': 'Du kannst leider noch keine Buchung vornehmen. Erst nachdem der Administrator der Anlage eine Saison erstellt und freigegeben hat, kannst du mit der Buchung von Plätzen beginnen.',
    'calendar-reservation_dialog-payment_type_title': 'Zahlungsmittel',
    'calendar-reservation_dialog-cash': 'Bar',
    'calendar-reservation_dialog-online': 'Online-Zahlung',
    'calendar-reservation_dialog-total-amount': 'GESAMTBETRAG',
    'calendar-reservation_dialog-description': 'BESCHREIBUNG',
    'calendar-reservation_dialog-booking-type': 'ART DER BUCHUNG',
    'calendar-reservation_dialog-description-placeholder': 'Beschreibungstext eingeben (optional)',
    'calendar-reservation_dialog-description-error': 'Bitte maximal 200 Zeichen verwenden.',
    'calendar-reservation_dialog-guest-error': 'Der Gastspieler ist bereits ausgewählt.',
    'calendar-reservation_dialog-recurring-booking': 'Wiederkehrende Buchung für aktuelle Saison',
    'calendar-reservation_dialog-subscription-booking': 'Abo für aktuelle Saison',
    'calendar-reservation_dialog-single-booking': 'Einzelbuchung',
    'calendar-reservation_dialog-recurring-booking-interval': 'Intervall',
    'calendar-reservation_dialog-recurring-booking-interval-weekly': 'wöchentlich',
    'calendar-reservation_dialog-recurring-booking-interval-biweekly': '2-wöchentlich',
    'calendar-reservation_dialog-recurring-booking-ending': 'Endet am',
    'calendar-reservation_dialog-delete_single_booking': 'Ausgewählte Buchung stornieren',
    'calendar-reservation_dialog-delete_recurring_booking': 'Alle Buchungen der wiederkehrenden Buchung stornieren',
    'calendar-reservation_dialog-cancellation_policy': 'Bitte beachte, dass kostenpflichtige Buchungen nur bis zu 6 Stunden vor Beginn storniert werden können.',
    'calendar_reservation-players_roles_members': 'Mitglieder',
    'calendar_reservation-players_roles_guests': 'Gäste',
    'calendar_reservation-players_roles_mixed': 'Mitglieder + Gastspieler',

    // Membership needs to be activated notice
    'membership_activation_notice-activated-notification-title': 'Glückwunsch!',
    'membership_activation_notice-activated-notification-body': 'Du wurdest von Deinem Verein freigeschaltet und kannst ab sofort Plätze buchen!',
    'membership_activation_notice-still-inactive-notification-title': 'Bitte versuche es später noch einmal...',
    'membership_activation_notice-still-inactive-notification-body': 'Du wurdest noch nicht von deinem Vereinsadminstrator freigeschaltet.',
    'membership_activation_notice-header': 'Warten auf Freischaltung',
    'membership_activation_notice-more-info': 'Mehr Infos',
    'membership_activation_notice-modal-first-paragraph': 'Nur nach erfolgter Freischaltung durch den Vereinsadministrator können Buchungen vorgenommen werden.',
    'membership_activation_notice-modal-second-paragraph': 'Wir bitten um Deine Geduld.',
    'membership_activation_notice-modal-refresh-button': 'Klicke hier, wenn Dein Verein dich bereits freigeschaltet hat.',

    // Owner Deactivated Facility Notice Header
    'header_notice-deactivated_facility-message': 'Deine Anlage ist deaktiviert. Gehe zu Meine Anlage > Anlage, um sie wieder zu aktivieren.',

    // Owner Unverified Facility Notice Header
    'header_notice-unverified_facility-message': 'Deine Anlage ist nicht verifiziert und Spieler können somit nicht beitreten. Außerdem kannst Du keine Spieler erstellen oder importieren und Buchungen sind auch nicht möglich. Bitte kontaktiere courthero@tennis-point.de um Deine Anlage zu verifizieren.',

    // Admin facility section
    club_title: 'ANLAGE',
    'club_settings-general': 'ALLGEMEIN',
    'club_settings-club_logo': 'LOGO',
    'club_settings-edit_logo': 'Logo bearbeiten',
    'club_settings-crop_image_title': 'Edit image',
    'club_settings-delete_logo': 'Logo löschen',
    'club_settings-delete_logo_back-button': 'Zurück',
    'club_settings-delete_logo_title': 'Bist du Dir sicher, dass Du das Vereinslogo löschen möchtest?',
    'club_settings-club_name': 'NAME',
    'club_settings-club_name_required': 'Bitte Vereinsnamen eingeben.',
    'club_settings_opening-hours': 'ÖFFNUNGSZEITEN',
    'club_settings_opening-from': 'von',
    'club_settings_opening-to': 'bis',
    club_settings_services: 'ZUSÄTZLICHE DIENSTLEISTUNGEN',
    'club_settings-save': 'Änderungen speichern',
    'club_settings-service-shop': 'Shop',
    'club_settings-service-restaurant': 'Restaurant',
    'club_settings-service-beverages': 'Getränke',
    'club_settings-service-rental': 'Schlägerverleih',
    'club_settings-service-balls': 'Kauf von Bällen',
    'club_settings-stringing': 'Bespannservice',
    'club_creation-success_modal_title': 'Anlage erfolgreich erstellt!',
    'club_settings-success_modal_title': 'Das Vereinsprofil wurde aktualisiert.',
    'club_settings_opening-hours-validation-opening': 'Die Startzeit muss vor der Endzeit beginnen.',
    'club_settings_opening-hours-validation-closing': 'Die Endzeit muss nach der Startzeit liegen.',
    'club_settings-public_calendar_link': 'ÖFFENTLICHER KALENDER LINK',
    'club_settings-public_calendar_copy_it': 'Link kopieren',
    'club_settings-public_calendar_copied': 'In die Zwischenablage kopiert!',
    'club_settings-public_calendar_ok': 'Okay',
    'club_settings-payments_title': 'ONLINE-BEZAHLUNG',
    'club_settings-payments_step_one': 'Wenn Du möchtest, dass Deine Spieler ihre Buchungen online bezahlen können, musst Du einen Account bei unserem Partner Stripe erstellen. Klicke auf den folgenden Button, um mit dem Einrichtungsprozess zu beginnen:',
    'club_settings-payments_button-step_one': 'Online-Bezahlung einrichten',
    'club_settings-payments_button-step_two': 'Einrichtung fortsetzen',
    'club_settings-payments_step_two': 'Der Einrichtungsprozess wurde gestartet. Falls Du diesen bereits abgeschlossen hast, wird dein Konto nun von Stripe verifiziert. Falls Du aber noch weitere Daten bei Stripe angeben musst, dann klicke auf den folgenden Button "Einrichtung fortsetzen":',
    'club_settings-payments_pending': 'Ausstehend',
    'club_settings-payments_step_three': 'Der Einrichtungsprozess ist erfolgreich abgeschlossen. Du kannst die Online-Zahlung als Zahlungsmethode für Deine Spieler nun aktivieren.',
    'club_settings-payments_toggle': 'Online-Bezahlung mit Stripe',
    'club_settings-payments_approved': 'Bestätigt',
    'club_settings-payments_step_four': 'Der Einrichtungsprozess ist erfolgreich abgeschlossen. Die Online-Zahlung als Zahlungsmethode ist für Deine Spieler aktiviert.',
    'club_settings-payments_go_to_account': 'Gehe zu Deinem Stripe Account',
    'club_settings-payments_setup_completed': 'Die Einrichtung Deiner Online-Zahlungen hat begonnen. Falls Du weitere Daten bei Stripe eingeben musst, dann kannst Du den Einrichtungsprozess im Abschnitt “Online-Bezahlung” auf der Seite der Anlageneinstellungen wieder aufnehmen.',
    'club_settings-cash_payments_title': 'BARZAHLUNG',
    'club_settings-cash_payments_description': 'Die Zahlungsmethode Barzahlung kannst Du einsetzen, wenn:',
    'club_settings-cash_payments_description_item_1': 'Deine Mitglieder kostenlose Buchungen vornehmen',
    'club_settings-cash_payments_description_item_2': 'Du kostenpflichtige Buchungen eigenverantwortlich vor Ort, auf Rechnung oder via SEPA-Lastschrift abrechnen möchtest',
    'club_settings-cash_payments_toggle': 'Barzahlung',
    // Faclity status card
    'club_settings-facility_status-title': 'STATUS DER ANLAGE',
    'club_settings-facility_status-active_text': 'Deine Anlage ist aktiv und für jeden in den Suchergebnissen sichtbar. Du kannst Deine Anlage hier deaktivieren. Wenn Du dies machst, ist Deine Anlage für niemanden außer Dir als Eigentümer zugänglich. Sie wird weder in den Suchergebnissen noch auf der Seite der Spielorte für die Spieler angezeigt.',
    'club_settings-facility_status-active_button': 'Anlage deaktivieren',
    'club_settings-facility_status-deactivation_dialog-header': 'DEAKTIVIERUNG BESTÄTIGEN',
    'club_settings-facility_status-deactivation_dialog-subheader': 'Diese Anlage wird deaktiviert werden. Bitte beachte folgendes:',
    'club_settings-facility_status-deactivation_dialog-subheader_point-one': 'Die Anlage wird für niemanden außer Dir als Eigentümer zugänglich sein.',
    'club_settings-facility_status-deactivation_dialog-subheader_point-two': 'Die Anlage erscheint nicht mehr in den Suchergebnissen und auch nicht auf der Seite der Spielorte für die Spieler.',
    'club_settings-facility_status-deactivation_dialog-subheader_point-three': 'Die Anlage kann nicht deaktiviert werden, wenn sie zukünftige Buchungen hat.',
    'club_settings-facility_status-deactivation_dialog-subheader_point-four': 'Du kannst Deine Anlage jederzeit wieder reaktivieren.',
    'club_settings-facility_status-deactivation_dialog-subheader_confirmation': 'Bestätige, indem Du DEAKTIVIEREN eingibst:',
    'club_settings-facility_status-deactivation_dialog-confirm_button': 'Anlage deaktivieren',
    'club_settings-facility_status-deactivation_dialog-back_button': 'Abbrechen',
    'club_settings-facility_status-deactivation_dialog-success_message': 'Deine Anlage ist jetzt deaktiviert. Wenn Du Deine Meinung änderst, kannst Du sie jederzeit wieder reaktivieren.',
    'club_settings-facility_status-deactivation_dialog-success_button': 'Verstanden',
    'club_settings-facility_status-deactivation_dialog-error_message': 'Die Anlage kann nicht deaktiviert werden, da sie zukünftige Buchungen hat. Bitte gehe zum Kalender und storniere alle zukünftigen Buchungen, bevor Du die Anlage deaktivierst.',
    'club_settings-facility_status-deactivation_dialog-error_button': 'Verstanden',
    'club_settings-facility_status-unactive_text': 'Deine Anlage ist deaktviert.',
    'club_settings-facility_status-unactive_button': 'Anlage aktivieren',
    'club_settings-facility_status-activation_dialog-success_message': 'Deine Anlage ist jetzt aktiviert.',
    'club_settings-facility_status-activation_dialog-success_button': 'Verstanden',
    // Booking extra information card
    'booking-extra-information-card_title': 'E-Mail-Kommunikation',
    'booking-extra-information-card_description-label': 'Hier kannst Du wichtige Informationen eintragen, welche Deine Spieler mit der Buchungsbestätigung erhalten sollen. Diese Information kann z.B. ein Zutrittscode oder eine andere Besonderheit Deiner Anlage sein.',
    'booking-extra-information-card_description-placeholder': 'Bitte füge hier wichtige Informationen für die Buchungsbestätigung hinzu.',
    'booking-extra-information-card_save-changes': 'Änderungen speichern',

    // Facility members page
    club_members_title: 'SPIELER',
    'club_members_header-first-button': 'Erklärung',
    'club_members_header-second-button': 'Neuer Spieler',
    'club_members_header-import-button': 'Spieler Import', // Translation missing
    'club_members_filter-season': 'Filter Season', // Translation missing
    'club_members-table_header-avatar': 'PROFILBILD',
    'club_members-table_header-name': 'VORNAME',
    'club_members-table_header-surname': 'NACHNAME',
    'club_members-table_header-email': 'E-MAIL',
    'club_members-table_header-role': 'ROLLE',
    'club_members-table_header-register-date': 'REGISTRIERUNGSDATUM',
    'club_members-table_header-status': 'STATUS',
    'club_members-actions-activate': 'Aktivieren',
    'club_members-user-changed-succesfully': 'Nutzer erfolgreich geändert',
    'club_members-filter-status': 'nach Status filtern',
    'club_members-filter-name': 'Spieler suchen',
    'club_members-filter-role': 'nach Rolle filtern',
    'club_members_modal_edit-title': 'SPIELER BEARBEITEN',
    'club_members_modal_create-title': 'NEUEN SPIELER ERSTELLEN',
    club_members_modal_subtitle: 'ADRESSE',
    club_members_modal_name: 'VORNAME',
    'club_members_modal_name-required': 'Bitte Vornamen eingeben.',
    club_members_modal_surname: 'NACHNAME',
    'club_members_modal_surname-required': 'Bitte Nachnamen eingeben.',
    club_members_modal_email: 'E-MAIL',
    'club_members_modal_email-required': 'Bitte E-Mail-Adresse eingeben.',
    'club-members-modal_no-email-required': 'Keine E-Mail Adresse',
    club_members_modal_gender: 'GESCHLECHT',
    'club_members_modal_gender-required': 'Bitte Geschlecht auswählen.',
    club_members_modal_language: 'SPRACHE',
    'club_members_modal_language-required': 'Bitte Sprache auswählen.',
    club_members_modal_role: 'ROLLE',
    'club_members_modal_role-required': 'Bitte Rolle auswählen.',
    club_members_modal_street: 'STRAßE',
    'club_members_modal_street-required': 'Bitte Straße eingeben.',
    club_members_modal_number: 'HAUSNUMMER',
    'club_members_modal_number-required': 'Bitte Hausnummer eingeben.',
    'club_members_modal_zip-code': 'PLZ',
    'club_members_modal_zip-code-required': 'Bitte PLZ eingeben.',
    club_members_modal_city: 'ORT',
    'club_members_modal_city-required': 'Bitte Ort eingeben.',
    club_members_modal_country: 'LAND',
    'club_members_modal_country-required': 'Bitte Land eingeben.',
    'club_members_modal_cancel-button': 'Abbrechen',
    'club_members_modal_create-button': 'Spieler erstellen',
    'club_members_modal_edit-button': 'Spieler bearbeiten',
    'club_members_success-modal_title-create': 'Spieler erfolgreich erstellt!',
    'club_members_success-modal_title-edit': 'Spieler erfolgreich bearbeitet!',
    'club_members_success-delete-modal_header': 'Spieler erfolgreich gelöscht!',
    'club_members_success-modal_first-button': 'Spieler anzeigen',
    'club_members_success-modal_second-button': 'Zurück zu den Spielern',
    'club_members_success-delete-second-button': 'Zurück zu den Spielern',
    'club_members-delete_player_confirmation-header': 'Bist du Dir sicher, dass Du __playerName__ endgültig löschen möchtest?',
    'club_members-delete_player_confirmation-back_button': 'Zurück',
    'club_members-delete_player_confirmation-delete_button': 'Spieler löschen',

    // Courts management page
    'courts-page_title': 'PLÄTZE',
    'courts-page_header-first-button': 'Erklärung',
    'courts-page_header-second-button': 'Platz hinzufügen',
    'courts-page_table-header-name': 'PLATZNAME',
    'courts-page_table-header-surface': 'BELAG',
    'courts-page_table-header-location': 'ORT',
    'courts-page_table-header-booking-hours': 'BUCHUNGSZEITRAUM',
    'courts-page_modal_create-title': 'NEUEN PLATZ ERSTELLEN',
    'courts-page_modal_edit-title': 'PLATZ BEARBEITEN',
    'courts-page_modal_court-name-label': 'PLATZNAME',
    'courts-page_modal_name-is-required': 'Bitte Platznamen angeben.',
    'courts-page_modal_opening-label': 'STARTZEIT',
    'courts-page_modal_opening-is-required': 'Bitte Startzeit auswählen.',
    'courts-page_modal_opening-before-closing': 'Die Startzeit muss vor der Endzeit beginnen.',
    'courts-page_modal_closing-label': 'ENDZEIT',
    'courts-page_modal_closing-is-required': 'Bitte Endzeit auswählen.',
    'courts-page_modal_closing-after-opening': 'Die Endzeit muss nach der Startzeit liegen.',
    'courts-page_modal_surface-label': 'BELAG',
    'courts-page_modal_surface-is-required': 'Bitte Belag auswählen.',
    'courts-page_modal_location-label': 'ORT',
    'courts-page_modal_location-is-required': 'Bitte Ort auswählen.',
    'courts-page_modal_cancel-button': 'Abbrechen',
    'courts-page_modal_create-button': 'Platz erstellen',
    'courts-page_modal_edit-button': 'Platz bearbeiten',
    'courts-page_success-modal_title': 'Platz erfolgreich erstellt!',
    'courts-page_success-modal_title-edit': 'Platz erfolgreich bearbeitet!',
    'courts-page_success-modal_first-button': 'Platz anzeigen',
    'courts-page_success-modal_second-button': 'Zurück zu Plätzen',
    'courts-page_delete-modal_header': 'Bist du Dir sicher, dass Du den Platz endgültig löschen möchtest?',
    'courts-page_delete-modal_notify-label': 'Stornierungsbestätigung an betroffene Spieler senden',
    'courts-page_delete-modal_back-button': 'Zurück',
    'courts-page_delete-modal_delete-button': 'Platz löschen',
    'courts-page_success-delete-modal_header': 'Platz erfolgreich gelöscht!',
    'courts-page_success-delete-modal_second-button': 'Zurück zu Plätzen',

    // Guest fees page
    'guest_fees-title': 'GASTGEBÜHREN',
    'guest_fees_header-first-button': 'Erklärung',
    'guest_fees-table_header-avatar': 'PROFIBILD',
    'guest_fees-table_header-name': 'NAME',
    'guest_fees-table_header-email': 'E-MAIL',
    'guest_fees-table_header-bookings_with_guests': 'BUCHUNGEN MIT GÄSTEN',
    'guest_fees-table_header-bookings': 'ALLE BUCHUNGEN',
    'guest_fees-table_header-total_guest_fees': 'SUMME GASTGEBÜHREN',
    'guest_fees-filters-search_by_name_placeholder': 'Mitglied suchen',
    'guest_fees_filter-season': 'nach Saison filtern', // translation missing
    'guest_fees-filters-status-all': 'Alle',
    'guest_fees-table_header-total_amount': 'GESAMTBETRAG',
    'guest_fees-currency_units': ' €',

    // Facility registration/invitation link notice
    'club_invitation_link_notice-text': 'Registrierungslink für Spieler',
    'club_invitation_link_notice-copy_it': 'kopieren!',
    'club_invitation_link_notice-copied': 'In die Zwischenablage kopiert!',
    'club_invitation_link_notice-ok': 'Okay',

    // Admin menu
    'admin_menu-calendar': 'Kalender',
    'admin_menu-club': 'Anlage',
    'admin_menu-my_facility': 'Meine Anlage',
    'admin_menu-courts': 'Plätze',
    'admin_menu-members': 'Spieler',
    'admin_menu-seasons': 'Saisons',
    'admin_menu-book_keeping': 'Buchhaltung',
    'admin_menu-guest_fees': 'Gastgebühren',
    'admin_menu-prices': 'Preise',
    'admin_menu-settlement': 'Abrechnung',
    'admin_menu-invoices': 'Rechnungen',

    // Facility register
    'club_register-register': 'Registrieren',
    'club_register-already_a_member': 'Vereinskonto bereits vorhanden?',

    // Login page
    'login-page_title': 'ANMELDUNG',
    'login-page_link': 'erstelle deinen eigenen Verein',
    'login-page_registration-link': 'Registrierung',
    'login-page_login-button-text': 'Anmelden',
    'login-page_privacy-policy': 'Datenschutz',
    'login-page_resend-verification-email': 'Willkommens-E-Mail erneut zusenden',
    'login-page_resend-verification-email-success': 'Willkommens-E-Mail versendet!',

    // Common Register page
    'register-page_already-have-an-account': 'Account bereits vorhanden?',

    // Password recovery page
    'password-recovery_log-in-link': 'Anmeldung',
    'password-recovery_email-is-required': 'Bitte E-Mail-Adresse eingeben.',
    'password-recovery_email-sent': 'Passwort zurücksetzen E-Mail versendet',

    // Profile page
    'profile-card_title': 'ALLGEMEIN',
    'profile-password-card_title': 'PASSWORT',
    'profile-password-card_password-changed-message': 'Passwort erfolgreich geändert',
    'profile-picture_crop-image-title': 'Edit image',
    'profile-picture_delete_title': 'Bist du Dir sicher, dass du Dein Profilbild löschen möchtest?',
    'profile-picture_delete-logo_back-button': 'Zurück',
    'profile-picture_delete-logo_delete-button': 'Profilbild löschen',

    // Profile page - Address Form
    'address-card_title': 'ADRESSE',
    'address-card_street': 'STRASSE',
    'address-card_number': 'HAUSNUMMER',
    'address-card_zipCode': 'PLZ',
    'address-card_city': 'ORT',
    'address-card_country': 'LAND',
    'address-card_street-is-required': 'Bitte Straße eingeben.',
    'address-card_number-is-required': 'Bitte Hausnummer eingeben.',
    'address-card_zipCode-is-required': 'Bitte PLZ eingeben.',
    'address-card_city-is-required': 'Bitte Ort eingeben.',
    'address-card_country-is-required': 'Bitte Land auswählen.',
    'address-card_save-changes': 'Änderungen speichern',
    DE: 'Deutschland',
    FR: 'Frankreich',
    GB: 'Großbritannien',
    IT: 'Italien',
    NL: 'Niederlande',
    AT: 'Österreich',
    CH: 'Schweiz',
    ES: 'Spanien',

    // Profile page - Settlement Form
    'club-invoice-card_title': 'ABRECHNUNGSDATEN',
    'club-invoice-card_bank': 'BANK',
    'club-invoice-card_iban': 'IBAN',
    'club-invoice-card_bic': 'BIC',
    'club-invoice-card_taxNumber': 'STEUERNUMMER',
    'club-invoice-card_creditorId': 'GLÄUBIGER-ID',
    'club-invoice-card_bank-is-required': 'Bitte Bank eingeben.',
    'club-invoice-card_iban-is-required': 'Bitte IBAN eingeben.',
    'club-invoice-card_bic-is-required': 'Bitte BIC eingeben.',
    'club-invoice-card_taxNumber-is-required': 'Bitte Steuernummer eingeben.',
    'club-invoice-card_creditorId-is-required': 'Bitte Gläubiger-ID eingeben.',
    'club-invoice-card_save-changes': 'Änderungen speichern',

    // Profile page - Contact Data
    'club-contact-card_title': 'KONTAKTDATEN',
    'club-contact-card_phoneNumber': 'TELEFONNUMMER',
    'club-contact-card_email': 'E-MAIL ADRESSE',
    'club-contact-card_homePage': 'HOMEPAGE',
    'club-contact-card_save-changes': 'Änderungen speichern',

    // Terms page
    'terms-page_go-back': 'Zurück',

    // Seasons page
    'seasons-page_title': 'SAISONS',
    'seasons-page_table-header-name': 'SAISONNAME',
    'seasons-page_table-header-time': 'ZEITRAUM',
    'seasons-page_table-header-status': 'BUCHUNG MÖGLICH',
    'seasons-page_header-first-button': 'Erklärung',
    'seasons-page_header-second-button': 'Neue Saison',
    'seasons-page_modal_title-create': 'NEUE SAISON ERSTELLEN',
    'seasons-page_modal_title-edit': 'SAISON BEARBEITEN',
    'seasons-page_modal_season-name-label': 'SAISONNAME',
    'seasons-page_modal_season-is-required': 'Bitte Saisonnamen angeben.',
    'seasons-page_modal_cancel-button': 'Abbrechen',
    'seasons-page_modal_create-button': 'Saison erstellen',
    'seasons-page_modal_edit-button': 'Saison bearbeiten',
    'seasons-page_modal_starting-from-label': 'STARTDATUM',
    'seasons-page_modal_starting-is-required': 'Bitte Startdatum auswählen.',
    'seasons-page_modal_starting-before-ending': 'Das Startdatum muss vor dem Enddatum liegen.',
    'seasons-page_modal_ending-on-label': 'ENDDATUM',
    'seasons-page_modal_ending-is-required': 'Bitte Enddatum auswählen.',
    'seasons-page_modal_ending-before-starting': 'Das Enddatum muss nach dem Startdatum liegen.',
    'seasons-page_modal_courts-label': 'PLÄTZE',
    'seasons-page_modal_courts-is-required': 'Bitte mindestens einen Platz auswählen.',
    'seasons-page_modal_active-label': 'Saison zur Buchung durch Spieler freigeben',
    'seasons-page_succes-modal_title-create': 'Saison erfolgreich erstellt!',
    'seasons-page_succes-modal_title-edit': 'Saison erfolgreich bearbeitet!',
    'seasons-page_succes-modal_show-button': 'Saison anzeigen',
    'seasons-page_succes-modal_back-button': 'Zurück zu den Saisons',
    'seasons-page_list_status-active': 'Ja',
    'seasons-page_list_status-inactive': 'Nein',
    'seasons-page_delete-modal_header': 'Bist du Dir sicher, dass Du die Saison endgültig löschen möchtest?',
    'seasons-page_delete-modal_notify-label': 'Bitte beachte, dass damit alle Preisregeln für diese Saison gelöscht als auch alle zukünftigen Buchungen storniert werden. Hiermit wird auch eine Stornierungsbestätigung an betroffene Spieler versendet.',
    'seasons-page_delete-modal_back-button': 'Abbrechen',
    'seasons-page_delete-modal_delete-button': 'Saison löschen',
    'seasons-page_delete-modal_notify-required': 'Bitte Checkbox abhaken um die Saison endgültig zu löschen.',
    'seasons-page_success-delete-modal_header': 'Saison erfolgreich gelöscht!',
    'seasons-page_success-delete-second-button': 'Zurück zu Saisons',
    'seasons-page_no-courts-modal_link-text': 'Plätze',
    'seasons-page_no-courts-modal_text1': 'Erstelle bitte im ersten Schritt deine ',
    'seasons-page_no-courts-modal_text2': ', die deine Mitglieder buchen können sollen. Im zweiten Schritt erstelle bitte eine aktive Saison und ordne die gewünschten Plätze dieser Saison zu.',
    'seasons-page_no-courts-modal_first-button': 'Zurück',
    'seasons-page_deleted-courts-modal_header': 'Bist du Dir Sicher, dass du den Platz aus der aktiven Saison entfernen möchtest?',
    'seasons-page_deleted-courts-modal_text': 'Stornierungsbestätigung an betroffene Spieler senden',
    'seasons-page_deleted-courts-modal_first-button': 'Platz entfernen',
    'seasons-page_deleted-courts-modal_second-button': 'Zurück',
    'seasons-page_move-end-date-modal_header': 'Bist du Dir sicher, dass du das Enddatum der Saison vorziehen möchtest? Damit werden ggfs. Buchungen storniert.',
    'seasons-page_move-end-date-modal_text': 'Stornierungsbestätigung an betroffene Spieler senden',
    'seasons-page_move-end-date-modal_first-button': 'Enddatum anpassen',
    'seasons-page_move-end-date-modal_second-button': 'Zurück',
    'seasons-page_modal_max-ending-date': 'Die maximale Saisondauer beträgt ein Jahr.',

    // Prices page
    'prices-page_title': 'PREISE',
    'prices-page_header-first-button': 'Erklärung',
    'prices-page_header-second-button': 'Neue Preisregel',
    'prices-page_table-header-name': 'REGELNAME',
    'prices-page_table-header-days': 'TAGE',
    'prices-page_table-header-courts': 'PLÄTZE',
    'prices-page_table-header-time': 'ZEITRAUM',
    'prices-page_table-header-role': 'ROLLE',
    'prices-page_table-header-price': 'PREIS/STD.',
    'prices-page_table-header-guest-fee': 'GASTGEBÜHR/STD.',
    'prices-page_table-header-color': 'FARBE',
    'prices-page_modal_create-title': 'NEUE PREISREGEL ERSTELLEN',
    'prices-page_modal_edit-title': 'PREISREGEL BEARBEITEN',
    'prices-page_modal_season-label': 'AUSGEWÄHLTE SAISON',
    'prices-page_modal_name-label': 'NAME DER PREISREGEL',
    'prices-page_modal_name-required': 'Bitte Namen der Preisregel angeben.',
    'prices-page_modal_days-label': 'TAGE',
    'prices-page_modal_days-required': 'Bitte mindestens einen Tag auswählen.',
    'prices-page_modal_starting-label': 'STARTZEIT',
    'prices-page_modal_starting-required': 'Bitte Startzeit auswählen.',
    'prices-page_modal_starting-before-ending': 'Die Startzeit muss vor der Endzeit beginnen.',
    'prices-page_modal_ending-label': 'ENDZEIT',
    'prices-page_modal_ending-required': 'Bitte Endzeit auswählen.',
    'prices-page_modal_ending-before-starting': 'Die Endzeit muss nach der Startzeit liegen.',
    'prices-page_modal_role-label': 'ROLLE',
    'prices-page_modal_role-required': 'Bitte Rolle auswählen.',
    'prices-page_modal_price-label': 'PREIS/STD.',
    'prices-page_modal_price-required': 'Bitte Preis eingeben.',
    'prices-page_modal_courts-label': 'PLÄTZE',
    'prices-page_modal_courts-required': 'Bitte mindestens einen Platz auswählen.',
    'prices-page_modal_color-label': 'FARBE',
    'prices-page_modal_color-required': 'Bitte Farbe auswählen.',
    'prices-page_modal_cancel-button': 'Abbrechen',
    'prices-page_modal_create-button': 'Preisregel erstellen',
    'prices-page_modal_edit-button': 'Preisregel bearbeiten',
    'prices-page_modal_guest-fee-visibility-label': 'Gastgebühr aktivieren (Mitglied spielt mit Gast)',
    'prices-page_modal_guest-fee-label': 'GASTGEBÜHR/STD.',
    'prices-page_modal_guest-fee-required': 'Bitte Gastgebühr eingeben.',
    'prices-page_modal_overlapping-error-message1': 'Die Preisregel',
    'prices-page_modal_overlapping-error-message2': 'hat eine zeitliche Überschneidung mit dieser Preisregel. Bitte passe eine der beiden Preisregeln an um fortzufahren.',
    'prices-page_success-modal_title-create': 'Preisregel erfolgreich erstellt!',
    'prices-page_success-modal_title-edit': 'Preisregel erfolgreich bearbeitet!',
    'prices-page_success-delete-modal_header': 'Preisregel erfolgreich gelöscht!',
    'prices-page_success-modal_first-button': 'Preisregel anzeigen',
    'prices-page_success-modal_second-button': 'Zurück zu den Preisen',
    'prices-page_no-season-info_step-one': 'Erstelle bitte im ersten Schritt deine Plätze, die für Spieler buchbar sein sollen.',
    'prices-page_no-season-info_step-two': 'Im zweiten Schritt erstelle bitte eine Saison und ordne die gewünschten Plätze dieser Saison zu.',
    'prices-page_no-season-info_step-three': 'Im dritten Schritt erstelle bitte deine Preisregeln für die zuvor erstellte Saison.',
    'prices-page_no-season-info_step-four': 'Im letzten Schritt bearbeite bitte deine zuvor erstellte Saison und gebe die Saison zur Buchung durch Spieler frei.',
    'prices-page_delete-modal_header': 'Bist du Dir sicher, dass Du die Preisregel endgültig löschen möchtest?',
    'prices-page_delete-modal_back-button': 'Zurück',
    'prices-page_delete-modal_delete-button': 'Preisregel löschen',
    'prices-page_mobile-filters_open': 'Saison auswählen',
    'prices-page_mobile-filters_close': 'Saison auswählen',
    'pricing-rule-unit': '€/Std.',

    // Book Keeping
    'book-keeping-page_no-data': 'Für diesen Zeitraum sind leider keine Daten verfügbar. Bitte wähle einen anderen Zeitraum.',

    // Settlement page
    'settlement-page_title': 'ABRECHNUNG',
    'settlement-page_header-first-button': 'Erklärung',
    'settlement-page_header-second-button': 'Ausgewählte Spieler abrechnen',
    'settlement-page_table-header-avatar': 'PROFILBILD',
    'settlement-page_table-header-name': 'VORNAME',
    'settlement-page_table-header-surname': 'NACHNAME',
    'settlement-page_table-header-role': 'ROLLE',
    'settlement-page_table-header-total-amount': 'GESAMTBETRAG',
    'settlement-page_table-settlement-button': 'Abrechnen',
    'settlement-page_filter-start': 'von',
    'settlement-page_filter-end': 'bis',
    'settlement-page_filter-name': 'Spieler suchen',
    'settlement-page_filter-role': 'nach Rolle filtern',
    'settlement-page_success-modal_title-success': 'Spieler erfolgreich abgerechnet!',
    'settlement-page_success-modal_title-with-errors': '__settled__/__total__ Spieler erfolgreich abgerechnet. __failed__ sind fehlgeschlagen.',
    'settlement-page_success-modal_first-button': 'Rechnung anzeigen',
    'settlement-page_success-modal_second-button': 'Zurück zur Abrechnung',

    // Invoices page
    'invoices-page_title': 'RECHNUNGEN',
    'invoices-page_header-explanation-button': 'Erklärung',
    'invoices-page_header-bulk-cancel-button': 'Auswahl stornieren',
    'invoices-page_table-header-avatar': 'Profilbild',
    'invoices-page_table-header-invoice-number': 'Rechnungs-Nr.',
    'invoices-page_table-header-name': 'Vorname',
    'invoices-page_table-header-surname': 'Nachname',
    'invoices-page_table-header-role': 'Rolle',
    'invoices-page_table-header-date': 'Rechnungsdatum',
    'invoices-page_table-header-status': 'Status',
    'invoices-page_table-header-total-amount': 'Gesamtbetrag',
    'invoices-page_table-header-payment-method': 'Zahlungsmethode',
    'invoices-page_cancel-button': 'Stornieren',
    'invoices-page_cancel-confirmation-header': 'Bist du Dir sicher, dass Du die Rechnung stornieren möchtest?',
    'invoices-page_cancel-multiple-confirmation-header': 'Bist du Dir sicher, dass Du alle ausgewählten Rechnungen stornieren möchtest?',
    'invoices-page_cancel-confirmation-proceed': 'Rechnung stornieren',
    'invoices-page_cancel-multiple-confirmation-proceed': 'Rechnungen stornieren',
    'invoices-page_cancel-confirmation-back': 'Zurück',
    'invoices-page_cancel-success-header': 'Rechnung erfolgreich storniert',
    'invoices-page_cancel-multiple-success-header': 'Rechnungen erfolgreich storniert',
    'invoices-page_cancel-success-button': 'Zurück zu den Rechnungen',
    'invoices-page_filter-start': 'von',
    'invoices-page_filter-end': 'bis',
    'invoices-page_filter-name': 'Spieler suchen',
    'invoices-page_filter-status': 'nach Status filtern',
    'invoices-page_filter-status_all': 'Alle',
    'invoices-page_filter-role': 'nach Rolle filtern',
    'invoices-page_filter-payment-method_placeholder': 'nach Zahlungsmethode filtern',
    'invoices-page_filter-invoice-number': 'Rechnungsnummer suchen',
    'invoices-page_unpay-button': 'Als unbezahlt markieren',
    'invoices-page_pay-button': 'Als bezahlt markieren',

    // Import players modal
    'import-players-title': 'SPIELER IMPORT',
    'import-players-modal_download-example-title': 'CSV Beispieldatei',
    'import-players-modal_download-example-text': 'Die CSV Beispieldatei soll dir als Hilfe dienen um die Anforderungen an den CSV Import zu erfüllen.',
    'import-players-modal_download-example-button': 'Download',
    'import-players-modal_upload-csv-input': 'CSV Datei auswählen',
    'import-players-modal_import-players-button': 'Import CSV Datei',
    'import-players-modal_data-rights-agreement': '',
    'import-players-modal_checkbox-text': 'Als Verantwortlicher dieser Anlage bestätige ich hiermit, dass ich dazu berechtigt bin, die Spielerdaten aus der ausgewählten CSV Datei zu importieren und zu verarbeiten.',
    'import-players-modal_report-title': 'IMPORT STATUS',
    'import-players-modal_report-summary': 'Datensätze importiert',
    'import-players-modal_report-table_email': 'E-Mail',
    'import-players-modal_report-table_row': 'Zeile',
    'import-players-modal_report-table_error': 'Fehler',
    'import-players-modal_download-csv_file': 'CSV Beispieldatei',

    // My facilities/select facility page
    'facility-card_go_to_calendar': 'Zum Kalender',
    'facility_card-join_facility': 'Neuer Anlage beitreten',
    'facility_card-create_facility': 'Neue Anlage erstellen',
    'facility_card-join_facitiy_input': 'ANLAGE',
    'facility_card-join_this_facility': 'Neuer Anlage beitreten',
    'select-facility-page_title': 'SPIELORTE',
    'create_facility_card-create_new_club': 'Neue Anlage erstellen',
    'create_facility_card-club_name': 'ANLAGE',
    'create_facility_card-club_name_required': 'Bitte Anlage eingeben.',

    // Errors
    'token-expired-error': 'Deine Sitzung ist abgelaufen, bitte melde Dich neu an.',

    // Mobile Footer
    'mobile-filters_open': 'Filter öffnen',
    'mobile-filters_close': 'Filter schließen',
};
