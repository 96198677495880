import { debounce } from 'adapters/debounce';
import { useEffect, useRef, useState } from 'react';

export const useWindowSize = () => {
    const sizeRef = useRef({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [isWindowResizing, setIsWindowResizing] = useState<boolean>(false);

    const handleResize = () => {
        const windowSize = {
            width: window.innerWidth,
            height: window.innerHeight,
        };
        if (sizeRef.current !== windowSize && !isWindowResizing) {
            setIsWindowResizing(true);
        }
        if (sizeRef.current === windowSize && isWindowResizing) {
            setIsWindowResizing(false);
        }
        sizeRef.current = {
            width: window.innerWidth,
            height: window.innerHeight,
        };
    };

    const debouncedHandleResize = debounce(handleResize, 200);

    useEffect(() => {
        window.addEventListener('resize', debouncedHandleResize);
        return () => window.removeEventListener('resize', () => {});
    }, []);
    return { isWindowResizing, setIsWindowResizing };
};
