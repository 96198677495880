import React from 'react';
import {
    Button, Checkbox, Form,
} from 'antd';
import { useAppContext } from 'context/AppContext';
import './DeleteModalContent.scss';

interface IProps {
    headerText: string;
    cancelText: string;
    proceedText: string;
    onProceed: (values: any) => void;
    onCancel: () => void;
    checkboxText?: string;
    requiredText?: string;
}

export const DeleteModalContent = ({
    headerText,
    cancelText,
    proceedText,
    onProceed,
    onCancel,
    checkboxText,
    requiredText,
}: IProps) => {
    const { isSpinnerVisible } = useAppContext();

    return (

        <div className="delete-modal-content">
            <p className="delete-modal-content__text">{headerText}</p>
            <Form
                requiredMark="optional"
                layout="vertical"
                initialValues={{ notify: false }}
                name="basic"
                onFinish={({ notify }) => onProceed(notify)}
            >
                {checkboxText && (
                    <Form.Item
                        name="notify"
                        rules={[{
                            transform: value => (value || undefined),
                            type: 'boolean',
                            required: !!requiredText,
                            message: requiredText,
                        }]}
                        valuePropName="checked"
                    >
                        <Checkbox
                            className="delete-modal-content__form-item-checkbox"
                        >
                            <p>{checkboxText}</p>
                        </Checkbox>
                    </Form.Item>
                )}
                <div className="delete-modal-content__buttons-container">
                    <Button
                        type="default"
                        className="delete-modal-content__button delete-modal-content__button--gray"
                        onClick={onCancel}
                    >
                        {cancelText}
                    </Button>
                    <Button
                        danger
                        htmlType="submit"
                        className="delete-modal-content__button delete-modal-content__button--red"
                        disabled={isSpinnerVisible}
                    >
                        {proceedText}
                    </Button>
                </div>
            </Form>
        </div>
    );
};
