import { AdminLayoutPage } from 'pages/admin/Layout/AdminLayoutPage';
import { NotFoundPage } from 'pages/common/NotFound/NotFoundPage';
import { UserLayoutPage } from 'pages/user/Layout/UserLayoutPage';
import { Redirect, Route, Switch } from 'react-router-dom';
import { isLoggedUserAdmin } from 'services/functionalStorage';
import { MyFacilitesLayoutPage } from 'pages/common/MyFacilities/MyFacilitesLayoutPage';
import { publicRoutes } from './publicRoutes';
import { ROUTES } from './routes.enum';

export const AuthenticatedApp = () => (
    <Switch>
        <Route
            exact
            key="/"
            path="/"
            render={() => <Redirect to={ROUTES.MULTICLUB.MY_FACILITIES} />}
        />

        {publicRoutes.map(route => (
            <Route
                exact
                key={route.path}
                path={route.path}
                render={() => <route.component />}
            />
        ))}

        <Route
            key="/admin"
            path="/admin"
            render={({ location }) => (
                <AdminLayoutPage
                    guard={() => isLoggedUserAdmin()}
                    location={location}
                />
            )}
        />

        <Route
            key="/player"
            path="/player"
            render={({ location }) => <UserLayoutPage guard={() => !isLoggedUserAdmin()} location={location} />}
        />

        <Route
            key="/my-facilities"
            path="/my-facilities"
            render={() => (
                <MyFacilitesLayoutPage />
            )}
        />

        <Route
            path="*"
            render={() => <NotFoundPage />}
        />
    </Switch>
);
