import { getUserFromStorage, setUserInStorage } from 'services/functionalStorage';
import { PROFILE } from 'config/endpoints';
import { deleteWrapper } from 'services/httpService/httpServiceWrapper';

export const deleteAvatar = async (): Promise<string> => {
    const response = await deleteWrapper(PROFILE.AVATAR);

    setUserInStorage({
        ...getUserFromStorage(),
        avatar: null,
    });

    return response;
};
