import React from 'react';
import { translate } from 'translations/translate';
import { ROUTES } from 'routing/routes.enum';
import { ILink } from 'types';
import {
    ACCOUNT_ICON, ACCOUNT_ICON_WHITE,
} from 'config/constants';
import { ConnectedLayoutMenu } from 'pages/common/Layout/LayoutMenu';
import { logoutUser } from 'services/logoutUser';
import {
    IMPRINT_SIDEBAR_DATALAYER,
    PROFILE_LOGOUT_SIDEBAR_DATALAYER,
    PROFILE_SETTINGS_SIDEBAR_DATALAYER,
    MY_FACILITIES_SIDEBAR_DATALAYER,
} from 'services/gtm/gtmDatalayers';

export const MyFacilitesLayoutMenu = () => {
    const accountMenu = {
        bottom: true,
        icons: [ACCOUNT_ICON, ACCOUNT_ICON_WHITE],
        text: translate('menu-profile'),
        routes: [
            {
                to: ROUTES.MULTICLUB.MY_FACILITIES,
                text: translate('menu-facilities'),
                datalayer: MY_FACILITIES_SIDEBAR_DATALAYER,
            },
            {
                to: ROUTES.MULTICLUB.PROFILE_SETTINGS,
                text: translate('menu-settings'),
                datalayer: PROFILE_SETTINGS_SIDEBAR_DATALAYER,
            },
            {
                run: logoutUser,
                text: translate('menu-logout'),
                datalayer: PROFILE_LOGOUT_SIDEBAR_DATALAYER,
            },
        ],
    };

    const links: ILink[] = [
        accountMenu,
        {
            to: ROUTES.IMPRINT,
            icon: <></>,
            text: translate('imprint'),
            bottom: true,
            targetBlank: true,
            datalayer: IMPRINT_SIDEBAR_DATALAYER,
        },
    ];

    return (
        <ConnectedLayoutMenu
            links={links}
        />
    );
};
