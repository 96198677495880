import { ClubPage } from 'pages/admin/Club/ClubPage';
import { Profile } from 'pages/common/Profile/Profile';
import UserReservationsPage from 'pages/user/Reservations/UserReservationsPage';
import { ROUTES } from './routes.enum';

export const playerRoutes = [
    {
        path: ROUTES.PLAYER.DASHBOARD,
        component: UserReservationsPage,
    },
    {
        path: ROUTES.PLAYER.CLUB,
        component: ClubPage,
    },
    {
        path: ROUTES.PLAYER.PROFILE_SETTINGS,
        component: Profile,
    },
];
