export const ROUTES = {
    LOGIN: '/login',
    REGISTER: '/register',
    EMAIL_VERIFICATION: '/email-verification',
    EMAIL_VERIFY: '/verify/:email/:token',
    PASSWORD_RECOVERY: '/password-recovery',
    CHANGE_FORGOTTEN_PASSWORD: '/change-password/:email/:token',
    IMPRINT: '/imprint',
    IMPRESSUM: '/impressum',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS: '/terms',
    PUBLIC_CALENDAR: '/calendar/:clubId',
    MULTICLUB: {
        MY_FACILITIES: '/my-facilities',
        PROFILE_SETTINGS: '/my-facilities/profile',
    },
    PLAYER: {
        DASHBOARD: '/player',
        REGISTER: '/player/register/:clubId',
        CLUB: '/player/club',
        PROFILE_SETTINGS: '/player/profile',
    },
    ADMIN: {
        DASHBOARD: '/admin',
        CLUB: '/admin/club',
        COURTS: '/admin/courts',
        REGISTER: '/admin/register',
        MEMBERS: '/admin/members',
        GUEST_FEES: '/admin/guest-fees',
        SEASONS: '/admin/seasons',
        PRICES: '/admin/prices',
        SETTLEMENT: '/admin/settlement',
        INVOICES: '/admin/invoices',
        PROFILE_SETTINGS: '/admin/profile',
    },

};
