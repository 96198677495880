import React, { useEffect } from 'react';
import { PaymentTypeEnum } from 'usecases/types/enums/payment_type.enum';
import { CHRadioGroup } from 'components/CHRadioGroup/CHRadioGroup';
import { IReservation } from 'usecases/types/reservations';
import { BookingType } from 'usecases/types/enums/booking_type.enum';
import { getUserFromStorage } from 'services/functionalStorage';
import './PaymentTypeSelector.scss';

interface IProps {
    disabled: boolean,
    cash: boolean,
    online: boolean,
    onChange: (paymentType: PaymentTypeEnum) => void
    booking: IReservation
}

export const PaymentTypeSelector = ({
    disabled,
    onChange,
    cash,
    online,
    booking,
}: IProps) => {
    const { paymentType, bookingType, owner } = booking;
    const isSingleBooking = bookingType === BookingType.SINGLE;
    const loggedUser = getUserFromStorage();
    const isBookingOnBehalfOf = owner && loggedUser.id !== owner.id;

    const showCashPaymentOption = (booking.id && booking.paymentType === PaymentTypeEnum.CASH)
        || isBookingOnBehalfOf
        || cash;
    const showOnlinePaymentOption = (
        online && isSingleBooking && !isBookingOnBehalfOf
    ) || (booking.id && booking.paymentType === PaymentTypeEnum.ONLINE);

    const radioOptions = [];
    if (showCashPaymentOption) {
        radioOptions.push({ value: PaymentTypeEnum.CASH, key: 'calendar-reservation_dialog-cash' });
    }
    if (showOnlinePaymentOption) {
        radioOptions.push({ value: PaymentTypeEnum.ONLINE, key: 'calendar-reservation_dialog-online' });
    }

    useEffect(() => {
        if (!booking.id) {
            onChange(PaymentTypeEnum.CASH);
        }
    }, [isSingleBooking, isBookingOnBehalfOf]);

    useEffect(
        () => {
            if (showCashPaymentOption && !showOnlinePaymentOption) {
                onChange(PaymentTypeEnum.CASH);
            }
            if (!showCashPaymentOption && showOnlinePaymentOption) {
                onChange(PaymentTypeEnum.ONLINE);
            }
        },
        [],
    );

    return (
        <div className="payment-type">
            <CHRadioGroup
                name="payment-type"
                disabled={!disabled}
                onChange={({ target }) => onChange(target.value)}
                value={paymentType}
                radioOptions={radioOptions}
            />
        </div>
    );
};
