import './NoticeHeader.scss';
import React from 'react';

interface Props {
    type?: 'info' | 'warning',
    children: React.ReactNode,
}
export const NoticeHeader = ({ type = 'info', children }: Props) => (
    <div className={`notice-header notice-header--${type}`}>
        {children}
    </div>
);
