import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
    Button, Card, notification, Upload,
} from 'antd';
import React, { useState } from 'react';
import { useAppContext } from 'context/AppContext';
import { getUserFromStorage } from 'services/functionalStorage';
import { LOGO } from 'config/constants';
import { changeLogo } from 'usecases/club/changeLogo';
import { IClub } from 'usecases/types/club';
import { translate } from 'translations/translate';
import { ImgCrop } from 'components/ImgCrop/ImgCrop';
import { deleteLogo } from 'usecases/club/deleteLogo';
import './ClubLogo.scss';

interface IProps {
    currentClub: IClub,
    onUpdateSuccessfully: () => void,
    showDeleteMessage: Function,
  }

export const ClubLogo = ({
    currentClub, onUpdateSuccessfully, showDeleteMessage,
}: IProps):JSX.Element => {
    const { isSpinnerVisible, setIsSpinnerVisible } = useAppContext();
    const [logo, setLogo] = useState<string>(currentClub.logo || LOGO);

    const onLogoChange = async (values: any): Promise<void> => {
        setIsSpinnerVisible(true);

        try {
            const logoUrl = await changeLogo(currentClub.id, values.file as Blob);
            setLogo(logoUrl);
            onUpdateSuccessfully();
        } catch (error: any) {
            notification.error({
                message: translate('error'),
                description: error.message,
            });
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    const onDeleteLogo = async () => {
        setIsSpinnerVisible(true);
        try {
            await deleteLogo(currentClub.id);
            setLogo(LOGO);
        } catch (error: any) {
            notification.error({
                message: translate('error'),
                description: error.message,
            });
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    return (
        <>
            <Card className="club-logo">

                <h1 className="club-logo__title">{translate('club_settings-club_logo')}</h1>

                <div className="club-logo__image-container">
                    <img
                        className="club-logo__image"
                        src={logo}
                        alt={getUserFromStorage().name}
                    />
                    <div className="club-logo__buttons">
                        <ImgCrop modalTitle={translate('club_settings-crop_image_title')}>
                            <Upload
                                className="club-logo__upload-button-container"
                                maxCount={1}
                                showUploadList={false}
                                name="logo"
                                listType="picture"
                                customRequest={onLogoChange}
                            >
                                <Button
                                    type="primary"
                                    size="large"
                                    className="club-logo__button"
                                    disabled={isSpinnerVisible}
                                >
                                    <EditOutlined />
                                    {' '}
                                    {translate('club_settings-edit_logo')}
                                </Button>
                            </Upload>
                        </ImgCrop>
                        <Button
                            type="default"
                            size="large"
                            className="club-logo__button club-logo__button--delete"
                            onClick={() => showDeleteMessage(onDeleteLogo)}
                            disabled={isSpinnerVisible}
                        >
                            <DeleteOutlined />
                            {' '}
                            {translate('club_settings-delete_logo')}
                        </Button>
                    </div>
                </div>
            </Card>

        </>
    );
};
