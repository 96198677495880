import { getWrapper } from 'services/httpService/httpServiceWrapper';
import { ACTIONS } from 'config/endpoints';
import { ICalculatedPrice, ICalculatedPriceInput } from 'types';

export const getCalculatedReservationPrice = async (
    calculatedPriceInput: ICalculatedPriceInput,
): Promise<ICalculatedPrice> => {
    const params = {
        ...calculatedPriceInput,
        hasGuests: calculatedPriceInput.hasGuests ? '1' : '0',
    };
    const endpoint = ACTIONS.CALCULATE_PRICE;
    const urlParams = new URLSearchParams();
    Object
        .keys(params)
        .map(key => urlParams.append(key, (params as any)[key]));
    const { price } = (await getWrapper<ICalculatedPrice>(`${endpoint}?${urlParams.toString()}`)).data;
    return price;
};
