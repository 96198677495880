import { IModalTexts, ListAPIAction } from 'types';

export const getSuccessModalTexts = (action: ListAPIAction | undefined, texts: IModalTexts) => {
    if (action === ListAPIAction.delete) {
        return {
            header: texts.successDeleteHeader,
            secondButton: texts.secondButtonDeleteSuccess,
        };
    }

    if (action === ListAPIAction.create || action === ListAPIAction.edit) {
        const header = action === ListAPIAction.edit
            ? texts.successEditHeader
            : texts.successCreateHeader;
        return {
            header,
            firstButton: texts.firstButtonSuccess,
            secondButton: texts.secondButtonSuccess,
        };
    }

    return {
        header: '',
        secondButton: '',
    };
};
