import React, { useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { DEFAULT_AVATAR } from 'config/constants';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { IListTableHeaderField, IListTableRowField } from 'types';
import { useAppContext } from 'context/AppContext';
import { useChevronIcon } from 'hooks/useChevronIcon';
import { useMobileMediaQuery } from 'hooks/useMobileMediaQuery';
import { adaptRowFields } from './adaptRowFields';
import './ListTable.scss';

interface IProps {
    firstColumn?: boolean;
    rows: string[][] | IListTableRowField[][];
    headers?: IListTableHeaderField[];
    getKey?: (item: any[]) => React.Key
    notBorderedImage?: boolean
    sortBy?: (field: any, order?: 'asc' | 'desc') => void;
}

export const ListTable = ({
    firstColumn = true,
    rows,
    headers = [],
    getKey,
    notBorderedImage = false,
    sortBy,
}: IProps) => {
    const { scrollArrowVisibility } = useAppContext();
    const { isMobile } = useMobileMediaQuery();
    const firsHeader = firstColumn && headers && headers.length > 0 ? headers[0] : undefined;
    const firstColumnValues: any[] = firstColumn && rows.length > 0
        ? rows.map((row: any) => row[0])
        : [];
    const [selectedHeader, setSelectedHeader] = useState<string | undefined>(undefined);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | undefined>(undefined);
    const listContainerRef = useRef<HTMLDivElement | null>(null);
    useChevronIcon(listContainerRef);

    const handleSort = (field: string): void => {
        if (sortBy) {
            let order: 'asc' | 'desc' = 'asc';
            switch (sortOrder) {
            case undefined:
                order = 'asc';
                break;
            case 'asc':
                order = 'desc';
                break;
            case 'desc':
                order = 'asc';
                break;
            default:
                break;
            }
            setSelectedHeader(field);
            setSortOrder(order);
            sortBy(field, order);
        }
    };

    const getColumnHeaderIcon = (header: IListTableHeaderField) => {
        const { field } = header;
        const isSelected = selectedHeader === field && sortOrder !== undefined;
        const icon = sortOrder && sortOrder === 'asc'
            ? <UpOutlined className="list-table__header-sort-icon" />
            : <DownOutlined className="list-table__header-sort-icon" />;
        if (isSelected) {
            return icon;
        }
        if (!isSelected && header.sortable) {
            return (
                <div className="list-table__header-sort-icon-container">
                    <UpOutlined className="list-table__header-sort-icon list-table__header-sort-icon--small" />
                    <DownOutlined className="list-table__header-sort-icon list-table__header-sort-icon--small" />
                </div>
            );
        }
        return null;
    };

    const onClickHandler = (header: IListTableHeaderField) => {
        if (header.sortable) {
            handleSort(header.field);
        }
    };

    return (
        <div className="list-table">
            {firstColumn && (
                <div className="list-table__avatar-list-container">
                    <div className="list-table__avatar-list-header">
                        <p className={`
                                list-table__avatar-list-header-text
                                list-table__avatar-list-header-text--hidden
                            `}
                        >
                            {firsHeader?.value}
                        </p>
                    </div>

                    <div className="list-table__avatar-list-body">
                        {firstColumnValues.map((firstColumnValue: any, index) => (
                            <div
                                className="list-table__avatar-item"
                                key={getKey
                                    ? getKey(rows[index])
                                    : uuid()}
                            >
                                <img
                                    src={firstColumnValue || DEFAULT_AVATAR}
                                    alt="list_icon"
                                    className={`
                                    list-table__avatar-item-image
                                    ${notBorderedImage ? 'list-table__avatar-item-image--not-bordered' : ''}
                                    `}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <div className="list-table__info-list-container" ref={listContainerRef}>
                <div className="list-table__info-list-header">
                    {headers && headers.slice(1, headers.length).map(header => (
                        <div
                            key={uuid()}
                            className={`
                                list-table__list-header-container
                                ${header.isLarge
                            ? 'list-table__list-header-container--large'
                            : ''}
                        `}
                            onClick={() => onClickHandler(header)}
                            onKeyPress={() => onClickHandler(header)}
                            role="button"
                            tabIndex={0}
                        >
                            <p className="list-table__list-header-text">
                                {header.value}
                            </p>
                            {getColumnHeaderIcon(header)}
                        </div>
                    ))}
                </div>
                <div className={`
                    list-table__info-list-body
                    ${!firstColumn ? 'list-table__info-list-body--bordered' : ''}
                `}
                >
                    {!isMobile && scrollArrowVisibility && (
                        <UpOutlined className="list-table__info-list-body__scroll-arrow" />
                    )}
                    {rows.map((row: any) => (
                        <div
                            className="list-table__info-row"
                            key={getKey
                                ? getKey(row)
                                : uuid()}
                        >
                            {adaptRowFields(row.slice(1, row.length)).map(column => (
                                <div
                                    className={`
                                        list-table__info-row-item
                                        ${column.isLarge ? 'list-table__info-row-item--large' : ''}
                                    `}
                                    key={uuid()}
                                >
                                    {column.value}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
