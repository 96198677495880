import { isLoggedUserAdmin } from 'services/functionalStorage';
import { CalendarViewEnum } from 'types';
import { ICourt } from 'usecases/types/court';
import { ISeason } from 'usecases/types/season';
import { isActiveSeason } from './isActiveSeason';

export const getBookableSeasons = (
    apiSeasons: ISeason[],
    selectedCourt: ICourt,
    view: CalendarViewEnum,
): ISeason[] => {
    if (isLoggedUserAdmin()) {
        if (view === CalendarViewEnum.WEEKVIEW && selectedCourt) {
            return apiSeasons.filter(season => season.courts.includes(selectedCourt.id));
        }
        return apiSeasons;
    }
    if (view === CalendarViewEnum.WEEKVIEW && selectedCourt) {
        return apiSeasons.filter(season => isActiveSeason(season) && season.courts.includes(selectedCourt.id));
    }
    return apiSeasons.filter(season => isActiveSeason(season));
};
