import { arrayToMapByAttribute } from 'adapters/arrayToMapByAttribute';
import { getUserMembership } from 'helpers/getUserMembership';
import { getClubFromStorage } from 'services/functionalStorage';
import { Dict, IUser } from 'types';
import { MEMBERSHIP_STATUS } from 'usecases/types/enums/membership_status.enum';

export const getActivePlayersDictionary = (clubUsers: IUser[], admin: IUser): Dict<IUser> => {
    const totalClubUsers = [...clubUsers
        .filter(user => getUserMembership(user, getClubFromStorage())?.status === MEMBERSHIP_STATUS.ACTIVE),
    admin];

    const usersSorted = totalClubUsers.sort((a: IUser, b: IUser) => a.name.localeCompare(b.name));
    const usersSortedMapped = arrayToMapByAttribute(usersSorted as IUser[]);
    return usersSortedMapped;
};
