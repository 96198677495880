import { PROFILE } from 'config/endpoints';
import { CreateUserRequestWithoutClub } from 'usecases/types/dto/CreateUserRequest';
import { IUser } from 'types';
import { postWrapper } from 'services/httpService/httpServiceWrapper';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { getSuccessfulRegistrationDatalayer } from 'services/gtm/gtmDatalayers';

export const createUserWithoutClub = async (request: CreateUserRequestWithoutClub): Promise<IUser> => {
    const url = PROFILE.CREATE;

    const data = { ...request };

    const response = await postWrapper(url, data);

    pushGtmDatalayer(getSuccessfulRegistrationDatalayer({
        userId: response.data.user.id,
        userGender: response.data.user.gender,
        userLanguage: response.data.user.language,
    }));

    // eslint-disable-next-line no-undef
    return response.data.user;
};
