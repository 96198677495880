import { CLOSE_ICON_GRAY } from 'config/constants';
import React from 'react';
import Icon from '@ant-design/icons';
import './HeaderIcon.scss';

export const HeaderIcon = ({ onClickIcon }: { onClickIcon: () => void }): JSX.Element => {
    const MemoizedIcon = React.useCallback(() => (
        <img
            className="header-icon"
            src={CLOSE_ICON_GRAY}
            alt="close_icon"
        />
    ), []);

    return (
        <Icon
            onClick={() => {
                onClickIcon();
            }}
            className="header-icon"
            component={MemoizedIcon}
        />
    );
};
