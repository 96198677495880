import { CLUBS, PLACEHOLDERS } from 'config/endpoints';
import { getUserFromStorage } from 'services/functionalStorage';
import { getDeactivateFacilityDataLayer } from 'services/gtm/gtmDatalayers';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { patchWrapper } from 'services/httpService/httpServiceWrapper';

export const changeFacilityActivation = (clubId: string, isActive: boolean): Promise<any> => {
    const endpoint = CLUBS.SINGLE.replace(PLACEHOLDERS.CLUB_ID, clubId);

    const data = patchWrapper(
        endpoint,
        { active: isActive },
    );

    const user = getUserFromStorage();

    if (!isActive) {
        pushGtmDatalayer(getDeactivateFacilityDataLayer(
            user.id,
            clubId,
        ));
    }

    return data;
};
