import { IClub } from 'usecases/types/club';
import { CHDateTime } from 'helpers/DateTime/CHDateTime';
import { Invoice } from 'types';
import { INVOICES, PLACEHOLDERS } from 'config/endpoints';
import { getWrapper } from 'services/httpService/httpServiceWrapper';
import { adaptInvoiceResponse } from './adaptInvoiceResponse';

export const getInvoices = async (club: IClub, from: CHDateTime, to: CHDateTime): Promise<Invoice[]> => {
    const baseUrl = INVOICES.LIST
        .replace(PLACEHOLDERS.CLUB_ID, club.id);

    const urlParams = new URLSearchParams();
    if (from && to) {
        urlParams.append('startDate', from.toString());
        urlParams.append('endDate', to.toString());
    }

    const { data } = await getWrapper(`${baseUrl}?${urlParams.toString()}`);

    return adaptInvoiceResponse(data);
};
