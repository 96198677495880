import { FACILITY_ICON_GRAY, FACILITY_ICON_WHITE } from 'config/constants';
import { ROUTES } from 'routing/routes.enum';
import { isLoggedUserAdmin } from 'services/functionalStorage';
import {
    MY_FACILITY_CLUB_SIDEBAR_DATALAYER,
    MY_FACILITY_COURTS_SIDEBAR_DATALAYER,
    MY_FACILITY_MEMBERS_SIDEBAR_DATALAYER,
    MY_FACILITY_PRICES_SIDEBAR_DATALAYER,
    MY_FACILITY_SEASON_SIDEBAR_DATALAYER,
} from 'services/gtm/gtmDatalayers';
import { translate } from 'translations/translate';
import { ILink } from 'types';

export const getMyFacilitySubmenu = (): ILink => {
    const clubRoute = {
        to: isLoggedUserAdmin() ? ROUTES.ADMIN.CLUB : ROUTES.PLAYER.CLUB,
        text: isLoggedUserAdmin() ? translate('admin_menu-club') : translate('user_menu-club'),
        datalayer: MY_FACILITY_CLUB_SIDEBAR_DATALAYER,
    };

    const adminRoutes = [
        {
            to: ROUTES.ADMIN.COURTS,
            text: translate('admin_menu-courts'),
            datalayer: MY_FACILITY_COURTS_SIDEBAR_DATALAYER,
        },
        {
            to: ROUTES.ADMIN.MEMBERS,
            text: translate('admin_menu-members'),
            datalayer: MY_FACILITY_MEMBERS_SIDEBAR_DATALAYER,
        },
        {
            to: ROUTES.ADMIN.SEASONS,
            text: translate('admin_menu-seasons'),
            datalayer: MY_FACILITY_SEASON_SIDEBAR_DATALAYER,
        },
        {
            to: ROUTES.ADMIN.PRICES,
            text: translate('admin_menu-prices'),
            datalayer: MY_FACILITY_PRICES_SIDEBAR_DATALAYER,
        },
    ];

    return {
        icons: [FACILITY_ICON_GRAY, FACILITY_ICON_WHITE],
        text: isLoggedUserAdmin() ? translate('admin_menu-my_facility') : translate('user_menu-my_facility'),
        routes: isLoggedUserAdmin() ? [clubRoute, ...adminRoutes] : [clubRoute],
    };
};
