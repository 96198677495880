import { CHDateTime } from 'helpers/DateTime/CHDateTime';
import moment from 'moment';

export const getLastDesiredDayOfWeekFromDate = (desiredDayOfWeek: number, date: CHDateTime): CHDateTime => {
    const momentDate = moment(date.toNativeDate());
    const lastDesiredDayOfWeek = momentDate.day() >= desiredDayOfWeek
        ? momentDate.clone().day(desiredDayOfWeek)
        : momentDate.clone().day(-7 + desiredDayOfWeek);
    return CHDateTime.fromNativeDate(lastDesiredDayOfWeek.toDate());
};
