import React from 'react';
import { getSuccessModalTexts } from 'adapters/getSuccessModalTexts';
import { translate } from 'translations/translate';
import { IModalTexts, ListAPIAction } from 'types';
import { AlertModalContent } from 'components/AlertModalContent/AlertModalContent';

const successModalTexts: IModalTexts = {
    successCreateHeader: translate('seasons-page_succes-modal_title-create'),
    successEditHeader: translate('seasons-page_succes-modal_title-edit'),
    successDeleteHeader: translate('seasons-page_success-delete-modal_header'),
    firstButtonSuccess: translate('seasons-page_succes-modal_show-button'),
    secondButtonSuccess: translate('seasons-page_succes-modal_back-button'),
    secondButtonDeleteSuccess: translate('seasons-page_success-delete-second-button'),
};

export const getSuccessModalContent = ({ actionFromAPI, onSeeDetailsCallback, onGoBackCallback }:
     {actionFromAPI: ListAPIAction,
    onSeeDetailsCallback: any,
    onGoBackCallback: any}): JSX.Element => {
    const { header, firstButton, secondButton } = getSuccessModalTexts(actionFromAPI, successModalTexts);

    return (
        <AlertModalContent
            type="success"
            header={header}
            goBackText={secondButton}
            seeDetailsText={firstButton}
            onSeeDetails={onSeeDetailsCallback}
            onGoBack={onGoBackCallback}
        />
    );
};
