import React from 'react';
import { translate } from 'translations/translate';
import { ROUTES } from 'routing/routes.enum';
import { IMPRINT_LINK_DATALAYER } from 'services/gtm/gtmDatalayers';
import { LegalAgreement } from '../LegalAgreement/LegalAgreement';

export const Imprint = () => (
    <LegalAgreement
        text1=""
        linkText={translate('imprint')}
        linkRoute={ROUTES.IMPRINT}
        linkDatalayer={IMPRINT_LINK_DATALAYER}
        text2=""
    />
);
