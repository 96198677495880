import { ICourt } from 'usecases/types/court';
import { IReservation } from 'usecases/types/reservations';
import moment from 'moment';

export const getCalendarEvents = (
    courtList: ICourt[],
    reservationList: IReservation[],
) => reservationList.map(reservation => (
    {
        id: reservation.id,
        start: moment(reservation.startTime).format('YYYY-MM-DD HH:mm'),
        end: moment(reservation.endTime).format('YYYY-MM-DD HH:mm'),
        reservation: {
            ...reservation,
        },
        resourceId: courtList.find(courtInfo => courtInfo.id === reservation.court.id)?.id,
    }
));
