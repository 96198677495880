import React, { useState } from 'react';
import { Drawer } from 'antd';
import {
    MenuOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import { LOGO_TEXTLESS } from 'config/constants';
import { closeNavbar, showNavbar } from 'redux/actions/navbarVisibility';
import './NavBarComponent.scss';

interface IProps {
    navbarVisibility: boolean;
    menu: JSX.Element;
    dispatch: (action: any) => void,
}

export const NavBarComponent = ({
    navbarVisibility,
    menu,
    dispatch,
} : IProps): JSX.Element => {
    const [showCloseIcon, setShowCloseIcon] = useState(navbarVisibility);

    return (
        <nav className="navbar">
            <div className="navbar__container">
                <MenuOutlined
                    className="navbar__button"
                    onClick={() => dispatch(showNavbar())}
                />
                <img
                    className="navbar__img"
                    src={LOGO_TEXTLESS}
                    alt="logo"
                />
            </div>
            <Drawer
                className="navbar__drawer"
                visible={navbarVisibility}
                closable
                placement="left"
                closeIcon={(
                    <CloseOutlined
                        className={`navbar__drawer-close-icon ${navbarVisibility && showCloseIcon
                            ? 'navbar__drawer-close-icon--visible' : ''}`}
                        alt="close_icon"
                    />
                )}
                onClose={() => dispatch(closeNavbar())}
                afterVisibleChange={visibleValueAfterAnimation => setShowCloseIcon(visibleValueAfterAnimation)}
            >
                {menu}
            </Drawer>
        </nav>
    );
};
