import React from 'react';
import { Button, Image } from 'antd';
import { translate } from 'translations/translate';
import { MINUS_ICON, PLUS_ICON } from 'config/constants';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { getDecreaseTimeDataLayer, getIncreaseTimeDataLayer } from 'services/gtm/gtmDatalayers';
import { getTimeText } from 'components/Reservations/BookingDialog/timeHelpers';
import {
    calculateDurationWithBoundaries,
    MAX_DURATION,
    MIN_DURATION,
    STEP_DURATION,
} from '../DurationRow/calculateDurationWithBoundaries';
import './Duration.scss';

interface IProps {
    onChange: (duration: number) => any;
    duration: number;
    isNewReservation: boolean
}

export const Duration = ({
    onChange,
    duration,
    isNewReservation,
}: IProps) => (
    <div className="duration">
        <p className="duration__title">{translate('calendar-reservation_dialog-duration_selector_label')}</p>
        <div className="duration__time-cnt">
            <div className="duration__button-cnt duration__button-cnt--margin-right">
                <Button
                    type="default"
                    className={`duration__time-btn ${!isNewReservation ? 'duration--disabled' : ''}`}
                    onClick={() => {
                        const calculatedDuration = calculateDurationWithBoundaries(duration - STEP_DURATION);
                        onChange(calculatedDuration);
                        pushGtmDatalayer(getDecreaseTimeDataLayer());
                    }}
                    disabled={duration <= MIN_DURATION || !isNewReservation}
                >
                    <Image
                        preview={false}
                        height={2}
                        width={14}
                        src={MINUS_ICON}
                        alt="minus-icon"
                    />
                </Button>
            </div>
            <div className={`duration__duration-label ${!isNewReservation ? 'duration--disabled' : ''}`}>
                <p>
                    {getTimeText(duration)}
                </p>
            </div>
            <div className="duration__button-cnt">
                <Button
                    type="default"
                    className={`duration__time-btn ${!isNewReservation ? 'duration--disabled' : ''}`}
                    onClick={() => {
                        const calculatedDuration = calculateDurationWithBoundaries(duration + STEP_DURATION);
                        onChange(calculatedDuration);
                        pushGtmDatalayer(getIncreaseTimeDataLayer());
                    }}
                    disabled={duration >= MAX_DURATION || !isNewReservation}
                >
                    <Image
                        preview={false}
                        height={14}
                        width={14}
                        src={PLUS_ICON}
                        alt="plus-icon"
                    />
                </Button>
            </div>
        </div>
    </div>
);
