import { PLACEHOLDERS, INVOICES } from 'config/endpoints';
import { getWrapper } from 'services/httpService/httpServiceWrapper';
import { IGetInvoiceableBookingsInput } from 'usecases/types/dto/dto';
import { IInvoiceableBooking } from 'usecases/types/invoiceable-booking';

export const getInvoiceableBookings = async ({
    clubId,
    startDate,
    endDate,
} : IGetInvoiceableBookingsInput): Promise<IInvoiceableBooking[]> => {
    const baseUrl = INVOICES.INVOICEABLE_BOOKINGS
        .replace(PLACEHOLDERS.CLUB_ID, clubId);

    const urlParams = new URLSearchParams();
    if (startDate && endDate) {
        urlParams.append('startDate', startDate.toISOString());
        urlParams.append('endDate', endDate.toISOString());
    }

    const response = await getWrapper(`${baseUrl}?${urlParams.toString()}`);
    return response.data;
};
