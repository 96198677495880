import { BkgPriceEvent, BkgTimeSlot } from 'types';

export const buildBkgPriceEvent = (timeSlot: BkgTimeSlot): BkgPriceEvent => ({
    start: timeSlot.slotStartDate,
    end: timeSlot.slotEndDate,
    display: 'background',
    color: 'transparent',
    slotPrice: timeSlot.slotPrice,
    resourceIds: timeSlot.courts,
    role: timeSlot.role,
    isAdmin: timeSlot.isAdmin,
});
