import { DeleteModalContent } from 'components/DeleteModalContent/DeleteModalContent';
import { translate } from 'translations/translate';
import React from 'react';

interface IParams {
    onProceedCallback: (invoiceId: string) => void,
    onCancelCallback: () => void,
    multiple?: boolean,
}

export const getConfirmationModalContent = ({ onProceedCallback, onCancelCallback, multiple = false }: IParams) => (
    <DeleteModalContent
        headerText={translate(multiple
            ? 'invoices-page_cancel-multiple-confirmation-header'
            : 'invoices-page_cancel-confirmation-header')}
        cancelText={translate('invoices-page_cancel-confirmation-back')}
        proceedText={translate(multiple
            ? 'invoices-page_cancel-multiple-confirmation-proceed'
            : 'invoices-page_cancel-confirmation-proceed')}
        onProceed={onProceedCallback}
        onCancel={onCancelCallback}
    />
);
