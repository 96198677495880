import { Dict } from 'types';

export const arrayToMapByAttribute = <T>(list: T[], attribute?: string): Dict<T> => {
    const intialMap = {} as Dict<T>;

    const dictionary = list.reduce((acc, cur) => {
        acc[(cur as any)[attribute || 'id']] = cur;
        return acc;
    }, intialMap);

    return dictionary;
};
