import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useState } from 'react';

export const useCheckboxList = (initialState: string[] = []) => {
    const [checkboxesSelected, setCheckboxesSelected] = useState<string[]>(initialState);

    const handleChangeCheckbox = (event: CheckboxChangeEvent): void => {
        if (event.target.checked) {
            setCheckboxesSelected([...checkboxesSelected, event.target.name!]);
        } else {
            setCheckboxesSelected(checkboxesSelected.filter(idItem => idItem !== event.target.name));
        }
    };

    return {
        checkboxesSelected,
        handleChangeCheckbox,
        setCheckboxesSelected,
    };
};
