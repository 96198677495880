import React, { useRef } from 'react';
import {
    Button,
    Card, Checkbox, Form, Input, notification, Select,
} from 'antd';
import { Link, useParams } from 'react-router-dom';
import { translate } from 'translations/translate';
import { customHistory } from 'routing/customHistory';
import { WhiteCardHeader } from 'pages/common/WhiteCardHeader/WhiteCardHeader';
import { useAppContext } from 'context/AppContext';
import { CreateUserRequest } from 'usecases/types/dto/CreateUserRequest';
import { createUser } from 'usecases/users/createUser';
import { ROUTES } from 'routing/routes.enum';
import { GenderEnum } from 'usecases/types/enums/gender.enum';
import { LanguageEnum } from 'usecases/types/enums/language.enum';
import {
    DesktopBlurredBackground,
} from 'components/Reservations/DesktopBlurredBackground/DesktopBlurredBackground';
import { PrivacyPolicy } from 'pages/common/Legal/PrivacyPolicy/PrivacyPolicy';
import { TermsOfService } from 'pages/common/Legal/TermsOfService/TermsOfService';
import { Imprint } from 'pages/common/Legal/Imprint/Imprint';
import 'pages/common/WhiteCard/WhiteCardForm.scss';
import { PLAYERROLECLIENT, PLAYERROLEMEMBER, PlayerRole } from 'types';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { USER_REGISTRATION_DATALAYER } from 'services/gtm/gtmDatalayers';
import { getAsyncSuccessfulUserRegistrationDatalayer } from 'services/gtm/getAsyncSuccessfulUserRegistrationDatalayer';
import './UserRegisterPage.scss';

interface IRouteProps {
    clubId: string;
}

interface IRegisterForm {
    email: string;
    name: string;
    surname: string;
    password: string;
    terms?: boolean;
    privacy?: boolean;
    gender: GenderEnum;
    language: LanguageEnum;
    role: PlayerRole;
}

const UserRegisterPage = (): JSX.Element => {
    const { isSpinnerVisible, setIsSpinnerVisible } = useAppContext();
    const { clubId } = useParams<IRouteProps>();
    const containerRef = useRef<HTMLElement>();
    const getRegisterFormValue = (values: IRegisterForm): CreateUserRequest => {
        const formValues: IRegisterForm = { ...values };
        delete formValues.terms;
        delete formValues.privacy;
        return {
            email: formValues.email,
            name: formValues.name,
            surname: formValues.surname,
            password: formValues.password,
            clubId,
            gender: formValues.gender,
            language: formValues.language,
            role: formValues.role,
        };
    };

    const register = async (values: IRegisterForm) => {
        pushGtmDatalayer(USER_REGISTRATION_DATALAYER);
        setIsSpinnerVisible(true);

        try {
            const request = getRegisterFormValue(values);
            const userCreatedInfo = await createUser(request);
            customHistory.push(ROUTES.EMAIL_VERIFICATION);

            const successfulUserRegistrationDatalayer = await
            getAsyncSuccessfulUserRegistrationDatalayer(userCreatedInfo, clubId);
            pushGtmDatalayer(successfulUserRegistrationDatalayer);
        } catch (error: any) {
            notification.error({
                message: translate('error'),
                description: error.message,
            });
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    return (
        <DesktopBlurredBackground>
            <Card className="user-register__card" style={{ maxWidth: '640px' }}>
                <WhiteCardHeader
                    pageTitleKey="user-register_title"
                    renderLegendText={() => (
                        <>
                            {translate('or')}
                            {' '}
                            <Link
                                to={ROUTES.LOGIN}
                                className="whitecard-header__top-link"
                            >
                                {translate('user-register_link')}
                            </Link>
                        </>
                    )}
                />
                <div ref={containerRef as any}>
                    <Form
                        requiredMark="optional"
                        onFinish={register}
                        name="form"
                        layout="vertical"
                    >
                        <Form.Item
                            name="email"
                            label={(
                                <label
                                    htmlFor="email"
                                    className="whitecard-form__label"
                                >
                                    {translate('e-mail')}
                                </label>
                            )}
                            rules={[{ required: true, message: translate('email-is-required') }]}
                        >
                            <Input
                                className="whitecard-form__custom-input"
                                id="email"
                                type="email"
                            />
                        </Form.Item>
                        <Form.Item
                            name="name"
                            label={(
                                <label
                                    htmlFor="name"
                                    className="whitecard-form__label"
                                >
                                    {translate('first-name')}
                                </label>
                            )}
                            rules={[{ required: true, message: translate('first-name-is-required') }]}
                        >
                            <Input
                                className="whitecard-form__custom-input"
                                id="name"
                                type="text"
                            />
                        </Form.Item>
                        <Form.Item
                            name="surname"
                            label={(
                                <label
                                    htmlFor="surname"
                                    className="whitecard-form__label"
                                >
                                    {translate('last-name')}
                                </label>
                            )}
                            rules={[{ required: true, message: translate('last-name-is-required') }]}
                        >
                            <Input
                                className="whitecard-form__custom-input"
                                id="surname"
                                type="text"
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label={(
                                <label
                                    htmlFor="password"
                                    className="whitecard-form__label"
                                >
                                    {translate('password')}
                                </label>
                            )}
                            rules={[{ required: true, message: translate('password-is-required') }]}
                        >
                            <Input
                                className="whitecard-form__custom-input"
                                id="password"
                                type="password"
                            />
                        </Form.Item>
                        <Form.Item
                            name="gender"
                            label={(
                                <label
                                    htmlFor="gender"
                                    className="whitecard-form__label"
                                >
                                    {translate('gender')}
                                </label>
                            )}
                            rules={[{ required: true, message: translate('gender-is-required') }]}
                        >
                            <Select
                                size="large"
                                className="whitecard-form__custom-input"
                                getPopupContainer={() => containerRef.current as HTMLElement}
                            >
                                <Select.Option value={GenderEnum.MALE}>{translate('male')}</Select.Option>
                                <Select.Option value={GenderEnum.FEMALE}>{translate('female')}</Select.Option>
                                <Select.Option value={GenderEnum.OTHER}>{translate('other')}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="language"
                            label={(
                                <label
                                    htmlFor="language"
                                    className="whitecard-form__label"
                                >
                                    {translate('language')}
                                </label>
                            )}
                            rules={[{ required: true, message: translate('language-is-required') }]}
                        >
                            <Select
                                size="large"
                                className="whitecard-form__custom-input"
                                getPopupContainer={() => containerRef.current as HTMLElement}
                            >
                                <Select.Option value={LanguageEnum.ENGLISH}>English</Select.Option>
                                <Select.Option value={LanguageEnum.GERMAN}>Deutsch</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="role"
                            label={(
                                <label
                                    htmlFor="role"
                                    className="whitecard-form__label"
                                >
                                    {translate('role')}
                                </label>
                            )}
                            rules={[{ required: true, message: translate('role-is-required') }]}
                        >
                            <Select
                                size="large"
                                className="whitecard-form__custom-input"
                                getPopupContainer={() => containerRef.current as HTMLElement}
                            >
                                <Select.Option value={PLAYERROLEMEMBER}>
                                    {translate(`role_${PLAYERROLEMEMBER}`)}
                                </Select.Option>
                                <Select.Option value={PLAYERROLECLIENT}>
                                    {translate(`role_${PLAYERROLECLIENT}`)}
                                </Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            className="whitecard-form__item"
                            name="terms"
                            rules={[{
                                required: true,
                                transform: value => (value || undefined),
                                type: 'boolean',
                                message: translate('terms-is-required'),
                            }]}
                            valuePropName="checked"
                        >
                            <Checkbox>
                                <TermsOfService route={ROUTES.TERMS} />
                            </Checkbox>
                        </Form.Item>
                        <Form.Item
                            className="whitecard-form__item"
                            name="privacy"
                            rules={[{
                                required: true,
                                transform: value => (value || undefined),
                                type: 'boolean',
                                message: translate('privacy-is-required'),
                            }]}
                            valuePropName="checked"
                        >
                            <Checkbox>
                                <PrivacyPolicy route={ROUTES.PRIVACY_POLICY} />
                            </Checkbox>
                        </Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="whitecard-form__login-button"
                            disabled={isSpinnerVisible}
                        >
                            {translate('user_register-sign_up')}
                        </Button>
                        <p className="whitecard-form__bottom-text">
                            {translate('user_register-already-a-member')}
                            {' '}
                            <Link className="whitecard-form__bottom-link" to={ROUTES.LOGIN}>
                                {translate('user-register_bottom-link')}
                            </Link>
                        </p>
                        <Imprint />
                    </Form>
                </div>
            </Card>
        </DesktopBlurredBackground>
    );
};

export default UserRegisterPage;
