export const disableReservationsCalendarScrollbar = (): void => {
    const disableBodyScrollingClass = 'body--scroll-disabled';
    const disableCalendarScrollingClass = 'reservations-calendar--calendar-scroll-disable';

    const scrollPosition = window.scrollY;
    const body = document.querySelector('body') as HTMLElement;
    const calendarElement = document.getElementsByClassName('calendar-container')[0] as HTMLElement;
    body.classList.add(disableBodyScrollingClass);
    calendarElement.classList.add(disableCalendarScrollingClass);
    body.style.top = `-${scrollPosition}px`;
};
