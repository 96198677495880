import { PLACEHOLDERS, PRICING_RULES } from 'config/endpoints';
import { IPricingRule } from 'usecases/types/price';
import { putWrapper } from 'services/httpService/httpServiceWrapper';
import moment from 'moment';
import { IUpdatePriceInput } from 'usecases/types/dto/dto';

export const updatePricingRule = async ({
    clubId,
    name,
    weekdays,
    courts,
    startTime: _startTime,
    endTime: _endTime,
    role,
    amount,
    seasonId,
    priceId,
    guestFee,
}: IUpdatePriceInput): Promise<IPricingRule> => {
    const startTime = moment(_startTime, 'HH:mm').format('HH:mmZ');
    const endTime = moment(_endTime, 'HH:mm').format('HH:mmZ');
    const payLoad = {
        name,
        weekdays,
        courts,
        startTime,
        endTime,
        role,
        amount,
        seasonId,
        priceId,
        guestFee,
    };

    const url = PRICING_RULES.SINGLE
        .replace(PLACEHOLDERS.CLUB_ID, clubId)
        .replace(PLACEHOLDERS.SEASON_ID, seasonId)
        .replace(PLACEHOLDERS.PRICING_RULE_ID, priceId);

    const { data } = await putWrapper(url, payLoad);
    return data.pricingRule;
};
