import { getDateTimeFormatter } from 'adapters/dateTimeFormatter';

export const formatWeekDayDate = (date: Date, locale: string) => {
    const formatter = getDateTimeFormatter(
        locale,
        { weekday: 'short' },
    );

    return `${date.getDate()}. ${formatter.format(date)}`;
};
