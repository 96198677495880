import React from 'react';
import { translate } from 'translations/translate';
import { Button } from 'antd';
import './DateNotInCurrentSeasonInfo.scss';

interface IProps {
    onClose: () => void,
}
export const DateNotInCurrentSeasonInfo = ({
    onClose,
} :IProps): JSX.Element => (
    <div className="date-not-in-current-season-info__container">

        <p className="date-not-in-current-season-info__text">
            {translate('calendar_info-modal_header')}
        </p>

        <Button
            type="default"
            className="date-not-in-current-season-info__button date-not-in-current-season-info__button--second-button"
            onClick={onClose}
        >
            {translate('calendar_info-modal_second-button')}
        </Button>
    </div>
);
