import { PLACEHOLDERS, PRICING_RULES } from 'config/endpoints';
import { IPricingRule } from 'usecases/types/price';
import { getWrapper } from 'services/httpService/httpServiceWrapper';
import { getPricingRulesByClubAdapter } from './getPricingRulesByClubAdapter';

export const getPricingRulesByClub = async (clubId: string, seasonId: string): Promise<IPricingRule[]> => {
    const { data } = await getWrapper(PRICING_RULES.LIST
        .replace(PLACEHOLDERS.CLUB_ID, clubId)
        .replace(PLACEHOLDERS.SEASON_ID, seasonId));

    return getPricingRulesByClubAdapter(data);
};
