import React from 'react';
import { Button } from 'antd';
import { CHECK_ICON, WARNING_ICON } from 'config/constants';
import 'components/AlertModalContent/AlertModalContent.scss';

interface IProps {
    type: 'success' | 'warning',
    header: string,
    goBackText:string,
    seeDetailsText?: string,
    onGoBack: () => void,
    onSeeDetails?: () => void,
    subHeader?: string,
}

const iconsPerType = {
    success: CHECK_ICON,
    warning: WARNING_ICON,
};

export const AlertModalContent = ({
    type,
    header,
    goBackText,
    seeDetailsText,
    onGoBack,
    onSeeDetails = () => {},
    subHeader,
}: IProps): JSX.Element => (
    <div className="alert-modal-content__container">
        <img
            src={iconsPerType[type]}
            alt={`${iconsPerType[type]} icon`}
            className="alert-modal-content__image"
        />
        <p className={`alert-modal-content__text alert-modal-content__text--${type}`}>
            {header}
        </p>
        {subHeader && (
            <p className="alert-modal-content__text">
                {subHeader}
            </p>
        )}
        {seeDetailsText && (
            <Button
                type="primary"
                className="alert-modal-content__button alert-modal-content__button--first-button"
                onClick={() => onSeeDetails()}
            >
                {seeDetailsText}
            </Button>
        )}
        <Button
            type="default"
            className="alert-modal-content__button alert-modal-content__button--second-button"
            onClick={() => onGoBack()}
        >
            {goBackText}
        </Button>
    </div>
);
