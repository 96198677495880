import { CalendarViewEnum } from 'types';
import { ICourt } from 'usecases/types/court';

export interface IOpeningHours {
    openingTime: string;
    closingTime: string;
}

export const calculateCourtsOpeningHours = (
    selectedView: CalendarViewEnum,
    displayedCourt: ICourt | null,
    courts: ICourt[],
): IOpeningHours => {
    if (selectedView === CalendarViewEnum.WEEKVIEW && displayedCourt) {
        if (displayedCourt.openingTime > displayedCourt.closingTime) {
            return {
                openingTime: '00:00:00',
                closingTime: '23:59:59',
            };
        }
        return {
            openingTime: displayedCourt.openingTime,
            closingTime: displayedCourt.closingTime,
        };
    }
    if (!courts.length) {
        return {
            openingTime: '00:00:00',
            closingTime: '00:00:00',
        };
    }
    if (courts.find(court => court.openingTime > court.closingTime)) {
        return {
            openingTime: '00:00:00',
            closingTime: '23:59:59',
        };
    }
    return courts
        .reduce((acc, current) => {
            let { openingTime } = acc;
            let { closingTime } = acc;
            if (current.openingTime < acc.openingTime) {
                openingTime = current.openingTime;
            }
            if (current.closingTime > acc.closingTime) {
                closingTime = current.closingTime;
            }
            return {
                openingTime,
                closingTime,
            };
        }, {
            openingTime: courts[0]?.openingTime,
            closingTime: courts[0]?.closingTime,
        });
};
