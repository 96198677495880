import { PlayerRole } from 'types';
import { IInvoiceableBooking } from 'usecases/types/invoiceable-booking';

export const filterInvoiceableBookings = (
    invoiceableBookins: IInvoiceableBooking[],
    nameFilterValue: string,
    roleFilterValue: PlayerRole | '',
): IInvoiceableBooking[] => {
    let filteredInvoiceableBookins = invoiceableBookins;
    if (nameFilterValue) {
        filteredInvoiceableBookins = filteredInvoiceableBookins
            .filter(({ name, surname }) => (
                `${name} ${surname}`.toLowerCase().includes(nameFilterValue.toLowerCase())));
    }
    if (roleFilterValue !== '') {
        filteredInvoiceableBookins = filteredInvoiceableBookins.filter(({ role }) => role === roleFilterValue);
    }
    return filteredInvoiceableBookins;
};
