export enum DATALAYER_EVENT {
    GA = 'gaEvent',
    EEC_PRODUCT_CLICK = 'EECproductClick'
}

export enum DATALAYER_EVENT_CATEGORY {
    NAVIGATION = 'Navigation',
    FOREIGN_BOOKING = 'Foreign Booking',
    OUTBOUND_LINKS = 'Outbound Links',
    BOOKING = 'Booking',
    DATEVIEW = 'DateView',
    CUSTOMER_RELATIONS = 'Customer relations',
    FACILITY_CREATION = 'Facility Creation',
    FACILITY_JOINING = 'Facility joining',
}

export enum DATALAYER_EVENT_ACTION {
    SIDEBAR = 'Sidebar',
    COURT_SELECTOR = 'CourtSelector',
    IMPRESSUM = 'Impressum',
    ADD_GUEST = 'Add guest player',
    DELETE_GUEST = 'Delete guest player',
    ADD_PLAYER = 'Add player',
    DELETE_PLAYER = 'Delete player',
    BOOKING_TIME = 'Dauer',
    BOOKING_CANCEL = 'Close booking',
    PAGINATION = 'Pagination',
    CREATE_ACCOUNT = 'Create Account',
    REGISTRATION_SUBMIT = 'Registration submit',
    NEW_FACILITY_CREATED = 'Create new facility',
    CONFIRMATION = 'Confirmation',
    SUBMIT = 'Submit',
    REGISTER = 'Register',
    NEW_COURT_CREATED = 'Create court',
    NEW_SEASON_CREATED = 'Create season',
    NEW_PRICE_RULE = 'Create price rule',
    SEASON_EDITED = 'Edit season',
    ONLINE_PAYMENT_ENABLED = 'Enable online-payment',
    SETTLE_SINGLE_PLAYER = 'Settle 1 player',
    SETTLE_BULK_PLAYERS = 'Settle selected players',
    FACILITY_DEACTIVATED = 'Deactivate facility',
    JOIN_NEW_FACILITY = 'Join new facility',
    ACTIVATE_PLAYER = 'Activate player',
    EDIT_PLAYER = 'Edit player',
    MAKE_BOOKING = 'Make a booking',
    SHOW_BOOKING_AFTER_BOOKING_SUCCESSFUL = 'Show booking after successful booking',
    BACK_TO_CALENDAR_AFTER_BOOKING_SUCCESSFUL = 'Back to calendar after successful booking',
}

export enum DATALAYER_EVENT_LABEL {
    SUCCESSFULLY = 'successfully',
    UNSUCCESSFULLY = 'unsuccessfully',
}

export enum LOGIN_EVENT_LABEL {
    SUCESSFULLY = 'User logged in successfully',
    UNSUCCESSFULLY = 'User has not logged in successfully'
}

export enum LOGIN_CUSTOMER_TYPE {
    ADMIN = 'admin',
    USER = 'user'
}

export enum LOGIN_CUSTOMER_ROLE {
    MEMBER = 'member',
    CLIENT = 'client'
}
