import React from 'react';
import { isUserActive } from 'helpers/isUserActive';
import { ReservationsCalendar }
    from 'components/Reservations/ReservationsCalendar/ReservationsCalendar';
import { IUser } from 'types';
import { logoutUser } from 'services/logoutUser';

interface Iprops {
    loggedUser: IUser,
    showWaitingActivationModal: () => void,
}

const UserReservationsPage = ({ loggedUser, showWaitingActivationModal }:Iprops): JSX.Element => {
    const userActive = isUserActive(loggedUser);
    if (loggedUser.memberships === undefined) {
        logoutUser();
        return <></>;
    }

    return (
        <>
            <ReservationsCalendar
                userActive={userActive}
                loggedUser={loggedUser}
                showWaitingActivationModal={showWaitingActivationModal}
            />
        </>

    );
};

export default UserReservationsPage;
