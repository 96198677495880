import React from 'react';
import { Address } from 'types';

interface IBuildFacilityAddress {
    address: Address | null
}
export const BuildFacilityAddress = ({ address }: IBuildFacilityAddress) => {
    const addressLineFirstRow = address ? `${address.street} ${address.number}` : '';
    const addressLineSecondRow = address ? `${address.zipCode} ${address.city}, ${address.country}` : '';
    const separator = address ? <br /> : '';

    return (
        <>
            { addressLineFirstRow }
            { separator }
            { addressLineSecondRow }
        </>
    );
};
