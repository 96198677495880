import { getCalculatedReservationPrice } from 'usecases/reservation/getCalculatedReservationPrice';
import { ICalculatedPriceInput } from 'types';
import { IReservation } from 'usecases/types/reservations';
import { Interval } from 'usecases/types/enums/booking_interval.enum';

export const useBookingPriceFromApi = async (
    booking: IReservation,
    bookingTime: number,
    interval: Interval | null,
    endingOn: string | null,
): Promise<any> => {
    let payload: ICalculatedPriceInput = {
        ownerId: booking.owner!.id,
        courtId: booking.court.id,
        startDateTime: booking.startTime,
        hasGuests: booking.guests.length > 0,
        duration: bookingTime,
    };
    if (interval && endingOn) {
        payload = { ...payload, interval, endingOn };
    }
    const APIpricing = await getCalculatedReservationPrice(payload);
    return APIpricing;
};
