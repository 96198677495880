import { getClubFromStorage, setClubInStorage } from 'services/functionalStorage';
import { CLUBS, PLACEHOLDERS } from 'config/endpoints';
import { putWrapper } from 'services/httpService/httpServiceWrapper';
import { IUpdateClubInput } from 'usecases/types/dto/dto';

export const updateClub = async ({
    clubId,
    name,
    openingTime,
    closingTime,
    services,
    address,
    invoiceData,
    contactData,
    bookingExtraInformation,
}: IUpdateClubInput) => {
    const payload = {
        name,
        openingTime,
        closingTime,
        services,
        address,
        invoiceData,
        contactData,
        bookingExtraInformation,
    };

    const url = CLUBS.SINGLE.replace(PLACEHOLDERS.CLUB_ID, clubId);
    await putWrapper(url, payload);

    setClubInStorage({
        ...getClubFromStorage(), ...payload,
    });
};
