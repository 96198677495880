import { PLACEHOLDERS, USERS } from 'config/endpoints';
import { getWrapper } from 'services/httpService/httpServiceWrapper';

export const verifyEmail = async (email: string, token: string): Promise<string> => {
    const url = USERS.VERIFICATION
        .replace(PLACEHOLDERS.EMAIL, email)
        .replace(PLACEHOLDERS.TOKEN, token);

    const userVerificationResponse = await getWrapper(url);
    return userVerificationResponse.data.message;
};
