import { IHeaders } from 'types';

const globalHeaders: IHeaders = { 'Content-Type': 'application/json' };

export const addTokenAuthorizationHeader = (): IHeaders => {
    const token: string = localStorage.getItem('token') as string;
    if (token) {
        return ({
            Authorization: `Bearer ${token}`,
        });
    }

    return {};
};

export const buildHeaders = (headers: IHeaders): IHeaders => ({
    ...addTokenAuthorizationHeader(),
    ...globalHeaders,
    ...headers,
});
