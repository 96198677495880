import { COLLAPSE_SIDEBAR, UNCOLLAPSE_SIDEBAR, SidebarActions } from 'redux/types';

const initialState = true;

export const sidebarCollapsed = (
    // eslint-disable-next-line default-param-last
    state: boolean = initialState,
    action: SidebarActions,
): boolean => {
    switch (action.type) {
    case COLLAPSE_SIDEBAR:
        return true;

    case UNCOLLAPSE_SIDEBAR:
        return false;

    default:
        return state;
    }
};
