import { PlayerRole, IUser } from 'types';
import { getUserMembership } from 'helpers/getUserMembership';
import { IClub } from 'usecases/types/club';

export const filterMembers = (
    _members: IUser[],
    statusFilterValue: string,
    nameFilterValue: string,
    roleFilterValue: PlayerRole | '',
    club: IClub,
): IUser[] => {
    let filteredMembers = _members;
    if (statusFilterValue) {
        filteredMembers = filteredMembers.filter(user => getUserMembership(user, club)!.status === statusFilterValue);
    }
    if (nameFilterValue) {
        filteredMembers = filteredMembers
            .filter(({ name, surname }) => (
                `${name} ${surname}`.toLowerCase().includes(nameFilterValue.toLowerCase())));
    }
    if (roleFilterValue !== '') {
        filteredMembers = filteredMembers.filter(user => getUserMembership(user, club)!.role === roleFilterValue);
    }
    return filteredMembers;
};
