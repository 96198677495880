import React, { useEffect, useMemo, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import { CalendarViewEnum, PlayerRole } from 'types';
import { Calendar } from 'components/Reservations/ReservationsCalendar/Calendar/Calendar';
import { ICourt } from 'usecases/types/court';
import { loadEvents } from 'components/Reservations/ReservationsCalendar/loadEvents';
import { CHDateTime } from 'helpers/DateTime/CHDateTime';
import { ISeason } from 'usecases/types/season';
import { IPricingRule } from 'usecases/types/price';
import { IReservation } from 'usecases/types/reservations';
import { CalendarFilters } from 'components/CalendarFilters/CalendarFilters';
import CourtSelector from 'components/Reservations/TopRowFilters/CourtSelector';
import { WeekSelector } from 'components/Reservations/TopRowFilters/WeekSelector';
import { DaySelector } from 'components/Reservations/DaySelector/DaySelector';
import {
    getLastDesiredDayOfWeekFromDate,
} from 'components/Reservations/ReservationsCalendar/getLastDesiredDayOfWeekFromDate';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { getDayPaginationDatalayer, getWeekPaginationDatalayer } from 'services/gtm/gtmDatalayers';
import { DateClickArg } from '@fullcalendar/interaction';
import { adaptCourtListInfo } from 'components/Reservations/ReservationsCalendar/adaptCourtListInfo';
import { calculateCourtsOpeningHours } from 'components/Reservations/adapters/calculateOpeningHours';
import { calculateBusinessHours } from 'components/Reservations/ReservationsCalendar/calculateBusinessHours';

interface IProps {
    courts: ICourt[],
    seasons: ISeason[],
    pricingRules: IPricingRule[],
    bookings: IReservation[],
    role: PlayerRole,
    onEventClick: (extendedProps: Record<string, any>) => void,
    onDateClick: (event: DateClickArg) => void,
}

export const useCalendar = ({
    courts, seasons, pricingRules, bookings, role, onEventClick, onDateClick,
}: IProps) => {
    const [selectedView, setSelectedView] = useState<CalendarViewEnum>(CalendarViewEnum.DAYVIEW);
    const [displayedCourt, setDisplayedCourt] = useState<ICourt>(courts[0]);
    const initialDate = new CHDateTime().toUtc();
    const [filterDate, setFilterDate] = useState<CHDateTime>(initialDate);
    const [bookingDate, setBookingDate] = useState<CHDateTime>(initialDate);

    const calendarRef = React.useRef<FullCalendar>(null);

    const onWeekChange = async (_date: CHDateTime): Promise<void> => {
        const currentDayOfWeek = new Date().getDay();
        const newDate = getLastDesiredDayOfWeekFromDate(currentDayOfWeek, _date);
        const week = newDate.getWeek();
        pushGtmDatalayer(getWeekPaginationDatalayer({ diff: week - new CHDateTime().getWeek() }));
        setFilterDate(newDate.toUtc());
    };

    const onDayChange = async (newDayDate: CHDateTime): Promise<void> => {
        const dayDiff = newDayDate.diffInDays(new CHDateTime());
        pushGtmDatalayer(getDayPaginationDatalayer({ diff: dayDiff }));
        setFilterDate(newDayDate.toUtc());
    };

    useEffect(() => {
        if (calendarRef.current) {
            calendarRef.current.getApi().changeView(selectedView);
        }
    }, [selectedView]);

    useEffect(() => {
        if (!displayedCourt && courts.length > 0) {
            setDisplayedCourt(courts[0]);
        }
        if (!courts.includes(displayedCourt)) {
            setDisplayedCourt(courts[0]);
        }
    }, [courts]);

    const calendarEventsMemoized = useMemo(() => loadEvents({
        seasons,
        pricingRules,
        bookings,
        courts,
        selectedCourt: displayedCourt,
        view: selectedView,
        initialDate: filterDate,
    }), [bookings, courts, filterDate]);

    const moveToDate = (date: CHDateTime) => {
        setFilterDate(date.toUtc());
    };

    useEffect(() => {
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            calendarApi.gotoDate(filterDate.toNativeDate());
        }
    }, [filterDate, bookings]);

    return {
        displayCourt: setDisplayedCourt,
        displayedCourt,
        view: selectedView,
        filterDate,
        moveToDate,
        bookingDate,
        calendar: (
            // TODO: Let's think at a higher level about this piece of code, should not be more interesting if the
            // bookingDate state belongs to the calendar and we pass all the necessary data to it?
            // This way he could calculate the oopening hours, business hours and adapt the court list info by itself?
            <Calendar
                onDateClick={event => {
                    setBookingDate(CHDateTime.fromNativeDate(event.date).toUtc());
                    if (selectedView === CalendarViewEnum.DAYVIEW) {
                        const { resource: { extendedProps: { court: resourceCourt } } }: any = event;
                        setDisplayedCourt(resourceCourt);
                    }
                    onDateClick(event);
                }}
                openingHours={calculateCourtsOpeningHours(selectedView, displayedCourt, courts)}
                businessHours={calculateBusinessHours(displayedCourt, pricingRules, filterDate, role)}
                onEventClick={onEventClick}
                ref={calendarRef}
                courtsAsResources={adaptCourtListInfo(courts, pricingRules, filterDate, role)}
                view={selectedView}
                events={calendarEventsMemoized}
            />
        ),
        filters: (
            <CalendarFilters
                filters={(
                    <>
                        {selectedView === CalendarViewEnum.WEEKVIEW && (
                            <>
                                <CourtSelector
                                    courts={courts}
                                    court={displayedCourt}
                                    onChange={setDisplayedCourt}
                                />
                                <WeekSelector
                                    dayDate={getLastDesiredDayOfWeekFromDate(new Date().getDay(), filterDate)}
                                    onWeekChange={onWeekChange}
                                />
                            </>
                        )}
                        {selectedView === CalendarViewEnum.DAYVIEW && (
                            <DaySelector
                                dayDate={filterDate}
                                onDayChange={onDayChange}
                            />
                        )}
                    </>
                )}
                selectedView={selectedView}
                setSelectedView={setSelectedView}
            />
        ),
    };
};
