/* eslint-disable react/no-unescaped-entities */
import { getProfileLanguage } from 'translations/getProfilelanguage';
import React from 'react';
import './GuestFeesExplanation.scss';

export const GuestFeesExplanation = () => {
    const profileLanguage = getProfileLanguage();
    const germanExplanation = () => (
        <>
            <h1 className="club-members-explanation__title">Gastgebühren</h1>
            <div className="club-members-explanation__text-container">
                <p className="club-members-explanation__text">
                    Die Liste „Gastgebühren“ zeigt dir deine Mitglieder an, welche mindestens eine Buchung mit einem
                    Gastspieler durchgeführt haben. Für jedes Mitglied wird die E-Mail-Adresse, die Anzahl der Buchungen
                    mit Gästen, die Anzahl aller Buchungen sowie der Gesamtbetrag aller Buchungen mit Gästen
                    dargestellt.
                </p>
                <p className="club-members-explanation__text">
                    Diese Liste gibt dir somit schnell Auskunft darüber, welche deiner Mitglieder am häufigsten mit
                    Gästen spielen.
                </p>
                <p className="club-members-explanation__text">
                    Folgende Funktionen stehen dir für diese Liste zur Verfügung:
                </p>
                <p className="club-members-explanation__text">1) Mitglied suchen</p>
                <p className="club-members-explanation__text">2) Nach Saison filtern</p>
                <p className="club-members-explanation__text">
                    3) Nach Buchungen mit Gästen, allen Buchungen oder nach dem Gesamtbetrag sortieren
                </p>
                <p className="club-members-explanation__text">
                    <b>Benötigst du weitere Hilfe? </b>
                    Dann schreibe eine E-Mail an
                    {' '}
                    <a
                        className="seasons-explanation__link"
                        href="mailto:courthero@tennis-point.de"
                    >
                        courthero@tennis-point.de
                    </a>
                </p>
            </div>
        </>
    );
    const englishExplanation = () => (
        <>
            <h1 className="club-members-explanation__title">Guest Fees</h1>
            <div className="club-members-explanation__text-container">
                <p className="club-members-explanation__text">
                    The list "Guest fees" shows you your members who have made at least one booking with a guest player.
                    For each member, the email address, the number of bookings with guests, the number of all bookings
                    and the total amount of all bookings with guests are displayed.
                </p>
                <p className="club-members-explanation__text">
                    This list thus gives you quick information about which of your members play most often with guests.
                </p>
                <p className="club-members-explanation__text">
                    The following functions are available to you for this list:
                </p>
                <p className="club-members-explanation__text">1) Search member</p>
                <p className="club-members-explanation__text">2) Filter by season</p>
                <p className="club-members-explanation__text">
                    3) Sort by bookings with guests, all bookings or by total amount.
                </p>
                <p className="club-members-explanation__text">
                    <b>Need more help? </b>
                    Then send an e-mail to
                    {' '}
                    <a
                        className="seasons-explanation__link"
                        href="mailto:courthero@tennis-point.de"
                    >
                        courthero@tennis-point.de
                    </a>
                </p>
            </div>
        </>
    );
    return (
        <div className="club-members-explanation">
            {profileLanguage === 'english'
                ? englishExplanation()
                : germanExplanation()}
        </div>

    );
};
