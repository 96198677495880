import { CreateCourtInput } from 'usecases/types/dto/CreateCourtInput';
import { COURTS, PLACEHOLDERS } from 'config/endpoints';
import { postWrapper } from 'services/httpService/httpServiceWrapper';
import moment from 'moment/moment';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { getCreateCourtDataLayer } from 'services/gtm/gtmDatalayers';
import { getUserFromStorage } from 'services/functionalStorage';

class CreateCourt {
    async execute({
        clubId, name, openingTime, closingTime, surface, location,
    }: CreateCourtInput) {
        const openingTimeFormatted = moment(openingTime, 'HH:mm');
        const closingTimeFormatted = moment(closingTime, 'HH:mm');
        const url = COURTS.LIST.replace(PLACEHOLDERS.CLUB_ID, clubId);
        const requestData = {
            name,
            openingTime: openingTimeFormatted.format('HH:mmZ'),
            closingTime: closingTimeFormatted.format('HH:mmZ'),
            surface,
            location,
        };

        const response = await postWrapper(url, requestData);
        const user = getUserFromStorage();
        const { court } = response.data;

        pushGtmDatalayer(getCreateCourtDataLayer(
            user.id,
            clubId,
            court.id,
        ));

        return {
            ...court,
            openingTime: moment(court.openingTime, 'HH:mmZ').local().format('HH:mm'),
            closingTime: moment(court.closingTime, 'HH:mmZ').local().format('HH:mm'),
        };
    }
}

export default new CreateCourt();
