import React from 'react';
import { GTMDataLayer } from 'types';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import 'pages/common/WhiteCard/WhiteCardForm.scss';

interface IProps {
    text1: string,
    linkText: string,
    linkRoute: string,
    linkDatalayer?: GTMDataLayer;
    text2: string,
}

export const LegalAgreement = ({
    text1, linkText, linkRoute, linkDatalayer, text2,
}: IProps) => (
    <p
        className="whitecard-form__checkbox-text"
    >
        {text1}
        {' '}
        <a
            className="whitecard-form__checkbox-link"
            href={linkRoute}
            onClick={() => (linkDatalayer
                ? pushGtmDatalayer(linkDatalayer)
                : null
            )}
            target="_blank"
            rel="noreferrer"
        >
            {linkText}
        </a>

        {'  '}
        <span>
            {text2}
        </span>
    </p>
);
