import { Rule } from 'antd/lib/form';
import { NamePath } from 'antd/lib/form/interface';
import { Moment } from 'moment';
import { translate } from 'translations/translate';

export const getMaxDateRuleValidator = (
    fieldName: string,
    yearsToAdd: number,
    messageKey: string,
) => ({ getFieldValue }: { getFieldValue: (name: NamePath) => any}) => ({
    validator(_: Rule, value: Moment) {
        const fieldValue = getFieldValue(fieldName);

        if (!fieldValue) {
            return Promise.resolve();
        }

        const nextYearFromStartDate = fieldValue.clone().add(yearsToAdd, 'years');
        const formEndDate = value.clone();

        const validationCheck = nextYearFromStartDate.isAfter(formEndDate);

        if (!nextYearFromStartDate.isValid() || validationCheck) {
            return Promise.resolve();
        }

        return Promise.reject(new Error(translate(messageKey)));
    },
});
