import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { collapseSidebar, unCollapseSidebar } from 'redux/actions/sidebarCollapsed';

const { Sider } = Layout;

interface IProps {
    menu: JSX.Element,
    dispatch: (action: any) => void,
    sidebarCollapsed: boolean,
}

export const SidebarComponent = ({
    menu,
    dispatch,
    sidebarCollapsed,
}: IProps): JSX.Element => {
    useEffect(
        () => () => dispatch(collapseSidebar()),
        [],
    );

    return (
        <Sider
            className="sidebar"
            collapsible
            collapsed={sidebarCollapsed}
            collapsedWidth="100"
            width={300}
            trigger={null}
            onMouseEnter={() => {
                if (sidebarCollapsed) {
                    dispatch(unCollapseSidebar());
                }
            }}
            onMouseLeave={() => {
                if (!sidebarCollapsed) {
                    dispatch(collapseSidebar());
                }
            }}
        >
            {menu}
        </Sider>
    );
};
