import { ErrorFromServer } from 'types';
import { buildQueryParams } from './buildQueryParams';
import { httpCall } from './httpCall';
import { handleErrorFromServer } from './handleErrorFromServer';
import { manageResponse } from './manageResponse';

export const getWrapper = async <T>(url: string, params = {}, headers = {}): Promise<T | any> => {
    try {
        const queryParams = buildQueryParams(params);
        const urlWithParams = `${url}${queryParams}`;
        const response = await httpCall({ url: urlWithParams, headers, method: 'GET' });

        return await manageResponse(response);
    } catch (error) {
        return handleErrorFromServer(error as ErrorFromServer);
    }
};

export const postWrapper = async <T>(url: string, data = {}, headers = {}): Promise<T | any> => {
    try {
        const response = await httpCall({
            url,
            headers,
            data,
            method: 'POST',
        });

        return await manageResponse(response);
    } catch (error) {
        return handleErrorFromServer(error as ErrorFromServer);
    }
};

export const putWrapper = async<T>(url: string, data = {}, headers = {}): Promise<T | any> => {
    try {
        const response = await httpCall({
            url,
            headers,
            data,
            method: 'PUT',
        });

        return await manageResponse(response);
    } catch (error) {
        return handleErrorFromServer(error as ErrorFromServer);
    }
};

export const patchWrapper = async <T>(url: string, data = {}, headers = {}): Promise<T | any> => {
    try {
        const response = await httpCall({
            url,
            headers,
            data,
            method: 'PATCH',
        });

        return await manageResponse(response);
    } catch (error) {
        return handleErrorFromServer(error as ErrorFromServer);
    }
};

export const deleteWrapper = async <T>(url: string, data = {}, headers = {}): Promise<T | any> => {
    try {
        const response = await httpCall({
            url,
            headers,
            data,
            method: 'DELETE',
        });

        return await manageResponse(response);
    } catch (error) {
        return handleErrorFromServer(error as ErrorFromServer);
    }
};

export const getFileWrapper = async (url: string, params = {}, headers = {}): Promise<Blob> => {
    try {
        const queryParams = buildQueryParams(params);
        const urlWithParams = `${url}${queryParams}`;
        const fileResponse = await httpCall({ url: urlWithParams, headers, method: 'GET' });

        if (!fileResponse.ok) {
            throw await fileResponse.json();
        }

        return fileResponse.blob();
    } catch (error) {
        return handleErrorFromServer(error as ErrorFromServer);
    }
};
