import { Radio, RadioGroupProps } from 'antd';
import { translate } from 'translations/translate';
import React from 'react';
import './CHRadioGroup.scss';

interface CHRadioGroupProps extends RadioGroupProps{
    radioOptions: {
        value: string | number | boolean,
        key: string
    }[],
    direction?: 'vertical' | 'horizontal',
    primarySelectedColor? : boolean,
    customClassName?: string
}

export const CHRadioGroup = ({
    radioOptions, direction = 'horizontal', primarySelectedColor = false, customClassName = '', ...rest
}: CHRadioGroupProps) => {
    const options = radioOptions.map(({ value, key }) => ({ label: translate(key), value }));
    const primaryColorForRadioButton = primarySelectedColor ? 'ch-radio-group--primary' : '';
    const directionModifier = `ch-radio-group--${direction}`;
    const classNameModifiers = `${directionModifier} ${primaryColorForRadioButton} ${customClassName}`;

    return (
        <Radio.Group
            className={`ch-radio-group ${classNameModifiers}`}
            options={options}
            {...rest}
        />
    );
};
