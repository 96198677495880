import React from 'react';
import { Button, notification } from 'antd';
import { translate } from 'translations/translate';
import { IUser } from 'types';
import { isUserActive } from 'helpers/isUserActive';
import { getUserInformationAndSetInStorage } from 'usecases/users/getUserInformationAndSetInStorage';
import 'components/Reservations/UserNotActiveNotice/UserNotActiveNotice.scss';

interface IProps {
    setLoggedUser: (user: IUser) => void,
    onUserInfoUpdated: () => void,
}

export const UserNotActiveNotice = ({ setLoggedUser, onUserInfoUpdated }: IProps) => {
    const handleReloadUserData = async () => {
        const userUpdated = await getUserInformationAndSetInStorage();

        if (isUserActive(userUpdated)) {
            notification.info({
                message: translate('membership_activation_notice-activated-notification-title'),
                description: translate('membership_activation_notice-activated-notification-body'),
            });
        } else {
            notification.info({
                message: translate('membership_activation_notice-still-inactive-notification-title'),
                description: translate('membership_activation_notice-still-inactive-notification-body'),
            });
        }
        setLoggedUser(userUpdated);
        onUserInfoUpdated();
    };

    return (
        <div className="user-not-active-notice">
            <p>{translate('membership_activation_notice-modal-first-paragraph')}</p>
            <p>{translate('membership_activation_notice-modal-second-paragraph')}</p>
            <Button
                type="primary"
                size="large"
                className="user-not-active-notice__close-button"
                onClick={handleReloadUserData}
            >
                {translate('membership_activation_notice-modal-refresh-button')}
            </Button>
        </div>
    );
};
