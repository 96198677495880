const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const PLACEHOLDERS = {
    USER_ID: '{userId}',
    CLUB_ID: '{clubId}',
    RESERVATION_ID: '{reservationId}',
    COURT_ID: '{courtId}',
    EMAIL: '{email}',
    TOKEN: '{token}',
    SEASON_ID: '{seasonId}',
    PLAYER_ID: '{playerId}',
    PRICING_RULE_ID: '{pricingRuleId}',
    START_TIME: '{startTime}',
    OWNER_ROLE: '{ownerRole}',
    INVOICE_NUMBER: '{invoiceNumber}',
    RECURRING_ID: '{recurringId}',
};

export const CLUBS = {
    SINGLE: `${baseUrl}/api/clubs/{clubId}`,
    MANY: `${baseUrl}/api/clubs`,
    CHANGE_LOGO: `${baseUrl}/api/clubs/{clubId}/logo`,
    MEMBERS: `${baseUrl}/api/clubs/{clubId}/members`,
    FEES: `${baseUrl}/api/clubs/{clubId}/members/fees`,
    MODIFY_MEMBER: `${baseUrl}/api/users/{userId}`,
    CHANGE_MEMBERSHIP_STATUS: `${baseUrl}/api/clubs/{clubId}/players/{userId}`,
    SEASON_LIST: `${baseUrl}/api/clubs/{clubId}/seasons`,
    SEASON_EDIT: `${baseUrl}/api/clubs/{clubId}/seasons/{seasonId}`,
    SEASON: `${baseUrl}/api/clubs/{clubId}/seasons/{seasonId}`,
    PLAYER_LIST: `${baseUrl}/api/clubs/{clubId}/players`,
    PLAYER: `${baseUrl}/api/clubs/{clubId}/players/{playerId}`,
    IMPORT_PLAYER: `${baseUrl}/api/clubs/{clubId}/players/import`,
    PAYMENT_PROVIDER_ONBOARDING_LINK: `${baseUrl}/api/clubs/{clubId}/payments/link`,
    PAYMENT_METHODS: `${baseUrl}/api/clubs/{clubId}/payment-methods`,
};

export const AUTH = {
    LOGIN: `${baseUrl}/api/login-check`,
};

export const PRICING_RULES = {
    LIST: `${baseUrl}/api/clubs/{clubId}/seasons/{seasonId}/pricing-rules`,
    SINGLE: `${baseUrl}/api/clubs/{clubId}/seasons/{seasonId}/pricing-rules/{pricingRuleId}`,
    RESERVATION: `${baseUrl}/api/prices/{courtId}?startTime={startTime}&ownerRole={ownerRole}`,
};

export const COURTS = {
    LIST: `${baseUrl}/api/clubs/{clubId}/courts`,
    SINGLE: `${baseUrl}/api/clubs/{clubId}/courts/{courtId}`,
};

export const RESERVATIONS = {
    LIST: `${baseUrl}/api/clubs/{clubId}/reservations`,
    SINGLE: `${baseUrl}/api/clubs/{clubId}/reservations/{reservationId}`,
    RECURRING: `${baseUrl}/api/clubs/{clubId}/recurring-booking`,
    CANCEL_RECURRING: `${baseUrl}/api/clubs/{clubId}/recurring-booking/{recurringId}`,
    CANCEL_SUBSCRIPTION: `${baseUrl}/api/clubs/{clubId}/subscription-booking/{recurringId}`,
};

export const INVOICES = {
    SINGLE: `${baseUrl}/api/clubs/{clubId}/invoices/{invoiceNumber}`,
    LIST: `${baseUrl}/api/clubs/{clubId}/invoices`,
    INVOICEABLE_BOOKINGS: `${baseUrl}/api/clubs/{clubId}/invoiceable-bookings`,
    CANCEL: `${baseUrl}/api/clubs/{clubId}/invoices`,
};

export const USERS = {
    LIST: `${baseUrl}/api/users`,
    VERIFICATION: `${baseUrl}/api/user/verify/{email}/{token}`,
    RESEND_VERIFICATION: `${baseUrl}/api/user/verify`,
    PASSWORD_RECOVERY: `${baseUrl}/api/user/password/recover`,
    PASSWORD_CHANGE: `${baseUrl}/api/user`,
    CHANGE_FORGOTTEN_PASSWORD: `${baseUrl}/api/user/password/change`,
    JOIN_FACILITY: `${baseUrl}/api/memberships`,
};

export const ACTIONS = {
    CALCULATE_PRICE: `${baseUrl}/api/calculate-price`,
};

export const PROFILE = {
    SINGLE: `${baseUrl}/api/user`,
    CREATE: `${baseUrl}/api/user`,
    AVATAR: `${baseUrl}/api/user/avatar`,
};
