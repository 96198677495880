import { DataLayerArgs } from 'react-gtm-module';
import { IUser } from 'types';
import { LOGIN_EVENT_LABEL } from './enums';
import { getLoginDataLayer } from './gtmDatalayers';

export const getAsyncLoginDataLayer = (user: IUser, facilityId?: string[]): DataLayerArgs['dataLayer'] => {
    const loginDataLayer = getLoginDataLayer({
        eventLabel: LOGIN_EVENT_LABEL.SUCESSFULLY,
        userId: user.id,
        facilityId,
    });

    return loginDataLayer;
};
