import React, { useEffect, useMemo, useState } from 'react';
import {
    Invoice, InvoiceStatus, PlayerRole,
} from 'types';
import { translate } from 'translations/translate';
import { getInvoices } from 'usecases/invoice/getInvoices';
import { getClubFromStorage } from 'services/functionalStorage';
import { CHDateTime } from 'helpers/DateTime/CHDateTime';
import { InvoicesFilters } from 'pages/admin/Invoices/InvoicesFilters/InvoicesFilters';
import { MobileFooter } from 'components/MobileFooter/MobileFooter';
import { ListPageHeader } from 'components/ListPageHeader/ListPageHeader';
import { BOOKKEEPING_ICON_INVOICES } from 'config/constants';
import { ListPageButton } from 'components/ListPageButton/ListPageButton';
import { InfoModalContent } from 'components/InfoModalContent/InfoModalContent';
import { useModalContext } from 'context/ModalContext';
import { InvoicesExplanation } from 'pages/admin/Invoices/InvoicesExplanation/InvoicesExplanation';
import { cancelInvoices } from 'usecases/invoice/cancelInvoices';
import { AlertModalContent } from 'components/AlertModalContent/AlertModalContent';
import { useApi } from 'hooks/useApi';
import { InfoBox } from 'components/InfoBox/InfoBox';
import { useMobileMediaQuery } from 'hooks/useMobileMediaQuery';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useCheckboxList } from 'hooks/useCheckboxList';
import { updateInvoiceStatus } from 'usecases/invoice/updateInvoiceStatus';
import { getUserMembership } from 'helpers/getUserMembership';
import { canInvoiceBeCancelled } from 'helpers/Invoice/canInvoiceBeCancelled';
import { InvoiceList } from './InvoicesList/InvoicesList';
import { getConfirmationModalContent } from './getConfirmationModalContent';

interface IFilters {
    name: string,
    status: InvoiceStatus | '',
    role: PlayerRole | '',
    invoiceNumber: string | '',
    paymentMethod: string | '',
}

export const InvoicesPage = (): JSX.Element => {
    const { isMobile } = useMobileMediaQuery();
    const { setModalContent, setModalContentProps } = useModalContext();
    const [invoiceList, setInvoiceList] = useState<Invoice[]>([]);
    const [filters, setFilters] = useState<IFilters>({
        name: '', status: '', role: '', invoiceNumber: '', paymentMethod: '',
    });
    const {
        checkboxesSelected, handleChangeCheckbox, setCheckboxesSelected,
    } = useCheckboxList();

    const callApi = useApi();

    const onDateRangeChange = async (from: CHDateTime, to: CHDateTime) => {
        const invoicesFromAPI = await callApi(getInvoices(getClubFromStorage(), from, to));
        setInvoiceList(invoicesFromAPI);
    };

    const showCancelSingleInvoiceModalSuccess = (multiple: boolean) => {
        setModalContentProps({
            closable: false,
        });
        setModalContent(
            <AlertModalContent
                type="success"
                header={multiple
                    ? translate('invoices-page_cancel-multiple-success-header')
                    : translate('invoices-page_cancel-success-header')}
                goBackText={translate('invoices-page_cancel-success-button')}
                onGoBack={() => { setModalContent(null); }}
            />,
        );
    };

    const cancelMultipleInvoices = async (invoiceIds: string[]) => {
        const { invoices: cancelledInvoices } = await callApi(cancelInvoices({
            invoiceIds,
            clubId: getClubFromStorage().id,
        }));

        const invoicesListFiltered = invoiceList.filter(invoice => !invoiceIds.includes(invoice.id));
        const newInvoiceList = [...invoicesListFiltered, ...cancelledInvoices];
        setInvoiceList(newInvoiceList);
        showCancelSingleInvoiceModalSuccess(invoiceIds.length > 1);
    };
    const cancelSingleInvoice = async (invoiceId: string) => {
        cancelMultipleInvoices([invoiceId]);
    };

    const showCancelMultipleInvoiceModal = () => {
        setModalContentProps({
            closable: false,
        });
        setModalContent(
            getConfirmationModalContent({
                onProceedCallback: () => {
                    cancelMultipleInvoices(checkboxesSelected).then(() => setCheckboxesSelected([]));
                },
                onCancelCallback: () => setModalContent(null),
                multiple: true,
            }),
        );
    };

    const showCancelSingleInvoiceModal = (invoiceId: string) => {
        setModalContentProps({
            closable: false,
        });
        setModalContent(
            getConfirmationModalContent({
                onProceedCallback: () => cancelSingleInvoice(invoiceId),
                onCancelCallback: () => setModalContent(null),
            }),
        );
    };

    const invoicesFiltered = useMemo(
        () => invoiceList.filter(invoice => {
            if (filters.name && !`${invoice.name} ${invoice.surname}`.toLocaleLowerCase().includes(
                filters.name.toLocaleLowerCase(),
            )) {
                return false;
            }
            if (filters.status && invoice.status !== filters.status) {
                return false;
            }
            if (filters.role !== '' && getUserMembership(invoice.user, getClubFromStorage())!.role !== filters.role) {
                return false;
            }
            if (filters.invoiceNumber !== '' && invoice.invoiceNumber !== filters.invoiceNumber) {
                return false;
            }
            if (filters.paymentMethod && invoice.paymentMethod !== filters.paymentMethod) {
                return false;
            }
            return true;
        }),
        [invoiceList, filters],
    );

    const onSelectAll = (e: CheckboxChangeEvent) => (e.target.checked
        ? setCheckboxesSelected(invoicesFiltered
            .filter(invoice => canInvoiceBeCancelled(invoice))
            .map(invoice => invoice.id))
        : setCheckboxesSelected([])
    );

    const getNameFilterOptions = () => {
        const fullNames = invoiceList.map(invoice => `${invoice.name} ${invoice.surname}`);
        // @ts-ignore
        return [...new Set(fullNames)];
    };

    const changeInvoiceStatus = async (invoiceId: string, currentInvoiceStatus: string) => {
        const invoiceIds = [invoiceId];
        const newStatus: string = currentInvoiceStatus === InvoiceStatus.UNPAID
            ? InvoiceStatus.PAID
            : InvoiceStatus.UNPAID;
        const { invoices: updatedInvoices } = await callApi(updateInvoiceStatus({
            invoiceIds,
            clubId: getClubFromStorage().id,
            newInvoiceStatus: newStatus,
        }));

        const invoicesListFiltered = invoiceList.filter(invoice => !invoiceIds.includes(invoice.id));
        const newInvoiceList = [...invoicesListFiltered, ...updatedInvoices];
        setInvoiceList(newInvoiceList);
    };

    const getInvoiceNumberOptions = () => invoiceList.map(invoice => invoice.invoiceNumber).sort();

    useEffect(
        () => setCheckboxesSelected([]),
        [invoicesFiltered],
    );

    const Filters = (
        <InvoicesFilters
            onDateRangeChange={onDateRangeChange}
            onNameFilterChange={name => setFilters(currentFilters => ({ ...currentFilters, name }))}
            onStatusFilterChange={status => setFilters(currentFilters => ({ ...currentFilters, status }))}
            // eslint-disable-next-line max-len
            onPaymentFilterChange={paymentMethod => setFilters(currentFilters => ({ ...currentFilters, paymentMethod }))}
            onRoleFilterChange={role => setFilters(currentFilters => ({ ...currentFilters, role }))}
            onInvoiceNumberChange={invoiceNumber => setFilters(currentFilters => (
                { ...currentFilters, invoiceNumber }))}
            nameFilterOptions={getNameFilterOptions()}
            invoiceNumberOptions={getInvoiceNumberOptions()}
        />
    );

    return (
        <div className="invoices-page">
            <ListPageHeader header={translate('invoices-page_title')} iconUrl={BOOKKEEPING_ICON_INVOICES}>
                <ListPageButton
                    onClick={() => {
                        setModalContentProps({
                            closable: true,
                            onCancel: () => setModalContent(null),
                        });
                        setModalContent(
                            <InfoModalContent>
                                <InvoicesExplanation />
                            </InfoModalContent>,
                        );
                    }}
                    grey
                    icon="?"
                    roundedIcon
                >
                    {translate('invoices-page_header-explanation-button')}
                </ListPageButton>
                <ListPageButton
                    onClick={showCancelMultipleInvoiceModal}
                    disabled={checkboxesSelected.length === 0}
                >
                    {translate('invoices-page_header-bulk-cancel-button')}
                </ListPageButton>
            </ListPageHeader>

            {!isMobile && Filters}

            {invoicesFiltered.length > 0 ? (
                <InvoiceList
                    invoices={invoicesFiltered}
                    cancelSingleInvoice={showCancelSingleInvoiceModal}
                    itemsSelected={checkboxesSelected}
                    onItemSelect={handleChangeCheckbox}
                    onSelectAll={onSelectAll}
                    changeInvoiceStatus={changeInvoiceStatus}
                />
            ) : <InfoBox>{translate('book-keeping-page_no-data')}</InfoBox>}

            {isMobile && <MobileFooter content={Filters} />}
        </div>
    );
};
