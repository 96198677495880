import React from 'react';
import {
    Button,
} from 'antd';
import { translate } from 'translations/translate';
import { DEFAULT_AVATAR } from 'config/constants';
import { IGuest } from 'types';
import { PlayerCard } from 'components/Reservations/PlayerCard/PlayerCard';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import {
    getAddGuestPlayerDataLayer, getDeleteGuestPlayerDataLayer,
} from 'services/gtm/gtmDatalayers';
import styles from './Guests.module.scss';

interface IProps {
    removeGuest: (player: IGuest) => void;
    guestList: IGuest[];
    onAddGuestClick: () => void;
    isNewReservation: boolean,
    loggedUserId: string,
    facilityId: string,
}

export const Guests = ({
    guestList = [], removeGuest, onAddGuestClick, isNewReservation, loggedUserId, facilityId,
} : IProps) => (
    <div className={styles.guests__container}>
        <p className={styles['guests__card-subtitle']}>
            {translate('calendar-reservation_dialog-guests_list_title')}
        </p>
        <div className={styles.guests__container}>
            <>
                {guestList.map(guest => (
                    <PlayerCard
                        image={DEFAULT_AVATAR}
                        playerName={`${guest.name} ${guest.surname}`}
                        showDeleteButton={isNewReservation}
                        removePlayer={() => {
                            pushGtmDatalayer(getDeleteGuestPlayerDataLayer(
                                loggedUserId,
                                facilityId,
                            ));
                            removeGuest(guest);
                        }}
                        key={`${guest.name}${guest.surname}`}
                    />
                ))}
            </>

        </div>
        <Button
            type="primary"
            htmlType="submit"
            className={styles['guests__profile-button']}
            onClick={() => {
                pushGtmDatalayer(getAddGuestPlayerDataLayer(
                    loggedUserId,
                    facilityId,
                ));
                onAddGuestClick();
            }}
            onMouseDown={event => event.preventDefault()}
            disabled={!isNewReservation}
        >
            {translate('calendar-reservation_dialog-add_guest_player')}
        </Button>
    </div>
);
