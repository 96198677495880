import React, { useState } from 'react';
import { Checkbox, notification, Upload } from 'antd';
import { Button } from 'components/Button/Button';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';
import { importCsvPlayers } from 'usecases/club/importCsvPlayers';
import { getClubFromStorage } from 'services/functionalStorage';
import { Report } from 'types';
import { translate } from 'translations/translate';
import { useAppContext } from 'context/AppContext';
import { CSV_EXAMPLE_FILE } from 'config/constants';
import { PlusOutlined } from '@ant-design/icons';
import './ImportPlayers.scss';

interface IProps {
    onImportSuccessful: () => void;
}

export const ImportPlayers = ({ onImportSuccessful }: IProps): JSX.Element => {
    const [checkboxTicked, setCheckboxTicked] = useState<boolean>(false);
    const [csvFile, setCsvFile] = useState<File | null>(null);
    const [report, setReport] = useState<Report | null>(null);
    const { setIsSpinnerVisible } = useAppContext();

    const handleUpload = async () => {
        setIsSpinnerVisible(true);
        try {
            const response = await importCsvPlayers(getClubFromStorage().id, csvFile!);
            setReport(response);
            onImportSuccessful();
        } catch (error: any) {
            notification.error({
                message: translate('error'),
                description: error.message,
            });
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    return (
        <div className="import-players">
            <h1 className="import-players__title">
                {translate('import-players-title')}
            </h1>
            <div className="import-players__csv-example">
                <h2 className="import-players__csv-example-title">
                    <span className="import-players__csv-example-icon">
                        i
                    </span>
                    {translate('import-players-modal_download-example-title')}
                </h2>
                <p>
                    {translate('import-players-modal_download-example-text')}
                </p>
                <a
                    href={CSV_EXAMPLE_FILE}
                    download={`${translate('import-players-modal_download-csv_file')}.csv`}
                    className="import-players__csv-example-button"
                >
                    <Button onClick={() => {}}>
                        {translate('import-players-modal_download-example-button')}
                    </Button>
                </a>
            </div>
            <Upload.Dragger
                className={`import-players__upload-button$ ${csvFile || !checkboxTicked
                    ? 'import-players__upload-button--hidden' : ''}`}
                disabled={!checkboxTicked}
                showUploadList={{ showRemoveIcon: true }}
                listType="picture"
                maxCount={1}
                accept=".csv"
                beforeUpload={(file: File) => {
                    setCsvFile(file);
                    return false;
                }}
                onRemove={() => setCsvFile(null)}
                data-testid="upload-dragger"
            >
                {!csvFile && (
                    <div>
                        <PlusOutlined />
                        <div>{translate('import-players-modal_upload-csv-input')}</div>
                    </div>
                )}
            </Upload.Dragger>
            <Button
                disabled={!checkboxTicked || !csvFile}
                onClick={handleUpload}
            >
                {translate('import-players-modal_import-players-button')}
            </Button>
            <div className="import-players__agreement">
                <Checkbox
                    onChange={({ target } : {target: CheckboxChangeEventTarget}) => setCheckboxTicked(target.checked)}
                >
                    {translate('import-players-modal_checkbox-text')}
                </Checkbox>
            </div>
            {report && (
                <div className="import-players__report">
                    <h1>{translate('import-players-modal_report-title')}</h1>
                    <h2>
                        {report.successful}
                        /
                        {report.processed}
                        {' '}
                        {translate('import-players-modal_report-summary')}
                    </h2>
                    {report.errors.length > 0 && (
                        <table>
                            <thead>
                                <tr>
                                    <th>{translate('import-players-modal_report-table_email')}</th>
                                    <th>{translate('import-players-modal_report-table_row')}</th>
                                    <th>{translate('import-players-modal_report-table_error')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {report.errors.map(error => (
                                    <tr key={error.row}>
                                        <td>{error.email}</td>
                                        <td>{error.row}</td>
                                        <td>{translate(`error-${error.error.code}`, error.error.data.errorValues)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            )}
        </div>
    );
};
