/* eslint-disable max-len */
import React from 'react';
import {
    Card,
} from 'antd';
import { DesktopBlurredBackground } from 'components/Reservations/DesktopBlurredBackground/DesktopBlurredBackground';
import './TermsPage.scss';

export const TermsPage = () => (
    <DesktopBlurredBackground>
        <Card className="user-terms__card">
            <h1 className="user-terms__card__title">Nutzungsbedingungen</h1>
            <h2>1. Geltungsbereich, Begriffsbestimmungen</h2>
            <p>
                <br />
                1.1 Diese Nutzungsbedingungen der Tennis-Point GmbH, Hans-B&ouml;ckler-Str. 29-35, 33442 Herzebrock-Clarholz (nachfolgend &bdquo;Anbieter&ldquo;) gelten f&uuml;r die Nutzung der vom Anbieter bereitgestellten Website mit der Bezeichnung &bdquo;Court Hero&ldquo; (nachfolgend &bdquo;Court Hero&ldquo;) durch einen Verbraucher oder Unternehmer (nachfolgend &bdquo;Nutzer&ldquo;). Der Einbeziehung von eigenen Bedingungen des Nutzers wird hiermit widersprochen, es sei denn, es ist etwas anderes vereinbart.
            </p>
            <p>
                <br />
                1.2 Verbraucher im Sinne dieser Nutzungsbedingungen ist jede nat&uuml;rliche Person, die ein Rechtsgesch&auml;ft zu Zwecken abschlie&szlig;t, die &uuml;berwiegend weder ihrer gewerblichen noch ihrer selbst&auml;ndigen beruflichen T&auml;tigkeit zugerechnet werden k&ouml;nnen.
            </p>
            <p>
                <br />
                1.3 Unternehmer im Sinne dieser Nutzungsbedingungen ist eine nat&uuml;rliche oder juristische Person oder eine rechtsf&auml;hige Personengesellschaft, die bei Abschluss eines Rechtsgesch&auml;fts in Aus&uuml;bung ihrer gewerblichen oder selbst&auml;ndigen beruflichen T&auml;tigkeit handelt.
            </p>
            <p>&nbsp;</p>
            <h2>2. Leistungen des Anbieters</h2>
            <p>
                <br />
                2.1 Der Anbieter stellt mit Court Hero eine Website f&uuml;r station&auml;re als auch mobile Endger&auml;te zur Verf&uuml;gung, &uuml;ber die Nutzer als Spieler Pl&auml;tze buchen k&ouml;nnen und als Platzbetreiber ihre Pl&auml;tze anlegen, verwalten und Spielern anbieten k&ouml;nnen.
            </p>
            <p>
                <br />
                2.2. Dem Nutzer werden folgende Funktionen zur Nutzung seitens des Anbieters zur Verf&uuml;gung gestellt:
            </p>
            <p>
                als Spieler:
            </p>
            <p>&bull; Registrierung</p>
            <p>&bull; &Auml;nderung der pers&ouml;nlichen Daten und der Zugangsdaten</p>
            <p>&bull; Buchung und Stornierung einer Platzbuchung im Rahmen der Buchungsregeln des Buchungssystems</p>
            <p>&bull; Bezahlung der Platzbuchungen mittels Online-Zahlung (je nach Anlage)</p>
            <p>&bull; Beitritt zu einer oder mehrerer Anlage/n;</p>
            <p>zus&auml;tzliche Funktionen als Platzbetreiber:</p>
            <p>&bull; Erstellung einer oder mehrerer Anlage/n</p>
            <p>&bull; Erstellung, Bearbeitung und L&ouml;schung von Pl&auml;tzen</p>
            <p>&bull; Erstellung und Bearbeitung von Spielern</p>
            <p>&bull; Erstellung, Bearbeitung und L&ouml;schung von Saisons</p>
            <p>&bull; Erstellung, Bearbeitung und L&ouml;schung von Preisregeln</p>
            <p>&bull; &Uuml;bersicht der Gastgeb&uuml;hren f&uuml;r Mitglieder</p>
            <p>&bull; Abrechnung von kostenlosen und bar bezahlten Buchungen</p>
            <p>&bull; Verwaltung von Rechnungen der Buchungen.</p>
            <p>
                <br />
                2.3 Der Anbieter schuldet keinen verbindlichen Anspruch f&uuml;r die Reservierung eines Platzes und in diesem Zusammenhang wird keine Haftung &uuml;bernommen. Der Platzbetreiber regelt die Verf&uuml;gbarkeit seiner angebotenen Pl&auml;tze.
            </p>
            <p>
                <br />
                2.4 Erstellt der Nutzer als Platzbetreiber eine Anlage, so hat er sie innerhalb von 2 Wochen nach Erstellung zu verifizieren. Hierzu erh&auml;lt der Platzbetreiber nach Erstellung eine E-Mail vom Anbieter. Zur Verifizierung hat der Platzbetreiber einen entsprechenden Nachweis (bspw. Auszugs seines Eintrags im Vereinsregister) per E-Mail an
                {' '}
                <a href="mailto:courthero@tennis-point.de">courthero@tennis-point.de</a>
                {' '}
                an den Anbieter zu senden. Wird eine Anlage nicht innerhalb von 2 Wochen verifiziert, so werden bis zur erfolgreichen Verifizierung die Funktionen in Court Hero eingeschr&auml;nkt und die Anlage ist nicht f&uuml;r andere Nutzer verf&uuml;gbar. Der Platzbetreiber wird &uuml;ber den Kalender dar&uuml;ber informiert, sobald die Nutzung f&uuml;r die Anlage eingeschr&auml;nkt ist.
            </p>
            <p>
                <br />
                2.5 Der Anbieter schuldet nicht die Aufbewahrung der Rechnungen des Platzbetreibers. Der Platzbetreiber ist zur Wahrung gesetzlicher oder sonstiger Pflichten f&uuml;r die Aufbewahrung seiner Rechnungen selbst verantwortlich.
            </p>
            <p>
                <br />
                2.6 Der Anbieter schuldet nicht die Herstellung und Aufrechterhaltung der Datenverbindung zwischen dem IT-System des Nutzers und dem Server des Anbieters.
            </p>
            <p>
                <br />
                2.7 Court Hero wird unter dem Vorbehalt der Verf&uuml;gbarkeit angeboten. Eine Verf&uuml;gbarkeit zu 100 Prozent ist technisch nicht zu realisieren und kann deshalb dem Nutzer vom Anbieter nicht gew&auml;hrleistet werden. Der Anbieter bem&uuml;ht sich jedoch, den Dienst m&ouml;glichst konstant verf&uuml;gbar zu halten. Insbesondere Wartungs-, Sicherheits- oder Kapazit&auml;tsbelange sowie Ereignisse, die nicht im Machtbereich des Anbieters stehen (St&ouml;rungen von &ouml;ffentlichen Kommunikationsnetzen, Stromausf&auml;lle etc.), k&ouml;nnen zu St&ouml;rungen oder zur vor&uuml;bergehenden Stilllegung des Dienstes f&uuml;hren. Der Anbieter wird die Wartungsarbeiten, soweit dies m&ouml;glich ist, in nutzungsarmen Zeiten durchf&uuml;hren.
            </p>
            <p>
                <br />
                2.8 Die Website wird in unregelm&auml;&szlig;igen Abst&auml;nden vom Anbieter aktualisiert. Der Nutzer erh&auml;lt dementsprechend nur ein Nutzungsrecht an der Website in der jeweils aktuellen Fassung. Der Nutzer hat dagegen keinen Anspruch auf Herbeif&uuml;hrung eines bestimmten Zustandes der Website.
            </p>
            <p>&nbsp;</p>
            <h2>3. Leistungs&auml;nderungen</h2>
            <p>
                <br />
                3.1 Der Anbieter beh&auml;lt sich vor, die angebotenen Leistungen zu &auml;ndern oder abweichende Leistungen anzubieten, es sei denn, dies ist f&uuml;r den Nutzer nicht zumutbar.
            </p>
            <p>
                <br />
                3.2 Der Anbieter beh&auml;lt sich dar&uuml;ber hinaus vor, die angebotenen Leistungen zu &auml;ndern oder abweichende Leistungen anzubieten,
            </p>
            <p>&bull; soweit er hierzu aufgrund einer &auml;nderung der Rechtslage verpflichtet ist;</p>
            <p>&bull; soweit er damit einem gegen sich gerichteten Gerichtsurteil oder einer Beh&ouml;rdenentscheidung nachkommt;</p>
            <p>&bull; soweit die jeweilige &auml;nderung notwendig ist, um bestehende Sicherheitsl&uuml;cken zu schlie&szlig;en;</p>
            <p>&bull; wenn die &auml;nderung lediglich vorteilhaft f&uuml;r den Nutzer ist; oder</p>
            <p>&bull; wenn die &auml;nderung rein technischer oder prozessualer Natur ohne wesentliche Auswirkungen f&uuml;r den Nutzer ist.</p>
            <p>
                <br />
                3.3 &Auml;nderungen mit lediglich unwesentlichem Einfluss auf die Funktionen der Website stellen keine Leistungs&auml;nderungen im Sinne dieser Ziffer dar. Dies gilt insbesondere f&uuml;r &auml;nderungen rein graphischer Art und die blo&szlig;e &auml;nderung der Anordnung von Funktionen.
            </p>
            <p>&nbsp;</p>
            <h2>
                <br />
                4. Registrierung
            </h2>
            <p>
                <br />
                4.1 Der Nutzer kann sich unter Angabe seiner pers&ouml;nlichen Daten f&uuml;r Court Hero registrieren. Die Registrierung erfolgt durch Er&ouml;ffnung eines Nutzerkontos, welches der Nutzer &uuml;ber Court Hero einrichten kann.
            </p>
            <p>
                <br />
                4.2 Nicht unbeschr&auml;nkt gesch&auml;ftsf&auml;hige nat&uuml;rliche Personen (insbesondere Minderj&auml;hrige) d&uuml;rfen Court Hero nur mit der Zustimmung ihres gesetzlichen Vertreters nutzen. Der Anbieter beh&auml;lt sich vor, die Nutzung von Court Hero in diesem Fall von der Vorlage einer schriftlichen Zustimmungserkl&auml;rung des gesetzlichen Vertreters abh&auml;ngig zu machen.
            </p>
            <p>
                <br />
                4.3 Jeder Nutzer kann nur ein Nutzerkonto f&uuml;r Court Hero einrichten. Seine Berechtigung zur Nutzung von Court Hero gilt nur f&uuml;r ihn pers&ouml;nlich und ist nicht &uuml;bertragbar.
            </p>
            <p>
                <br />
                4.4 Die im Rahmen der Registrierung abgefragten Daten sind vom Nutzer vollst&auml;ndig und korrekt anzugeben. Der Nutzer ist verpflichtet, diese Daten (auch E-Mail-Adressen) stets auf dem aktuellen Stand zu halten. Die &uuml;bermittelten Daten werden vom Anbieter grunds&auml;tzlich nicht auf ihre Richtigkeit und Vollst&auml;ndigkeit &uuml;berpr&uuml;ft.
            </p>
            <p>
                <br />
                4.5 Wird ein Spieler durch einen Platzbetreiber bei Court Hero angelegt, so erh&auml;lt der Spieler vom Anbieter eine E-Mail, dass er eingeladen wurde, ein pers&ouml;nliches Nutzerkonto bei Court Hero zu erstellen. Durch Erstellen eines Passworts nimmt der Spieler eine Registrierung im Sinne der Ziffer 4.1 vor.
            </p>
            <h2>
                <br />
                5. Vertragsschluss
            </h2>
            <p>
                <br />
                5.1 Durch die Registrierung eines Nutzers kommt zwischen dem Nutzer und dem Anbieter ein Nutzungsvertrag &uuml;ber die Nutzung von Court Hero zustande. Der Vertragstext wird vom Anbieter gespeichert, ist dem Nutzer nach Abgabe von dessen Vertragserkl&auml;rung jedoch nicht mehr zug&auml;nglich.
            </p>
            <p>
                <br />
                5.2 F&uuml;r den Vertragsschluss steht ausschlie&szlig;lich die deutsche Sprache zur Verf&uuml;gung.
            </p>
            <h2>
                <br />
                6. Pflichten des Nutzers, Verantwortlichkeit f&uuml;r Inhalte
            </h2>
            <p>
                <br />
                6.1 Der Nutzer haftet grunds&auml;tzlich f&uuml;r alle Aktivit&auml;ten, die unter Verwendung seines Zugangs vorgenommen werden, soweit er diese zu vertreten hat. F&uuml;r die Geheimhaltung der Zugangsdaten ist der Nutzer verantwortlich. Er hat daf&uuml;r Sorge zu tragen, dass sie Dritten nicht zug&auml;nglich sind. Der Nutzer hat den Anbieter umgehend zu informieren, wenn es Anhaltspunkte daf&uuml;r gibt, dass sein Zugang von Dritten genutzt wird oder wurde.
            </p>
            <h2>
                <br />
                7. Rechte des Anbieters
            </h2>
            <p>
                <br />
                7.1 Verst&ouml;&szlig;t der Nutzer gegen gesetzliche Bestimmungen oder gegen diese Nutzungsbedingungen oder liegen dem Anbieter konkrete Anhaltspunkte hierf&uuml;r vor, ist der Anbieter berechtigt, ohne Vorank&uuml;ndigung und weitere Pr&uuml;fung
            </p>
            <p>&bull; damit zusammenh&auml;ngende Eintr&auml;ge des Nutzers zu l&ouml;schen,</p>
            <p>&bull; Eintr&auml;ge im Rahmen des Bearbeitungsrechts gem&auml;&szlig; Ziffer 9 zu &auml;ndern,</p>
            <p>&bull; eine Abmahnung auszusprechen,</p>
            <p>&bull; den Zugang des Nutzers vor&uuml;bergehend oder dauerhaft zu sperren oder</p>
            <p>&bull; sonstige erforderliche und geeignete Ma&szlig;nahmen zu ergreifen.</p>
            <p>Welche Ma&szlig;nahme der Anbieter ergreift, ist einzelfallabh&auml;ngig und steht im billigen Ermessen des Anbieters.</p>
            <p>
                <br />
                7.2 Wird ein Nutzer gesperrt, darf er Court Hero nicht mehr nutzen und sich nicht erneut hierf&uuml;r registrieren.
            </p>
            <p>
                <br />
                7.3 Der Anbieter beh&auml;lt sich ausdr&uuml;cklich vor, wegen eines Versto&szlig;es des Nutzers gegen gesetzliche Bestimmungen oder gegen diese Nutzungsbedingungen rechtliche Schritte einzuleiten.
            </p>
            <h2>
                <br />
                8. Einr&auml;umung von Nutzungsrechten durch den Anbieter
            </h2>
            <p>
                <br />
                8.1 Der Anbieter r&auml;umt dem Nutzer ein einfaches, nicht &uuml;bertragbares, auf die Dauer des Nutzungsvertrages befristetes Recht ein, Court Hero im Rahmen dieser Nutzungsbedingungen zu privaten oder beruflichen Zwecken zu nutzen.
            </p>
            <p>
                <br />
                8.2 Eine kommerzielle Verwertung der Website ist nicht erlaubt.
            </p>
            <p>
                <br />
                8.3 Ein Bearbeitungsrecht wird dem Nutzer nicht einger&auml;umt. Dies verbietet insbesondere auch Ver&auml;nderungen der Software im Rahmen des Reverse-Engineering.
            </p>
            <p>
                <br />
                8.4 Der Nutzer darf Court Hero Dritten nicht zug&auml;nglich machen, sofern dies nicht zur Verwirklichung des Nutzungszwecks der Website zwingend erforderlich ist. Der Nutzer tr&auml;gt daf&uuml;r Sorge, dass er Dritten keine Umgehungsm&ouml;glichkeit f&uuml;r den Zugang zu Court Hero er&ouml;ffnet.
            </p>
            <p>
                <br />
                8.5 Verletzt der Nutzer die getroffene Vereinbarung zu den Nutzungsrechten so schwerwiegend, dass dem Anbieter eine Fortsetzung des Vertragsverh&auml;ltnisses unter Ber&uuml;cksichtigung der beiderseitigen Interessen nicht zumutbar ist, kann der Anbieter die Einr&auml;umung der Nutzungsrechte ohne vorherige Abmahnung au&szlig;erordentlich k&uuml;ndigen und den Zugang zu Court Hero dauerhaft sperren.
            </p>
            <p>
                <br />
                8.6 Die sonstigen gesetzlichen und vertraglichen Regelungen bleiben unber&uuml;hrt.
            </p>
            <h2>
                <br />
                9. Einr&auml;umung von Nutzungsrechten durch den Nutzer
            </h2>
            <p>
                <br />
                Der Anbieter ist berechtigt, von Nutzern zur Verf&uuml;gung gestellte Inhalte und Informationen f&uuml;r Court Hero zu nutzen oder durch Dritte nutzen zu lassen. Der Nutzer r&auml;umt dem Anbieter unentgeltlich nicht ausschlie&szlig;lich und auf die Dauer des Nutzungsvertrages sowie einer dar&uuml;ber hinausgehenden, sich an den Zeitpunkt der Beendigung des Nutzungsvertrages anschlie&szlig;enden &uuml;bergangsfrist von f&uuml;nf Tagen beschr&auml;nkt die hierf&uuml;r erforderlichen Nutzungsrechte, insbesondere das Recht zur dauerhaften Vorhaltung und Speicherung und zur &ouml;ffentlichen Zug&auml;nglichmachung, das Vervielf&auml;ltigungs- und Ver&ouml;ffentlichungsrecht sowie das Bearbeitungs- und Verbreitungsrecht ein und sichert zu, zu dieser Nutzungsrechtseinr&auml;umung berechtigt zu sein.
            </p>
            <h2>
                <br />
                10. Verg&uuml;tung und Zahlungsbedingungen, Stornierung von Buchungen
            </h2>
            <p>
                <br />
                10.1 Die Nutzung der Plattform Court Hero ist f&uuml;r Nutzer grunds&auml;tzlich kostenlos.
            </p>
            <p>
                <br />
                10.2 Sofern bei einer Platzbuchung Geb&uuml;hren anfallen, k&ouml;nnen diese direkt zwischen Spieler und Platzbetreiber abgerechnet werden. Zudem kann der Platzbetreiber die Bezahlmethode &bdquo;Online-Zahlung&ldquo; mit Hilfe des Zahlungsdienstleisters Stripe integrieren, &uuml;ber welche Spieler die Zahlung direkt bei der Platzbuchung vornehmen k&ouml;nnen. Die Integration von Stripe in Court Hero erfordert eine vorherige Registrierung des Platzbetreibers bei Stripe. Bei Zahlungsabwicklungen &uuml;ber Stripe gelten die Nutzungsbedingungen und Transaktionsgeb&uuml;hren von Stripe.
            </p>
            <p>
                <br />
                10.3 Bei einer Zahlung &uuml;ber Stripe wird eine Plattformgeb&uuml;hr in H&ouml;he von 2,5% des Buchungsbetrags aufgrund der dem Anbieter entstandenen Kosten direkt &uuml;ber Stripe abgef&uuml;hrt. Dem Spieler entstehen hierdurch keine zus&auml;tzlichen Kosten.
            </p>
            <p>
                <br />
                10.4 Der Spieler kann kostenpflichtige Buchungen bis sp&auml;testens 6 Stunden vor Beginn der Platzbuchung kostenfrei &uuml;ber Court Hero stornieren. Der Spieler erh&auml;lt den vollen Buchungsbetrag zur&uuml;ck. Entstandene Transaktionsgeb&uuml;hren von Stripe und die Plattformgeb&uuml;hr des Anbieters hat der Platzbetreiber zu tragen.
            </p>
            <p>
                <br />
                10.5 Veranlasst der Platzbetreiber &uuml;ber Court Hero eine R&uuml;ckerstattung an den Spieler, so erh&auml;lt der Spieler den vollen Buchungsbetrag zur&uuml;ck. Der Platzbetreiber hat entstandene Transaktionsgeb&uuml;hren von Stripe sowie die Plattformgeb&uuml;hr des Anbieters zu tragen.
            </p>
            <p>
                <br />
                10.6 Veranlasst der Platzbetreiber &uuml;ber das Stripe Dashboard eine R&uuml;ckerstattung an den Spieler, so erh&auml;lt der Spieler den vollen Buchungsbetrag zur&uuml;ck. Der Platzbetreiber hat entstandene Transaktionsgeb&uuml;hren von Stripe sowie die Plattformgeb&uuml;hr des Anbieters zu tragen. Eine Stornierung der Buchung im Kalender erfolgt in diesem Fall nicht.
            </p>
            <p>&nbsp;</p>
            <h2>11. Haftung</h2>
            <p>
                <br />
                Der Anbieter haftet dem Nutzer aus allen vertraglichen, vertrags&auml;hnlichen und gesetzlichen, auch deliktischen Anspr&uuml;chen auf Schadens- und Aufwendungsersatz wie folgt:
            </p>
            <p>
                <br />
                11.1 Der Anbieter haftet aus jedem Rechtsgrund uneingeschr&auml;nkt
            </p>
            <p>&bull; bei Vorsatz oder grober Fahrl&auml;ssigkeit,</p>
            <p>&bull; bei vors&auml;tzlicher oder fahrl&auml;ssiger Verletzung des Lebens, des K&ouml;rpers oder der Gesundheit,</p>
            <p>&bull; aufgrund eines Garantieversprechens, soweit diesbez&uuml;glich nichts anderes geregelt ist,</p>
            <p>&bull; aufgrund zwingender Haftung wie etwa nach dem Produkthaftungsgesetz.</p>
            <p>
                <br />
                11.2 Verletzt der Anbieter fahrl&auml;ssig eine wesentliche Vertragspflicht, ist die Haftung auf den vertragstypischen, vorhersehbaren Schaden begrenzt, sofern nicht gem&auml;&szlig; vorstehender Ziffer unbeschr&auml;nkt gehaftet wird. Wesentliche Vertragspflichten sind Pflichten, die der Vertrag dem Anbieter nach seinem Inhalt zur Erreichung des Vertragszwecks auferlegt, deren Erf&uuml;llung die ordnungsgem&auml;&szlig;e Durchf&uuml;hrung des Vertrags &uuml;berhaupt erst erm&ouml;glicht und auf deren Einhaltung der Nutzer regelm&auml;&szlig;ig vertrauen darf.
            </p>
            <p>
                <br />
                11.3 Im &uuml;brigen ist eine Haftung des Anbieters ausgeschlossen.
            </p>
            <p>
                <br />
                11.4 Vorstehende Haftungsregelungen gelten auch im Hinblick auf die Haftung des Anbieters f&uuml;r seine Erf&uuml;llungsgehilfen und gesetzlichen Vertreter.
            </p>
            <p>
                <br />
                11.5 Eine Haftung des Anbieters f&uuml;r durch technisch bedingte Ausf&auml;lle verursachte Datenverluste, abgebrochene Daten&uuml;bertragungen oder sonstige Probleme und Sch&auml;den in diesem Zusammenhang, welche von ihm nicht zu vertreten sind, ist ausgeschlossen. Der Anbieter haftet nicht f&uuml;r Sch&auml;den, die durch die St&ouml;rung seines Betriebs bzw. des Betriebs seiner Website infolge von h&ouml;herer Gewalt, Aufruhr, Kriegs- und Naturereignissen oder infolge von sonstigen von ihm nicht zu vertretenden Vorkommnissen (z. B. Streik, Aussperrung, Verkehrsst&ouml;rungen, Verf&uuml;gungen von &ouml;ffentlicher Hand des In- und Auslands) veranlasst oder auf nicht schuldhaft verursachte technische Probleme zur&uuml;ckzuf&uuml;hren sind. Dies gilt auch, soweit diese St&ouml;rungen bei vom Anbieter beauftragten Dritten eintreten.
            </p>
            <h2>
                <br />
                12. Laufzeit, K&uuml;ndigung
            </h2>
            <p>
                <br />
                12.1 Sofern sich aus der Leistungsbeschreibung des Anbieters nichts anderes ergibt, wird der Vertrag &uuml;ber die Nutzung von Court Hero zwischen dem Anbieter und dem Nutzer auf unbestimmte Zeit geschlossen und kann von beiden Seiten jederzeit ohne Einhaltung einer K&uuml;ndigungsfrist und ohne Angabe von Gr&uuml;nden mit sofortiger Wirkung gek&uuml;ndigt werden.
            </p>
            <p>
                <br />
                12.2 Das Recht zur au&szlig;erordentlichen K&uuml;ndigung aus wichtigem Grund bleibt unber&uuml;hrt.
            </p>
            <p>
                <br />
                12.3 Im Falle einer berechtigten au&szlig;erordentlichen K&uuml;ndigung des Nutzungsvertrages durch den Anbieter darf der Nutzer Court Hero nicht mehr nutzen.
            </p>
            <p>
                <br />
                12.4 K&uuml;ndigungen des Nutzers k&ouml;nnen schriftlich bzw. in Textform (z. B. E-Mail) an den Anbieter ohne Einhaltung einer K&uuml;ndigungsfrist erfolgen.
            </p>
            <p>
                <br />
                12.5 Wird der Nutzungsvertrag beendet, verliert der Nutzer den Zugriff auf ein ggf. eingerichtetes Nutzerkonto.
            </p>
            <h2>
                <br />
                13. &Auml;nderung der Nutzungsbedingungen
            </h2>
            <p>
                <br />
                13.1 Der Anbieter beh&auml;lt sich vor, diese Nutzungsbedingungen jederzeit ohne Angabe von Gr&uuml;nden zu &auml;ndern, es sei denn, dies ist f&uuml;r den Nutzer nicht zumutbar. Der Anbieter wird den Nutzer &uuml;ber &auml;nderungen der Nutzungsbedingungen rechtzeitig in Textform benachrichtigen. Widerspricht der Nutzer der Geltung der neuen Nutzungsbedingungen nicht innerhalb einer Frist von vier Wochen nach der Benachrichtigung, gelten die ge&auml;nderten Nutzungsbedingungen als vom Nutzer angenommen. Der Anbieter wird den Nutzer in der Benachrichtigung auf sein Widerspruchsrecht und die Bedeutung der Widerspruchsfrist hinweisen. Widerspricht der Nutzer den &auml;nderungen innerhalb der vorgenannten Frist, so besteht das Vertragsverh&auml;ltnis zu den urspr&uuml;nglichen Bedingungen fort.
            </p>
            <p>
                <br />
                13.2 Der Anbieter beh&auml;lt sich dar&uuml;ber hinaus vor, diese Nutzungsbedingungen zu &auml;ndern,
            </p>
            <p>&bull; soweit er hierzu aufgrund einer &auml;nderung der Rechtslage verpflichtet ist;</p>
            <p>&bull; soweit er damit einem gegen sich gerichteten Gerichtsurteil oder einer Beh&ouml;rdenentscheidung nachkommt;</p>
            <p>&bull; soweit er zus&auml;tzliche, g&auml;nzlich neue Dienstleistungen, Dienste oder Dienstelemente einf&uuml;hrt, die einer Leistungsbeschreibung in den Nutzungsbedingungen bed&uuml;rfen, es sei denn, das bisherige Nutzungsverh&auml;ltnis wird dadurch nachteilig ver&auml;ndert;</p>
            <p>&bull; wenn die &auml;nderung lediglich vorteilhaft f&uuml;r den Nutzer ist; oder</p>
            <p>&bull; wenn die &auml;nderung rein technisch oder prozessual bedingt ist, es sei denn, sie hat wesentliche Auswirkungen f&uuml;r den Nutzer.</p>
            <p>
                <br />
                13.3 Das K&uuml;ndigungsrecht des Nutzers gem&auml;&szlig; Ziffer 12 bleibt hiervon unber&uuml;hrt.
            </p>
            <h2>
                <br />
                14. Anwendbares Recht
            </h2>
            <p>
                <br />
                F&uuml;r s&auml;mtliche Rechtsbeziehungen der Parteien gilt das Recht der Bundesrepublik Deutschland. Bei Verbrauchern gilt diese Rechtswahl nur insoweit, als nicht der gew&auml;hrte Schutz durch zwingende Bestimmungen des Rechts des Staates, in dem der Verbraucher seinen gew&ouml;hnlichen Aufenthalt hat, entzogen wird.
            </p>
            <h2>
                <br />
                15. Gerichtsstand
            </h2>
            <p>
                <br />
                Handelt der Nutzer als Kaufmann, juristische Person des &ouml;ffentlichen Rechts oder &ouml;ffentlich-rechtliches Sonderverm&ouml;gen mit Sitz im Hoheitsgebiet der Bundesrepublik Deutschland, ist ausschlie&szlig;licher Gerichtsstand f&uuml;r alle Streitigkeiten aus diesem Vertrag der Gesch&auml;ftssitz des Anbieters. Hat der Nutzer seinen Sitz au&szlig;erhalb des Hoheitsgebiets der Bundesrepublik Deutschland, so ist der Gesch&auml;ftssitz des Anbieters ausschlie&szlig;licher Gerichtsstand f&uuml;r alle Streitigkeiten aus diesem Vertrag, wenn der Vertrag oder Anspr&uuml;che aus dem Vertrag der beruflichen oder gewerblichen T&auml;tigkeit des Nutzers zugerechnet werden k&ouml;nnen. Der Anbieter ist in den vorstehenden F&auml;llen jedoch in jedem Fall berechtigt, das Gericht am Sitz des Nutzers anzurufen.
            </p>
            <h2>
                <br />
                16. Alternative Streitbeilegung
            </h2>
            <p>
                <br />
                16.1 Die EU-Kommission stellt im Internet unter folgendem Link eine Plattform zur Online-Streitbeilegung bereit:
                {' '}
                <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noreferrer">https://ec.europa.eu/consumers/odr</a>
            </p>
            <p>Diese Plattform dient als Anlaufstelle zur au&szlig;ergerichtlichen Beilegung von Streitigkeiten aus Online-Kauf- oder Dienstleistungsvertr&auml;gen, an denen ein Verbraucher beteiligt ist.</p>
            <p>
                <br />
                16.2 Der Anbieter ist zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.
            </p>
        </Card>
    </DesktopBlurredBackground>
);
