import React from 'react';
import {
    Image,
} from 'antd';
import {
    DeleteOutlined,
} from '@ant-design/icons';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { getDeletePlayerToBookingDataLayer } from 'services/gtm/gtmDatalayers';
import { translate } from 'translations/translate';
import styles from './PlayerCard.module.scss';

interface IProps {
    image: string;
    playerName: string;
    showDeleteButton?: boolean;
    removePlayer: () => void;
    loggedUserId?: string;
    facilityId?: string;
}

export const PlayerCard = ({
    image,
    playerName,
    showDeleteButton = false,
    removePlayer,
    loggedUserId,
    facilityId,
}: IProps): JSX.Element => (
    <div className={styles['player-card']}>
        <div
            className={styles['player-card__info']}
        >
            <div
                className={styles['player-card__image-container']}
            >
                <Image
                    preview={false}
                    height={62}
                    width={62}
                    src={image}
                    alt={translate('calendar-reservation_dialog-player-alt-image')}
                />
            </div>
            <p className={styles['player-card__name']}>{playerName}</p>

            {showDeleteButton && (
                <div
                    className={styles['player-card__trash-icon']}
                    onClick={() => {
                        removePlayer();
                        if (loggedUserId && facilityId) {
                            pushGtmDatalayer(getDeletePlayerToBookingDataLayer(
                                loggedUserId,
                                facilityId,
                            ));
                        }
                    }}
                    onKeyPress={removePlayer}
                    role="button"
                    tabIndex={0}
                >
                    <DeleteOutlined />
                </div>
            )}
        </div>
    </div>
);
