import React from 'react';
import { AutoComplete, Input } from 'antd';
import './CHAutoComplete.scss';

interface IProps {
    options: string[];
    onChange: (value: string) => void;
    placeholder: string;
}

export const CHAutoComplete = ({
    options, onChange, placeholder,
}: IProps): JSX.Element => (
    <AutoComplete
        className="autocomplete__container"
        options={options.map(option => ({ label: option, value: option, key: option }))}
        filterOption={(inputValue: string, option: any): any => option.value.toUpperCase()}
        onChange={value => onChange(value)}
    >
        <Input
            placeholder={placeholder}
            bordered={false}
        />
    </AutoComplete>
);
