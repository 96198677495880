import React from 'react';
import { Button, Image } from 'antd';
import { translate } from 'translations/translate';
import {
    IMembership, PLAYERROLEADMIN, PLAYERROLEMEMBER, PLAYERROLECLIENT,
} from 'types';
import { LOGO } from 'config/constants';
import { IClub } from 'usecases/types/club';
import { calculateStatusClassName } from 'helpers/calculateStatusClassName';
import { MEMBERSHIP_STATUS } from 'usecases/types/enums/membership_status.enum';

import { BuildFacilityAddress } from '../BuildFacilityAddress';
import './FacilityCard.scss';

interface IProps {
    club: IClub,
    membership: IMembership,
    onClick: Function,
}

export const FacilityCard = ({
    club,
    membership,
    onClick,
}: IProps): JSX.Element => {
    const {
        logo, name, address,
    } = club;
    const { role, status } = membership;

    const roleClassModifiers = {
        [PLAYERROLEADMIN]: '--admin',
        [PLAYERROLEMEMBER]: '--member',
        [PLAYERROLECLIENT]: '--client',
    };

    return (
        <div className="facility-card">
            <div className="facility-card__club-information">
                <div>
                    <Image
                        className="facility-card__club-logo"
                        src={logo || LOGO}
                        height={60}
                        width={60}
                        preview={false}
                    />
                </div>
                <div className="facility-card__club-data">
                    <span className="facility-card__club-title">{name}</span>
                    <br />
                    <span data-testid="facility-card-club-address">
                        <BuildFacilityAddress address={address} />
                    </span>
                </div>
            </div>

            <div className={`facility-card__club-role
                    facility-card__club-role${roleClassModifiers[role]}`}
            >
                {translate(`role_${role}`)}
            </div>
            {status === MEMBERSHIP_STATUS.ACTIVE
                ? (
                    <Button
                        onClick={() => onClick()}
                        type="primary"
                        className="facility-card__go-to-calendar"
                    >
                        {translate('facility-card_go_to_calendar')}
                    </Button>
                ) : (
                    <div className="facility-card__user-status">
                        <div className={`facility-card__user-status-marker ${calculateStatusClassName(status)}`} />
                        <div className={`facility-card__user-status-name ${calculateStatusClassName(status)}`}>
                            {translate(`user-status_${status.toLowerCase()}`)}
                        </div>
                    </div>

                )}

        </div>
    );
};
