import React from 'react';
/* eslint-disable react/no-unescaped-entities */
import { getProfileLanguage } from 'translations/getProfilelanguage';
import './CourtsExplanation.scss';

export const CourtsExplanation = () => {
    const profileLanguage = getProfileLanguage();

    const germanExplanation = () => (
        <>
            <h1 className="courts-explanation__title">Plätze</h1>
            <div className="courts-explanation__text-container">
                <p className="courts-explanation__text">
                    Der Menüpunkt „Plätze“ zeigt dir alle deine erstellten Plätze an. Für jeden Platz wird der Name,
                    Belag, Ort sowie der Buchungszeitraum in der Platzliste angezeigt.
                </p>

                <p className="courts-explanation__text">
                    Folgende Funktionen stehen dir für diese Liste zur Verfügung:
                </p>

                <p className="courts-explanation__text">
                    1) Platz hinzufügen
                </p>
                <p className="courts-explanation__text">
                    2) Platz bearbeiten (Stift-Symbol)
                </p>
                <p className="courts-explanation__text">
                    3) Platz löschen (Papierkorb-Symbol)
                </p>

                <p className="courts-explanation__text">
                    Die Erstellung der Plätze ist der erste Schritt der Einrichtung deines Vereins bei Court Hero.
                    Die Angabe des Belages als auch des Ortes sind optionale Eingaben.
                    Jedoch können diese Informationen für die Spieler bei der Buchung eines Platzes relevant sein.
                </p>

                <p className="courts-explanation__text">
                    Du solltest hier alle Plätze hinzufügen, welche du grundsätzlich für die Online-Platzbuchung über
                    Court Hero freigeben möchtest.
                </p>

                <p className="courts-explanation__text">
                    In den weiteren Menüpunkten „Saisons“ und „Preise“ kannst du dann diese Plätze der jeweiligen Saison
                    hinzufügen sowie Preisregeln für die jeweiligen Plätze erstellen.
                </p>

                <p className="courts-explanation__text">
                    Bitte beachte, dass die erstellten Plätze erst im Kalender der Spieler angezeigt werden, wenn du
                    diese einer Saison zugeordnet hast und diese Saison für Spieler zur Buchung freigegeben hast.
                </p>

                <p className="courts-explanation__text">
                    <b>Benötigst du weitere Hilfe? </b>
                    Dann schreibe eine E-Mail an
                    {' '}
                    <a
                        className="courts-explanation__link"
                        href="mailto:courthero@tennis-point.de"
                    >
                        courthero@tennis-point.de
                    </a>
                </p>
            </div>
        </>
    );

    const englishExplanation = () => (
        <>
            <h1 className="courts-explanation__title">Courts</h1>
            <div className="courts-explanation__text-container">
                <p className="courts-explanation__text">
                    The menu item "Courts" shows you all the courts you have created. For each court, the name,
                    surface, location and booking period are displayed in the court list.
                </p>

                <p className="courts-explanation__text">
                    The following functions are available for this list:
                </p>

                <p className="courts-explanation__text">
                    1) Add court
                </p>

                <p className="courts-explanation__text">
                    2) Edit court (pencil icon)
                </p>

                <p className="courts-explanation__text">
                    3) Delete court (trash icon)
                </p>

                <p className="courts-explanation__text">
                    The creation of courts is the first step in setting up your club at Court Hero.
                    The specification of the surface as well as the location are optional entries.
                    However, this information may be relevant to players when booking a court.
                </p>

                <p className="courts-explanation__text">
                    You should add all courts here which you would like to make available for online
                    court booking via Court Hero.
                </p>

                <p className="courts-explanation__text">
                    In the other menu items "Seasons" and "Prices" you can then add these courts to
                    the respective season and create price rules for the respective courts.
                </p>

                <p className="courts-explanation__text">
                    Please note that the created courts will only be displayed in the players' calendar
                    once you have assigned them to a season and released the season for players to book.
                </p>

                <p className="courts-explanation__text">
                    <b>Need more help? </b>
                    Then send an e-mail to
                    {' '}
                    <a
                        className="courts-explanation__link"
                        href="mailto:courthero@tennis-point.de"
                    >
                        courthero@tennis-point.de
                    </a>
                </p>
            </div>
        </>
    );
    return (
        <div className="courts-explanation">
            {profileLanguage === 'english'
                ? englishExplanation()
                : germanExplanation()}
        </div>

    );
};
