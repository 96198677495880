import { CLUBS, PLACEHOLDERS } from 'config/endpoints';
import { UserWithReservationsCount } from 'types';
import { getWrapper } from 'services/httpService/httpServiceWrapper';

export const getMemberFees = async (clubId: string, seasonId?: string): Promise<UserWithReservationsCount[]> => {
    const params = seasonId
        ? {
            seasonId,
        }
        : {};
    const url = CLUBS.FEES.replace(PLACEHOLDERS.CLUB_ID, clubId);
    const { data } = await getWrapper(url, params);
    return data;
};
