import { IHeaders } from 'types';
import { buildHeaders } from './headers';

export const httpCall = ({
    url, headers = {}, data = undefined, method,
} :{url: string, headers: IHeaders, data?: any, method: string}): Promise<any> => {
    const fetchCall = fetch(url, {
        method,
        headers: buildHeaders(headers),
        body: JSON.stringify(data),
    });

    return fetchCall;
};
