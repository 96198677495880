import { setUserInStorage } from 'services/functionalStorage';
import { PROFILE } from 'config/endpoints';
import { getWrapper } from 'services/httpService/httpServiceWrapper';
import { IUser } from 'types';

export const getUserInformationAndSetInStorage = async (): Promise<IUser> => {
    const userInformationResponse = await getWrapper(
        PROFILE.SINGLE,
    );

    const { user } = userInformationResponse.data;
    setUserInStorage(user);

    return user;
};
