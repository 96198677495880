import React from 'react';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { ICourt } from 'usecases/types/court';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { getNavigateToNextCourtDataLayer, getNavigateToPreviousCourtDataLayer } from 'services/gtm/gtmDatalayers';
import './TopRowFilters.scss';

interface IProps {
    courts: ICourt[];
    court: ICourt | null;
    onChange: (court: ICourt) => void;
}

const CourtSelector = ({ courts, court, onChange }: IProps) => {
    let selectedCourt: ICourt;
    let selectedCourtIndex: number;
    if (!court) {
        [selectedCourt] = courts;
        selectedCourtIndex = 0;
    } else {
        selectedCourt = court;
        selectedCourtIndex = courts.indexOf(court);
    }

    const onCourtChange = (indexQuantity: -1 | 1) => {
        let newIndex = selectedCourtIndex + indexQuantity;
        if (indexQuantity === 1) {
            pushGtmDatalayer(getNavigateToNextCourtDataLayer());
        }
        if (indexQuantity === -1) {
            pushGtmDatalayer(getNavigateToPreviousCourtDataLayer());
        }
        if (newIndex < 0) {
            newIndex = courts.length - 1;
        }
        if (newIndex >= courts.length) {
            newIndex = 0;
        }
        onChange(courts[newIndex]);
    };

    return (
        <div className="filter-selector filter-selector--left">
            <Button
                onClick={() => onCourtChange(-1)}
                icon={(
                    <LeftOutlined
                        className="icon-bold"
                    />
                )}
                className="filter-selector__button filter-selector__button--margin-right"
            />
            <div className="filter-selector__filter-container">
                <p className="filter-selector__filters-text">{selectedCourt ? selectedCourt.name : '-'}</p>
            </div>
            <Button
                onClick={() => onCourtChange(+1)}
                icon={(
                    <RightOutlined
                        className="icon-bold"
                    />
                )}
                className="filter-selector__button filter-selector__button--margin-left"
            />
        </div>
    );
};

export default CourtSelector;
