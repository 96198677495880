import { getAntdLocaleFromStorage } from 'services/functionalStorage';

export const formatNumberAsLocaleTwoDigits = (amount: number):string => {
    const locale = getAntdLocaleFromStorage();

    return amount.toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};
