import moment from 'moment';

export const getWeekDayDatesBetweenTwoDates = (startDateTime: string, endDateTime: string, weekDayNumber: number)
: string[] => {
    const start = moment(startDateTime);
    const end = moment(endDateTime);

    const result: any[] = [];
    const current = start.clone();

    current.set('day', (current.get('day')) + ((weekDayNumber - current.get('day') + 7) % 7));

    while (current.isSameOrBefore(end)) {
        result.push(current.clone());
        current.set('day', (current.get('day') + 7));
    }

    return result.map(date => date.format('YYYY-MM-DD'));
};
