import { Link, LinkProps, useLocation } from 'react-router-dom';
import React from 'react';

export const LinkWithQuery = ({ children, to, ...props }: LinkProps) => {
    const { search } = useLocation();

    return (
        <Link to={`${to}${search}`} {...props}>
            {children}
        </Link>
    );
};
