import { AlertModalContent } from 'components/AlertModalContent/AlertModalContent';
import { translate } from 'translations/translate';
import React from 'react';

export const getDeleteSuccessModalContent = (closeActionsDialog: () => void, headerKey: string): JSX.Element => (
    <AlertModalContent
        type="success"
        header={translate(headerKey)}
        goBackText={translate('calendar_reservation-confirmed-back-to-calendar')}
        onGoBack={closeActionsDialog}
    />
);
