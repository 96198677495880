import { Button } from 'antd';
import { translate } from 'translations/translate';
import React from 'react';

interface IProps {
    onSettingOnlinePayment: () => void,
}

export const OnlinePaymentMethodStepOne = ({ onSettingOnlinePayment }: IProps) => (
    <div className="payment-methods__container payment-methods__container--step-one">
        <p className="payment-methods__text">
            {translate('club_settings-payments_step_one')}
        </p>

        <Button
            type="primary"
            htmlType="submit"
            className="payment-methods__button"
            onClick={onSettingOnlinePayment}
        >
            {translate('club_settings-payments_button-step_one')}
        </Button>
    </div>

);
