import { FormInstance } from 'antd';
import { translate } from 'translations/translate';

export const validateOpeningHours = ({ getFieldValue, setFields }: FormInstance, formKey: string) => {
    const openingTime = getFieldValue('openingTime');
    const closingTime = getFieldValue('closingTime');
    if (closingTime && openingTime && closingTime < openingTime) {
        const openingError = translate('club_settings_opening-hours-validation-opening');
        const closingError = translate('club_settings_opening-hours-validation-closing');
        setFields([
            {
                name: 'openingTime',
                errors: [openingError],
            },
            {
                name: 'closingTime',
                errors: [closingError],
            },
        ]);
        const errorMessage = formKey === 'openingTime'
            ? openingError
            : closingError;
        return Promise.reject(errorMessage);
    }
    setFields([
        {
            name: 'openingTime',
            errors: [],
        },
        {
            name: 'closingTime',
            errors: [],
        },
    ]);
    return Promise.resolve();
};
