import React from 'react';
import { isUserActive } from 'helpers/isUserActive';
import { getUserFromStorage } from 'services/functionalStorage';
import { ReservationsCalendar }
    from 'components/Reservations/ReservationsCalendar/ReservationsCalendar';
import { logoutUser } from 'services/logoutUser';

export const AdminReservationsPage = (): JSX.Element => {
    const loggedUser = getUserFromStorage();
    if (loggedUser.memberships === undefined) {
        logoutUser();
        return <></>;
    }
    const userActive = isUserActive(loggedUser);

    return (
        <ReservationsCalendar
            loggedUser={loggedUser}
            userActive={userActive}
        />
    );
};
