import { RESERVATIONS, PLACEHOLDERS } from 'config/endpoints';
import { postWrapper } from 'services/httpService/httpServiceWrapper';
import { IReservation } from 'usecases/types/reservations';
import { PaymentTypeEnum } from 'usecases/types/enums/payment_type.enum';
import { ICreateReservationInput } from '../types/dto/CreateReservationInput';

export const createReservation = async (createReservationInputDto: ICreateReservationInput):
    Promise<{ reservation: IReservation, checkoutUrl: string }> => {
    const {
        club,
        court,
        companions,
        guests,
        startTime,
        endTime,
        owner,
        price,
        description,
        paymentType = PaymentTypeEnum.CASH,
        bookingType,
    } = createReservationInputDto;

    const url = RESERVATIONS.LIST.replace(PLACEHOLDERS.CLUB_ID, club.id);

    const data = {
        companions: companions.map((companion: any) => companion.id),
        guests,
        courtId: court.id,
        startTime,
        endTime,
        price,
        description,
        paymentType,
        bookingType,
    };

    if (owner) {
        (data as any).ownerId = owner.id;
    }
    return (await postWrapper(url, data)).data;
};
