import moment from 'moment';

export const formatWeekDate = (_date: moment.Moment, antdLocale: string): string => {
    const formatter = new Intl.DateTimeFormat(antdLocale, { day: 'numeric', month: 'short' });
    const startOfWeek = _date.clone();
    const endOfWeek = startOfWeek.clone().add(6, 'days');
    const startOfWeekFormatted = formatter.format(startOfWeek.toDate());
    const endOfWeekFormatted = formatter.format(endOfWeek.toDate());
    return `${startOfWeekFormatted} - ${endOfWeekFormatted}`;
};
