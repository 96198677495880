import React from 'react';
import { translate } from 'translations/translate';
import { DEFAULT_AVATAR } from 'config/constants';
import { IUser, Dict } from 'types';
import { PlayerCard } from 'components/Reservations/PlayerCard/PlayerCard';
import { AddPlayer } from '../AddPlayer/AddPlayer';
import styles from './Players.module.scss';

interface IProps {
    companionList: IUser[];
    userOptions: Dict<IUser>;
    removeOption: (id: string) => void;
    addOption: (id: string) => void;
    isNewReservation: boolean,
    loggedUserId: string,
    facilityId: string,
}

export const Players = ({
    companionList = [],
    userOptions,
    removeOption,
    addOption,
    isNewReservation,
    loggedUserId,
    facilityId,
} : IProps) => (

    <div>
        <p
            className={styles['players__card-subtitle']}
        >
            {translate('calendar-reservation_dialog-players')}
        </p>
        <div className={styles.players__container}>
            <>
                {companionList.map(player => (
                    <PlayerCard
                        image={player.avatar || DEFAULT_AVATAR}
                        playerName={`${player.name} ${player.surname}`}
                        key={player.id}
                        showDeleteButton={isNewReservation}
                        removePlayer={() => addOption(player.id)}
                        loggedUserId={loggedUserId}
                        facilityId={facilityId}
                    />
                ))}
            </>
            <AddPlayer
                userOptions={userOptions}
                removeOption={removeOption}
                placeholder={translate('calendar-reservation_dialog-add_player_placeholder')}
                isNewReservation={isNewReservation}
                loggedUserId={loggedUserId}
                facilityId={facilityId}
            />
        </div>
    </div>
);
