import { getAntdLocaleFromStorage } from 'services/functionalStorage';

export const sortItemsBy = (field: string, items: any[], order?: 'asc' | 'desc'): any[] => {
    if (items.length && items[0][`${field}`]) {
        const descSortedItems = items.sort((current, next) => current[`${field}`].localeCompare(
            next[`${field}`],
            getAntdLocaleFromStorage(),
            {
                numeric: true,
                sensitivity: 'base',
            },
        ));

        const sortedItems = order === 'asc' ? [...descSortedItems].reverse() : descSortedItems;
        return sortedItems;
    }

    return items;
};
