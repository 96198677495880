import React, { useState } from 'react';
import { Button } from 'antd';
import {
    DELETE_ICON_GRAY, DELETE_ICON_WHITE, EDIT_ICON_GRAY, EDIT_ICON_WHITE,
} from 'config/constants';
import Icon from '@ant-design/icons';
import './ActionElements.scss';

interface IProps {
    firstButtonOnClick: () => void
    secondButtonOnClick?: () => void
}

export const ActionElements = ({
    firstButtonOnClick,
    secondButtonOnClick,
}: IProps) => {
    const [deleteIcon, setDeleteIcon] = useState<string>(DELETE_ICON_GRAY);
    const [editIcon, setEditIcon] = useState<string>(EDIT_ICON_GRAY);

    const MemoizedIcon = React.useCallback((props: {imgSrc: string, alt: string}) => (
        <img
            className="action-elements__button-image"
            src={props.imgSrc}
            alt={props.alt}
        />
    ), []);

    return (
        <div className="action-elements__item-actions">
            <Button
                type="default"
                className="action-elements__button action-elements__button--first "
                size="large"
                onMouseOver={() => setEditIcon(EDIT_ICON_WHITE)}
                onMouseLeave={() => setEditIcon(EDIT_ICON_GRAY)}
                // eslint-disable-next-line react/no-unstable-nested-components
                icon={<Icon component={() => <MemoizedIcon imgSrc={editIcon} alt="edit_icon" />} />}
                onClick={firstButtonOnClick}
            />

            {
                secondButtonOnClick && (
                    <Button
                        danger
                        className="action-elements__button"
                        size="large"
                        onClick={secondButtonOnClick}
                        onMouseOver={() => setDeleteIcon(DELETE_ICON_WHITE)}
                        onMouseLeave={() => setDeleteIcon(DELETE_ICON_GRAY)}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        icon={<Icon component={() => <MemoizedIcon imgSrc={deleteIcon} alt="delete_icon" />} />}
                        data-testid="delete_button"
                    />
                )
            }
        </div>
    );
};
