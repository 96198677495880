/* eslint-disable indent */
import { Button, Image } from 'antd';
import { CLOSE_ICON_GRAY, PLUS_ICON_GRAY } from 'config/constants';
import React, { useState } from 'react';
import { translate } from 'translations/translate';
import { IClub } from 'usecases/types/club';
import { JoinFacilityCard } from '../JoinFacilityCard/JoinFacilityCard';
import './AddFacilityCard.scss';

interface IAddFacilityCard {
    availableFacilities: IClub[],
}

enum CardToShow {
    AddFacility,
    CreateFacility,
    JoinFacility
}

export const AddFacilityCard = ({ availableFacilities }: IAddFacilityCard) => {
    const [cardToShow, setCardToShow] = useState<CardToShow>(CardToShow.AddFacility);

    const onGoToJoinFacilty = () => {
        setCardToShow(CardToShow.JoinFacility);
    };

    const showAddFacilityCard = () => {
        setCardToShow(0);
    };

    const renderCard = () => {
        switch (cardToShow) {
            case CardToShow.AddFacility:
                return (
                    <>

                        <div className="add-facility-card__plus-logo">
                            <Image
                                preview={false}
                                height={91}
                                width={91}
                                src={PLUS_ICON_GRAY}
                                alt="plus-icon"
                            />
                        </div>
                        <Button
                            onClick={onGoToJoinFacilty}
                            type="primary"
                            className="add-facility-card__button"
                        >
                            {translate('facility_card-join_facility')}
                        </Button>
                    </>
                );

            case CardToShow.JoinFacility:
                return (
                    <JoinFacilityCard
                        showAddFacilityCard={showAddFacilityCard}
                        availableFacilities={availableFacilities}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="add-facility-card">
            <>
                {cardToShow !== CardToShow.AddFacility && (
                    <img
                        className="add-facility-card__close-icon"
                        src={CLOSE_ICON_GRAY}
                        alt="close_icon"
                        onClick={showAddFacilityCard}
                        onKeyPress={showAddFacilityCard}
                        role="presentation"
                    />
                )}
                {renderCard()}

            </>
        </div>
    );
};
