import { CourtModalContent } from 'components/CourtModalContent/CourtModalContent';
import { translate } from 'translations/translate';
import { ICourt } from 'usecases/types/court';
import React from 'react';

interface IgetCourtModalContent {
    titleKey:string,
    court?: ICourt,
    onProceedCallback: Function
    onCancelCallback: Function
}

export const getCourtModalContent = ({
    titleKey, court, onProceedCallback, onCancelCallback,
} : IgetCourtModalContent): JSX.Element => (
    <CourtModalContent
        title={translate(titleKey)}
        onProceed={onProceedCallback}
        onCancel={onCancelCallback}
        court={court}
    />
);
