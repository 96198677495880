import { FormInstance } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { PLAYERROLEMEMBER } from 'types';

export const onRoleChange = (
    roleValue: SelectValue,
    form: FormInstance,
    setGuestFeeCheckboxVisibility: Function,
    setGuestFeeVisibility: Function,
): void => {
    if (roleValue === PLAYERROLEMEMBER) {
        setGuestFeeCheckboxVisibility(true);
        if (form.getFieldValue('guestFeeCheckbox') === true) {
            setGuestFeeVisibility(true);
        }
        return;
    }
    setGuestFeeCheckboxVisibility(false);
    setGuestFeeVisibility(false);
    form.setFields([
        {
            name: 'guestFeeCheckbox',
            value: false,
        },
        {
            name: 'guestFee',
            value: undefined,
        },
    ]);
};
