import { MEMBERSHIP_STATUS } from 'usecases/types/enums/membership_status.enum';
import { IUser } from 'types';
import { getClubFromStorage } from 'services/functionalStorage';
import { getUserMembership } from './getUserMembership';

export const isUserActive = (user: IUser):boolean => {
    const facilty = getClubFromStorage();

    const membershipInFacility = getUserMembership(user, facilty);

    return membershipInFacility?.status === MEMBERSHIP_STATUS.ACTIVE;
};
