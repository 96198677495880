import React from 'react';
import { Button } from 'antd';
import './InfoContent.scss';

interface IProps {
    texts:{
        header?: string;
        firstButton?: string;
        secondButton?: string;
    }
    headerComponent?: JSX.Element;
    firstButtonSuccesModalOnClick?: (...params: any) => any;
    secondButtonSuccesModalOnClick?: (...params: any) => any;
}

export const InfoContent = ({
    texts,
    headerComponent,
    secondButtonSuccesModalOnClick = () => {},
    firstButtonSuccesModalOnClick = () => {},
}: IProps): JSX.Element => (
    <div className="info-content">
        {headerComponent && (
            headerComponent
        )}
        {!headerComponent && (
            <p className="info-content__text">
                {texts.header}
            </p>
        )}
        {texts.firstButton && (
            <Button
                type="primary"
                htmlType="submit"
                className="info-content__button info-content__button--first-button"
                onClick={firstButtonSuccesModalOnClick}
            >
                {texts.firstButton}
            </Button>
        )}
        {texts.secondButton && (
            <Button
                type="default"
                className="info-content__button info-content__button--second-button"
                onClick={() => secondButtonSuccesModalOnClick()}
            >
                {texts.secondButton}
            </Button>
        )}
    </div>
);
