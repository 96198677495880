import { Card, notification } from 'antd';
import React, { useEffect } from 'react';
import { translate } from 'translations/translate';
import { PaymentProviderSetupStatus } from 'types';
import { IClub } from 'usecases/types/club';
import { useQuery } from 'hooks/useQuery';
import { useApi } from 'hooks/useApi';
import { getPaymentProviderOnboardingLink } from 'usecases/club/getPaymentProviderOnboardingLink';
import { CashPayment } from 'pages/admin/Club/PaymentMethods/CashPayment';
import { OnlinePaymentMethodStepOne } from 'pages/admin/Club/PaymentMethods/OnlinePaymentMethodStepOne';
import { OnlinePaymentMethodStepTwo } from 'pages/admin/Club/PaymentMethods/OnlinePaymentMethodStepTwo';
import { OnlinePaymentMethodStepThreeFour } from 'pages/admin/Club/PaymentMethods/OnlinePaymentMethodStepThreeFour';
import { getResponseCodeFromUrl } from './getResponseCodeFromUrl';
import './PaymentMethods.scss';

export const PaymentMethods = ({ club }: { club: IClub }) => {
    const urlParams = useQuery();
    const callApi = useApi();

    useEffect(
        () => {
            const { statusCode } = getResponseCodeFromUrl(urlParams);

            if (statusCode === '010006') {
                notification.error({
                    message: translate('error-010006'),
                });
            }
            if (statusCode === '000000') {
                notification.success({
                    message: translate('club_settings-payments_setup_completed'),
                });
            }
        },
        [],
    );

    const onSettingOnlinePayment = () => {
        callApi(getPaymentProviderOnboardingLink(club))
            .then(({ link }) => window.location.replace(link));
    };

    const renderPaymentCard = (currentStep: PaymentProviderSetupStatus) => {
        switch (currentStep) {
        case PaymentProviderSetupStatus.NOT_INITIATED:
            return <OnlinePaymentMethodStepOne onSettingOnlinePayment={onSettingOnlinePayment} />;
        case PaymentProviderSetupStatus.PENDING:
            return <OnlinePaymentMethodStepTwo onSettingOnlinePayment={onSettingOnlinePayment} />;
        case PaymentProviderSetupStatus.COMPLETED:
            return (
                <OnlinePaymentMethodStepThreeFour />
            );
        default:
            return <OnlinePaymentMethodStepOne onSettingOnlinePayment={onSettingOnlinePayment} />;
        }
    };

    return (
        <div className="payment-methods">
            <Card className="payment-methods__card">
                <h1 className="payment-methods__title">{translate('club_settings-cash_payments_title')}</h1>
                <CashPayment />
            </Card>
            <Card className="payment-methods__card">
                <h1 className="payment-methods__title">{translate('club_settings-payments_title')}</h1>
                {renderPaymentCard(club.paymentProviderSetupStatus)}
            </Card>
        </div>
    );
};
