import React from 'react';
import { Select } from 'antd';
import './CHSelect.scss';

interface IProps {
    onChange: (value: string) => void;
    options: {value: string | number, label: string}[];
    placeholder: string;
}

export const CHSelect = ({ onChange, options, placeholder }: IProps) => (
    <Select
        className="select__container"
        onChange={value => onChange(`${value}`)}
        placeholder={placeholder}
        size="large"
    >
        {options.map(option => (
            <Select.Option value={option.value} key={option.value}>
                {option.label}
            </Select.Option>
        ))}
    </Select>
);
