import React, { useRef } from 'react';
import {
    Button, Form, Input, Select, TimePicker,
} from 'antd';
import { translate } from 'translations/translate';

import { useAppContext } from 'context/AppContext';
import {
    COURT_CARPET_ICON, COURT_CLAY_ICON, COURT_GRASS_ICON, COURT_HARD_ICON,
} from 'config/constants';
import { ICourt } from 'usecases/types/court';
import { CourtLocationEnum, CourtSurfaceEnum } from 'types';
import moment from 'moment';
import { getTimeRuleValidator } from 'adapters/getTimeRuleValidator';
import './CourtModalContent.scss';

interface IProps {
    title: string;
    onCancel: Function;
    onProceed: Function;
    court?: ICourt
}

export const CourtModalContent = ({
    title,
    onCancel,
    onProceed,
    court,
}: IProps) => {
    const { isSpinnerVisible } = useAppContext();
    const containerRef = useRef<HTMLElement>();

    const getIntialFormValues = (courtToChange?: ICourt) => ({
        name: courtToChange?.name,
        openingTime: courtToChange?.openingTime ? moment(courtToChange.openingTime, 'HH:mmZ') : '',
        closingTime: courtToChange?.closingTime ? moment(courtToChange.closingTime, 'HH:mmZ') : '',
        surface: courtToChange?.surface || null,
        location: courtToChange?.location || null,
    });

    return (
        <div
            className="court-modal-content"
            ref={containerRef as any}
        >
            <h1 className="court-modal-content__title">
                {title}
            </h1>
            <Form
                requiredMark="optional"
                layout="vertical"
                initialValues={getIntialFormValues(court)}
                name="basic"
                onFinish={values => onProceed(values, court)}
            >
                <Form.Item
                    label={(
                        <label className="court-modal-content__form-label">
                            {translate('courts-page_modal_court-name-label')}
                        </label>
                    )}
                    name="name"
                    rules={[{ required: true, message: translate('courts-page_modal_name-is-required') }]}
                >
                    <Input
                        className="court-modal-content__form-item"
                    />
                </Form.Item>
                <div className="court-modal-content__time-container">
                    <Form.Item
                        label={(
                            <label className="court-modal-content__form-label">
                                {translate('courts-page_modal_opening-label')}
                            </label>
                        )}
                        name="openingTime"
                        rules={[{
                            required: true,
                            message: translate('courts-page_modal_opening-is-required'),
                        },
                        getTimeRuleValidator(
                            'closingTime',
                            false,
                            'courts-page_modal_opening-before-closing',
                        ),
                        ]}
                    >
                        <TimePicker
                            name="openingTime"
                            className="court-modal-content__form-item court-modal-content__form-item-time"
                            minuteStep={30}
                            showSecond={false}
                            format="HH:mm"
                            getPopupContainer={() => containerRef.current as HTMLElement}
                        />
                    </Form.Item>
                    <Form.Item
                        label={(
                            <label className="court-modal-content__form-label">
                                {translate('courts-page_modal_closing-label')}
                            </label>
                        )}
                        name="closingTime"
                        rules={[{
                            required: true,
                            message: translate('courts-page_modal_closing-is-required'),
                        },
                        getTimeRuleValidator(
                            'openingTime',
                            true,
                            'courts-page_modal_closing-after-opening',
                        ),
                        ]}
                    >
                        <TimePicker
                            name="closingTime"
                            className="court-modal-content__form-item court-modal-content__form-item-time"
                            minuteStep={30}
                            showSecond={false}
                            format="HH:mm"
                            getPopupContainer={() => containerRef.current as HTMLElement}
                        />
                    </Form.Item>
                </div>
                <div className="court-modal-content__time-container">
                    <Form.Item
                        label={(
                            <label className="court-modal-content__form-label">
                                {translate('courts-page_modal_surface-label')}
                            </label>
                        )}
                        name="surface"
                    >
                        <Select
                            size="large"
                            className="court-modal-content__form-item court-modal-content__form-item-select"
                            getPopupContainer={() => containerRef.current as HTMLElement}
                        >
                            <Select.Option value={CourtSurfaceEnum.CARPET}>
                                <div className="court-modal-content__select-option-container">
                                    <img className="court-modal-content__select-image" src={COURT_CARPET_ICON} alt="" />
                                    {translate('carpet')}
                                </div>
                            </Select.Option>
                            <Select.Option value={CourtSurfaceEnum.CLAY}>
                                <div className="court-modal-content__select-option-container">
                                    <img className="court-modal-content__select-image" src={COURT_CLAY_ICON} alt="" />
                                    {translate('clay')}
                                </div>
                            </Select.Option>
                            <Select.Option value={CourtSurfaceEnum.GRASS}>
                                <div className="court-modal-content__select-option-container">
                                    <img className="court-modal-content__select-image" src={COURT_GRASS_ICON} alt="" />
                                    {translate('grass')}
                                </div>
                            </Select.Option>
                            <Select.Option value={CourtSurfaceEnum.HARD}>
                                <div className="court-modal-content__select-option-container">
                                    <img className="court-modal-content__select-image" src={COURT_HARD_ICON} alt="" />
                                    {translate('hard')}
                                </div>
                            </Select.Option>
                            <Select.Option value={null as any}>{translate('not-specified')}</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={(
                            <label className="court-modal-content__form-label">
                                {translate('courts-page_modal_location-label')}
                            </label>
                        )}
                        name="location"
                    >
                        <Select
                            size="large"
                            className="court-modal-content__form-item court-modal-content__form-item-select"
                            getPopupContainer={() => containerRef.current as HTMLElement}
                        >
                            <Select.Option value={CourtLocationEnum.INDOOR}>
                                {translate('indoor')}
                            </Select.Option>
                            <Select.Option value={CourtLocationEnum.OUTDOOR}>
                                {translate('outdoor')}
                            </Select.Option>
                            <Select.Option value={null as any}>{translate('not-specified')}</Select.Option>
                        </Select>
                    </Form.Item>
                </div>
                <div className="court-modal-content__buttons-container">
                    <Button
                        type="default"
                        className="court-modal-content__button court-modal-content__button--gray"
                        onClick={() => onCancel(false)}
                    >
                        {translate('courts-page_modal_cancel-button')}
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="court-modal-content__button"
                        disabled={isSpinnerVisible}
                    >
                        {court
                            ? translate('courts-page_modal_edit-button')
                            : translate('courts-page_modal_create-button')}
                    </Button>
                </div>
            </Form>
        </div>
    );
};
