import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
    IAutocompleteOption,
    IListTableHeaderField, PlayerRole,
} from 'types';
import {
    Button, Checkbox, notification,
} from 'antd';
import { BOOKKEEPING_ICON_SETTLEMENTS } from 'config/constants';
import { translate } from 'translations/translate';
import { ListPageHeader } from 'components/ListPageHeader/ListPageHeader';
import { ListPageButton } from 'components/ListPageButton/ListPageButton';
import { MobileFooter } from 'components/MobileFooter/MobileFooter';
import { ListTable } from 'components/ListTable/ListTable';
import { formatNumberAsLocaleCurrency } from 'adapters/formatNumberAsLocaleCurrency';
import { getInvoiceableBookings } from 'usecases/invoice/getInvoiceableBookings';
import { getClubFromStorage } from 'services/functionalStorage';
import { IInvoiceableBooking } from 'usecases/types/invoiceable-booking';
import { ICreateInvoiceInput } from 'usecases/types/dto/dto';
import { InfoModalContent } from 'components/InfoModalContent/InfoModalContent';
import { ListRoleItem } from 'components/ListRoleItem/ListRoleItem';
import { AlertModalContent } from 'components/AlertModalContent/AlertModalContent';
import { createInvoice } from 'usecases/invoice/createInvoice';
import { useModalContext } from 'context/ModalContext';
import { useApi } from 'hooks/useApi';
import { InfoBox } from 'components/InfoBox/InfoBox';
import { useMobileMediaQuery } from 'hooks/useMobileMediaQuery';
import { useCheckboxList } from 'hooks/useCheckboxList';
import { filterInvoiceableBookings } from './filterInvoiceableBookings';
import { SettlementExplanation } from './SettlementExplanation';
import { SettlementFilters } from './SettlementFilters';
import { pickInitialEndDateFilterValue } from './pickInitialEndDateFilterValue';
import './SettlementPage.scss';

export const SettlementPage = (): JSX.Element | null => {
    const { isMobile } = useMobileMediaQuery();
    const { setModalContentProps, setModalContent } = useModalContext();
    const [invoiceableBookings, setInvoiceableBookings] = useState<IInvoiceableBooking[]>([]);
    const [nameFilterOptions, setNameFilterOptions] = useState<IAutocompleteOption[]>([]);
    const [nameFilterValue, setNameFilterValue] = useState<string>('');
    const [roleFilterValue, setRoleFilterValue] = useState<PlayerRole | ''>('');
    const firstDayOfCurrentMonth = moment().startOf('month');
    const [startDateFilterValue, setStartDateFilterValue] = useState<moment.Moment>(firstDayOfCurrentMonth);
    const yestedayEndOfDay = moment().endOf('d').subtract(1, 'd');
    const initialEndDateFilterValue = pickInitialEndDateFilterValue(firstDayOfCurrentMonth, yestedayEndOfDay);
    const [endDateFilterValue, setEndDateFilterValue] = useState<moment.Moment>(initialEndDateFilterValue);
    const {
        checkboxesSelected, handleChangeCheckbox, setCheckboxesSelected,
    } = useCheckboxList();
    const callApi = useApi();

    const filteredInvoiceableBookings = useMemo(() => filterInvoiceableBookings(
        invoiceableBookings,
        nameFilterValue,
        roleFilterValue,
    ), [invoiceableBookings, nameFilterValue, roleFilterValue]);

    const filteredInvoiceableBookingsIds = useMemo(
        () => filteredInvoiceableBookings.map(invoiceableBooking => invoiceableBooking.id),
        [filteredInvoiceableBookings],
    );

    const headers: IListTableHeaderField[] = [
        {
            field: 'avatar',
            value: translate('settlement-page_table-header-avatar'),
        },
        {
            field: 'selection',
            value: <Checkbox
                checked={checkboxesSelected.length === filteredInvoiceableBookingsIds.length}
                onChange={e => (e.target.checked ? setCheckboxesSelected(filteredInvoiceableBookingsIds)
                    : setCheckboxesSelected([]))}
                name="all-settlement-selection"
            />,
        },
        {
            field: 'name',
            value: translate('settlement-page_table-header-name'),
            sortable: true,
        },
        {
            field: 'surname',
            value: translate('settlement-page_table-header-surname'),
            sortable: true,
        },
        {
            field: 'role',
            value: translate('settlement-page_table-header-role'),
        },
        {
            field: 'total-amount',
            value: translate('settlement-page_table-header-total-amount'),
            sortable: true,
        },
        {
            field: 'action',
            value: '',
        },
    ];

    const sortBy = (field: string, order?: 'asc' | 'desc', _invoiceableBookings?: IInvoiceableBooking[]): void => {
        const invoiceableBookingsList = _invoiceableBookings || invoiceableBookings;
        const descList = invoiceableBookingsList.sort((current, next) => {
            if (field === 'name') {
                return current.name.localeCompare(
                    next.name,
                    undefined,
                    {
                        numeric: true,
                        sensitivity: 'base',
                    },
                );
            }
            if (field === 'surname') {
                return current.surname.localeCompare(
                    next.surname,
                    undefined,
                    {
                        numeric: true,
                        sensitivity: 'base',
                    },
                );
            }
            if (field === 'total-amount') {
                return (current as any).totalAmount - (next as any).totalAmount;
            }
            return 0;
        });
        const ascList = JSON.parse(JSON.stringify(descList)).reverse();
        const sortedList = order === 'asc' ? ascList : descList;
        setInvoiceableBookings([...sortedList]);
    };

    const getInvoiceableBookingsFromAPI = async (
        _startDateFilterValue: moment.Moment,
        _endDateFilterValue: moment.Moment,
    ): Promise<IInvoiceableBooking[]> => {
        const invoiceableBookingList: IInvoiceableBooking[] = await callApi(getInvoiceableBookings(
            {
                clubId: getClubFromStorage().id,
                startDate: _startDateFilterValue,
                endDate: _endDateFilterValue,
            },
        ));

        return invoiceableBookingList;
    };

    const showInvoicesSorted = async (startDateFilter: moment.Moment, endDateFilter: moment.Moment) => {
        const invoiceableBookingList = await getInvoiceableBookingsFromAPI(startDateFilter, endDateFilter);

        sortBy('surname', 'asc', invoiceableBookingList);
        setNameFilterOptions(invoiceableBookingList.map(user => ({
            label: `${user.name} ${user.surname}`,
            value: `${user.name} ${user.surname}`,
            key: `${user.name} ${user.surname}`,
        })));
    };

    const mapOwnerIdsToOwnerNames = (ownerIds: string[]): string[] => ownerIds.map(id => {
        const booking = invoiceableBookings.find(invoiceableBooking => invoiceableBooking.id === id);
        if (booking) {
            return `${booking.name} ${booking.surname}`;
        }
        return id;
    });

    const settleInvoice = async (invoiceData: ICreateInvoiceInput) => {
        try {
            const newInvoices = await createInvoice(invoiceData);
            if (newInvoices) {
                let ownerIdsAlreadySettled = invoiceData.ownerIds;
                if (newInvoices.errors) {
                    ownerIdsAlreadySettled = invoiceData.ownerIds.filter(
                        ownerId => !newInvoices.errors.includes(ownerId),
                    );
                }

                const message = !newInvoices.errors || newInvoices.errors.length === 0
                    ? translate('settlement-page_success-modal_title-success')
                    : translate('settlement-page_success-modal_title-with-errors', {
                        settled: invoiceData.ownerIds.length - newInvoices.errors.length,
                        total: invoiceData.ownerIds.length,
                        failed: mapOwnerIdsToOwnerNames(newInvoices.errors).join(', '),
                    });
                setInvoiceableBookings(invoiceableBookings.filter(
                    invoiceableBooking => !ownerIdsAlreadySettled.includes(invoiceableBooking.id),
                ));
                setModalContentProps({
                    closable: false,
                    onCancel: () => setModalContent(null),
                });
                setModalContent(
                    <AlertModalContent
                        type={newInvoices.errors.length ? 'warning' : 'success'}
                        header={message}
                        goBackText={translate('settlement-page_success-modal_second-button')}
                        onGoBack={() => setModalContent(null)}
                    />,
                );
            }
        } catch (error: any) {
            notification.error({
                message: translate('error'),
                description: error.message,
            });
        }
    };

    const singleSettlement = (booking: IInvoiceableBooking) => {
        settleInvoice({
            ownerIds: [booking.id],
            clubId: getClubFromStorage().id,
            startDate: startDateFilterValue,
            endDate: endDateFilterValue,
        });
    };

    const bulkSettlement = () => {
        settleInvoice({
            ownerIds: checkboxesSelected,
            clubId: getClubFromStorage().id,
            startDate: startDateFilterValue,
            endDate: endDateFilterValue,
        }).then(() => setCheckboxesSelected([]));
    };

    const getListRows = (invoiceableBookingsFiltered: IInvoiceableBooking[]): any[][] => invoiceableBookingsFiltered
        .map(booking => [
            booking.avatar,
            <Checkbox
                checked={checkboxesSelected.includes(booking.id)}
                onChange={handleChangeCheckbox}
                name={booking.id}
            />,
            booking.name as string,
            booking.surname as string,
            <ListRoleItem role={booking.role} />,
            formatNumberAsLocaleCurrency((booking as any).totalAmount),
            <Button
                type="primary"
                size="large"
                className="settlement-page__settle-btn"
                onClick={() => singleSettlement(booking)}
            >
                {translate('settlement-page_table-settlement-button')}
            </Button>,
        ]);

    const Filters = (
        <SettlementFilters
            startDateFilterValue={startDateFilterValue}
            endDateFilterValue={endDateFilterValue}
            initialEndDateFilterValue={initialEndDateFilterValue}
            nameFilterValue={nameFilterValue}
            nameFilterOptions={nameFilterOptions}
            onStartDateFilterChange={newStartDate => {
                const newStartDateFilterValue = newStartDate!.startOf('d');
                setStartDateFilterValue(newStartDateFilterValue);
            }}
            onEndDateFilterChange={newEndDate => {
                const newEndDateFilterValue = newEndDate!.endOf('d');
                setEndDateFilterValue(newEndDateFilterValue);
            }}
            onNameFilterChange={value => {
                setNameFilterValue(value);
                setCheckboxesSelected([]);
            }}
            onRoleFilterChange={value => {
                setRoleFilterValue(value);
                setCheckboxesSelected([]);
            }}
        />
    );

    useEffect(() => {
        showInvoicesSorted(startDateFilterValue, endDateFilterValue);
    }, [startDateFilterValue, endDateFilterValue]);

    return (
        <div className="settlement-page">
            <ListPageHeader header={translate('settlement-page_title')} iconUrl={BOOKKEEPING_ICON_SETTLEMENTS}>
                <ListPageButton
                    onClick={() => {
                        setModalContentProps({
                            closable: true,
                            onCancel: () => setModalContent(null),
                        });
                        setModalContent(
                            <InfoModalContent>
                                <SettlementExplanation />
                            </InfoModalContent>,
                        );
                    }}
                    grey
                    icon="?"
                    roundedIcon
                >
                    {translate('settlement-page_header-first-button')}
                </ListPageButton>
                <ListPageButton disabled={!checkboxesSelected.length} onClick={bulkSettlement}>
                    {translate('settlement-page_header-second-button')}
                </ListPageButton>
            </ListPageHeader>
            {!isMobile && Filters}
            {filteredInvoiceableBookings.length > 0 ? (
                <ListTable
                    rows={getListRows(filteredInvoiceableBookings)}
                    sortBy={sortBy}
                    headers={headers}
                />
            ) : <InfoBox>{translate('book-keeping-page_no-data')}</InfoBox>}
            {isMobile && <MobileFooter content={Filters} />}
        </div>
    );
};
