import { translate } from 'translations/translate';
import { BookedBy } from 'types';

export const formatReservationRoles = (bookedBy: BookedBy | undefined) => {
    switch (bookedBy) {
    case BookedBy.MEMBERS:
        return translate('calendar_reservation-players_roles_members');
    case BookedBy.CLIENTS:
        return translate('calendar_reservation-players_roles_guests');
    default:
        return translate('calendar_reservation-players_roles_mixed');
    }
};
