/* eslint-disable indent */
import { translate } from 'translations/translate';
import { IListTableHeaderField } from 'types';
import { Checkbox } from 'antd';
import React from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface GetInvoiceListHeaders {
    allSelected: boolean,
    onClickGlobalCheck: (e: CheckboxChangeEvent) => void,
}

export const getInvoiceListHeaders = ({ allSelected, onClickGlobalCheck }:
    GetInvoiceListHeaders): IListTableHeaderField[] => [
        { field: 'avatar', value: translate('invoices-page_table-header-avatar') },
        {
            field: 'selection',
            value: <Checkbox
                checked={allSelected}
                onChange={onClickGlobalCheck}
                name="all-invoices-selection"
            />,
        },
        {
            field: 'invoice-number',
            value: translate('invoices-page_table-header-invoice-number'),
            sortable: true,
        },
        { field: 'name', value: translate('invoices-page_table-header-name'), sortable: true },
        { field: 'surname', value: translate('invoices-page_table-header-surname'), sortable: true },
        { field: 'role', value: translate('invoices-page_table-header-role') },
        { field: 'date', value: translate('invoices-page_table-header-date'), sortable: true },
        { field: 'status', value: translate('invoices-page_table-header-status') },
        { field: 'payment-method', value: translate('invoices-page_table-header-payment-method'), sortable: true },
        { field: 'total-amount', value: translate('invoices-page_table-header-total-amount'), sortable: true },
        { field: 'check', value: '' },
        { field: 'cancel', value: '' },
    ];
