import { PROFILE } from 'config/endpoints';
import { CreateUserRequest } from 'usecases/types/dto/CreateUserRequest';
import { IUser } from 'types';
import { postWrapper } from 'services/httpService/httpServiceWrapper';

export const createUser = async (request: CreateUserRequest): Promise<IUser> => {
    const url = PROFILE.CREATE;

    const data = { ...request };

    const response = await postWrapper(url, data);
    return response.data.user;
};
