import { Divider } from 'antd';
import React from 'react';

export const FormDivider = () => (
    <>
        <div />
        <Divider />
        <div />
    </>
);
