import { Dict } from 'types';

export const calculateDiffBetweenTwoMaps = <T extends any>(
    firstDictionary: Dict<T>,
    secondDictionary: Dict<T>,
): Dict<T> => {
    if (JSON.parse(JSON.stringify(firstDictionary)) === JSON.parse(JSON.stringify(secondDictionary))) {
        return {};
    }
    const firstDictionaryKeys = Object.keys(firstDictionary);
    const secondDictionaryKeys = Object.keys(secondDictionary);
    const optionIds = firstDictionaryKeys.filter(playerKey => !secondDictionaryKeys.includes(playerKey));

    const options = optionIds.reduce((acc, cur) => {
        const result = { ...acc, [cur]: firstDictionary[cur] };
        return result;
    }, {});

    return options;
};
