import { COURTS, PLACEHOLDERS } from 'config/endpoints';
import { deleteWrapper } from 'services/httpService/httpServiceWrapper';

class DeleteCourt {
    execute(clubId: string, courtId: string, notify = false) {
        const endpoint = COURTS.SINGLE
            .replace(PLACEHOLDERS.CLUB_ID, clubId)
            .replace(PLACEHOLDERS.COURT_ID, courtId);
        return deleteWrapper(endpoint, { notify });
    }
}

export default new DeleteCourt();
