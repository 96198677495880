import {
    createBrowserHistory, Location, Path,
} from 'history';

const history = createBrowserHistory();

export const customHistory = {
    ...history,
    push: (path: Path, state?: Location) => history.push(
        `${path}${history.location.search}`,
        state,
    ),
    replace: (path: any, state?: Location) => history.replace(
        `${path.pathname}${history.location.search}`,
        state,
    ),
    originalReplace: history.replace,
};
