import React, { MouseEventHandler } from 'react';
import { Button as AntButton } from 'antd';
import './Button.scss';

interface IProps {
    grey?: boolean,
    onClick: MouseEventHandler,
    children: React.ReactNode,
    disabled?: boolean;
}

export const Button = ({
    grey = false, onClick, children, disabled,
}: IProps) => (
    <AntButton
        onClick={onClick}
        type={grey ? 'default' : 'primary'}
        className={`ch-button ${grey && 'ch-button--grey'}`}
        disabled={disabled}
    >
        {children}
    </AntButton>
);
