import React from 'react';
import { InvoiceStatus } from 'types';
import { translate } from 'translations/translate';
import './InvoiceStatusDisplay.scss';

interface IProps {
    status: InvoiceStatus;
}

export const InvoiceStatusDisplay = ({ status }: IProps) => (
    <div className={`invoice-status-display invoice-status-display--${status.toLocaleLowerCase()}`}>
        <div className="invoice-status-display__circle" />
        <div className="invoice-status-display__text">
            {translate(`invoice_${status}`)}
        </div>
    </div>
);
