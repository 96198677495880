import React from 'react';
import { multiClubRoutes } from 'routing/multiClubRoutes';
import { LayoutPage } from 'pages/common/Layout/LayoutPage';
import { MyFacilitesLayoutMenu } from './MyFacilitesLayoutMenu';

export const MyFacilitesLayoutPage = (): JSX.Element => (
    <LayoutPage
        routes={multiClubRoutes}
        menu={<MyFacilitesLayoutMenu />}
    />
);
