import React, { useEffect, useState } from 'react';
import {
    getClubFromStorage, isLoggedUserAdmin, isLoggedUserOwner, setClubInStorage,
} from 'services/functionalStorage';
import { AddressCard } from 'pages/common/AddressCard/AddressCard';
import { Address, IContactData, IInvoiceData } from 'types';
import { translate } from 'translations/translate';
import { updateClub } from 'usecases/club/updateClub';
import { AlertModalContent } from 'components/AlertModalContent/AlertModalContent';
import { DeleteModalContent } from 'components/DeleteModalContent/DeleteModalContent';
import { useModalContext } from 'context/ModalContext';
import { ListPageHeader } from 'components/ListPageHeader/ListPageHeader';
import { COURTS_ICON_GRAY } from 'config/constants';
import { useApi } from 'hooks/useApi';
import { getClubAndSetInStorage } from 'usecases/club/getClub';
import { IClub } from 'usecases/types/club';
import { ClubLogo } from './ClubLogo/ClubLogo';
import { ClubSettings } from './ClubSettings/ClubSettings';
import { ClubSettlementInfoCard } from './ClubSettlementInfoCard/ClubSettlementInfoCard';
import { ClubContactData } from './ClubContactData/ClubContactData';
import { PaymentMethods } from './PaymentMethods/PaymentMethods';
import { FacilityStatusCard } from './FacilityStatusCard/FacilityStatusCard';
import { BookingExtraInformation, IBookingExtraInformation } from './BookingExtraInformation/BookingExtraInformation';
import './ClubPage.scss';

export const ClubPage = (): JSX.Element => {
    const [currentClub, setCurrentClub] = useState<IClub>(getClubFromStorage());
    const { setModalContentProps, setModalContent } = useModalContext();
    const callApi = useApi();

    const updateFacilityState = (isActive: boolean) => {
        const facilityUpdated = { ...currentClub, active: isActive };
        setCurrentClub(facilityUpdated);
        setClubInStorage(facilityUpdated);
    };

    useEffect(() => {
        const fetchUpdatedInfoFromFacility = async () => {
            const updatedCurrentClub = await getClubAndSetInStorage(currentClub.id);
            setCurrentClub(updatedCurrentClub);
        };

        fetchUpdatedInfoFromFacility();
    }, []);

    const showSuccessMessage = () => {
        setModalContentProps({ closable: false });
        setModalContent(
            <AlertModalContent
                type="success"
                goBackText={translate('go-back')}
                header={translate('club_settings-success_modal_title')}
                onGoBack={() => setModalContent(null)}
            />,
        );
    };

    const showDeleteMessage = (onProceedCallback: Function) => {
        setModalContentProps({ closable: false });
        setModalContent(
            <DeleteModalContent
                headerText={translate('club_settings-delete_logo_title')}
                cancelText={translate('club_settings-delete_logo_back-button')}
                proceedText={translate('club_settings-delete_logo')}
                onProceed={async () => {
                    await onProceedCallback();
                    setModalContent(null);
                }}
                onCancel={() => setModalContent(null)}
            />,
        );
    };

    const onAddressChange = async (newAddress: Address) => {
        await callApi(updateClub({
            clubId: currentClub.id,
            name: currentClub.name,
            openingTime: currentClub.openingTime || '',
            closingTime: currentClub.closingTime || '',
            services: currentClub.services || [],
            address: newAddress,
            invoiceData: currentClub.invoiceData,
            contactData: currentClub.contactData,
            bookingExtraInformation: currentClub.bookingExtraInformation,
        }).then(showSuccessMessage));
    };

    const onSettlementInfoChange = async (newInvoiceData: IInvoiceData) => {
        await callApi(updateClub({
            clubId: currentClub.id,
            name: currentClub.name,
            openingTime: currentClub.openingTime || '',
            closingTime: currentClub.closingTime || '',
            services: currentClub.services || [],
            address: currentClub.address,
            invoiceData: newInvoiceData,
            contactData: currentClub.contactData,
            bookingExtraInformation: currentClub.bookingExtraInformation,
        }).then(showSuccessMessage));
    };

    const onContactDataChange = async (newContactData: IContactData) => {
        await callApi(updateClub({
            clubId: currentClub.id,
            name: currentClub.name,
            openingTime: currentClub.openingTime || '',
            closingTime: currentClub.closingTime || '',
            services: currentClub.services || [],
            address: currentClub.address,
            invoiceData: currentClub.invoiceData,
            contactData: newContactData,
            bookingExtraInformation: currentClub.bookingExtraInformation,
        }).then(showSuccessMessage));
    };

    const updateBookingExtraInformation = async (newBookingExtraInformation: IBookingExtraInformation) => {
        await callApi(updateClub({
            clubId: currentClub.id,
            name: currentClub.name,
            openingTime: currentClub.openingTime || '',
            closingTime: currentClub.closingTime || '',
            services: currentClub.services || [],
            address: currentClub.address,
            invoiceData: currentClub.invoiceData,
            contactData: currentClub.contactData,
            bookingExtraInformation: newBookingExtraInformation.bookingExtraInformation || null,
        }).then(showSuccessMessage));
    };

    const userIsAdmin = isLoggedUserAdmin();

    return (
        <>
            <ListPageHeader header={translate('club_title')} iconUrl={COURTS_ICON_GRAY} />

            <div className="club-page">
                <ClubSettings
                    currentClub={currentClub}
                    onUpdateSuccessfully={showSuccessMessage}
                    disabled={!userIsAdmin}
                />

                {userIsAdmin && (
                    <ClubLogo
                        currentClub={currentClub}
                        onUpdateSuccessfully={showSuccessMessage}
                        showDeleteMessage={showDeleteMessage}
                    />
                )}

                <AddressCard initialValue={currentClub.address} onFinish={onAddressChange} disabled={!userIsAdmin} />

                <ClubContactData
                    onFinish={onContactDataChange}
                    initialValues={currentClub.contactData}
                    disabled={!userIsAdmin}
                />

                {userIsAdmin && (
                    <ClubSettlementInfoCard
                        onFinish={onSettlementInfoChange}
                        initialValues={currentClub.invoiceData}
                    />
                )}

                {userIsAdmin && (
                    <PaymentMethods club={currentClub} />
                )}

                { isLoggedUserOwner() && (
                    <FacilityStatusCard club={currentClub} updateFacilityState={updateFacilityState} />
                )}

                {userIsAdmin && (
                    <BookingExtraInformation
                        bookingExtraInformation={currentClub.bookingExtraInformation!}
                        onFinish={updateBookingExtraInformation}
                    />
                )}

            </div>
        </>
    );
};
