import React, { useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { getClubFromStorage } from 'services/functionalStorage';
import { ReservationsCalendarHeader } from 'components/Reservations/ReservationsCalendar/ReservationsCalendarHeader';
import { InfoBox } from 'components/InfoBox/InfoBox';
import { NoSeasonInfoContent } from 'components/NoSeasonInfoContent/NoSeasonInfoContent';
import { NoCourtsAvailableInfoContent }
    from 'components/Reservations/ReservationsCalendar/NoCourtsAvailableInfoContent';
import { IClub } from 'usecases/types/club';
import { getClub } from 'usecases/club/getClub';
import { areCourtsInSeason } from 'components/Reservations/ReservationsCalendar/areCourtsInSeason';
import { isActiveSeason } from 'components/Reservations/ReservationsCalendar/isActiveSeason';
import { ICourt } from 'usecases/types/court';
import { ISeason } from 'usecases/types/season';
import { getCourts } from 'usecases/court/getCourts';
import { getClubSeasons } from 'usecases/club/getClubSeasons';
import { getPricingRulesByClub } from 'usecases/club/getPricingRulesByClub';
import { getBookableCourts } from 'components/Reservations/ReservationsCalendar/getBookableCourts';
import { getBookableSeasons } from 'components/Reservations/ReservationsCalendar/getBookableSeasons';
import { useApi } from 'hooks/useApi';
import { IPricingRule } from 'usecases/types/price';
import { CalendarViewEnum, PlayerRole } from 'types';
import { CHDateTime, TimeUnits } from 'helpers/DateTime/CHDateTime';
import { IReservation } from 'usecases/types/reservations';
import { getReservations } from 'usecases/reservation/getReservations';
import { MobileFooter } from 'components/MobileFooter/MobileFooter';
import { formatSingleDayDate } from 'components/Reservations/TopRowFilters/formatSingleDayDate';
import { useAppContext } from 'context/AppContext';
import { useCalendar } from 'hooks/useCalendar';
import { ROUTES } from 'routing/routes.enum';
import { useMobileMediaQuery } from 'hooks/useMobileMediaQuery';
import './PublicCalendar.scss';

interface IRouteProps {
    clubId: string;
}

export const PublicCalendar = () => {
    const { antdLocaleObject } = useAppContext();
    const { locale: antdLocale } = antdLocaleObject;
    const [club, setClub] = useState<IClub>(getClubFromStorage());
    const [seasons, setSeasons] = useState<ISeason[]>([]);
    const [courts, setCourts] = useState<ICourt[]>([]);
    const [pricingRules, setPricingRules] = useState<IPricingRule[]>([]);
    const [bookings, setBookings] = useState<IReservation[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const { clubId } = useParams<IRouteProps>();
    const callApi = useApi();
    const { isMobile } = useMobileMediaQuery();

    const redirectToLogin = () => {
        window.open(generatePath(ROUTES.LOGIN));
    };
    const {
        calendar, filters, displayCourt, view, filterDate, bookingDate, displayedCourt,
    } = useCalendar({
        courts,
        seasons,
        pricingRules,
        bookings,
        role: PlayerRole.CLIENT,
        onEventClick: redirectToLogin,
        onDateClick: redirectToLogin,
    });

    const getReservationsByDate = (dateToFilter: string): Promise<IReservation[]> => {
        const dateInstance = new CHDateTime(dateToFilter);
        return callApi(getReservations(
            {
                club,
                court: undefined,
                from: dateInstance.startOfDay(),
                to: dateInstance.endOfDay(),
            },
        ));
    };

    const getReservationsByCourtAndWeek = (court: ICourt, initialDateString: string): Promise<IReservation[]> => {
        const initialDate = new CHDateTime(initialDateString);
        return callApi(getReservations(
            {
                club,
                court,
                from: initialDate.startOfDay(),
                to: initialDate.add(6, TimeUnits.DAY).endOfDay(),
            },
        ));
    };

    const setUpdatedReservationsByView = (
        _view: CalendarViewEnum,
        courtDisplayed: ICourt | null,
        filterDateString: string,
    ): void => {
        if (filterDateString && _view === CalendarViewEnum.DAYVIEW) {
            getReservationsByDate(filterDateString).then(setBookings);
        }
        if (courtDisplayed && _view === CalendarViewEnum.WEEKVIEW) {
            getReservationsByCourtAndWeek(courtDisplayed!, filterDateString).then(setBookings);
        }
    };

    useEffect(() => {
        if (club) {
            setUpdatedReservationsByView(view, displayedCourt, filterDate.toString());
        }
    }, [displayedCourt, filterDate, view]);

    useEffect(
        () => {
            const asyncFunc = async () => {
                const apiClub = await callApi(getClub(clubId));
                const [apiCourts, apiSeasons]: [ICourt[], ISeason[]] = await callApi(Promise.all([
                    getCourts(apiClub),
                    getClubSeasons(apiClub.id),
                ]));
                setClub(apiClub);
                const activeSeasons = apiSeasons.filter(season => isActiveSeason(season));
                const seasonsCourts = apiCourts
                    .filter(court => activeSeasons
                        .some(season => season.courts.includes(court.id)));
                setCourts(seasonsCourts);
                if (activeSeasons) {
                    const pricingRulesBySeasonFromAPI = await Promise.all(
                        activeSeasons.map(season => callApi(getPricingRulesByClub(apiClub.id, season.id))),
                    );
                    const pricingRulesFromAPI = pricingRulesBySeasonFromAPI
                        .reduce((acc, current) => [...acc, ...current], []);
                    setPricingRules(pricingRulesFromAPI);
                }

                const bookableCourts = getBookableCourts(
                    seasonsCourts,
                    apiSeasons,
                    view,
                    bookingDate.toString(),
                );
                setCourts(bookableCourts);
                displayCourt(bookableCourts[0]);
                setSeasons(getBookableSeasons(activeSeasons, bookableCourts[0], view));
                setDataLoaded(true);
            };
            asyncFunc();
        },
        [],
    );

    const shouldShowCalendarAndFilters = (
        !!(seasons && seasons.some(season => (areCourtsInSeason(season))))
    )
        || !!(seasons && seasons.some(season => (isActiveSeason(season) && areCourtsInSeason(season))));

    return dataLoaded && (
        <>
            <div className="public-calendar public-calendar--input-height">
                {!isMobile && (
                    <ReservationsCalendarHeader
                        clubLogo={club?.logo}
                        filtersVisibility={shouldShowCalendarAndFilters}
                    >
                        {filters}
                    </ReservationsCalendarHeader>
                )}

                {shouldShowCalendarAndFilters ? (
                    <>
                        {calendar}
                        {isMobile && (
                            <MobileFooter
                                content={filters}
                                mobileFooterOpenkey={
                                    view === CalendarViewEnum.DAYVIEW
                                        ? formatSingleDayDate(filterDate, antdLocale)
                                        : displayedCourt?.name
                                }
                            />
                        )}
                    </>
                ) : (
                    <InfoBox>
                        <NoSeasonInfoContent season={seasons.find(season => season.active)} />
                    </InfoBox>
                )}
                <NoCourtsAvailableInfoContent
                    courts={courts}
                    shouldShowCalendarAndFilters={shouldShowCalendarAndFilters}
                />
            </div>
        </>
    );
};
