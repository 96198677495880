import { getUserFromStorage, setUserInStorage } from 'services/functionalStorage';
import { PROFILE } from 'config/endpoints';
import { IUpdateUserAddress } from 'usecases/types/dto/dto';
import { putWrapper } from 'services/httpService/httpServiceWrapper';

export const updateUserAddress = async (
    { address }: IUpdateUserAddress,
) => {
    const {
        name, surname, gender, language,
    } = getUserFromStorage();

    const response = await putWrapper(
        PROFILE.SINGLE,
        {
            name, surname, gender, language, address,
        },
    );

    setUserInStorage({
        ...getUserFromStorage(),
        address,
    });

    return response;
};
