import React from 'react';
import { Button, ButtonProps } from 'antd';
import './ActionElement.scss';

interface IProps {
    icon: JSX.Element,
    onProceed: () => void,
    buttonProps?: ButtonProps
}

export const ActionElement = ({ icon, onProceed, buttonProps }: IProps) => (
    <Button
        type="default"
        className="action-element"
        size="large"
        icon={icon}
        onClick={onProceed}
        {...buttonProps}
    />
);
