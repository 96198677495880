import { DataLayerArgs } from 'react-gtm-module';
import { IClub } from 'usecases/types/club';
import { getSuccessfulAdminRegistrationDatalayer } from './gtmDatalayers';

export const buildSuccessfulAdminRegistrationDatalayer = (clubInfo: IClub): DataLayerArgs['dataLayer'] => {
    const { name, admin } = clubInfo;
    const { gender, language, id } = admin;

    const successfulAdminRegistrationDatalayer = getSuccessfulAdminRegistrationDatalayer({
        customerClubName: name,
        customerGender: gender,
        customerLanguage: language,
        customerUserId: id,
    });

    return successfulAdminRegistrationDatalayer;
};
