import { ICourt } from 'usecases/types/court';
import { calculateBusinessHours } from 'components/Reservations/ReservationsCalendar/calculateBusinessHours';
import { IPricingRule } from 'usecases/types/price';
import { CHDateTime } from 'helpers/DateTime/CHDateTime';
import { PlayerRole } from 'types';

export const adaptCourtListInfo = (
    courtList: ICourt[],
    pricingRules: IPricingRule[],
    date: CHDateTime,
    role: PlayerRole,
) => courtList.map(courtItem => (
    {
        id: courtItem.id,
        title: courtItem.name,
        court: courtItem,
        businessHours: calculateBusinessHours(courtItem, pricingRules, date, role),
    }
));
