import {
    AutoComplete, Button, Input, RadioChangeEvent,
} from 'antd';
import { CHRadioGroup } from 'components/CHRadioGroup/CHRadioGroup';
import { useApi } from 'hooks/useApi';
import React, { useMemo, useState } from 'react';
import { translate } from 'translations/translate';
import { PlayerRole, PLAYERROLECLIENT, PLAYERROLEMEMBER } from 'types';
import { IClub } from 'usecases/types/club';
import { getUserInformationAndSetInStorage } from 'usecases/users/getUserInformationAndSetInStorage';
import { joinFacilty } from 'usecases/users/joinFacility';
import { BuildFacilityAddress } from '../BuildFacilityAddress';
import './JoinFacilityCard.scss';

interface ISearchFacilityCard {
    availableFacilities: IClub[];
    showAddFacilityCard: () => void
}

export const JoinFacilityCard = ({ availableFacilities, showAddFacilityCard }: ISearchFacilityCard) => {
    const [inputText, setInputText] = useState<string>('');
    const [chosenClubId, setChosenClubId] = useState<string>('');
    const [userMembership, setUserMembership] = useState<PlayerRole>(PLAYERROLECLIENT);
    const callApi = useApi();

    const options = availableFacilities
        .map(({ name, id }: { name: string, id: string }) => (
            { label: name, value: id }));

    const filterOption = (inputValue: string, option: any) => option.label
        .toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

    const onChange = (value: string) => {
        setInputText(value);
        setChosenClubId('');
    };

    const onSelect = (value: string, option: any) => {
        setInputText(option.label);
        setChosenClubId(value);
    };

    const onJoinFacility = () => {
        callApi(joinFacilty({ clubId: chosenClubId, role: userMembership })
            .then(() => getUserInformationAndSetInStorage())
            .finally(() => {
                setChosenClubId('');
                setInputText('');
                showAddFacilityCard();
            }));
    };

    const onSelectMembership = (e: RadioChangeEvent) => {
        setUserMembership(e.target.value);
    };

    const clubToJoin = useMemo(
        () => availableFacilities
            .find(availableFacility => availableFacility.id === chosenClubId),
        [chosenClubId],
    );

    return (
        <>
            <div>
                <label className="join-facility-card__input-label">
                    {translate('facility_card-join_facitiy_input')}
                </label>

                <AutoComplete
                    className="join-facility-card__filter"
                    value={inputText}
                    options={options}
                    filterOption={filterOption}
                    onChange={onChange}
                    onSelect={onSelect}
                >

                    <Input
                        className="join-facility-card__input"
                    />
                </AutoComplete>
            </div>

            {clubToJoin && (
                <>
                    <span>
                        <BuildFacilityAddress address={clubToJoin.address} />
                    </span>

                    <CHRadioGroup
                        radioOptions={[
                            { value: PLAYERROLECLIENT, key: `role_${PLAYERROLECLIENT}` },
                            { value: PLAYERROLEMEMBER, key: `role_${PLAYERROLEMEMBER}` },
                        ]}
                        primarySelectedColor
                        customClassName="join-facility-card__options"
                        name="membership"
                        value={userMembership}
                        onChange={onSelectMembership}
                        disabled={Boolean(!chosenClubId)}
                    />
                </>
            )}

            <Button
                onClick={onJoinFacility}
                type="primary"
                disabled={Boolean(!chosenClubId)}
                className="join-facility-card__button"
            >
                {translate('facility_card-join_this_facility')}
            </Button>
        </>
    );
};
