import React from 'react';
import { Button, DatePicker } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment/moment';
import { CHDateTime, TimeUnits } from 'helpers/DateTime/CHDateTime';
import { formatSingleDayDate } from 'components/Reservations/TopRowFilters/formatSingleDayDate';
import { useAppContext } from 'context/AppContext';
import './DaySelector.scss';

interface IProps {
    dayDate: CHDateTime,
    onDayChange: (day: CHDateTime) => void;
}

export const DaySelector = ({ dayDate, onDayChange }: IProps) => {
    const { antdLocaleObject } = useAppContext();
    const { locale } = antdLocaleObject;

    return (
        <div className="day-selector">
            <Button
                icon={<LeftOutlined className="icon-bold" />}
                className="day-selector__button day-selector__button--margin-right"
                onClick={() => onDayChange(dayDate.subtract(1, TimeUnits.DAY).toUtc())}
            />
            <div className="day-selector__filter-title">
                <DatePicker
                    bordered={false}
                    format={date => formatSingleDayDate(CHDateTime.fromNativeDate(date.toDate()), locale)}
                    value={moment(dayDate.toNativeDate())}
                    onChange={_value => onDayChange(CHDateTime.fromNativeDate(_value!.toDate()))}
                    className="day-selector__picker-input"
                    suffixIcon={false}
                    allowClear={false}
                />
            </div>
            <Button
                icon={<RightOutlined className="icon-bold" />}
                className="day-selector__button day-selector__button--margin-left"
                onClick={() => onDayChange(dayDate.add(1, TimeUnits.DAY).toUtc())}
            />
        </div>
    );
};
