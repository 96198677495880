import { RESERVATIONS, PLACEHOLDERS } from 'config/endpoints';
import { getWrapper } from 'services/httpService/httpServiceWrapper';

export const getBookingById = async (reservationId: string): Promise<any> => {
    const url = RESERVATIONS.SINGLE.replace(
        PLACEHOLDERS.RESERVATION_ID,
        reservationId,
    );
    const { data } = await getWrapper(url);
    const { reservation } = data;

    return reservation;
};
