import { CLUBS } from 'config/endpoints';
import { getWrapper } from 'services/httpService/httpServiceWrapper';
import { IClub } from 'usecases/types/club';

export const getAllClubs = async (): Promise<IClub[]> => {
    const url = CLUBS.MANY;

    const { data } = await getWrapper(url);

    return data;
};
