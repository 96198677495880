import { translate } from 'translations/translate';
import { Dict } from 'types';

export const getCountryMap = () : Dict<string> => ({
    DE: translate('DE'),
    FR: translate('FR'),
    GB: translate('GB'),
    IT: translate('IT'),
    NL: translate('NL'),
    AT: translate('AT'),
    CH: translate('CH'),
    ES: translate('ES'),
});
