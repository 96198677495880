import { AUTH } from 'config/endpoints';
import { postWrapper } from 'services/httpService/httpServiceWrapper';

export const login = async (email: string, password: string, remember: boolean): Promise<string> => {
    const loginResponse = await postWrapper<any>(
        AUTH.LOGIN,
        {
            email,
            password,
            remember,
        },
        { Authorization: null },
    );

    const { token } = loginResponse.data;
    localStorage.setItem('token', token);

    return token;
};
