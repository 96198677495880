import { PLACEHOLDERS, PRICING_RULES } from 'config/endpoints';
import { IPricingRule } from 'usecases/types/price';
import { CreatePriceInput } from 'usecases/types/dto/CreatePriceInput';
import { postWrapper } from 'services/httpService/httpServiceWrapper';
import moment from 'moment';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { getCreatePricingRuleDataLayer } from 'services/gtm/gtmDatalayers';
import { getUserFromStorage } from 'services/functionalStorage';
import { getRoleName } from 'helpers/getRoleName';

export const createPricingRule = async ({
    clubId,
    name,
    weekdays,
    courts,
    startTime: _startTime,
    endTime: _endTime,
    role,
    amount,
    seasonId,
    guestFee,
}: CreatePriceInput): Promise<IPricingRule> => {
    const startTime = moment(_startTime, 'HH:mm').format('HH:mmZ');
    const endTime = moment(_endTime, 'HH:mm').format('HH:mmZ');
    const payLoad = {
        name,
        weekdays,
        courts,
        startTime,
        endTime,
        role,
        amount,
        seasonId,
        guestFee,
    };

    const url = PRICING_RULES.LIST
        .replace(PLACEHOLDERS.CLUB_ID, clubId)
        .replace(PLACEHOLDERS.SEASON_ID, seasonId);

    const { data } = await postWrapper(url, payLoad);

    const user = getUserFromStorage();

    pushGtmDatalayer(getCreatePricingRuleDataLayer(
        user.id,
        clubId,
        courts,
        seasonId,
        data.pricingRule.id,
        getRoleName(role),
        amount,
    ));

    return data.pricingRule;
};
