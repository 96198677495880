import React from 'react';
import { Button } from 'antd';
import './ListPageButton.scss';

interface IProps {
    icon?: string;
    grey?: boolean;
    roundedIcon?: boolean;
    onClick: () => any;
    children: React.ReactNode;
    disabled?: boolean
}

export const ListPageButton = ({
    icon,
    grey = false,
    roundedIcon = false,
    onClick,
    children,
    ...rest
}: IProps) => (
    <Button
        type={grey ? 'default' : 'primary'}
        className={`list-page-button ${grey && 'list-page-button--grey'}`}
        onClick={() => onClick()}
        {...rest}
    >
        {icon && (
            <p className={`list-page-button__icon ${roundedIcon && 'list-page-button__icon--rounded'}`}>
                {icon}
            </p>
        )}

        <p className="list-page-button__text">{children}</p>
    </Button>
);
