import { translate } from 'translations/translate';
import { getDateTimeFormatter } from 'adapters/dateTimeFormatter';
import { CHDateTime } from 'helpers/DateTime/CHDateTime';

export const formatSingleDayDate = (date: CHDateTime, locale: string) => {
    const formatter = getDateTimeFormatter(locale, { day: 'numeric', month: 'short', year: 'numeric' });

    let dateText = formatter.format(date.toNativeDate());
    if (date.startOfDay().isEqual((new CHDateTime()).startOfDay())) {
        dateText += ` (${translate('calendar_filter-today')})`;
    }
    return dateText;
};
