import React, { useEffect, useState } from 'react';
import { getClubFromStorage } from 'services/functionalStorage';
import { ListTable } from 'components/ListTable/ListTable';
import { MONEY_ICON_GRAY } from 'config/constants';
import { translate } from 'translations/translate';
import { getMemberFees } from 'usecases/club/getMemberFees';
import { IListTableHeaderField, UserWithReservationsCount } from 'types';
import { ListPageHeader } from 'components/ListPageHeader/ListPageHeader';
import { ListPageButton } from 'components/ListPageButton/ListPageButton';
import { InfoModalContent } from 'components/InfoModalContent/InfoModalContent';
import { GuestFeesFilters } from 'components/GuestFeesFilters/GuestFeesFilters';
import { MobileFooter } from 'components/MobileFooter/MobileFooter';
import { ISeason } from 'usecases/types/season';
import { getClubSeasons } from 'usecases/club/getClubSeasons';
import { formatNumberAsLocaleTwoDigits } from 'adapters/formatNumberAsLocaleTwoDigits';
import { useModalContext } from 'context/ModalContext';
import { InfoBox } from 'components/InfoBox/InfoBox';
import { useApi } from 'hooks/useApi';
import { useMobileMediaQuery } from 'hooks/useMobileMediaQuery';
import { GuestFeesExplanation } from './GuestFeesExplanation';
import './GuestFeesPage.scss';

interface INameFilterOption {
    label: string;
    value: string;
    key: string;
}

export const GuestFeesPage = () => {
    const { isMobile } = useMobileMediaQuery();
    const { setModalContentProps, setModalContent } = useModalContext();
    const [members, setMembers] = useState<UserWithReservationsCount[]>([]);
    const [seasons, setSeasons] = useState<ISeason[]>([]);
    const [nameFilterOptions, setNameFilterOptions] = useState<INameFilterOption[]>([]);
    const [nameFilterValue, setNameFilterValue] = useState<string>('');
    const [seasonFilterValue, setSeasonFilterValue] = useState<string | null>(null);
    const callApi = useApi();

    const headers: IListTableHeaderField[] = [
        {
            field: 'avatar',
            value: translate('guest_fees-table_header-avatar'),
        },
        {
            field: 'name',
            value: translate('guest_fees-table_header-name'),
        },
        {
            field: 'email',
            value: translate('guest_fees-table_header-email'),
        },
        {
            field: 'bookings-with-guests',
            value: translate('guest_fees-table_header-bookings_with_guests'),
            sortable: true,
        },
        {
            field: 'bookings',
            value: translate('guest_fees-table_header-bookings'),
            sortable: true,
        },
        {
            field: 'total-amount',
            value: translate('guest_fees-table_header-total_amount'),
            sortable: true,
        },
    ];

    const getListRows = (_members: UserWithReservationsCount[]): string[][] => _members
        .filter(member => `${member.name} ${member.surname}`.toLowerCase().includes(nameFilterValue.toLowerCase()))
        .map(member => [
            member.avatar as string,
            `${member.name} ${member.surname}`,
            member.email,
            member.totalReservationsWithGuests.toString(),
            member.totalReservations.toString(),
            `${formatNumberAsLocaleTwoDigits(member.totalAmountGuestsFee)} ${translate('guest_fees-currency_units')}`,
        ]);

    const sortBy = (field: string, order?: 'asc' | 'desc'): void => {
        const descSortedMembers = members.sort((current, next) => {
            if (field === 'bookings-with-guests') {
                return current.totalReservationsWithGuests - next.totalReservationsWithGuests;
            }
            if (field === 'bookings') {
                return current.totalReservations - next.totalReservations;
            }
            if (field === 'total-amount') {
                return current.totalAmountGuestsFee - next.totalAmountGuestsFee;
            }
            return 0;
        });
        const ascSortedMembers = JSON.parse(JSON.stringify(descSortedMembers)).reverse();
        const sortedMembers = order === 'asc' ? ascSortedMembers : descSortedMembers;
        setMembers([...sortedMembers]);
    };

    const getMembersFromAPI = async (seasonId: string): Promise<UserWithReservationsCount[]> => {
        const { id } = getClubFromStorage();

        const memberList = await callApi(getMemberFees(id, seasonId));

        return memberList;
    };

    const showMembersSorted = async (seasonId: string) => {
        const memberList = await getMembersFromAPI(seasonId);

        const memberListSorted = [...memberList].sort(
            (a, b) => b.totalReservationsWithGuests - a.totalReservationsWithGuests,
        );

        setNameFilterOptions(memberListSorted.map(member => ({
            label: `${member.name} ${member.surname}`,
            value: `${member.name} ${member.surname}`,
            key: member.id,
        })));

        setMembers(memberListSorted);
    };

    useEffect(() => {
        const getData = async () => {
            const { id } = getClubFromStorage();
            const seasonsFromAPI = await getClubSeasons(id);
            setSeasons(seasonsFromAPI);
        };
        getData();
    }, []);

    useEffect((): void => {
        if (seasonFilterValue || seasonFilterValue === '') {
            showMembersSorted(seasonFilterValue);
        }
    }, [seasonFilterValue]);

    const getNameFilterOption = (inputValue: string, { value }: { value: string }): boolean => value
        .toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

    const onChangeNameFilter = (value: string): void => {
        setNameFilterValue(value);
    };

    const RenderedGuestFilters = (
        <GuestFeesFilters
            nameFilterValue={nameFilterValue}
            nameFilterOptions={nameFilterOptions}
            getNameFilterOption={getNameFilterOption}
            onChangeNameFilter={onChangeNameFilter}
            setSeasonFilterValue={setSeasonFilterValue}
            seasonOptions={seasons}
        />
    );

    return (
        <div className="guest-fees">
            <ListPageHeader header={translate('guest_fees-title')} iconUrl={MONEY_ICON_GRAY}>
                <>
                    <ListPageButton
                        onClick={() => {
                            setModalContentProps({
                                closable: true,
                                onCancel: () => setModalContent(null),
                            });
                            setModalContent(
                                <InfoModalContent>
                                    <GuestFeesExplanation />
                                </InfoModalContent>,
                            );
                        }}
                        grey
                        icon="?"
                        roundedIcon
                    >
                        {translate('guest_fees_header-first-button')}
                    </ListPageButton>
                </>
            </ListPageHeader>

            {!isMobile && RenderedGuestFilters}

            {isMobile && <MobileFooter content={RenderedGuestFilters} />}

            {members.length > 0 ? (
                <ListTable
                    rows={getListRows(members)}
                    headers={headers}
                    sortBy={sortBy}
                />
            ) : <InfoBox>{translate('book-keeping-page_no-data')}</InfoBox>}
        </div>
    );
};
