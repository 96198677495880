import { RESERVATIONS, PLACEHOLDERS } from 'config/endpoints';
import { getWrapper } from 'services/httpService/httpServiceWrapper';
import { CHDateTime } from 'helpers/DateTime/CHDateTime';
import { IReservation } from '../types/reservations';

interface IParams {
    club: any,
    court?: any,
    from?: CHDateTime,
    to?: CHDateTime,
}
export const getReservations = async ({
    club,
    court,
    from,
    to,
} : IParams): Promise<IReservation[]> => {
    const baseUrl = RESERVATIONS.LIST
        .replace(PLACEHOLDERS.CLUB_ID, club.id);

    const urlParams = new URLSearchParams();
    if (from && to) {
        urlParams.append('from', from.toString());
        urlParams.append('to', to.toString());
    }

    if (court) {
        urlParams.append('court', court.id);
    }

    const response = await getWrapper(`${baseUrl}?${urlParams.toString()}`);
    return response.data;
};
