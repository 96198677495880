import { CHDateTime } from 'helpers/DateTime/CHDateTime';
import moment from 'moment';

interface IParams {
    calendarDate: moment.Moment,
    bookingSeasonEndDateTime: string
    bookingStartTime: string
}

export const getDisabledDates = ({ calendarDate, bookingSeasonEndDateTime, bookingStartTime }: IParams) => {
    const calendarDateNative = CHDateTime.fromNativeDate(calendarDate.toDate());
    const bookingSeasonEndNative = new CHDateTime(bookingSeasonEndDateTime);
    const bookingStartTimeNative = new CHDateTime(bookingStartTime);

    return calendarDateNative.isBeforeOrEqual(bookingStartTimeNative)
    || calendarDateNative.isAfter(bookingSeasonEndNative);
};
