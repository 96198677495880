import { Button, Input } from 'antd';
import { AlertModalContent } from 'components/AlertModalContent/AlertModalContent';
import { useModalContext } from 'context/ModalContext';
import { useState } from 'react';
import { getProfileLanguage } from 'translations/getProfilelanguage';
import { translate } from 'translations/translate';
import './DeactivateConfirmation.scss';

interface DeactivateConfirmationProps {
    onChangeFacilityActivation: (isActive: boolean, nofify: boolean) => Promise<void>,
}

export const DeactivateConfirmation = ({
    onChangeFacilityActivation,
}: DeactivateConfirmationProps) => {
    const [inputText, setInputText] = useState<string>('');
    const { setModalContentProps, setModalContent } = useModalContext();
    const profileLanguage = getProfileLanguage();

    const deactvationPhrase = profileLanguage === 'english' ? 'DEACTIVATE' : 'DEAKTIVIEREN';

    return (
        <div className="deactivate-confirmation">
            <p className="deactivate-confirmation__title">
                {translate('club_settings-facility_status-deactivation_dialog-header')}
            </p>

            <p className="deactivate-confirmation__text">
                {translate('club_settings-facility_status-deactivation_dialog-subheader')}
                <ul>
                    <li>{translate('club_settings-facility_status-deactivation_dialog-subheader_point-one')}</li>
                    <li>{translate('club_settings-facility_status-deactivation_dialog-subheader_point-two')}</li>
                    <li>{translate('club_settings-facility_status-deactivation_dialog-subheader_point-three')}</li>
                    <li>{translate('club_settings-facility_status-deactivation_dialog-subheader_point-four')}</li>
                </ul>
            </p>

            <p className="deactivate-confirmation__text deactivate-confirmation__text--alert">
                {translate('club_settings-facility_status-deactivation_dialog-subheader_confirmation')}
            </p>

            <div className="deactivate-confirmation__buttons">
                <Input
                    className="deactivate-confirmation__deactivate-input"
                    type="text"
                    onChange={e => setInputText(e.target.value)}
                    value={inputText}
                />

                <Button
                    type="primary"
                    className="deactivate-confirmation__button"
                    onClick={async () => {
                        await onChangeFacilityActivation(false, false).then(() => {
                            setModalContentProps({ closable: false });
                            setModalContent(<AlertModalContent
                                type="success"
                                header={translate('club_settings-facility_status-deactivation_dialog-success_message')}
                                goBackText={translate(
                                    'club_settings-facility_status-deactivation_dialog-success_button',
                                )}
                                onGoBack={() => setModalContent(null)}
                            />);
                        }).catch(() => {
                            setModalContentProps({ closable: false });
                            setModalContent(<AlertModalContent
                                type="warning"
                                header={translate('club_settings-facility_status-deactivation_dialog-error_message')}
                                goBackText={translate('club_settings-facility_status-deactivation_dialog-error_button')}
                                onGoBack={() => setModalContent(null)}
                            />);
                        });
                    }}
                    disabled={inputText !== deactvationPhrase}
                >
                    <p className="">{translate('club_settings-facility_status-deactivation_dialog-confirm_button')}</p>
                </Button>

                <Button
                    type="default"
                    className="deactivate-confirmation__button"
                    onClick={() => setModalContent(null)}
                >
                    <p className="">{translate('club_settings-facility_status-deactivation_dialog-back_button')}</p>
                </Button>

            </div>
        </div>
    );
};
