import { USERS } from 'config/endpoints';
import { getRoleName } from 'helpers/getRoleName';
import { getUserFromStorage } from 'services/functionalStorage';
import { getJoinFacilityDataLayer } from 'services/gtm/gtmDatalayers';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { postWrapper } from 'services/httpService/httpServiceWrapper';
import { JoinFacilityRequest } from 'types';

export const joinFacilty = async (joinFacilityRequest: JoinFacilityRequest): Promise<string> => {
    const url = USERS.JOIN_FACILITY;

    const data = { ...joinFacilityRequest };

    const response = await postWrapper(url, data);

    const user = getUserFromStorage();

    pushGtmDatalayer(getJoinFacilityDataLayer(
        user.id,
        joinFacilityRequest.clubId,
        getRoleName(joinFacilityRequest.role),
    ));

    return response.data.message;
};
