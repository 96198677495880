import {
    Button, Card, Form, Input,
} from 'antd';
import { useAppContext } from 'context/AppContext';
import React from 'react';
import { IInvoiceData } from 'types';
import { translate } from 'translations/translate';
import './ClubSettlementInfoCard.scss';

interface IProps {
    onFinish: (settelment: any) => void,
    initialValues: IInvoiceData | null
}

const defaultInitialValues = {
    bankName: '',
    iban: '',
    bic: '',
    taxNumber: '',
    creditorId: '',
} as IInvoiceData;

export const ClubSettlementInfoCard = ({ onFinish, initialValues }: IProps) => {
    const { isSpinnerVisible } = useAppContext();

    const intialFormValues = initialValues || defaultInitialValues;

    return (
        <Card className="club-invoice-card">
            <h1 className="club-invoice-card__title">{translate('club-invoice-card_title')}</h1>

            <Form
                layout="vertical"
                name="basic"
                initialValues={intialFormValues}
                onFinish={onFinish}
            >
                <Form.Item
                    label={(
                        <label className="club-invoice-card__form-label">
                            {translate('club-invoice-card_bank')}
                        </label>
                    )}
                    name="bankName"
                    rules={[{ required: true, message: translate('club-invoice-card_bank-is-required') }]}
                >
                    <Input className="club-invoice-card__form-item" />
                </Form.Item>

                <Form.Item
                    label={(
                        <label className="club-invoice-card__form-label">
                            {translate('club-invoice-card_iban')}
                        </label>
                    )}
                    name="iban"
                    rules={[{ required: true, message: translate('club-invoice-card_iban-is-required') }]}
                >
                    <Input className="club-invoice-card__form-item" />
                </Form.Item>

                <Form.Item
                    label={(
                        <label className="club-invoice-card__form-label">
                            {translate('club-invoice-card_bic')}
                        </label>
                    )}
                    name="bic"
                    rules={[{ required: true, message: translate('club-invoice-card_bic-is-required') }]}
                >
                    <Input className="club-invoice-card__form-item" />
                </Form.Item>

                <Form.Item
                    label={(
                        <label className="club-invoice-card__form-label">
                            {translate('club-invoice-card_taxNumber')}
                        </label>
                    )}
                    name="taxNumber"
                    rules={[{ required: true, message: translate('club-invoice-card_taxNumber-is-required') }]}
                >
                    <Input className="club-invoice-card__form-item" />
                </Form.Item>

                <Form.Item
                    label={(
                        <label className="club-invoice-card__form-label">
                            {translate('club-invoice-card_creditorId')}
                        </label>
                    )}
                    name="creditorId"
                    rules={[{ required: true, message: translate('club-invoice-card_creditorId-is-required') }]}
                >
                    <Input className="club-invoice-card__form-item" />
                </Form.Item>

                <Button
                    type="primary"
                    htmlType="submit"
                    className="club-invoice-card__button"
                    disabled={isSpinnerVisible}
                >
                    {translate('club-invoice-card_save-changes')}
                </Button>
            </Form>
        </Card>
    );
};
