import React, { useEffect, useState } from 'react';
import {
    CheckOutlined, CloseOutlined, EditFilled, WarningOutlined,
} from '@ant-design/icons';
import {
    Alert, Button, Card, Form, Input, InputNumber, Spin,
} from 'antd';
import { useAppContext } from 'context/AppContext';
import { translate } from 'translations/translate';
import { getErrorsValues } from 'adapters/getErrorsValues';
import { IReservation } from 'usecases/types/reservations';
import { formatNumberAsLocaleCurrency } from 'adapters/formatNumberAsLocaleCurrency';
import { isLoggedUserAdmin } from 'services/functionalStorage';
import { getDecimalSeparatorFromLocale } from 'components/PricingRuleModalContent/getDecimalSeparatorFromLocale';
import { useMobileMediaQuery } from 'hooks/useMobileMediaQuery';
import { BookingType } from 'usecases/types/enums/booking_type.enum';
import { PaymentTypeEnum } from 'usecases/types/enums/payment_type.enum';
import './ModalBookButton.scss';

interface IProps {
    setErrors: (errors: { [key: string]: string }) => void;
    onBookingProceed: (desiredPrice: number) => Promise<void>
    reservationFormErrors: { [key: string]: string };
    reservation: IReservation;
    guestFeeAmount: number | null;
    paymentTypeSelector: JSX.Element;
}

export const ModalBookButton = ({
    setErrors,
    onBookingProceed,
    reservationFormErrors,
    reservation,
    guestFeeAmount,
    paymentTypeSelector,
}: IProps): JSX.Element => {
    const initialErrorValue = getErrorsValues(reservationFormErrors);
    const [pricing, setPricing] = useState<number | null>(null);
    const [ownErrors, setOwnErrors] = useState<any[]>(initialErrorValue);
    const [priceEditing, setPriceEditing] = useState<boolean>(false);
    const [priceSpinnerVisibility, setPriceSpinnerVisibility] = useState<boolean>(false);
    const { isMobile } = useMobileMediaQuery();
    const [feeForm] = Form.useForm();
    const { antdLocaleObject } = useAppContext();
    const { locale: antdLocale } = antdLocaleObject;
    const isAdmin = isLoggedUserAdmin();

    const refreshPrice = (): void => {
        setPriceSpinnerVisibility(true);
        const { court, startTime, owner } = reservation;
        if (court && startTime && owner) {
            setPricing(reservation.price);
            setPriceSpinnerVisibility(false);
            return;
        }
        setPricing(null);
        setPriceSpinnerVisibility(false);
    };

    useEffect(() => {
        setOwnErrors(getErrorsValues(reservationFormErrors));
    }, [reservationFormErrors]);

    useEffect(refreshPrice, [reservation.price]);

    const modifyPrice = () => {
        setPriceEditing(true);
    };

    const changePrice = (newPrice: number) => {
        if (newPrice !== null) {
            setPricing(newPrice);
        }
        setPriceEditing(false);
    };

    const PriceModifier = React.useCallback(() => (
        <Form onFinish={values => changePrice(values.newPrice)}>
            <Input.Group compact>
                <Form.Item
                    className="modal-book-button__price-modifier-input"
                    initialValue={pricing}
                    name="newPrice"
                >
                    <InputNumber
                        size="small"
                        min={0}
                        decimalSeparator={getDecimalSeparatorFromLocale(antdLocale)}
                    />
                </Form.Item>
                <Button htmlType="submit" size="small" type="primary">
                    <CheckOutlined />
                </Button>
                <Button onClick={() => setPriceEditing(false)} size="small" type="default">
                    <CloseOutlined />
                </Button>
            </Input.Group>
        </Form>
    ), [pricing]);

    const getPriceDisplay = () => {
        if (pricing === null) {
            return <></>;
        }
        if (priceEditing) {
            return <PriceModifier />;
        }
        if (!isAdmin) {
            return formatNumberAsLocaleCurrency(pricing);
        }

        return (
            <>
                {formatNumberAsLocaleCurrency(pricing)}
                {' '}
                <EditFilled role="button" onClick={modifyPrice} />
            </>
        );
    };

    const isBookingCorrectlySetup = (): boolean => {
        if (priceSpinnerVisibility || reservation.price === null) {
            return false;
        }
        if (reservation.price > 0 && !reservation.paymentType) {
            return false;
        }
        if (reservation.bookingType !== BookingType.SINGLE && reservation.paymentType === PaymentTypeEnum.ONLINE) {
            return false;
        }
        return true;
    };

    return (
        <div className="modal-book-button__container">
            <Card
                className="modal-book-button"
            >
                <div className="modal-book-button__pricing-container">
                    <p className="modal-book-button__pricing-total">
                        {translate('calendar-reservation_dialog-book-modal_total')}
                    </p>
                    <div className="modal-book-button__pricing-text">
                        {priceSpinnerVisibility && (
                            <Spin
                                size="default"
                                className="modal-book-button__spinner"
                            />
                        )}

                        {!priceSpinnerVisibility && getPriceDisplay()}
                    </div>
                </div>
                <Form
                    form={feeForm}
                    requiredMark="optional"
                    name="feeForm"
                    id="feeForm"
                    layout="vertical"
                >
                    {Boolean(pricing) && paymentTypeSelector}

                    {Boolean(guestFeeAmount) && (
                        <div className="modal-book-button__fee-text">
                            <p>
                                {translate(
                                    'calendar-reservation_dialog-book-modal_fee-notice',
                                    { feeAmount: formatNumberAsLocaleCurrency(guestFeeAmount!) },
                                )}
                            </p>
                        </div>
                    )}

                    <div>
                        <Button
                            form="feeForm"
                            type="primary"
                            htmlType="submit"
                            className="modal-book-button__button"
                            onClick={(mouseEvent: React.MouseEvent<HTMLElement, MouseEvent>) => {
                                mouseEvent.preventDefault();
                                onBookingProceed(pricing!);
                            }}
                            disabled={!isBookingCorrectlySetup()}
                        >
                            {reservation.price === 0
                                ? translate('calendar-reservation_dialog-book_now_free_booking')
                                : translate('calendar-reservation_dialog-book_now')}
                        </Button>
                        <div
                            className="modal-book-button__image-container"
                        >
                            <img
                                src="./assets/icons/info.png"
                                className="modal-book-button__image"
                                alt="info-icon"
                            />
                            <p className="modal-book-button__text">
                                {translate('calendar-reservation_dialog-email_notification_notice')}
                            </p>
                        </div>
                    </div>
                </Form>
            </Card>

            {ownErrors.map(error => (
                <Alert
                    className="modal-book-button__error-container"
                    message={error}
                    type="error"
                    showIcon
                    icon={<WarningOutlined />}
                    key={error}
                    closable={!!isMobile}
                    onClose={() => setErrors({})}
                />
            ))}
        </div>
    );
};
