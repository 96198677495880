import { translate } from 'translations/translate';
import { ROUTES } from 'routing/routes.enum';
import React from 'react';
import './PricingRuleOverlappingErrorMessage.scss';
import { useQuery } from 'hooks/useQuery';

interface IProps {
    id: string;
    name: string;
    season: string;
}
export const PricingRuleOverlappingErrorMessage = ({ id, name, season }: IProps) => {
    const query = useQuery();

    return (
        <>
            {translate('prices-page_modal_overlapping-error-message1')}
            {' '}
            <a
                target="_blank"
                rel="noreferrer"
                href={`${ROUTES.ADMIN.PRICES}?pricingRule=${id}&season=${season}&${query.toString()}`}
                className="pricing-rule-overlapping-error-message__link"
            >
                {name}
            </a>
            {' '}
            {translate('prices-page_modal_overlapping-error-message2')}
        </>
    );
};
