import { ModalProps } from 'antd';
import React from 'react';
import { GenericModal } from 'components/GenericModal/GenericModal';

interface ModalValueContext {
    setModalContent: Function,
    setModalContentProps: Function,
}

const ModalContext = React.createContext<ModalValueContext | undefined>(undefined);

interface IProps {
    children: React.ReactNode;
}

const ModalProvider = ({ children }: IProps) => {
    const [modalContent, setModalContent] = React.useState<null | JSX.Element>(null);
    const [modalContentProps, setModalContentProps] = React.useState<ModalProps>({});

    // TODO: Fix disabling jsx-no-constructed-context-values
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const value = {
        setModalContent,
        setModalContentProps,
    } as ModalValueContext;

    return (
        <ModalContext.Provider value={value}>
            <GenericModal
                modalContentProps={modalContentProps}
            >
                {modalContent}
            </GenericModal>
            {children}
        </ModalContext.Provider>
    );
};

const useModalContext = () => {
    const context = React.useContext(ModalContext);
    if (context === undefined) {
        throw new Error('useModalContext must be used within a ModalProvider');
    }
    return context;
};

export { ModalProvider, useModalContext };
