import { getTranslationsMap } from './getTranslationsMap';

export const translate = (key: string, wildcards?: {[key:string]: string|number} | null): string => {
    const translationsMap = getTranslationsMap();
    let text = translationsMap[key] || key;

    if (wildcards) {
        Object.keys(wildcards).forEach(wildcardKey => {
            text = text.replace(`__${wildcardKey}__`, (wildcards[wildcardKey]).toString());
        });
    }

    return text;
};
