/**
 * This method is used as a workaround to allow the opening of new tabs in browsers like Safari
 * https://caniuse.com/?search=window.open
 */
export const openNewTabAsynchronously = (promiseToGetLink: Promise<string>): Promise<void> => {
    const windowReference: Window = window.open()!;

    return promiseToGetLink.then(link => {
        windowReference.location.href = link;
    }).catch(error => {
        windowReference.close();
        throw error;
    });
};
