import moment from 'moment/moment';
import { COURTS, PLACEHOLDERS } from 'config/endpoints';
import { putWrapper } from 'services/httpService/httpServiceWrapper';

class UpdateCourt {
    execute(club: any, court: any) {
        const url = COURTS.SINGLE
            .replace(PLACEHOLDERS.CLUB_ID, club.id)
            .replace(PLACEHOLDERS.COURT_ID, court.id);

        const requestData = {
            name: court.name,
            openingTime: moment(court.openingTime, 'HH:mm').format('HH:mmZ'),
            closingTime: moment(court.closingTime, 'HH:mm').format('HH:mmZ'),
            surface: court.surface,
            location: court.location,
        };
        return putWrapper(url, requestData);
    }
}

export default new UpdateCourt();
