import React from 'react';
import classNames from 'classnames';
import { CalendarViewEnum } from 'types';
import { translate } from 'translations/translate';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { getCalendarViewChangedDataLayer } from 'services/gtm/gtmDatalayers';
import './CalendarFilters.scss';

interface IProps {
    filters: JSX.Element;
    selectedView: CalendarViewEnum,
    setSelectedView: (view: CalendarViewEnum) => void
}

export const CalendarFilters = ({ filters, selectedView, setSelectedView }: IProps): JSX.Element => (
    <div className="calendar-filters">
        <div className="calendar-filters__date-court-filters">
            {filters}
        </div>

        <div className="calendar-filters__view-filters">
            <div
                className={classNames({
                    'calendar-filters__filters-title': true,
                    'calendar-filters__filters-title--view-selector': true,
                    'calendar-filters__filters-title--not-selected': selectedView !== CalendarViewEnum.WEEKVIEW,
                })}
                onClick={() => {
                    setSelectedView(CalendarViewEnum.WEEKVIEW);
                    pushGtmDatalayer(getCalendarViewChangedDataLayer(CalendarViewEnum.WEEKVIEW));
                }}
                role="presentation"
            >
                <p
                    className={classNames({
                        'calendar-filters__filters-text-not-selected':
                        selectedView !== CalendarViewEnum.WEEKVIEW,
                    })}
                >
                    {translate('calendar_display-selector-week')}
                </p>
            </div>
            <div
                className={classNames({
                    'calendar-filters__filters-title': true,
                    'calendar-filters__filters-title--view-selector': true,
                    'calendar-filters__filters-title--not-selected': selectedView !== CalendarViewEnum.DAYVIEW,
                })}
                onClick={() => {
                    setSelectedView(CalendarViewEnum.DAYVIEW);
                    pushGtmDatalayer(getCalendarViewChangedDataLayer(CalendarViewEnum.DAYVIEW));
                }}
                role="presentation"
            >
                <p
                    className={classNames({
                        'calendar-filters__filters-text-not-selected': selectedView !== CalendarViewEnum.DAYVIEW,
                    })}
                >
                    {translate('calendar_display-selector-day')}
                </p>
            </div>
        </div>
    </div>
);
