import { IUser, PLAYERROLEADMIN } from 'types';
import { IClub } from 'usecases/types/club';
import { getUserMembership } from './getUserMembership';
import { isUserOwner } from './isUserOwner';

export const isUserAdmin = (user: IUser, facility: IClub): boolean => {
    const { role } = getUserMembership(user, facility)!;

    return role === PLAYERROLEADMIN || isUserOwner(user, facility);
};
