import {
    CollapseSideBarAction, COLLAPSE_SIDEBAR, UncollapseSideBarAction, UNCOLLAPSE_SIDEBAR,
} from 'redux/types';

export const collapseSidebar = (): CollapseSideBarAction => ({
    type: COLLAPSE_SIDEBAR,
});

export const unCollapseSidebar = (): UncollapseSideBarAction => ({
    type: UNCOLLAPSE_SIDEBAR,
});
