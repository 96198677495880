import { CLUBS, PLACEHOLDERS } from 'config/endpoints';
import { getRoleName } from 'helpers/getRoleName';
import { getPlayerActivatedInFacilityDataLayer } from 'services/gtm/gtmDatalayers';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { patchWrapper } from 'services/httpService/httpServiceWrapper';
import { PlayerRole } from 'types';
import { MEMBERSHIP_STATUS } from 'usecases/types/enums/membership_status.enum';

export const changeMemberStatusInClub = (
    userId: string,
    clubId: string,
    status: MEMBERSHIP_STATUS,
    role: PlayerRole,
): Promise<any> => {
    const endpoint = CLUBS.CHANGE_MEMBERSHIP_STATUS
        .replace(PLACEHOLDERS.USER_ID, userId)
        .replace(PLACEHOLDERS.CLUB_ID, clubId);
    const data = patchWrapper(
        endpoint,
        { status },
    );

    pushGtmDatalayer(getPlayerActivatedInFacilityDataLayer(
        userId,
        clubId,
        getRoleName(role),
    ));

    return data;
};
