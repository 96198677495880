import moment from 'moment';
import { CalendarViewEnum } from 'types';
import { ICourt } from 'usecases/types/court';
import { ISeason } from 'usecases/types/season';

export const getBookableCourts = (
    courts: ICourt[],
    bookableSeasons: ISeason[],
    view: CalendarViewEnum,
    bookingDate: string,
): ICourt[] => {
    if (view === CalendarViewEnum.DAYVIEW) {
        return courts.filter(court => bookableSeasons.some(season => {
            const startTime = moment(season.startDateTime).toDate().getTime();
            const endTime = moment(season.endDateTime).toDate().getTime();
            const time = moment(bookingDate).toDate().getTime();
            return (season.courts.includes(court.id) && (time >= startTime && time <= endTime));
        }));
    }
    return courts.filter(court => bookableSeasons.some(season => {
        const seasonStart = moment(season.startDateTime);
        const seasonEnd = moment(season.endDateTime);
        const weekStart = moment(bookingDate);
        const weekEnd = weekStart.clone().add(1, 'week');

        return season.courts.includes(court.id)
            && (weekStart.isBetween(seasonStart, seasonEnd) || weekEnd.isBetween(seasonStart, seasonEnd));
    }));
};
