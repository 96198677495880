import { CHDateTime } from 'helpers/DateTime/CHDateTime';
import { IReservation } from 'usecases/types/reservations';
import { ISeason } from 'usecases/types/season';

export const getSeasonFromBooking = (
    bookableSeasons: ISeason[],
    booking: IReservation,
) => bookableSeasons.find(bookableSeason => {
    const seasonStart = new CHDateTime(bookableSeason.startDateTime);
    const seasonEnd = new CHDateTime(bookableSeason.endDateTime);
    const reservationStart = new CHDateTime(booking.startTime);

    return seasonStart.isBeforeOrEqual(reservationStart) && reservationStart.isBefore(seasonEnd);
});
