import { IMembership } from 'types';
import { IClub } from 'usecases/types/club';

export const filterFacilities = ({ userMemberships, facilities }:
    { userMemberships: IMembership[], facilities: IClub[] }) => facilities.reduce((accumulator, currentFacility) => {
    const isFacilityInUserMemberships = userMemberships
        .some(userMembership => userMembership.clubId === currentFacility.id);

    if (isFacilityInUserMemberships) {
        accumulator.joinedFacilites.push(currentFacility);
    } else {
        accumulator.availableFacilities.push(currentFacility);
    }

    return accumulator;
}, { joinedFacilites: [] as IClub[], availableFacilities: [] as IClub[] });
