import { IClub } from 'usecases/types/club';
import { LanguageEnum } from 'usecases/types/enums/language.enum';
import { GenderEnum } from 'usecases/types/enums/gender.enum';
import { CLUBS } from 'config/endpoints';
import { postWrapper } from 'services/httpService/httpServiceWrapper';

export interface CreateClubRequest {
    email: string,
    name: string,
    surname: string,
    password: string,
    clubName: string,
    gender: GenderEnum,
    language: LanguageEnum
}

class CreateClub {
    execute(request: CreateClubRequest): Promise<IClub> {
        const user = {
            name: request.name,
            surname: request.surname,
            email: request.email,
            password: request.password,
            gender: request.gender,
            language: request.language,
        };

        const data = {
            club: {
                name: request.clubName,
            },
            user,
        };

        const url = CLUBS.MANY;

        return postWrapper(url, data)
            .then(response => response.data.club);
    }
}

export default new CreateClub();
