import { Locale } from 'antd/lib/locale-provider';
import { useAntdLocale } from 'hooks/useAntdLocale';
import { useWindowSize } from 'hooks/useWindowSize';
import * as React from 'react';

interface AppValueContext {
    antdLocaleObject: Locale,
    updateAntdLocaleMapFromProfileLanguage: () => void
    isSpinnerVisible: boolean,
    setIsSpinnerVisible: (value: boolean) => void,
    sidebarCollapsed: boolean,
    setSidebarCollapsed:(value: boolean) => void,
    isWindowResizing: boolean
    setIsWindowResizing: (value: boolean) => void,
    scrollArrowVisibility: boolean
    setScrollArrowVisibility: (value: boolean) => void,
}

const AppContext = React.createContext<AppValueContext | undefined>(undefined);

interface IProps {
    children: React.ReactNode;
}

const AppProvider = ({ children }: IProps) => {
    const { antdLocaleObject, updateAntdLocaleMapFromProfileLanguage } = useAntdLocale();
    const [isSpinnerVisible, setIsSpinnerVisible] = React.useState<boolean>(false);
    const { isWindowResizing, setIsWindowResizing } = useWindowSize();
    const [scrollArrowVisibility, setScrollArrowVisibility] = React.useState<boolean>(false);

    // TODO: Fix disabling jsx-no-constructed-context-values
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const value = {
        antdLocaleObject,
        updateAntdLocaleMapFromProfileLanguage,
        isSpinnerVisible,
        setIsSpinnerVisible,
        isWindowResizing,
        setIsWindowResizing,
        scrollArrowVisibility,
        setScrollArrowVisibility,
    } as AppValueContext;
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const useAppContext = () => {
    const context = React.useContext(AppContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within a AppProvider');
    }
    return context;
};

export { AppProvider, useAppContext };
