/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { getProfileLanguage } from 'translations/getProfilelanguage';
import './InvoicesExplanation.scss';

export const InvoicesExplanation = () => {
    const profileLanguage = getProfileLanguage();
    const germanExplanation = () => (
        <>
            <h1 className="invoices-explanation__title">Rechnungen</h1>
            <div className="invoices-explanation__text-container">
                <p className="invoices-explanation__text">
                    Die Funktion „Rechnungen“ enthält alle Rechnungen, die durch dich unter dem Menüpunkt
                    „Abrechnung“ erstellt worden sind.
                </p>

                <p className="invoices-explanation__text">
                    Die Liste kannst Du nach dem Zeitraum, der Rolle des Spielers und dem Status der Rechnung filtern.
                </p>

                <p className="invoices-explanation__text">
                    Zudem kannst du in der angezeigten Liste auch nach der Rechnungsnummer oder nach einzelnen Spielern
                    suchen. Die Liste wird nur auf diese Rechnungsnummer bzw. diesen Spieler reduziert, falls die
                    Rechnungsnummer bzw. der Spieler in der Liste enthalten sein sollte.
                </p>

                <p className="invoices-explanation__text">
                    Des Weiteren kannst du die Liste nach den Spalten Rechnungs-Nr., Vorname, Nachname, Rechnungsdatum
                    und Gesamtbetrag auf- und absteigend sortieren.
                </p>

                <p className="invoices-explanation__text">
                    Auf Basis deiner Filter stehen dir für die Liste verschiedene Funktionen zur Verfügung:
                </p>

                <p className="invoices-explanation__text">
                    1) Stornierung einer oder mehrerer Rechnungen/en
                </p>

                <p className="invoices-explanation__text">
                    2) Anzeige bzw. Herunterladen einer Rechnung
                </p>

                <p className="invoices-explanation__text">
                    3) Rechnungen als bezahlt oder unbezahlt markieren
                </p>

                <p className="invoices-explanation__text">
                    Wenn du eine einzelne Rechnung stornieren möchtest, dann klickst du auf den grünen Button
                    „Stornieren“ am Ende der Zeile der Rechnung. Wenn der Button grau ist, dann wurde die Rechnung
                    bereits storniert. Wenn du mehrere Rechnungen auf einmal stornieren möchtest, dann wählst du die
                    entsprechenden Rechnungen per Checkbox aus und klickst du auf den grünen Button
                    „Auswahl stornieren“.
                </p>

                <p className="invoices-explanation__text">
                    Wenn du eine einzelne Rechnung ansehen oder herunterladen möchtest, dann klickst du auf das
                    „Auge“ Icon. Dann kannst du entscheiden, ob du die Rechnung herunterladen möchtest.
                </p>

                <p className="invoices-explanation__text">
                    Wenn du Rechnungen als bezahlt oder unbezahlt markieren möchtest, dann klickst du auf den
                    grünen Button „als bezahlt markieren“ bzw. „als unbezahlt markieren“ am Ende der Zeile der
                    Rechnung. Auf Basis dieser Markierung ändert sich der Status zu bezahlt oder unbezahlt.
                </p>

                <p className="invoices-explanation__text">
                    <b>Benötigst du weitere Hilfe? </b>
                    Dann schreibe eine E-Mail an
                    {' '}
                    <a
                        className="seasons-explanation__link"
                        href="mailto:courthero@tennis-point.de"
                    >
                        courthero@tennis-point.de
                    </a>
                </p>
            </div>
        </>
    );
    const englishExplanation = () => (
        <>
            <h1 className="invoices-explanation__title">Invoices</h1>
            <div className="invoices-explanation__text-container">
                <p className="invoices-explanation__text">
                    The function "Invoices" contains all invoices that have been created by you
                    under the menu item "Settlement".
                </p>

                <p className="invoices-explanation__text">
                    You can filter the list according to the period, the role of the player and
                    the status of the invoice.
                </p>

                <p className="invoices-explanation__text">
                    You can also search for the invoice number or for individual players in the list displayed.
                    The list will only show the invoice number or player if the invoice number or player is
                    included in the list.
                </p>

                <p className="invoices-explanation__text">
                    Furthermore, you can sort the list by the columns invoice number, name, last name, invoice
                    date and total amount in ascending and descending order.
                </p>

                <p className="invoices-explanation__text">
                    Based on your filters, various functions are available to you for the list:
                </p>

                <p className="invoices-explanation__text">
                    1) Cancellation of one or more invoices
                </p>

                <p className="invoices-explanation__text">
                    2) Display respectively download of an invoice
                </p>

                <p className="invoices-explanation__text">
                    3) Mark invoices as paid or unpaid
                </p>

                <p className="invoices-explanation__text">
                    If you want to cancel a single invoice, click on the green "Cancel" button at the end
                    of the invoice line. If the button is grey, the invoice has already been cancelled.
                    If you want to cancel several invoices at once, select the corresponding invoices by
                    checkbox and click on the green button "Cancel selection".
                </p>

                <p className="invoices-explanation__text">
                    If you want to view or download a single invoice, click on the "eye" icon. Then you can decide
                    whether you want to download the invoice.
                </p>

                <p className="invoices-explanation__text">
                    If you want to mark invoices as paid or unpaid, click on the green button "mark as paid" or "mark as
                    unpaid" at the end of the line of the invoice. Based on this marking, the status changes
                    to paid or unpaid.
                </p>

                <p className="invoices-explanation__text">
                    <b>Need more help? </b>
                    Then send an e-mail to
                    {' '}
                    <a
                        className="seasons-explanation__link"
                        href="mailto:courthero@tennis-point.de"
                    >
                        courthero@tennis-point.de
                    </a>
                </p>
            </div>
        </>
    );
    return (
        <div className="invoices-explanation">
            {profileLanguage === 'english'
                ? englishExplanation()
                : germanExplanation()}
        </div>

    );
};
