import React from 'react';
import Icon from '@ant-design/icons';
import moment from 'moment';
import {
    AutoComplete, DatePicker, Input, Select,
} from 'antd';
import { translate } from 'translations/translate';
import {
    IAutocompleteOption, PlayerRole, PLAYERROLECLIENT, PLAYERROLEMEMBER,
} from 'types';
import { CALENDAR_ICON_GRAY } from 'config/constants';
import { dateFormatter } from 'adapters/dateFormatter';
import { useAppContext } from 'context/AppContext';
import './SettlementFilters.scss';

interface IProps {
    startDateFilterValue: moment.Moment;
    endDateFilterValue: moment.Moment;
    initialEndDateFilterValue: moment.Moment;
    nameFilterValue: string;
    nameFilterOptions: IAutocompleteOption[];
    onStartDateFilterChange: (value: moment.Moment | null) => void;
    onEndDateFilterChange: (value: moment.Moment | null) => void;
    onNameFilterChange: (value: string) => void;
    onRoleFilterChange: (value: PlayerRole | '') => void
}

export const SettlementFilters = ({
    startDateFilterValue,
    endDateFilterValue,
    initialEndDateFilterValue,
    nameFilterValue,
    nameFilterOptions,
    onStartDateFilterChange,
    onEndDateFilterChange,
    onNameFilterChange,
    onRoleFilterChange,
}: IProps): JSX.Element => {
    const { antdLocaleObject: { locale: antdLocale } } = useAppContext();

    const MemoizedIcon = React.useCallback(() => (
        <img
            className="season-modal__calendar-icon"
            src={CALENDAR_ICON_GRAY}
            alt="close_icon_white"
        />
    ), []);

    return (
        <div className="settlement-filters">
            <div className="settlement-filters__filter">
                <DatePicker
                    value={startDateFilterValue}
                    inputReadOnly
                    bordered={false}
                    placeholder={translate('settlement-page_filter-start')}
                    format={date => dateFormatter(date.toDate(), antdLocale)}
                    onChange={onStartDateFilterChange}
                    className="settlement-filters__picker-input"
                    suffixIcon={<Icon component={MemoizedIcon} />}
                    allowClear={false}
                    disabledDate={value => (
                        value.valueOf() > endDateFilterValue.valueOf()
                        || value.valueOf() >= initialEndDateFilterValue.valueOf()
                    )}
                />
            </div>
            <div className="settlement-filters__filter">
                <DatePicker
                    value={endDateFilterValue}
                    inputReadOnly
                    bordered={false}
                    placeholder={translate('settlement-page_filter-end')}
                    format={date => dateFormatter(date.toDate(), antdLocale)}
                    onChange={onEndDateFilterChange}
                    className="settlement-filters__picker-input"
                    suffixIcon={<Icon component={MemoizedIcon} />}
                    allowClear={false}
                    disabledDate={value => (
                        value.valueOf() > initialEndDateFilterValue.valueOf()
                        || startDateFilterValue.valueOf() > value.valueOf()
                    )}
                />
            </div>
            <AutoComplete
                className="settlement-filters__filter"
                value={nameFilterValue}
                options={nameFilterOptions}
                filterOption={(inputValue: string, option: any): any => option.value
                    .toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                onChange={onNameFilterChange}
            >
                <Input
                    placeholder={translate('settlement-page_filter-name')}
                    bordered={false}
                />
            </AutoComplete>
            <Select
                size="large"
                className="settlement-filters__filter"
                placeholder={translate('settlement-page_filter-role')}
                onChange={onRoleFilterChange}
            >
                <Select.Option value={PLAYERROLEMEMBER}>{translate(`role_${PLAYERROLEMEMBER}`)}</Select.Option>
                <Select.Option value={PLAYERROLECLIENT}>{translate(`role_${PLAYERROLECLIENT}`)}</Select.Option>
                <Select.Option value="">{translate('role_all')}</Select.Option>
            </Select>
        </div>
    );
};
