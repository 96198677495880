import TagManager, { DataLayerArgs } from 'react-gtm-module';

type CustomDimensionDataLayer = {[key: string] : null}

export const pushGtmDatalayer = (gtmDatalayer?: DataLayerArgs['dataLayer']) => {
    if (gtmDatalayer) {
        TagManager.dataLayer({
            dataLayer: { ...gtmDatalayer, uniqueCHId: Date.now() },
            _clear: true,
        }as any);

        const baseParameters = ['event', 'eventCategory', 'eventAction', 'eventLabel', 'eventValue'];
        const customDimensions = Object.keys(gtmDatalayer).filter(key => !baseParameters.includes(key));

        const dimensionsDataLayer: CustomDimensionDataLayer = {};
        customDimensions.forEach(key => {
            dimensionsDataLayer[key] = null;
        });

        if (customDimensions.length > 0) {
            TagManager.dataLayer({
                dataLayer: { ...dimensionsDataLayer },
            }as any);
        }
    }
};
