import { getClubFromStorage, setClubInStorage } from 'services/functionalStorage';
import { CLUBS, PLACEHOLDERS } from 'config/endpoints';
import { deleteWrapper } from 'services/httpService/httpServiceWrapper';

const updateLogoInLocalStorage = () => {
    const currentClub = getClubFromStorage();
    const modifiedClub = { ...currentClub, logo: null as any };
    setClubInStorage(modifiedClub);
};

export const deleteLogo = async (clubId: string): Promise<string> => {
    const url = CLUBS.CHANGE_LOGO.replace(PLACEHOLDERS.CLUB_ID, clubId);
    const response = await deleteWrapper(url);
    updateLogoInLocalStorage();
    return response;
};
