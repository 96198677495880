import React from 'react';
import Icon from '@ant-design/icons';
import { translate } from 'translations/translate';
import { ACCOUNT_ICON_DARK_GRAY } from 'config/constants';
import { ProfilePicture } from 'pages/common/ProfilePicture/ProfilePicture';
import { PasswordForm } from 'pages/common/PasswordForm/PasswordForm';
import { ProfileCardComponent } from 'pages/common/ProfileCardComponent/ProfileCardComponent';
import { getUserFromStorage } from 'services/functionalStorage';
import { updateUserAddress } from 'usecases/users/updateUserAddress';
import { notification } from 'antd';
import { useAppContext } from 'context/AppContext';
import { AddressCard } from 'pages/common/AddressCard/AddressCard';
import { Address } from 'types';
import { useModalContext } from 'context/ModalContext';
import { AlertModalContent } from 'components/AlertModalContent/AlertModalContent';
import './Profile.scss';

export const Profile = (): JSX.Element => {
    const initialValue = getUserFromStorage().address;
    const { setIsSpinnerVisible } = useAppContext();
    const { setModalContentProps, setModalContent } = useModalContext();

    const showSuccessMessage = (headerTranslation: string = translate('user-profile-updated')) => {
        setModalContentProps({ closable: false });
        setModalContent(
            <AlertModalContent
                type="success"
                goBackText={translate('go-back')}
                header={headerTranslation}
                onGoBack={() => setModalContent(null)}
            />,
        );
    };
    const onAddressChange = async (newAddress: Address) => {
        setIsSpinnerVisible(true);

        try {
            await updateUserAddress({
                address: newAddress,
            });
            showSuccessMessage();
        } catch (error: any) {
            notification.error({
                message: translate('error'),
                description: error.message,
            });
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    const MemoizedIcon = React.useCallback(() => (
        <img
            className="profile__logo"
            src={ACCOUNT_ICON_DARK_GRAY}
            alt="account_icon"
        />
    ), []);

    return (
        <>
            <h1 className="profile__section-title">
                <Icon component={MemoizedIcon} />
                {' '}
                {translate('account-settings')}
            </h1>
            <div className="profile__container">
                <ProfilePicture onSuccessfulChange={showSuccessMessage} />
                <ProfileCardComponent onSuccessfulChange={showSuccessMessage} />
                <AddressCard initialValue={initialValue} onFinish={onAddressChange} />
                <PasswordForm onSuccessfulChange={
                    () => showSuccessMessage(translate('profile-password-card_password-changed-message'))
                }
                />
            </div>
        </>
    );
};
