import React from 'react';
import { ROUTES } from 'routing/routes.enum';
import {
    ACCOUNT_ICON,
    ACCOUNT_ICON_WHITE,
    MONEY_ICON,
    MONEY_ICON_WHITE,
    DASHBOARD_ICON_WHITE,
    DASHBOARD_ICON_GRAY,
} from 'config/constants';
import { ILink } from 'types';
import { translate } from 'translations/translate';
import { ConnectedLayoutMenu } from 'pages/common/Layout/LayoutMenu';
import { logoutUser } from 'services/logoutUser';
import {
    BOOK_KEEPING_GUEST_FEES_SIDEBAR_DATALAYER,
    CALENDAR_SIDEBAR_DATALAYER,
    IMPRINT_SIDEBAR_DATALAYER,
    PROFILE_LOGOUT_SIDEBAR_DATALAYER,
    PROFILE_SETTINGS_SIDEBAR_DATALAYER,
} from 'services/gtm/gtmDatalayers';
import { getMyFacilitySubmenu } from 'pages/user/Layout/getMyFacilitySubmenu';

export const AdminLayoutMenu = () => {
    const bookKeepingSubmenu = {
        icons: [MONEY_ICON, MONEY_ICON_WHITE],
        text: translate('admin_menu-book_keeping'),
        routes: [
            {
                to: ROUTES.ADMIN.SETTLEMENT,
                text: translate('admin_menu-settlement'),
            },
            {
                to: ROUTES.ADMIN.INVOICES,
                text: translate('admin_menu-invoices'),
            },
            {
                to: ROUTES.ADMIN.GUEST_FEES,
                text: translate('admin_menu-guest_fees'),
                datalayer: BOOK_KEEPING_GUEST_FEES_SIDEBAR_DATALAYER,
            },
        ],
    };

    const accountMenu = {
        icons: [ACCOUNT_ICON, ACCOUNT_ICON_WHITE],
        text: translate('menu-profile'),
        routes: [
            {
                to: ROUTES.MULTICLUB.MY_FACILITIES,
                text: translate('menu-facilities'),
            },
            {
                to: ROUTES.ADMIN.PROFILE_SETTINGS,
                text: translate('menu-settings'),
                datalayer: PROFILE_SETTINGS_SIDEBAR_DATALAYER,
            },
            {
                run: logoutUser,
                text: translate('menu-logout'),
                datalayer: PROFILE_LOGOUT_SIDEBAR_DATALAYER,
            },

        ],
        bottom: true,
    };

    const links: ILink[] = [
        {
            to: ROUTES.ADMIN.DASHBOARD,
            icons: [DASHBOARD_ICON_GRAY, DASHBOARD_ICON_WHITE],
            text: translate('admin_menu-calendar'),
            datalayer: CALENDAR_SIDEBAR_DATALAYER,
        },
        getMyFacilitySubmenu(),
        bookKeepingSubmenu,
        accountMenu,
        {
            to: ROUTES.IMPRINT,
            icon: <></>,
            text: translate('imprint'),
            bottom: true,
            targetBlank: true,
            datalayer: IMPRINT_SIDEBAR_DATALAYER,
        },
    ];

    return (
        <ConnectedLayoutMenu
            links={links}
        />
    );
};
