import { MEMBERSHIP_STATUS } from 'usecases/types/enums/membership_status.enum';

export const calculateStatusClassName = (status:MEMBERSHIP_STATUS): string => {
    const membershipClassMap = {
        [MEMBERSHIP_STATUS.ACTIVE]: 'user-active',
        [MEMBERSHIP_STATUS.INACTIVE]: 'user-inactive',
        [MEMBERSHIP_STATUS.PENDING]: 'user-pending',
    };

    return membershipClassMap[status];
};
