/* eslint-disable react/no-unescaped-entities */
import { getProfileLanguage } from 'translations/getProfilelanguage';
import React from 'react';
import './ClubMembersExplanation.scss';

export const ClubMembersExplanation = () => {
    const profileLanguage = getProfileLanguage();
    const germanExplanation = () => (
        <>
            <h1 className="club-members-explanation__title">Spieler</h1>
            <div className="club-members-explanation__text-container">
                <p className="club-members-explanation__text">
                    Der Menüpunkt „Spieler“ ermöglicht es dir deine Spieler zu verwalten.
                </p>
                <p className="club-members-explanation__text">
                    Folgenden Funktionen stehen dir dabei zur Verfügung:
                </p>
                <p className="club-members-explanation__text">1) Neuen Spieler erstellen</p>
                <p className="club-members-explanation__text">2) Spieler bearbeiten (Stift-Symbol)</p>
                <p className="club-members-explanation__text">3) Spieler aktivieren/deaktivieren</p>
                <p className="club-members-explanation__text">4) Spieler importieren</p>
                <p className="club-members-explanation__text">
                    Du kannst neue Spieler erstellen, indem Du auf den Button „Neuer Spieler“ klickst.
                    Wenn du z.B. Accounts für Kinder und Jugendliche erstellen möchtest, die noch keine E-Mail
                    Adresse besitzen, dann markiere bitte die Checkbox mit dem Text „Keine E-Mail Adresse“.
                </p>
                <p className="club-members-explanation__text">
                    Für bereits registrierte oder erstelle Spieler kannst du nur die Rolle ändern,
                    welche Sie in deiner Anlage haben. Die persönlichen Daten können nur vom Spieler
                    selbst geändert werden, da diese für alle Anlagen verwendet werden, für welche er
                    sich registriert hat. Bitte klicke dazu auf das Stift-Symbol am Ende der Zeile eines Spielers.
                </p>
                <p className="club-members-explanation__text">
                    Die Funktion "Spieler importieren" ist für Dich als Admin sehr hilfreich und spart viel Zeit,
                    wenn du mehr als 1 Spieler (aktuell max. 10 Spieler pro Datei und Import) auf einmal in dem
                    System anlegen möchtest.
                </p>
                <p className="club-members-explanation__text">
                    Bitte beachte unbedingt, dass du bereits in Court Hero gelöschte Spieler nicht nochmal in Court Hero
                    hochlädst und das eine Überprüfung auf gelöschte Spieler während des Importvorgangs nicht
                    stattfindet.
                </p>

                <p className="club-members-explanation__text">
                    Bitte beachte zudem, dass Spieler, deren E-Mail bereits in Court Hero vorhanden ist,
                    nicht importiert werden.
                </p>

                <p className="club-members-explanation__text">
                    Um die gewünschten Spieler in das System zu importieren, gehst du am besten wie folgt vor:
                </p>

                <p className="club-members-explanation__text">
                    1) Du klickst auf den grünen Button "Spieler Import" rechts oberhalb der Spielerliste.
                </p>
                <p className="club-members-explanation__text">
                    2) Es öffnet sich ein neues Fenster mit der Überschrift „SPIELER IMPORT“.
                    Dort kannst du dir die CSV Beispieldatei herunterladen. Diese Beispieldatei enthält alle Spalten,
                    die für einen erfolgreichen Import ausgefüllt werden müssen. Anbei eine Auflistung der Spalten:
                </p>
                <p className="club-members-explanation__text">- Vorname</p>
                <p className="club-members-explanation__text">- Nachname</p>
                <p className="club-members-explanation__text">
                    - Geschlecht (erlaubter Text: Männlich, Weiblich, Divers)
                </p>
                <p className="club-members-explanation__text">- Sprache (erlaubter Text: Deutsch, English)</p>
                <p className="club-members-explanation__text">- Straße</p>
                <p className="club-members-explanation__text">- Hausnummer</p>
                <p className="club-members-explanation__text">- PLZ</p>
                <p className="club-members-explanation__text">- Ort</p>
                <p className="club-members-explanation__text">
                    - Land (erlaubter Text: Deutschland, Frankreich,
                    Großbritannien, Italien, Niederlande, Österreich, Schweiz, Spanien)
                </p>
                <p className="club-members-explanation__text">- E-Mail (gültiges E-Mail-Format)</p>
                <p className="club-members-explanation__text">- Rolle (erlaubter Text: Mitglied, Gast)</p>
                <p className="club-members-explanation__text">
                    3) Bevor du deine CSV Datei importieren kannst, musst du durch Anklicken der Checkbox bestätigen,
                    dass du berechtigt bist und die Erlaubnis der Spieler hast, die Daten zu verarbeiten.
                </p>
                <p className="club-members-explanation__text">
                    4) Nachdem du die CSV Datei ausgefüllt hast, kannst du diese nun im in unser System hochladen.
                    Dazu klickst du auf „CSV Datei auswählen“ und wählst deine gewünschte Datei aus.
                    Alternativ kannst du die Datei per Drag and Drop dorthin verschieben.
                </p>
                <p className="club-members-explanation__text">
                    5) Dann klickst du abschließend auf den grünen Button „Import CSV Datei“.
                </p>
                <p className="club-members-explanation__text">
                    6) Sobald der Import der Datei abgeschlossen ist, werden dir unter der Überschrift „IMPORT STATUS“
                    die Ergebnisse deines Imports angezeigt.
                </p>
                <p className="club-members-explanation__text">
                    - Dort wird angezeigt, wie viele Spieler bzw. Datensätze hochgeladen werden konnten.
                </p>
                <p className="club-members-explanation__text">
                    - Ebenso werden die Spieler bzw. E-Mail-Adressen angezeigt, die nicht importiert werden konnten.
                    Der Fehler wird hierbei auch angegeben.
                </p>
                <p className="club-members-explanation__text">
                    7) Sobald alle Spieler importiert worden sind, wird die E-Mail zum Zurücksetzen des zufällig
                    generierten Passworts automatisch an alle importierten Spieler gesendet.
                </p>
                <p className="club-members-explanation__text">
                    8) Die Spieler können dann selbst ein Passwort vergeben und sich damit im Buchungssystems Deines
                    Vereins anmelden und mit der Buchung von Plätzen beginnen.
                </p>
                <p className="club-members-explanation__text">
                    <b>Benötigst du weitere Hilfe? </b>
                    Dann schreibe eine E-Mail an
                    {' '}
                    <a
                        className="seasons-explanation__link"
                        href="mailto:courthero@tennis-point.de"
                    >
                        courthero@tennis-point.de
                    </a>
                </p>
            </div>
        </>
    );
    const englishExplanation = () => (
        <>
            <h1 className="club-members-explanation__title">Players</h1>
            <div className="club-members-explanation__text-container">
                <p className="club-members-explanation__text">
                    The "Players" menu item allows you to manage your players.
                </p>
                <p className="club-members-explanation__text">
                    The following functions are available:
                </p>
                <p className="club-members-explanation__text">1) Create new player</p>
                <p className="club-members-explanation__text">2) Edit player (pencil icon)</p>
                <p className="club-members-explanation__text">3) Activate/deactivate player</p>
                <p className="club-members-explanation__text">4) Import players</p>
                <p className="club-members-explanation__text">
                    You can create new players by clicking on the button "New player".
                    If, for example, you want to create accounts for children and young people who do not yet have
                    an email address, please mark the checkbox with the text "No email address".
                </p>
                <p className="club-members-explanation__text">
                    For already registered or created players, you can only change the role they have in your facility.
                    The personal data can only be changed by the player himself, as this data is used for all facilities
                    for which he has registered. Please click on the pencil icon at the end of a player's row.
                </p>
                <p className="club-members-explanation__text">
                    The "Import players" function is very helpful for you as admin and saves a lot of time if you want
                    to create more than 1 player (max. 10 players per file and import) in the system at once.
                </p>
                <p className="club-members-explanation__text">
                    Please make sure that you do not re-upload players that have already been deleted in Court Hero and
                    that a check for deleted players does not take place during the import process.
                </p>

                <p className="club-members-explanation__text">
                    Please also note that players whose email already exists in Court Hero will not be imported.
                </p>

                <p className="club-members-explanation__text">
                    To import the desired players into the system, proceed as follows:
                </p>

                <p className="club-members-explanation__text">
                    1) Click on the green button "Import players" on the right above the player list.
                </p>
                <p className="club-members-explanation__text">
                    2) A new window opens with the heading "PLAYERS IMPORT". There you can download the CSV sample file.
                    This sample file contains all the columns that need to be filled for a player import in our system.
                    Below is a list of the columns:
                </p>
                <p className="club-members-explanation__text">- First name</p>
                <p className="club-members-explanation__text">- Surname</p>
                <p className="club-members-explanation__text">
                    - Gender (allowed text: Male, Female, Other)
                </p>
                <p className="club-members-explanation__text">
                    - Language (allowed text: English, Deutsch)
                </p>
                <p className="club-members-explanation__text">- Street</p>
                <p className="club-members-explanation__text">- Number</p>
                <p className="club-members-explanation__text">- ZIP Code</p>
                <p className="club-members-explanation__text">- Location</p>
                <p className="club-members-explanation__text">
                    - Country (Germany, France, UK, Italy, Netherlands,
                    Austria, Switzerland, Spain)

                </p>
                <p className="club-members-explanation__text">- E-mail (valid e-mail format)</p>
                <p className="club-members-explanation__text">- Role (allowed text: Member, Client)</p>
                <p className="club-members-explanation__text">
                    3) Before you can import your CSV file, you must confirm that you are authorized and
                    have the permission of the players to process the data by clicking the checkbox.
                </p>
                <p className="club-members-explanation__text">
                    4) After you have filled in the CSV file, you can upload it into our system.
                    To do this, click on "Select CSV file" and select your desired file.
                    Alternatively you can drag and drop the file there.
                </p>
                <p className="club-members-explanation__text">
                    5) Then click on the green button "Import CSV file".
                </p>
                <p className="club-members-explanation__text">
                    6) As soon as the import of the file is finished, you will see the results of
                    your import under the heading "IMPORT STATUS".
                </p>
                <p className="club-members-explanation__text">
                    - There you can see how many players or records could be uploaded.
                </p>
                <p className="club-members-explanation__text">
                    - The players or e-mail addresses that could not be imported are also displayed.
                    The error is also indicated here..
                </p>
                <p className="club-members-explanation__text">
                    7) Once all players have been imported, the email to reset the randomly generated
                    password is automatically sent to all imported players.
                </p>
                <p className="club-members-explanation__text">
                    8) The players can then assign a password themselves and use it to log in to your
                    club's booking system and start booking places.
                </p>
                <p className="club-members-explanation__text">
                    <b>Need more help? </b>
                    Then send an e-mail to
                    {' '}
                    <a
                        className="club-members-explanation__link"
                        href="mailto:courthero@tennis-point.de"
                    >
                        courthero@tennis-point.de
                    </a>
                </p>
            </div>
        </>
    );
    return (
        <div className="club-members-explanation">
            {profileLanguage === 'english'
                ? englishExplanation()
                : germanExplanation()}
        </div>

    );
};
