import { sortItemsBy } from 'adapters/sortItemsBy';
import moment from 'moment';
import { ICourt } from 'usecases/types/court';

export const getCourtsAdapter = (courts: ICourt[]) => {
    const orderedCourts = sortItemsBy('name', courts);

    return orderedCourts.map(court => {
        const openingTime = moment(court.openingTime, 'HH:mmZ');
        const closingTime = moment(court.closingTime, 'HH:mmZ');

        return {
            ...court,
            openingTime: openingTime.local().format('HH:mm'),
            closingTime: closingTime.local().format('HH:mm'),
        };
    });
};
