// TODO: This method should be remove in favor of the method formatDateToLocale from the CHDateTime value-object
export const dateFormatter = (date: Date, locale: string): string => {
    const formatter = new Intl.DateTimeFormat(
        locale,
        {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        },
    );
    return formatter.format(date);
};
