import { login } from 'usecases/auth/login';
import { getUserInformationAndSetInStorage } from 'usecases/users/getUserInformationAndSetInStorage';

export const loginAndStorageUserInfo = async (email: string, password: string, rememberMe: boolean) => {
    await login(email, password, rememberMe);

    const loggedUser = await getUserInformationAndSetInStorage();

    return loggedUser;
};
