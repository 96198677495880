import { CLUBS, PLACEHOLDERS } from 'config/endpoints';
import { getUserFromStorage } from 'services/functionalStorage';
import { getEditSeasonDataLayer } from 'services/gtm/gtmDatalayers';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { putWrapper } from 'services/httpService/httpServiceWrapper';
import { CreateSeasonInput } from 'usecases/types/dto/CreateSeasonInput';
import { ISeason } from 'usecases/types/season';

export const editSeason = async ({
    clubId, seasonId, name, startDate, endDate, courts, notify, active,
}: CreateSeasonInput): Promise<ISeason> => {
    const url = CLUBS.SEASON_EDIT
        .replace(PLACEHOLDERS.CLUB_ID, clubId)
        .replace(PLACEHOLDERS.SEASON_ID, seasonId!);

    const requestData = {
        name,
        startDate,
        endDate,
        courts,
        notify,
        active,
    };

    const { data } = await putWrapper(url, requestData);

    const user = getUserFromStorage();

    pushGtmDatalayer(getEditSeasonDataLayer(
        user.id,
        clubId,
        courts,
        seasonId!,
        active,
    ));

    return data.season;
};
