import React from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Card, Form, Input, notification,
} from 'antd';
import { translate } from 'translations/translate';
import {
    DesktopBlurredBackground,
} from 'components/Reservations/DesktopBlurredBackground/DesktopBlurredBackground';
import { passwordRecover } from 'usecases/users/PasswordRecover';
import { WhiteCardHeader } from 'pages/common/WhiteCardHeader/WhiteCardHeader';
import 'pages/common/WhiteCard/Whitecard.scss';
import 'pages/common/WhiteCard/WhiteCardForm.scss';

export const PasswordRecoveryPage = () => {
    const onSendEmail = async ({ email }: {email: string}) => {
        try {
            await passwordRecover.execute(email);
            notification.success({
                message: translate('success'),
                description: translate('password-recovery_email-sent'),
            });
        } catch (error: any) {
            notification.error({
                message: translate('error'),
                description: error.message,
            });
        }
    };
    return (
        <DesktopBlurredBackground>
            <Card className="whitecard">
                <WhiteCardHeader
                    pageTitleKey="forgot-password"
                    renderLegendText={() => (
                        <>
                            {translate('enter-your-email-to-recover-password')}
                            .
                            {' '}
                            {translate('an-email-will-be-sent-to-reset-your-password')}
                            .
                        </>
                    )}
                />
                <Form onFinish={onSendEmail} requiredMark="optional" name="form" layout="vertical">
                    <div>
                        <Form.Item
                            name="email"
                            label={
                                <label htmlFor="email" className="whitecard-form__label">{translate('e-mail')}</label>
                            }
                            rules={[{ required: true, message: translate('password-recovery_email-is-required') }]}
                        >
                            <Input className="whitecard-form__custom-input" id="email" type="email" />
                        </Form.Item>
                    </div>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="whitecard__confirm-button"
                    >
                        {translate('send')}
                    </Button>
                    <p>
                        <Link to="/login">
                            {translate('password-recovery_log-in-link')}
                        </Link>
                        {' '}
                        {translate('or')}
                        {' '}
                        <Link to="/register">
                            {translate('sign-up')}
                        </Link>
                    </p>
                </Form>
            </Card>
        </DesktopBlurredBackground>
    );
};
