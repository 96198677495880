import { LOGO } from 'config/constants';
import { translate } from 'translations/translate';
import React from 'react';

interface Iprops {
    children: JSX.Element
    filtersVisibility: boolean
    clubLogo?: string
}

export const ReservationsCalendarHeader = ({
    children,
    filtersVisibility,
    clubLogo,
}: Iprops): JSX.Element => (
    <div className="reservations-calendar__header">

        <div className="reservations-calendar__header-logo-container">
            <img
                className="reservations-calendar__header-image"
                src={clubLogo || LOGO}
                alt="logo"
            />
            <h2 className="reservations-calendar__header-title">
                {translate('calendar_header')}
            </h2>
        </div>
        {filtersVisibility && children}

    </div>
);
