import React from 'react';
import { translate } from 'translations/translate';
import { getClubFromStorage, getUserFromStorage, isLoggedUserAdmin } from 'services/functionalStorage';
import { DEFAULT_AVATAR } from 'config/constants';
import { IUser, Dict } from 'types';
import { PlayerCard } from 'components/Reservations/PlayerCard/PlayerCard';
import { AddPlayer } from '../AddPlayer/AddPlayer';
import { getPlayerName } from './getPlayerName';
import styles from './Owner.module.scss';

interface IProps {
    owner: IUser;
    userOptions: Dict<IUser>;
    removeOption: (id: string) => void;
    addOption: (id: string) => void
    isNewReservation: boolean
}

export const Owner = ({
    owner,
    userOptions,
    removeOption,
    addOption,
    isNewReservation,
} : IProps) => {
    const isAdmin = isLoggedUserAdmin();
    const loggedUser = getUserFromStorage();
    const currentClub = getClubFromStorage();

    return (

        <div>
            <p
                className={styles['players__card-subtitle']}
            >
                {translate('calendar-reservation_dialog-owner')}
            </p>

            <div className={styles.players__container}>
                {owner ? (
                    <PlayerCard
                        image={owner.avatar || DEFAULT_AVATAR}
                        playerName={getPlayerName(owner)}
                        key={owner.id}
                        showDeleteButton={isAdmin && isNewReservation}
                        removePlayer={() => addOption(owner.id)}
                        loggedUserId={loggedUser.id}
                        facilityId={currentClub.id}
                    />
                )
                    : (
                        <AddPlayer
                            userOptions={userOptions}
                            removeOption={removeOption}
                            placeholder={translate('calendar-reservation_dialog-choose_an_owner')}
                            isNewReservation={isNewReservation}
                            loggedUserId={loggedUser.id}
                            facilityId={currentClub.id}
                        />
                    )}
            </div>
        </div>
    );
};
