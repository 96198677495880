import React, { useState } from 'react';
import { translate } from 'translations/translate';
import { Button, Drawer } from 'antd';
import { DownOutlined, FilterOutlined, UpOutlined } from '@ant-design/icons';

import './MobileFooter.scss';

interface IProps {
    content: JSX.Element;
    mobileFooterOpenkey?: string,
    mobileFooterCloseKey?: string
}

export const MobileFooter = ({
    content,
    mobileFooterOpenkey = 'mobile-filters_open',
    mobileFooterCloseKey = 'mobile-filters_close',
}: IProps): JSX.Element => {
    const [visible, setVisible] = useState(false);

    return (
        <div className="mobile-footer">
            <div
                className="mobile-footer__open"
                onClick={() => setVisible(true)}
                onKeyPress={() => setVisible(true)}
                role="button"
                tabIndex={0}
            >
                <Button className="mobile-footer__open-button">
                    {translate(mobileFooterOpenkey)}
                    <UpOutlined />
                </Button>
                <FilterOutlined className="mobile-footer__open-icon" />

            </div>

            <Drawer
                title={(
                    <div
                        onClick={() => setVisible(false)}
                        onKeyPress={() => setVisible(false)}
                        role="button"
                        tabIndex={0}
                    >
                        <Button className="mobile-footer__close-button">
                            {translate(mobileFooterCloseKey)}
                            <DownOutlined />
                        </Button>
                        <FilterOutlined className="mobile-footer__close-icon" />
                    </div>
                )}
                className="mobile-footer__drawer"
                visible={visible}
                closable={false}
                placement="bottom"
                onClose={() => setVisible(false)}
                height="auto"
            >
                {content}
            </Drawer>
        </div>
    );
};
