import { Invoice, Order } from 'types';

interface SortInvoices {
    sorting: {field: string, order: Order},
    invoices: Invoice[]
}

export const sortInvoices = ({ invoices, sorting }: SortInvoices) => invoices.sort((a, b) => {
    let result: number;

    switch (sorting.field) {
    case 'name':
    case 'surname':
        result = a[sorting.field].localeCompare(
            b[sorting.field],
            undefined,
            {
                numeric: true,
                sensitivity: 'base',
            },
        );
        break;
    case 'date':
        result = a.createdAt.getTimestamp() - b.createdAt.getTimestamp();
        break;
    case 'total-amount':
        result = a.amount - b.amount;
        break;
    case 'invoice-number':
        result = parseInt(a.invoiceNumber, 10) - parseInt(b.invoiceNumber, 10);
        break;
    default:
        result = parseInt(a.invoiceNumber, 10) - parseInt(b.invoiceNumber, 10);
    }

    return sorting.order === 'asc' ? result : result * -1;
});
