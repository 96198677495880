import React from 'react';
import { EventContentArg } from '@fullcalendar/common';
import { CalendarPriceSlot } from 'components/CalendarPriceSlot/CalendarPriceSlot';
import { Reservation } from '../Reservations/Reservation/Reservation';

interface IProps {
    eventInfo: EventContentArg
}

export const CalendarEventContent = ({ eventInfo }: IProps): JSX.Element => {
    const { extendedProps } = eventInfo.event;

    if (extendedProps) {
        if (extendedProps.reservation) {
            return <Reservation reservation={extendedProps.reservation} />;
        }

        const { slotPrice: amount, isAdmin, role } = extendedProps;

        return (
            <CalendarPriceSlot
                amount={amount}
                role={role}
                isadmin={isAdmin}
            />
        );
    }

    return <div />;
};
