import React, { useState } from 'react';
import { customHistory } from 'routing/customHistory';
import { ROUTES } from 'routing/routes.enum';
import { IRoute, IUser } from 'types';
import { playerRoutes } from 'routing/playerRoutes';
import { UserHeader } from 'components/Reservations/UserHeader/UserHeader';
import { isUserActive } from 'helpers/isUserActive';
import { getUserFromStorage } from 'services/functionalStorage';
import { LayoutPage } from 'pages/common/Layout/LayoutPage';
import { Location } from 'history';
import { NotFoundPage } from 'pages/common/NotFound/NotFoundPage';
import { UserNotActiveNotice } from 'components/Reservations/UserNotActiveNotice/UserNotActiveNotice';
import { useModalContext } from 'context/ModalContext';
import { UserLayoutMenu } from './UserLayoutMenu';

export const UserLayoutPage = ({ guard, location }: {guard: ()=> boolean, location: Location}): JSX.Element => {
    const { setModalContentProps, setModalContent } = useModalContext();
    const userActive = isUserActive(getUserFromStorage());
    const [loggedUser, setLoggedUser] = useState<IUser>(getUserFromStorage());

    const closeModal = () => setModalContent(null);
    const showWaitingActivationModal = () => {
        setModalContentProps({ closable: true, onCancel: closeModal });
        setModalContent(
            <UserNotActiveNotice
                setLoggedUser={setLoggedUser}
                onUserInfoUpdated={closeModal}
            />,
        );
    };

    const contentProps = {
        showWaitingActivationModal,
        loggedUser,
        setLoggedUser,
    };

    if (!guard()) {
        customHistory.push(ROUTES.LOGIN);
        return <></>;
    }

    const isPlayerPath = playerRoutes.some((route: IRoute) => route.path === location.pathname);

    if (!isPlayerPath) {
        return <NotFoundPage />;
    }

    return (
        <LayoutPage
            routes={playerRoutes}
            menu={<UserLayoutMenu />}
            notice={<UserHeader userActive={userActive} openModal={showWaitingActivationModal} />}
            contentProps={contentProps}
        />
    );
};
