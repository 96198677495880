import React, { useRef } from 'react';
import {
    Button, Checkbox, DatePicker, Form, Input,
} from 'antd';
import { translate } from 'translations/translate';

import { useAppContext } from 'context/AppContext';
import moment from 'moment';
import { CALENDAR_ICON_GRAY } from 'config/constants';
import Icon from '@ant-design/icons';
import { ISeason } from 'usecases/types/season';
import { isThereSeason } from 'adapters/isThereSeason';
import { ICourt } from 'usecases/types/court';
import { ISeasonForm, ListAPIAction } from 'types';
import { dateFormatter } from 'adapters/dateFormatter';
import { FormCheckboxGroup } from 'components/FormCheckboxGroup/FormCheckboxGroup';
import { getDateRuleValidator } from 'adapters/getDateRuleValidator';
import { getMaxDateRuleValidator } from 'adapters/getMaxDateRuleValidator';
import './CreateEditSeasonModalContent.scss';

interface IProps {
    APIAction?: ListAPIAction
    onCancel: () => void;
    onProceed: (form: ISeasonForm) => Promise<void>
    season?: ISeason;
    courts: ICourt[]
}

export const CreateEditSeasonModalContent = ({
    APIAction,
    onCancel,
    onProceed,
    season,
    courts,
}: IProps) => {
    const { isSpinnerVisible, antdLocaleObject } = useAppContext();
    const { locale: antdLocale } = antdLocaleObject;
    const containerRef = useRef<HTMLElement>();

    const getIntialFormValues = () => {
        const seasonSelectedCourtIds = courts.filter(court => season?.courts && season.courts.includes(court.id))
            .map(court => court.id);
        if (isThereSeason(season)) {
            return {
                name: season!.name,
                start: moment(season!.startDateTime),
                end: moment(season!.endDateTime),
                courtIds: seasonSelectedCourtIds,
                active: season!.active,
            };
        }

        return {
            name: '',
            start: '',
            end: '',
            courtIds: seasonSelectedCourtIds,
            active: false,
        };
    };

    const MemoizedIcon = React.useCallback(() => (
        <img
            className="season-modal__calendar-icon"
            src={CALENDAR_ICON_GRAY}
            alt="close_icon_white"
        />
    ), []);

    return (

        <div className="season-modal__modal-container" ref={containerRef as any}>
            <h1 className="season-modal__title">
                {APIAction === ListAPIAction.edit
                    ? translate('seasons-page_modal_title-edit')
                    : translate('seasons-page_modal_title-create')}
            </h1>
            <Form
                requiredMark="optional"
                layout="vertical"
                initialValues={getIntialFormValues()}
                name="basic"
                onFinish={values => onProceed(values)}
            >
                <Form.Item
                    label={(
                        <label className="season-modal__form-label">
                            {translate('seasons-page_modal_season-name-label')}
                        </label>
                    )}
                    name="name"
                    rules={[{ required: true, message: translate('seasons-page_modal_season-is-required') }]}
                >
                    <Input
                        disabled={season && season.active}
                        className="season-modal__form-item"
                    />
                </Form.Item>
                <div className="season-modal__time-container">
                    <Form.Item
                        label={(
                            <label className="season-modal__form-label">
                                {translate('seasons-page_modal_starting-from-label')}
                            </label>
                        )}
                        name="start"
                        rules={[{
                            required: true,
                            message: translate('seasons-page_modal_starting-is-required'),
                        },
                        getDateRuleValidator(
                            'end',
                            false,
                            'seasons-page_modal_starting-before-ending',
                        ),
                        ]}
                    >
                        <DatePicker
                            disabled={season && season.active}
                            picker="date"
                            format={date => dateFormatter(date.toDate(), antdLocale)}
                            className="season-modal__date-picker-input"
                            dropdownClassName="season-modal__date-picker-dropdown"
                            allowClear={false}
                            getPopupContainer={() => containerRef.current as HTMLElement}
                            suffixIcon={<Icon component={MemoizedIcon} />}
                        />
                    </Form.Item>
                    <hr className="season-modal__time-divider" />
                    <Form.Item
                        label={(
                            <label className="season-modal__form-label">
                                {translate('seasons-page_modal_ending-on-label')}
                            </label>
                        )}
                        name="end"
                        rules={[{
                            required: true,
                            message: translate('seasons-page_modal_ending-is-required'),
                        },
                        getDateRuleValidator(
                            'start',
                            true,
                            'seasons-page_modal_ending-before-starting',
                        ),
                        getMaxDateRuleValidator('start', 1, 'seasons-page_modal_max-ending-date'),
                        ]}
                    >
                        <DatePicker
                            picker="date"
                            format={date => dateFormatter(date.toDate(), antdLocale)}
                            className="season-modal__date-picker-input"
                            dropdownClassName="season-modal__date-picker-dropdown"
                            allowClear={false}
                            getPopupContainer={() => containerRef.current as HTMLElement}
                            suffixIcon={<Icon component={MemoizedIcon} />}
                        />
                    </Form.Item>
                </div>
                <FormCheckboxGroup
                    required
                    formItem={{
                        name: 'courtIds',
                        labelText: translate('seasons-page_modal_courts-label'),
                        errorText: translate('seasons-page_modal_courts-is-required'),
                    }}
                    checkboxes={courts.map(court => ({ value: court.id, name: court.name }))}
                />
                <Form.Item
                    name="active"
                    rules={[{
                        transform: value => (value || undefined),
                        type: 'boolean',

                    }]}
                    valuePropName="checked"
                >
                    <Checkbox
                        className="season-modal__checkbox"
                    >
                        <p className="season-modal__form-label">{translate('seasons-page_modal_active-label')}</p>
                    </Checkbox>
                </Form.Item>
                <div className="season-modal__buttons-container">
                    <Button
                        type="default"
                        className="season-modal__button season-modal__button--gray"
                        onClick={onCancel}
                    >
                        {translate('seasons-page_modal_cancel-button')}
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="season-modal__button"
                        disabled={isSpinnerVisible}
                    >
                        {isThereSeason(season) ? translate('seasons-page_modal_edit-button')
                            : translate('seasons-page_modal_create-button')}
                    </Button>
                </div>
            </Form>
        </div>
    );
};
