import React, { useState } from 'react';
import { Menu } from 'antd';
import Icon from '@ant-design/icons';
import {
    Link, withRouter, RouteComponentProps,
} from 'react-router-dom';
import { ILink } from 'types';
import { LOGO_WHITE } from 'config/constants';
import { connect } from 'react-redux';
import { closeNavbar } from 'redux/actions/navbarVisibility';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import './LayoutMenu.scss';
import { v4 as uuid } from 'uuid';

interface IProps extends RouteComponentProps {
    links: ILink[];
    dispatch: (action: any) => void,
}

const layoutMenu = ({
    location,
    links,
    dispatch,
}: IProps): JSX.Element => {
    const getOpenedSubmenuKey = (): string | null => {
        const openedSubmenu = links.find((link:ILink) => {
            if (!link.routes) return false;
            return link.routes.some((route: ILink) => route.to === location.pathname);
        });
        return openedSubmenu ? openedSubmenu.text : null;
    };

    const currentSubmenuKey = getOpenedSubmenuKey();
    const [openSubmenusKeys, setOpenSubmenusKeys] = useState<string[]>(currentSubmenuKey ? [currentSubmenuKey] : []);

    const isActiveMenu = (key: string): boolean => key === location.pathname || key === currentSubmenuKey;

    const getCustomIcon = (iconUrls: string[], linkText: string) => (
        <Icon component={() => (
            <img
                className="menu__icon"
                src={!isActiveMenu(linkText)
                    ? iconUrls[0]
                    : iconUrls[1]}
                alt="icon"
            />
        )}
        />
    );

    const renderSubmenu = (link: ILink): JSX.Element => (
        <Menu.SubMenu
            key={link.text}
            icon={link.icons
                ? getCustomIcon(link.icons, link.text)
                : link.icon}
            title={link.text}
            className={`
                    menu__sub-menu
                    ${isActiveMenu(link.text) ? 'menu__sub-menu--active' : ''}
                `}
        >
            {link.routes?.map(route => {
                if (route.to) {
                    return (
                        <Menu.Item key={route.text} className="bottom-menu__items">
                            <Link
                                onClick={() => {
                                    pushGtmDatalayer(route.datalayer);
                                    dispatch(closeNavbar());
                                }}
                                to={route.to}
                            >
                                {route.text}
                            </Link>
                        </Menu.Item>
                    );
                }
                return (
                    <Menu.Item
                        key={route.text}
                        onClick={() => {
                            pushGtmDatalayer(route.datalayer);
                            route.run!();
                            dispatch(closeNavbar());
                        }}
                        className="bottom-menu__items"
                    >
                        {route.text}
                    </Menu.Item>
                );
            })}
        </Menu.SubMenu>
    );

    const renderMenuItem = (link: ILink): JSX.Element => {
        if (link.routes) {
            return renderSubmenu(link);
        }
        return (
            <Menu.Item
                className="menu__item"
                key={link.to}
                icon={link.icons
                    ? getCustomIcon(link.icons, link.to!)
                    : link.icon}
            >
                <Link
                    onClick={() => {
                        dispatch(closeNavbar());
                        pushGtmDatalayer(link.datalayer);
                    }}
                    target={link.targetBlank ? '_blank' : '_self'}
                    to={link.to!}
                >
                    {link.text}

                </Link>
            </Menu.Item>
        );
    };

    const linksByPosition = links.reduce(
        (acc: { top:ILink[], bottom:ILink[] }, link: ILink) => {
            const position = link.bottom ? 'bottom' : 'top';
            acc[position].push(link);
            return acc;
        },
        { top: [], bottom: [] },
    );

    return (
        <>
            <div className="logo">
                <img
                    src={LOGO_WHITE}
                    alt="Court Hero Logo"
                    className="logo__image"
                />
            </div>
            <Menu
                theme="dark"
                selectedKeys={[location.pathname]}
                openKeys={openSubmenusKeys}
                onOpenChange={keys => setOpenSubmenusKeys([keys.pop() as string])}
                mode="inline"
                className="menu"
            >
                <Menu.Divider className="menu__divider" />
                {linksByPosition.top.map(renderMenuItem)}

                <Menu.ItemGroup key={uuid()} className="bottom-menu">
                    {linksByPosition.bottom.map(renderMenuItem)}
                </Menu.ItemGroup>
            </Menu>
        </>
    );
};

const routedLayoutMenu = withRouter(layoutMenu);
export const LayoutMenu = routedLayoutMenu;

export const ConnectedLayoutMenu = connect(() => ({}))(LayoutMenu);
