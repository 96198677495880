import React from 'react';
import { AutoComplete, Input, Select } from 'antd';
import { translate } from 'translations/translate';
import {
    PlayerRole, PLAYERROLEADMIN, PLAYERROLECLIENT, PLAYERROLEMEMBER,
} from 'types';
import { MEMBERSHIP_STATUS } from 'usecases/types/enums/membership_status.enum';
import './ClubMembersFilters.scss';

interface INameFilterOption {
    label: string;
    value: string;
}

interface IProps {
    nameFilterValue : string,
    nameFilterOptions : INameFilterOption[],
    getNameFilterOption : (inputValue: string, option: any) => any,
    onChangeNameFilter : (value: string) => void,
    setRoleFilterValue : (value: PlayerRole | '') => void,
    changeStatusFilterValue : (value: string) => void,

}

export const ClubMembersFilters = ({
    nameFilterValue, nameFilterOptions, getNameFilterOption,
    onChangeNameFilter, setRoleFilterValue, changeStatusFilterValue,
}: IProps): JSX.Element => (
    <div className="club-members-filters__filters-container">
        <AutoComplete
            className="club-members-filters__filter"
            value={nameFilterValue}
            options={nameFilterOptions}
            filterOption={getNameFilterOption}
            onChange={onChangeNameFilter}
        >

            <Input
                placeholder={translate('club_members-filter-name')}
                bordered={false}
            />
        </AutoComplete>
        <Select
            size="large"
            className="club-members-filters__filter"
            placeholder={translate('club_members-filter-role')}
            onChange={value => setRoleFilterValue(value as PlayerRole)}
        >
            <Select.Option value={PLAYERROLEMEMBER}>{translate(`role_${PLAYERROLEMEMBER}`)}</Select.Option>
            <Select.Option value={PLAYERROLECLIENT}>{translate(`role_${PLAYERROLECLIENT}`)}</Select.Option>
            <Select.Option value={PLAYERROLEADMIN}>{translate(`role_${PLAYERROLEADMIN}`)}</Select.Option>
            <Select.Option value="">{translate('role_all')}</Select.Option>
        </Select>
        <Select
            className="club-members-filters__filter"
            onChange={changeStatusFilterValue}
            placeholder={translate('club_members-filter-status')}
        >
            <Select.Option
                value={MEMBERSHIP_STATUS.PENDING}
            >
                {translate('user-status_pending')}

            </Select.Option>
            <Select.Option
                value={MEMBERSHIP_STATUS.ACTIVE}
            >
                {translate('user-status_active')}

            </Select.Option>
            <Select.Option
                value={MEMBERSHIP_STATUS.INACTIVE}
            >
                {translate('user-status_inactive')}

            </Select.Option>
            <Select.Option
                value=""
                key="all"
            >
                {translate('user-status_all')}

            </Select.Option>
        </Select>
    </div>
);
