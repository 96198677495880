import React from 'react';
/* eslint-disable react/no-unescaped-entities */
import { getProfileLanguage } from 'translations/getProfilelanguage';
import './SeasonsExplanation.scss';
import { EDIT_ICON_GRAY, DELETE_ICON_GRAY } from 'config/constants';

export const SeasonsExplanation = () => {
    const profileLanguage = getProfileLanguage();
    const germanExplanation = () => (
        <>
            <h1 className="seasons-explanation__title">Saisons</h1>
            <div className="seasons-explanation__text-container">
                <p className="seasons-explanation__text">
                    Die Funktion „Saisons“ ist ein zentraler Bestandteil von Court Hero.
                    Die Erstellung einer Saison mit der Saison zugeordneten Plätzen und die Aktivierung der
                    Saison ist Voraussetzung dafür, dass Spieler deines Vereins oder Gäste deine Plätze buchen können.
                </p>
                <p className="seasons-explanation__text">
                    Bei der Einrichtung deines Vereins bei Court Hero ist die Erstellung einer Saison idealerweise
                    der dritte Schritt nach der Vervollständig der allgemeinen Informationen im Menüpunkt „Verein“
                    sowie der Erstellung deiner Plätze im Menüpunkt „Plätze“.
                </p>
                <p className="seasons-explanation__text">Um eine neue Saison zu erstellen, gehe bitte wie folgt vor:</p>
                <p className="seasons-explanation__text">1) Klicke auf den grünen Bereich „+Neue Saison“.</p>
                <p className="seasons-explanation__text">
                    2) In dem neu geöffneten Fenster gib bitte den Saisonnamen ein, wähle Start- und Enddatum
                    der Saison aus und weise der Saison die gewünschten Plätze zu.
                </p>
                <p className="seasons-explanation__text">
                    3) Klicke die Checkbox „Saison zur Buchung durch Spieler freigeben“ an, wenn du die Buchung
                    für Spieler bereits jetzt aktivieren möchtest. Bitte bedenke aber, dass die Spieler so lange
                    keinen Platz buchen können, bis ein Preis für den Platz von dir festgelegt worden ist.
                    Wir empfehlen daher zuerst die Preisregeln zu erstellen und nach Abschluss dieser diese
                    Checkbox zu aktivieren.
                </p>
                <p className="seasons-explanation__text">
                    4) Klicke auf den grünen Bereich „Saison erstellen“ um die Saison zu erstellen und
                    somit deine Eingaben zu speichern.
                </p>
                <p className="seasons-explanation__text">
                    Du kannst die erstellte Saison jederzeit bearbeiten, indem du auf
                    <img className="seasons-explanation__text-icon" src={EDIT_ICON_GRAY} alt="das Stift-Symbol" />
                    klickst oder die Saison löschen, indem du auf
                    <img className="seasons-explanation__text-icon" src={DELETE_ICON_GRAY} alt="den Papierkorb" />
                    klickst.
                </p>
                <p className="seasons-explanation__text">
                    Bei den aktiven Saisons kannst du alles bis auf den Saisonnamen sowie das Startdatum bearbeiten.
                </p>
                <p className="seasons-explanation__text">
                    Nur die aktiven Saisons aus der Saisonliste sind jene, für welche du und deine Spieler einen
                    Platz buchen können, solange dafür ein Preis festgelegt worden ist.
                </p>
                <p className="seasons-explanation__text">
                    <b>Benötigst du weitere Hilfe? </b>
                    Dann schreibe eine E-Mail an
                    {' '}
                    <a
                        className="seasons-explanation__link"
                        href="mailto:courthero@tennis-point.de"
                    >
                        courthero@tennis-point.de
                    </a>
                </p>
            </div>
        </>
    );
    const englishExplanation = () => (
        <>
            <h1 className="seasons-explanation__title">Seasons</h1>
            <div className="seasons-explanation__text-container">
                <p className="seasons-explanation__text">
                    The "Seasons" function is a central component of Court Hero. The creation of a season with
                    courts assigned to the season and the activation of the season is a prerequisite for players
                    of your club or guests to be able to book your courts.
                </p>
                <p className="seasons-explanation__text">
                    When setting up your club on Court Hero, creating a season is ideally the third step after
                    completing the general information in the "Club" menu item and creating your courts in the
                    "Courts" menu item.
                </p>
                <p className="seasons-explanation__text">To create a new season, please proceed as follows:</p>
                <p className="seasons-explanation__text">1) Click on the green button "+New season".</p>
                <p className="seasons-explanation__text">
                    2) In the newly opened window, please enter the season name, select the start and end date
                    of the season and assign the desired courts to the season.
                </p>
                <p className="seasons-explanation__text">
                    3) Click on the checkbox "Enable season for booking by players" if you want to activate the
                    booking for players already now. Please keep in mind, however, that players will not be able
                    to book a court until you have set a price for the court. We therefore recommend that you
                    create the price rules first and activate this checkbox after you have completed them.
                </p>
                <p className="seasons-explanation__text">
                    4) Click on the green button "Create season" to create the season and save your entries.
                </p>
                <p className="seasons-explanation__text">
                    You can edit the created season at any time by clicking on
                    <img className="seasons-explanation__text-icon" src={EDIT_ICON_GRAY} alt="the pencil icon" />
                    or delete the season by clicking on
                    <img className="seasons-explanation__text-icon" src={DELETE_ICON_GRAY} alt="the recycle bin" />
                    .
                </p>
                <p className="seasons-explanation__text">
                    You can edit everything in the active seasons except for the season name and the start date.
                </p>
                <p className="seasons-explanation__text">
                    Only the active seasons from the season list are those for which the you and your players can
                    book a court as long as a price has been set for it.
                </p>
                <p className="seasons-explanation__text">
                    <b>Need more help? </b>
                    Then send an e-mail to
                    {' '}
                    <a
                        className="seasons-explanation__link"
                        href="mailto:courthero@tennis-point.de"
                    >
                        courthero@tennis-point.de
                    </a>
                </p>
            </div>
        </>
    );
    return (
        <div className="seasons-explanation">
            {profileLanguage === 'english'
                ? englishExplanation()
                : germanExplanation()}
        </div>

    );
};
