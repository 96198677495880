import React, { useState } from 'react';
import {
    createInstance, OptimizelyContext, OptimizelyProvider,
} from '@optimizely/react-sdk';
import { getUserFromStorage } from 'services/functionalStorage';

interface IProps {
    sdkKey: string,
    children: React.ReactNode,
}
const FeatureFlagProvider = ({ sdkKey, children }: IProps) => {
    const optimizely = createInstance({ sdkKey });
    return (
        <OptimizelyProvider user={{ id: getUserFromStorage()?.id }} optimizely={optimizely}>
            {children}
        </OptimizelyProvider>
    );
};

const useFeatureFlagContext = () => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const context = React.useContext(OptimizelyContext);
    if (context === undefined) {
        throw new Error('useFeatureFlagContext must be used within a FeatureFlagProvider');
    }
    context.optimizely!.onReady().then(() => setLoaded(true));
    return { loaded };
};

export { FeatureFlagProvider, useFeatureFlagContext };
