import React from 'react';
import { Card } from 'antd';
import { DesktopBlurredBackground } from 'components/Reservations/DesktopBlurredBackground/DesktopBlurredBackground';
import { WhiteCardHeader } from '../WhiteCardHeader/WhiteCardHeader';
import 'pages/common/Terms/TermsPage.scss';

export const ImprintInfo = (): JSX.Element => (
    <DesktopBlurredBackground>
        <Card className="user-terms__card">
            <WhiteCardHeader />

            <h1 className="user-terms__card__title">Impressum</h1>
            <p>
                <br />
                <br />
                Tennis-Point GmbH
                <br />
                Hans-Böckler-Str. 29-35
                <br />
                33442 Herzebrock-Clarholz
                <br />
                E-Mail:
                {' '}
                <a className="user-terms__link" href="mailto:courthero@tennis-point.de">
                    courthero@tennis-point.de
                </a>
            </p>
            <br />
            <p>
                Registergericht: Gütersloh | Registernummer: HRB 9528
                <br />
                Geschäftsführer: Christian Miele, Stefan Salzer
                <br />
                Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
                <br />
                DE282500217
            </p>
        </Card>
    </DesktopBlurredBackground>
);
