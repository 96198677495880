import { IClub } from 'usecases/types/club';
import { CLUBS, PLACEHOLDERS } from 'config/endpoints';
import { putWrapper } from 'services/httpService/httpServiceWrapper';

export const changePaymentMethodsStatus = async (
    club: IClub,
    values: {
        online?: boolean,
        cash?: boolean,
    },
): Promise<void> => {
    const url = CLUBS.PAYMENT_METHODS.replace(PLACEHOLDERS.CLUB_ID, club.id);
    const { data } = await putWrapper(url, {
        onlinePayments: values.online,
        cashPayments: values.cash,
    });
    return data;
};
