import React from 'react';
import { Spin } from 'antd';
import { useAppContext } from 'context/AppContext';
import './SpinnerComponent.scss';

export const SpinnerComponent = (): JSX.Element | null => {
    const { isSpinnerVisible } = useAppContext();
    if (isSpinnerVisible) {
        return (
            <Spin
                size="large"
                className="spin-component"
            />
        );
    }
    return null;
};
