import moment from 'moment';
import { TimeSlot } from 'types';

export const getTimeSlotsBetweenTwoDates = (startDateTime: string, endDateTime: string): TimeSlot[] => {
    const start = moment(startDateTime);
    const end = moment(endDateTime);

    const current = start.clone();
    const result: any[] = [];

    while (current.isSameOrBefore(end)) {
        result.push(current.clone().toISOString());
        current.add(30, 'minutes');
    }

    const slots = result.map((element, index, array) => {
        if (index + 1 < array.length) {
            return {
                slotStartDate: array[index],
                slotEndDate: array[index + 1],
            };
        }

        return null;
    }).filter(value => Boolean(value));

    return slots as TimeSlot[];
};
