import { CLUBS, PLACEHOLDERS } from 'config/endpoints';
import { IUpdateClubPlayer } from 'usecases/types/dto/dto';
import { putWrapper } from 'services/httpService/httpServiceWrapper';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { getEditPlayerRoleInFacilityDataLayer } from 'services/gtm/gtmDatalayers';
import { getRoleName } from 'helpers/getRoleName';

interface IupdateClubPlayer {
    message: string
}

export const updateClubPlayer = async ({
    clubId,
    playerId,
    name,
    surname,
    gender,
    language,
    role,
    address,
    oldRole,
}: IUpdateClubPlayer): Promise<IupdateClubPlayer> => {
    const payLoad = {
        name,
        surname,
        gender,
        language,
        role,
        address,
    };
    const url = CLUBS.PLAYER
        .replace(PLACEHOLDERS.CLUB_ID, clubId)
        .replace(PLACEHOLDERS.PLAYER_ID, playerId);
    const { data } = await putWrapper(url, payLoad);

    pushGtmDatalayer(getEditPlayerRoleInFacilityDataLayer(
        playerId,
        clubId,
        getRoleName(oldRole),
        getRoleName(role),
    ));

    return data;
};
