export const buildQueryParams = (params: {[key:string]: any}): string => {
    const paramsKeys = Object.keys(params);
    if (paramsKeys.length === 0) {
        return '';
    }
    return paramsKeys.reduce((acc, current, index) => {
        if (Object.values(params).length === index + 1) {
            return `${acc}${current}=${params[current]}`;
        }
        return `${acc}${current}=${params[current]},`;
    }, '?');
};
