import { CLUBS, PLACEHOLDERS } from 'config/endpoints';
import { translate } from 'translations/translate';
import { Report } from 'types';

export const importCsvPlayers = async (clubId: string, file: File): Promise<Report> => {
    const url = CLUBS.IMPORT_PLAYER.replace(PLACEHOLDERS.CLUB_ID, clubId);

    const formData = new FormData();
    formData.append('csv', file);

    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        method: 'POST',
        body: formData,
    });

    const responseJson = await response.json();

    if (response.status !== 200) {
        throw new Error(translate(`error-${responseJson.code}`, responseJson.data.errorValues));
    }

    return responseJson.data.report;
};
