import { EUR_CURRENCY } from 'config/constants';
import { getAntdLocaleFromStorage } from 'services/functionalStorage';

export const formatNumberAsLocaleCurrency = (amount: number):string => {
    const locale = getAntdLocaleFromStorage();

    return amount.toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
        currency: EUR_CURRENCY,
    });
};
