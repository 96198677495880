import moment from 'moment';

export enum TimeUnits {
    MINUTE = 'm',
    DAY = 'day',
    WEEK = 'week',
}

export class CHDateTime {
    private datetime: moment.Moment;

    constructor(inputDate: string = 'now') {
        if (inputDate === 'now') {
            this.datetime = moment();
            return;
        }
        if (!moment(inputDate, moment.ISO_8601).isValid()) {
            throw new Error(`${inputDate} does not have the right format`);
        }
        this.datetime = moment(inputDate);
    }

    public static fromNativeDate(date: Date): CHDateTime {
        return new CHDateTime(date.toISOString());
    }

    public toUtc(): CHDateTime {
        return CHDateTime.fromNativeDate(this.datetime.clone().utc().toDate());
    }

    public toNativeDate(): Date {
        return this.datetime.toDate();
    }

    public isBefore(dateToCompare: CHDateTime): boolean {
        return this.datetime.isBefore(dateToCompare.toNativeDate());
    }

    public isBeforeOrEqual(dateToCompare: CHDateTime): boolean {
        return this.datetime.isSameOrBefore(dateToCompare.toNativeDate());
    }

    public isAfter(dateToCompare: CHDateTime): boolean {
        return this.datetime.isAfter(dateToCompare.toNativeDate());
    }

    public isAfterOrEqual(dateToCompare: CHDateTime): boolean {
        return this.datetime.isSameOrAfter(dateToCompare.toNativeDate());
    }

    public getTimestamp(): number {
        return this.datetime.valueOf();
    }

    public startOfDay(): CHDateTime {
        return new CHDateTime(this.datetime.clone().startOf('d').toISOString());
    }

    public endOfDay(): CHDateTime {
        return new CHDateTime(this.datetime.clone().endOf('d').toISOString());
    }

    public add(number: number, unit: TimeUnits): CHDateTime {
        return new CHDateTime(this.datetime.clone().add(number, unit).toISOString());
    }

    public subtract(number: number, unit: TimeUnits): CHDateTime {
        return new CHDateTime(this.datetime.clone().subtract(number, unit).toISOString());
    }

    public toString(): string {
        return this.datetime.toISOString();
    }

    public formatDateToLocale(locale: string): string {
        const formatter = new Intl.DateTimeFormat(
            locale,
            {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            },
        );

        return formatter.format(this.toNativeDate());
    }

    public format(format: string): string {
        return this.datetime.format(format);
    }

    public getWeek(): number {
        return this.datetime.isoWeek();
    }

    public getWeekday(): number {
        return this.datetime.weekday();
    }

    public getMinutes(): number {
        return this.datetime.minutes();
    }

    public diffInDays(initialUtcDate: CHDateTime): number {
        return Math.ceil(moment.duration(this.datetime.diff(initialUtcDate.toNativeDate())).asDays());
    }

    public isEqual(dateToCompare: CHDateTime): boolean {
        return this.datetime.isSame(dateToCompare.toNativeDate());
    }
}
