import React from 'react';
import { translate } from 'translations/translate';
import './WhiteCardHeader.scss';

interface IProps {
    pageTitleKey?: string;
    renderLegendText?: () => JSX.Element
}

export const WhiteCardHeader = ({
    pageTitleKey, renderLegendText = () => <></>,
}: IProps) => (
    <>
        <div className="whitecard-header__mobile">
            <img className="whitecard-header__mobile-img" src="/assets/logo_RGB.png" alt="Court Hero Logo" />
        </div>
        {pageTitleKey && (
            <div className="whitecard-header__legend">
                <h1 className="whitecard-header__legend-title">{translate(pageTitleKey)}</h1>
                <p className="whitecard-header__legend-text">
                    {renderLegendText()}
                </p>
            </div>
        )}
    </>
);
