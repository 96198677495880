// Sidebar
export const COLLAPSE_SIDEBAR = 'COLLAPSE_SIDEBAR';
export const UNCOLLAPSE_SIDEBAR = 'UNCOLLAPSE_SIDEBAR';
export interface CollapseSideBarAction {
    type: typeof COLLAPSE_SIDEBAR
}

export interface UncollapseSideBarAction {
    type: typeof UNCOLLAPSE_SIDEBAR
}
export type SidebarActions = CollapseSideBarAction | UncollapseSideBarAction;

// Navbar
export enum NAVBAR_ACTIONS {
    SHOW = 'SHOW_NAVBAR',
    CLOSE = 'CLOSE_NAVBAR',
}
export interface IShowNavbarAction {
    type: NAVBAR_ACTIONS.SHOW
}
export interface ICloseNavbarAction {
    type: NAVBAR_ACTIONS.CLOSE
}
export type NavbarActions = IShowNavbarAction | ICloseNavbarAction;
