import React from 'react';
import Icon from '@ant-design/icons';
import './ListPageHeader.scss';

interface IProps {
    header: string;
    iconUrl: string;
    children?: React.ReactNode | React.ReactNode[]
}

export const ListPageHeader = ({
    header,
    iconUrl,
    children,
}: IProps) => {
    const MemoizedIcon = React.useCallback(() => (
        <img
            className="list-page-header__icon"
            src={iconUrl}
            alt="facility_icon"
        />
    ), []);

    return (
        <div className="list-page-header">
            <h1 className="list-page-header__title">
                <Icon component={MemoizedIcon} />
                {' '}
                {header}
            </h1>
            <div className="list-page-header__header-buttons">
                {children}
            </div>
        </div>
    );
};
