import React from 'react';
import { translate } from 'translations/translate';
import { ROUTES } from 'routing/routes.enum';
import { ILink } from 'types';
import {
    ACCOUNT_ICON, ACCOUNT_ICON_WHITE,
    RESERVATION_ICON_GRAY, RESERVATION_ICON_WHITE,
} from 'config/constants';
import { ConnectedLayoutMenu } from 'pages/common/Layout/LayoutMenu';
import { logoutUser } from 'services/logoutUser';
import {
    CALENDAR_SIDEBAR_DATALAYER,
    IMPRINT_SIDEBAR_DATALAYER,
    PROFILE_LOGOUT_SIDEBAR_DATALAYER,
    PROFILE_SETTINGS_SIDEBAR_DATALAYER,
} from 'services/gtm/gtmDatalayers';
import { getMyFacilitySubmenu } from './getMyFacilitySubmenu';

export const UserLayoutMenu = () => {
    const accountMenu = {
        bottom: true,
        icons: [ACCOUNT_ICON, ACCOUNT_ICON_WHITE],
        text: translate('menu-profile'),
        routes: [
            {
                to: ROUTES.MULTICLUB.MY_FACILITIES,
                text: translate('menu-facilities'),
            },
            {
                to: ROUTES.PLAYER.PROFILE_SETTINGS,
                text: translate('menu-settings'),
                datalayer: PROFILE_SETTINGS_SIDEBAR_DATALAYER,
            },
            {
                run: logoutUser,
                text: translate('menu-logout'),
                datalayer: PROFILE_LOGOUT_SIDEBAR_DATALAYER,
            },
        ],
    };

    const links: ILink[] = [
        {
            to: ROUTES.PLAYER.DASHBOARD,
            icons: [RESERVATION_ICON_GRAY, RESERVATION_ICON_WHITE],
            text: translate('user_menu-calendar'),
            datalayer: CALENDAR_SIDEBAR_DATALAYER,
        },
        getMyFacilitySubmenu(),
        accountMenu,
        {
            to: ROUTES.IMPRINT,
            icon: <></>,
            text: translate('imprint'),
            bottom: true,
            targetBlank: true,
            datalayer: IMPRINT_SIDEBAR_DATALAYER,
        },
    ];

    return (
        <ConnectedLayoutMenu
            links={links}
        />
    );
};
