import React from 'react';
import { MIDDLE_DOT } from 'config/constants';
import { getClubFromStorage } from 'services/functionalStorage';

export const UserActiveHeader = () => {
    const { name, address, contactData } = getClubFromStorage();

    return (
        <span>
            {name}
            {address && ` ${MIDDLE_DOT} ${address?.street} ${address?.number}, ${address?.zipCode} ${address?.city}`}
            {contactData?.phoneNumber && ` ${MIDDLE_DOT} ${contactData?.phoneNumber}`}
            {contactData?.contactEmail && ` ${MIDDLE_DOT} ${contactData?.contactEmail}`}
            {contactData?.homepage && ` ${MIDDLE_DOT} ${contactData?.homepage}`}
        </span>
    );
};
