import { IReservation } from 'usecases/types/reservations';
import { RESERVATIONS, PLACEHOLDERS } from 'config/endpoints';
import { postWrapper } from 'services/httpService/httpServiceWrapper';
import { ICreateRecurringBookingInput } from 'usecases/types/dto/CreateRecurringBookingInput';

export const createRecurringBooking = async (createReservationInputDto: ICreateRecurringBookingInput)
: Promise<IReservation[]> => {
    const {
        club,
        court,
        companions,
        guests,
        startTime,
        endTime,
        owner,
        price,
        description,
        interval,
        endingOn,
        bookingType,
    } = createReservationInputDto;

    const url = RESERVATIONS.RECURRING.replace(PLACEHOLDERS.CLUB_ID, club.id);

    const data = {
        companions: companions.map((companion: any) => companion.id),
        guests,
        courtId: court.id,
        startTime,
        endTime,
        price,
        description,
        interval,
        endingOn,
        bookingType,
    };

    if (owner !== undefined) {
        (data as any).ownerId = owner.id;
    }

    return (await postWrapper(url, data)).data.bookings;
};
