import { CreateSeasonInput } from 'usecases/types/dto/CreateSeasonInput';
import { CLUBS, PLACEHOLDERS } from 'config/endpoints';
import { ISeason } from 'usecases/types/season';
import { postWrapper } from 'services/httpService/httpServiceWrapper';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { getCreateSeasonDataLayer } from 'services/gtm/gtmDatalayers';
import { getUserFromStorage } from 'services/functionalStorage';

export const createSeason = async ({
    clubId,
    name,
    startDate,
    endDate,
    courts,
    active,
}: CreateSeasonInput): Promise<ISeason> => {
    const payLoad = {
        name,
        startDate,
        endDate,
        courts,
        active,
    };

    const url = CLUBS.SEASON_LIST.replace(PLACEHOLDERS.CLUB_ID, clubId);

    const { data } = await postWrapper(url, payLoad);
    const user = getUserFromStorage();

    pushGtmDatalayer(getCreateSeasonDataLayer(
        user.id,
        clubId,
        courts,
        data.season.id,
        active,
    ));

    return data.season;
};
