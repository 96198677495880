import React from 'react';
import { isLoggedUserAdmin } from 'services/functionalStorage';
import { ICourt } from 'usecases/types/court';
import { IReservation } from 'usecases/types/reservations';
import { CourtSelector } from '../CourtSelector/CourtSelector';
import { Duration } from '../Duration/Duration';
import './DurationRow.scss';

interface IProps {
    onDurationChange: (duration: number) => any;
    reservationTime: number;
    court: ICourt;
    courts: ICourt[];
    reservation: IReservation | null;
    onCourtChange: (court: ICourt) => any;
    isNewReservation: boolean
}

export const DurationRow = ({
    onDurationChange,
    reservationTime,
    court,
    courts,
    reservation,
    onCourtChange,
    isNewReservation,
}: IProps) => (
    <div className="duration-row">
        <Duration
            onChange={duration => {
                onDurationChange(duration);
            }}
            duration={reservationTime}
            isNewReservation={isNewReservation}
        />
        {reservation && isLoggedUserAdmin() && (
            <CourtSelector
                court={court}
                courts={courts}
                onChange={onCourtChange}
                isNewReservation={isNewReservation}
            />
        )}
    </div>
);
