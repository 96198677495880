import { USERS } from 'config/endpoints';
import { postWrapper } from 'services/httpService/httpServiceWrapper';

class PasswordRecover {
    execute(email: string) {
        const url = USERS.PASSWORD_RECOVERY;
        const data = {
            email,
        };

        return postWrapper(url, data);
    }
}

export const passwordRecover = new PasswordRecover();
