import { USERS } from 'config/endpoints';
import { postWrapper } from 'services/httpService/httpServiceWrapper';

export const changeForgottenPassword = (
    email: string,
    token: string,
    password: string,
): Promise<any> => postWrapper(
    USERS.CHANGE_FORGOTTEN_PASSWORD,
    { email, token, password },
    { Authorization: null },
);
