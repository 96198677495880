import React from 'react';
import { translate } from 'translations/translate';
import { useMobileMediaQuery } from 'hooks/useMobileMediaQuery';
import { useModalContext } from 'context/ModalContext';
import { ListPageButton } from 'components/ListPageButton/ListPageButton';
import { CreateFacilityCard } from './CreateFacilityCard';
import '../AddFacilityCard.scss';

interface IProps {
    updateFacilityCreated: Function,
}

export const CreateFacilityButton = ({ updateFacilityCreated }: IProps) => {
    const { setModalContentProps, setModalContent } = useModalContext();
    const { isMobile } = useMobileMediaQuery();

    return (

        <>
            <ListPageButton
                onClick={() => {
                    setModalContentProps({
                        closable: !!isMobile,
                        onCancel: () => setModalContent(null),
                    });
                    setModalContent(<CreateFacilityCard
                        onCancel={() => setModalContent(null)}
                        updateFacilityCreated={updateFacilityCreated}
                    />);
                }}
                grey
            >
                {translate('facility_card-create_facility')}
            </ListPageButton>

        </>

    );
};
