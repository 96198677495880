import moment from 'moment';
import { translate } from 'translations/translate';

export const getTime = (value: string) => new Date(moment(value).format()).toLocaleTimeString(
    [],
    { hour: '2-digit', minute: '2-digit' },
);

export const getTimeText = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes % 60;

    if (hours === 1) {
        if (minutesLeft === 30) {
            return translate('x_minutes', { minutes });
        }
        if (minutesLeft > 0) {
            return translate(
                'x_hour_and_y_minutes',
                { hours, minutes: minutesLeft },
            );
        }

        return translate('x_hour', { hours });
    }

    if (hours > 1) {
        if (minutesLeft > 0) {
            return translate(
                'x_hours_and_y_minutes',
                { hours, minutes: minutesLeft },
            );
        }

        return translate('x_hours', { hours });
    }

    return minutesLeft > 0 ? translate(
        'x_minutes',
        { minutes: minutesLeft },
    ) : '';
};

export const getMinutesDiffBetweenDates = ({ startTime, endTime }:
    {startTime: string, endTime: string}) => moment(endTime).diff(startTime, 'minutes');
