import React, { useRef } from 'react';
import {
    Button, Form, Input, Select,
} from 'antd';
import { useApi } from 'hooks/useApi';
import { useMobileMediaQuery } from 'hooks/useMobileMediaQuery';
import { translate } from 'translations/translate';
import { AlertModalContent } from 'components/AlertModalContent/AlertModalContent';
import { getCountryMap } from 'pages/common/AddressCard/getCountryMap';
import { useModalContext } from 'context/ModalContext';
import { createClubWithoutAdmin } from 'usecases/club/createClubWithoutAdmin';
import { getUserInformationAndSetInStorage } from 'usecases/users/getUserInformationAndSetInStorage';
import './CreateFacilityCard.scss';

interface ICreateFacility {
    onCancel: Function,
    updateFacilityCreated: Function,
}

interface IRegisterForm {
    name: string;
    street: string;
    number: string;
    city: string;
    zipCode: string;
    country: string;
    address: {
        street: string,
        number: string,
        city: string,
        zipCode: string,
        country: string,
    }
}

export const CreateFacilityCard = ({
    onCancel, updateFacilityCreated,
}: ICreateFacility) => {
    const { setModalContentProps, setModalContent } = useModalContext();
    const { isMobile } = useMobileMediaQuery();
    const containerRef = useRef<HTMLElement>();
    const callApi = useApi();

    const countryMap = getCountryMap();

    const showSuccessMessage = () => {
        setModalContentProps({ closable: false });
        setModalContent(
            <AlertModalContent
                type="success"
                goBackText={translate('go-back')}
                header={translate('club_creation-success_modal_title')}
                onGoBack={() => setModalContent(null)}
            />,
        );
    };

    const getCreateClubFormValues = (values: IRegisterForm) => {
        const formValues: IRegisterForm = { ...values };
        return {
            name: formValues.name,
            address: {
                street: formValues.street,
                number: formValues.number,
                city: formValues.city,
                zipCode: formValues.zipCode,
                country: formValues.country,
            },
        };
    };

    const createClub = async (values: IRegisterForm) => {
        const request = getCreateClubFormValues(values);
        await callApi(createClubWithoutAdmin(request)).then(() => {
            getUserInformationAndSetInStorage();
            updateFacilityCreated(request);

            showSuccessMessage();
        });
    };

    return (
        <div className="create-facility-card" ref={containerRef as any}>
            <h1 className="create-facility-card__title">
                {translate('facility_card-create_facility').toUpperCase()}
            </h1>
            <Form
                requiredMark="optional"
                layout={isMobile ? 'horizontal' : 'vertical'}
                name="basic"
                onFinish={createClub}
                className="create-facility-card__form"
            >
                <Form.Item
                    label={(
                        <label className="create-facility-card__form-label">
                            {translate('register-club_name')}
                        </label>
                    )}
                    name="name"
                    rules={[{ required: true, message: translate('create_facility_card-club_name_required') }]}
                >
                    <Input name="name" className="create-facility-card__form-item" />
                </Form.Item>
                <h2 className="create-facility-card__subtitle">
                    {translate('address-card_title')}
                </h2>
                <div className="create-facility-card__items-container">
                    <Form.Item
                        label={(
                            <label className="create-facility-card__form-label">
                                {translate('address-card_street')}
                            </label>
                        )}
                        name="street"
                        rules={[{ required: true, message: translate('address-card_street-is-required') }]}
                    >
                        <Input className="create-facility-card__form-item" />
                    </Form.Item>
                    <Form.Item
                        label={(
                            <label className="create-facility-card__form-label">
                                {translate('address-card_number')}
                            </label>
                        )}
                        name="number"
                        rules={[{ required: true, message: translate('address-card_number-is-required') }]}
                    >
                        <Input className="create-facility-card__form-item" />
                    </Form.Item>
                </div>
                <div className="create-facility-card__items-container">
                    <Form.Item
                        label={(
                            <label className="create-facility-card__form-label">
                                {translate('address-card_zipCode')}
                            </label>
                        )}
                        name="zipCode"
                        rules={[{ required: true, message: translate('address-card_zipCode-is-required') }]}
                    >
                        <Input className="create-facility-card__form-item" />
                    </Form.Item>
                    <Form.Item
                        label={(
                            <label className="create-facility-card__form-label">
                                {translate('address-card_city')}
                            </label>
                        )}
                        name="city"
                        rules={[{ required: true, message: translate('address-card_city-is-required') }]}
                    >
                        <Input className="create-facility-card__form-item" />
                    </Form.Item>
                    <Form.Item
                        label={(
                            <label className="create-facility-card__form-label">
                                {translate('address-card_country')}
                            </label>
                        )}
                        name="country"
                        rules={[{ required: true, message: translate('address-card_country-is-required') }]}
                    >
                        <Select
                            size="large"
                            className=" create-facility-card__form-item create-facility-card__form-item-select"
                        >
                            {Object.entries(countryMap)
                                .map(([countryCode, countryName]) => (
                                    <Select.Option
                                        key={countryCode}
                                        value={countryCode}
                                    >
                                        {countryName}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </div>
                <div className="create-facility-card__buttons-container">
                    <Button
                        type="default"
                        className="create-facility-card__button create-facility-card__button--gray"
                        onClick={() => onCancel(false)}
                    >
                        {translate('courts-page_modal_cancel-button')}
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="create-facility-card__button"
                    >
                        {translate('facility_card-create_facility')}
                    </Button>
                </div>
            </Form>
        </div>
    );
};
