import { CLUBS } from 'config/endpoints';
import { postWrapper } from 'services/httpService/httpServiceWrapper';

import { IClub } from 'usecases/types/club';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { getFacilityCreationDataLayer } from 'services/gtm/gtmDatalayers';
import { getUserFromStorage } from 'services/functionalStorage';

export const createClubWithoutAdmin = async (club: any):
    Promise<{ club: IClub, errors: string[] }> => {
    const endpoint = CLUBS.MANY;
    const { data } = await postWrapper(
        endpoint,
        {
            club,
        },
    );

    pushGtmDatalayer(getFacilityCreationDataLayer(getUserFromStorage().id, data.club.id));
    return data.club;
};
