import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Card, Form, Input, Checkbox, Button, notification,
} from 'antd';
import { translate } from 'translations/translate';
import { customHistory } from 'routing/customHistory';

import { useAppContext } from 'context/AppContext';
import { loginAndStorageUserInfo } from 'services/loginAndStorageUserInfo';
import {
    DesktopBlurredBackground,
} from 'components/Reservations/DesktopBlurredBackground/DesktopBlurredBackground';
import { ROUTES } from 'routing/routes.enum';
import { WhiteCardHeader } from 'pages/common/WhiteCardHeader/WhiteCardHeader';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { getLoginUnsuccesfullDataLayer, IMPRINT_LINK_DATALAYER } from 'services/gtm/gtmDatalayers';
import { useApi } from 'hooks/useApi';
import { AccountNotVerified } from 'services/errors/AccountNotVerified';
import { resendVerificationEmail } from 'usecases/users/resendVerificationEmail';
import { isLoggedUserAdmin } from 'services/functionalStorage';
import { LinkWithQuery } from 'routing/LinkWithQuery';
import { getClubAndSetInStorage } from 'usecases/club/getClub';
import './LoginPage.scss';
import 'pages/common/WhiteCard/Whitecard.scss';
import 'pages/common/WhiteCard/WhiteCardForm.scss';
import { getAsyncLoginDataLayer } from 'services/gtm/getAsyncLoginDataLayer';

export const LoginPage = (): JSX.Element => {
    const { isSpinnerVisible } = useAppContext();
    const [email, setEmailValue] = useState<string>('');
    const [password, setPasswordValue] = useState<string>('');
    const [rememberMe, setRememberMeValue] = useState<boolean>(false);
    const callApi = useApi();
    const notificationKey = 'notificationError';

    const sendVerificationEmail = async (emailToRecover: string) => {
        await resendVerificationEmail(emailToRecover);
        notification.success({
            key: notificationKey,
            message: translate('login-page_resend-verification-email-success'),
        });
    };

    const login = async (): Promise<void> => {
        try {
            const destinationPath = await callApi(loginAndStorageUserInfo(email, password, rememberMe)
                .then(async loggedUser => {
                    const facilityIds: string[] = [];
                    loggedUser.memberships.forEach(membership => {
                        facilityIds.push(membership.clubId);
                    });

                    const loginDataLayer = getAsyncLoginDataLayer(loggedUser, facilityIds);
                    pushGtmDatalayer(loginDataLayer);

                    if (loggedUser.memberships.length === 1) {
                        await getClubAndSetInStorage(loggedUser.memberships[0].clubId);
                        return isLoggedUserAdmin() ? ROUTES.ADMIN.DASHBOARD : ROUTES.PLAYER.DASHBOARD;
                    }
                    return ROUTES.MULTICLUB.MY_FACILITIES;
                }), false);

            customHistory.push(destinationPath);
        } catch (error: any) {
            let btn = null;
            if (error instanceof AccountNotVerified) {
                btn = (
                    <Button type="primary" onClick={() => sendVerificationEmail(email)}>
                        {translate('login-page_resend-verification-email')}
                    </Button>
                );
            }

            notification.error({
                key: notificationKey,
                message: error.message,
                btn,
            });

            const loginDataLayer = getLoginUnsuccesfullDataLayer();
            pushGtmDatalayer(loginDataLayer);
        }
    };

    return (
        <DesktopBlurredBackground>
            <Card className="whitecard">
                <WhiteCardHeader
                    pageTitleKey="login-page_title"
                    renderLegendText={() => (
                        <>
                            {translate('or')}
                            {' '}
                            <Link
                                to={ROUTES.REGISTER}
                                className="whitecard-header__top-link"
                            >
                                {translate('login-page_registration-link')}
                            </Link>
                        </>
                    )}
                />
                <Form onFinish={login}>
                    <div className="login-page__form-item">
                        <label className="whitecard-form__label" htmlFor="email">
                            {translate('e-mail')}
                        </label>
                        <Input
                            className="whitecard-form__custom-input"
                            id="email"
                            type="email"
                            value={email}
                            required
                            onChange={event => {
                                setEmailValue(event.target.value);
                            }}
                        />
                    </div>
                    <div className="login-page__form-item">
                        <label className="whitecard-form__label" htmlFor="password">
                            {translate('password')}
                        </label>
                        <Input
                            className="whitecard-form__custom-input"
                            id="password"
                            type="password"
                            value={password}
                            required
                            onChange={event => {
                                setPasswordValue(event.target.value);
                            }}
                        />
                    </div>
                    <div className="login-page__card-footer">
                        <Checkbox
                            className="login-page__checkbox"
                            value={rememberMe}
                            onChange={event => {
                                setRememberMeValue(event.target.checked);
                            }}
                        >
                            {translate('remember-me')}
                        </Checkbox>
                        <Link
                            className="
                                login-page__bottom-link
                            "
                            to={ROUTES.PASSWORD_RECOVERY}
                        >
                            {translate('forgot-your-password')}
                        </Link>
                    </div>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-page__login-button"
                        disabled={isSpinnerVisible}
                    >
                        {translate('login-page_login-button-text')}
                    </Button>
                </Form>
                <div className="login-page__legal-agreements-links">
                    <LinkWithQuery
                        onClick={() => pushGtmDatalayer(IMPRINT_LINK_DATALAYER)}
                        target="_blank"
                        to={ROUTES.IMPRINT}
                    >
                        {translate('imprint')}
                    </LinkWithQuery>
                    <LinkWithQuery
                        target="_blank"
                        to={ROUTES.PRIVACY_POLICY}
                    >
                        {translate('login-page_privacy-policy')}

                    </LinkWithQuery>
                    <LinkWithQuery
                        target="_blank"
                        to={ROUTES.TERMS}
                    >
                        {translate('terms_and_conditions')}

                    </LinkWithQuery>
                </div>
            </Card>
        </DesktopBlurredBackground>
    );
};
