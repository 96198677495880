import { getClubFromStorage, setClubInStorage } from 'services/functionalStorage';
import { CLUBS, PLACEHOLDERS } from '../../config/endpoints';
import { translate } from '../../translations/translate';

const updateLogoInLocalStorage = (logoUpdated: string) => {
    const currentClub = getClubFromStorage();
    const modifiedClub = { ...currentClub, logo: logoUpdated };
    setClubInStorage(modifiedClub);
};

export const changeLogo = async (clubId: string, image: Blob): Promise<string> => {
    const url = CLUBS.CHANGE_LOGO.replace(PLACEHOLDERS.CLUB_ID, clubId);

    const formData = new FormData();
    formData.append('logo', image);

    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        method: 'POST',
        body: formData,
    });
    const responseJson = await response.json();
    if (response.status !== 200) {
        throw new Error(translate(`error-${responseJson.code}`, responseJson.data.errorValues));
    }
    const logoUpdated = `${responseJson.data.url}?${new Date().getTime()}`;

    updateLogoInLocalStorage(logoUpdated);

    return logoUpdated;
};
