import React from 'react';
import {
    Button, Card, Form, Input,
} from 'antd';
import { useAppContext } from 'context/AppContext';
import { IContactData } from 'types';
import { translate } from 'translations/translate';
import './ClubContactData.scss';

interface IProps {
    onFinish: (settelment: any) => void,
    initialValues: IContactData | null,
    disabled: boolean
}

const defaultInitialValues = {
    phoneNumber: '',
    contactEmail: '',
    homepage: '',
} as IContactData;

export const ClubContactData = ({ onFinish, initialValues, disabled }: IProps) => {
    const { isSpinnerVisible } = useAppContext();

    const intialFormValues = initialValues || defaultInitialValues;

    return (
        <Card className="club-contact-card">
            <h1 className="club-contact-card__title">{translate('club-contact-card_title')}</h1>

            <Form
                layout="vertical"
                name="basic"
                initialValues={intialFormValues}
                onFinish={onFinish}
            >
                <Form.Item
                    label={(
                        <label className="club-contact-card__form-label">
                            {translate('club-contact-card_phoneNumber')}
                        </label>
                    )}
                    name="phoneNumber"
                >
                    <Input disabled={disabled} className="club-contact-card__form-item" />
                </Form.Item>

                <Form.Item
                    label={(
                        <label className="club-contact-card__form-label">
                            {translate('club-contact-card_email')}
                        </label>
                    )}
                    name="contactEmail"
                >
                    <Input disabled={disabled} className="club-contact-card__form-item" />
                </Form.Item>

                <Form.Item
                    label={(
                        <label className="club-contact-card__form-label">
                            {translate('club-contact-card_homePage')}
                        </label>
                    )}
                    name="homepage"
                >
                    <Input disabled={disabled} className="club-contact-card__form-item" />
                </Form.Item>

                {!disabled && (
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="club-contact-card__button"
                        disabled={isSpinnerVisible}
                    >
                        {translate('club-contact-card_save-changes')}
                    </Button>
                )}
            </Form>
        </Card>
    );
};
