import React from 'react';
import { Modal, ModalProps } from 'antd';
import Icon from '@ant-design/icons';
import { CLOSE_ICON_GRAY } from 'config/constants';
import './GenericModal.scss';

interface IProps {
    initialProps?: ModalProps,
    modalContentProps?: ModalProps,
    children: JSX.Element | null,
}

export const GenericModal = ({
    initialProps,
    modalContentProps,
    children,
}: IProps): JSX.Element => {
    const defaultProps = {
        centered: true,
        footer: null,
        width: 'auto',
        className: 'generic-modal',
        getContainer: false as HTMLElement | (() => HTMLElement) | false,
        onCancel: () => {},
        keyboard: false,
        maskClosable: false,
        zIndex: 1001,
    };

    const visible = Boolean(children);
    const mergedProps = {
        ...defaultProps, ...initialProps, ...modalContentProps, visible,
    };

    const {
        closable, onCancel,
    } = mergedProps;

    if (visible) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'visible';
    }

    const MemoizedIcon = React.useCallback(() => (
        <img
            className="generic-modal__close-icon"
            src={CLOSE_ICON_GRAY}
            alt="close_icon"
        />
    ), []);

    return (
        <Modal
            closeIcon={closable && (
                <Icon
                    onClick={onCancel}
                    component={MemoizedIcon}
                />
            )}
            {...mergedProps}
        >
            {children}
        </Modal>
    );
};
