import React from 'react';
import { Divider } from 'antd';
import {
    DeleteOutlined,
} from '@ant-design/icons';

import styles from './Footer.module.scss';

interface IProps {
    onShowDeleteReservationModal: () => void;
}

export const Footer = ({
    onShowDeleteReservationModal,
}: IProps) => (
    <>
        <Divider />
        <div className={`${styles.footer__container}`}>
            <DeleteOutlined
                className={styles['footer__trash-icon']}
                onClick={() => onShowDeleteReservationModal()}
            />
        </div>
    </>
);
