export const sortAscendingByString = (current: string, next: string): number => {
    const currentNumbers = current.match(/\d/g)?.join('');
    const nextNumbers = next.match(/\d/g)?.join('');
    if (currentNumbers && nextNumbers) {
        if (+currentNumbers > +nextNumbers) { return 1; }
        if ((+currentNumbers < +nextNumbers)) { return -1; }
        return (+currentNumbers - +nextNumbers);
    }
    if (current.toLowerCase() > next.toLowerCase()) { return 1; }
    if ((current.toLowerCase() < next.toLowerCase())) { return -1; }
    return 0;
};
