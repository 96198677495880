import { ISeason } from 'usecases/types/season';
import { BkgPriceEvent, CalendarViewEnum } from 'types';
import { IPricingRule } from 'usecases/types/price';
import { ICourt } from 'usecases/types/court';
import { CHDateTime } from 'helpers/DateTime/CHDateTime';
import {
    getLastDesiredDayOfWeekFromDate,
} from 'components/Reservations/ReservationsCalendar/getLastDesiredDayOfWeekFromDate';
import { mapPricingRulesToBkgEvents } from './mapPricingRulesToBkgEvents';

export const getMappedPricingRules = (
    bookableSeasons: ISeason[],
    pricingRules: IPricingRule[],
    displayedCourt: ICourt | null,
    displayedDate: CHDateTime,
    selectedView: CalendarViewEnum,
): BkgPriceEvent[] => {
    if (!displayedCourt) {
        return [];
    }

    let date = displayedDate;

    if (selectedView === CalendarViewEnum.WEEKVIEW) {
        const newDate = getLastDesiredDayOfWeekFromDate(new Date().getDay(), date);
        date = newDate.toUtc();
    }

    return bookableSeasons
        .reduce(
            (seasonPricingRulesBkgEventList: BkgPriceEvent[], bookableSeason: ISeason) => {
                const seasonPricingRulesBkgEvents = mapPricingRulesToBkgEvents(
                    pricingRules,
                    bookableSeason,
                    selectedView,
                    displayedCourt,
                    date,
                );
                return [...seasonPricingRulesBkgEventList, ...seasonPricingRulesBkgEvents];
            },
            [],
        );
};
