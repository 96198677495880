import { getUserMembership } from 'helpers/getUserMembership';
import { isUserAdmin } from 'helpers/isUserAdmin';
import { isUserOwner } from 'helpers/isUserOwner';
import { PlayerRole, IUser } from 'types';
import { IClub } from 'usecases/types/club';

export const isUserAuthenticated = () => JSON.parse(localStorage.getItem('user') || 'null') !== null;
export const getUserFromStorage = (): IUser => JSON.parse(localStorage.getItem('user') || 'null') || {};
export const getClubFromStorage = (): IClub => JSON.parse(localStorage.getItem('club') || 'null');
export const getAntdLocaleFromStorage = (): string => JSON.parse(localStorage.getItem('locale') || 'null');
export const setUserInStorage = (user: IUser) => localStorage.setItem('user', JSON.stringify(user));
export const setClubInStorage = (club: IClub) => localStorage.setItem('club', JSON.stringify(club));
export const setAntdLocaleInStorage = (locale: string) => localStorage.setItem('locale', JSON.stringify(locale));

export const clearStorage = (): void => {
    localStorage.clear();
};

export const isLoggedUserAdmin = (): boolean => {
    const userFromStorage = getUserFromStorage();
    const facilityFromStorage = getClubFromStorage();

    if (!facilityFromStorage || !userFromStorage.id) {
        return false;
    }

    return isUserAdmin(userFromStorage, facilityFromStorage);
};

export const isLoggedUserOwner = (): boolean => {
    const userFromStorage = getUserFromStorage();
    const facilityFromStorage = getClubFromStorage();

    if (!facilityFromStorage || !userFromStorage.id) {
        return false;
    }

    return isUserOwner(userFromStorage, facilityFromStorage);
};

export const getCurrentUserRole = ():PlayerRole => {
    const userMembership = getUserMembership(getUserFromStorage(), getClubFromStorage()!);

    return userMembership!.role;
};
