import { ImprintInfo } from 'pages/common/ImprintInfo/ImprintInfo';
import { LoginPage } from 'pages/common/Login/LoginPage';
import { EmailVerification } from 'pages/common/EmailVerification/EmailVerification';
import { EmailVerify } from 'pages/common/EmailVerification/EmailVerify';
import { PasswordRecoveryPage } from 'pages/common/PasswordRecovery/PasswordRecoveryPage';
import { ChangeForgottenPassword } from 'pages/common/ChangeForgottenPassword/ChangeForgottenPassword';
import { AdminRegisterPage } from 'pages/admin/AdminRegisterPage/AdminRegisterPage';
import { TermsPage } from 'pages/common/Terms/TermsPage';
import UserRegisterPage from 'pages/user/UserRegister/UserRegisterPage';
import { PublicCalendar } from 'pages/common/PublicCalendar/PublicCalendar';
import { IRoute } from 'types';
import { PrivacyPolicyPage } from 'pages/common/Terms/PrivacyPolicyPage';
import { RegisterPage } from 'pages/common/Register/RegisterPage';
import { ROUTES } from './routes.enum';

export const publicRoutes: IRoute[] = [
    {
        path: ROUTES.LOGIN,
        component: LoginPage,
    },
    {
        path: ROUTES.REGISTER,
        component: RegisterPage,
    },
    {
        path: ROUTES.EMAIL_VERIFICATION,
        component: EmailVerification,
    },
    {
        path: ROUTES.EMAIL_VERIFY,
        component: EmailVerify,
    },
    {
        path: ROUTES.PASSWORD_RECOVERY,
        component: PasswordRecoveryPage,
    },
    {
        path: ROUTES.CHANGE_FORGOTTEN_PASSWORD,
        component: ChangeForgottenPassword,
    },
    {
        path: ROUTES.IMPRINT,
        component: ImprintInfo,
    },
    {
        path: ROUTES.IMPRESSUM,
        component: ImprintInfo,
    },
    {
        path: ROUTES.ADMIN.REGISTER,
        component: AdminRegisterPage,
    },
    {
        path: ROUTES.TERMS,
        component: TermsPage,
    },
    {
        path: ROUTES.PRIVACY_POLICY,
        component: PrivacyPolicyPage,
    },
    {
        path: ROUTES.PLAYER.REGISTER,
        component: UserRegisterPage,
    },
    {
        path: ROUTES.TERMS,
        component: TermsPage,
    },
    {
        path: ROUTES.PUBLIC_CALENDAR,
        component: PublicCalendar,
    },
];
