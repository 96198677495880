import { IPricingRule } from 'usecases/types/price';
import { deleteWrapper } from 'services/httpService/httpServiceWrapper';
import { PLACEHOLDERS, PRICING_RULES } from 'config/endpoints';
import { IClub } from 'usecases/types/club';

export const deletePricingRule = (pricingRule: IPricingRule, club: IClub) => {
    const endpoint = PRICING_RULES.SINGLE
        .replace(PLACEHOLDERS.CLUB_ID, club.id)
        .replace(PLACEHOLDERS.SEASON_ID, pricingRule.season.id)
        .replace(PLACEHOLDERS.PRICING_RULE_ID, pricingRule.id);
    return deleteWrapper(endpoint);
};
