import React from 'react';
import { Form, Input, Button } from 'antd';
import { getSaveGuestPlayerDataLayer } from 'services/gtm/gtmDatalayers';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { translate } from 'translations/translate';
import { IGuest } from 'types';
import { isGuestRepeated } from './isGuestRepeated';
import './AddGuestModalContent.scss';

interface IProps{
    addGuest: (formValues: IGuest) => void;
    onError: () => void;
    guests: IGuest[];
    loggedUserId: string;
    facilityId: string;
}
export const AddGuestModalContent = ({
    addGuest,
    onError,
    guests,
    loggedUserId,
    facilityId,
}: IProps) => {
    const [form] = Form.useForm();

    const onFinish = (formValues : IGuest) => {
        if (isGuestRepeated(guests, formValues)) {
            onError();
            form.resetFields();
            return;
        }
        addGuest(formValues);
        form.resetFields();
        pushGtmDatalayer(getSaveGuestPlayerDataLayer(
            loggedUserId,
            facilityId,
        ));
    };

    return (
        <div className="add-guest-modal-content__modal-container">
            <p className="add-guest-modal-content__card-subtitle add-guest-modal-content__card-subtitle--large">
                {translate('calendar-reservation_dialog-add_guest_modal_title')}
            </p>
            <Form
                form={form}
                requiredMark="optional"
                onFinish={onFinish}
                name="form"
                layout="vertical"
            >
                <div className="add-guest-modal-content__grid-container">

                    <Form.Item
                        name="name"
                        label={(
                            <label htmlFor="name" className="add-guest-modal-content__label">
                                {translate('first-name')}
                            </label>
                        )}
                        rules={[{ required: true, message: translate('first-name-is-required') }]}
                    >
                        <Input className="add-guest-modal-content__custom-input" />
                    </Form.Item>
                    <Form.Item
                        name="surname"
                        label={(
                            <label htmlFor="surname" className="add-guest-modal-content__label">
                                {translate('last-name')}
                            </label>
                        )}
                        rules={[{ required: true, message: translate('last-name-is-required') }]}
                    >
                        <Input className="add-guest-modal-content__custom-input" />
                    </Form.Item>

                </div>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="add-guest-modal-content__profile-button
                    add-guest-modal-content__profile-button--width-100"
                >
                    {translate('calendar-reservation_dialog-add_guest_modal_button')}
                </Button>
            </Form>

        </div>
    );
};
