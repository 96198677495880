import React, {
    useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import {
    Button, Checkbox, Form, Input, InputNumber, Select, TimePicker,
} from 'antd';

import { useAppContext } from 'context/AppContext';
import { translate } from 'translations/translate';
import { IPricingRule } from 'usecases/types/price';
import { ICourt } from 'usecases/types/court';
import {
    IPriceForm,
    PlayerRole, PLAYERROLECLIENT, PLAYERROLEMEMBER,
} from 'types';
import moment from 'moment';
import { FormCheckboxGroup } from 'components/FormCheckboxGroup/FormCheckboxGroup';
import { getDecimalSeparatorFromLocale } from 'components/PricingRuleModalContent/getDecimalSeparatorFromLocale';
import { getTimeRuleValidator } from 'adapters/getTimeRuleValidator';
import { useForm } from 'antd/lib/form/Form';
import {
    PricingRuleOverlappingErrorMessage,
} from 'components/PricingRuleModalContent/PricingRuleOverlappingErrorMessage';
import { onGuesFeeChexboxChange } from './onGuestFeeChexboxChange';
import { onRoleChange } from './onRoleChange';
import './PricingRuleModalContent.scss';

interface IProps {
    onCancel: Function;
    onProceed: Function;
    pricingRule?: IPricingRule
    seasonName?: string;
    courts: ICourt[];
}

interface OverlappingError {
    id: string;
    name: string;
    season: string;
}

const PricingRuleModalContentRefless = ({
    onCancel,
    onProceed,
    pricingRule,
    seasonName,
    courts,
}: IProps, ref: React.Ref<{ [key: string]: Function }>) => {
    const [error, setError] = useState<OverlappingError | null>();
    useImperativeHandle(
        ref,
        () => ({ setError }),
    );
    const { isSpinnerVisible, antdLocaleObject } = useAppContext();
    const { locale: antdLocale } = antdLocaleObject;
    const containerRef = useRef<HTMLElement>();
    const [guestFeeVisibility, setGuestFeeVisibility] = useState<boolean>(false);
    const [guestFeeCheckboxVisibility, setGuestFeeCheckboxVisibility] = useState<boolean>(false);
    const [form] = useForm<IPriceForm>();

    const days = moment.weekdays(true).map(day => moment().isoWeekday(day).isoWeekday());

    const getInitialFormValues = (_pricingRule: IPricingRule | undefined): IPriceForm => {
        if (_pricingRule) {
            const selectedCourtIds = courts.filter(court => _pricingRule.courts.includes(court.id))
                .map(court => court.id);
            const selectedWeekdays = _pricingRule.weekdays;
            const startTime = moment(_pricingRule.startTime, 'HH:mmZ');
            const endTime = moment(_pricingRule.endTime, 'HH:mmZ');
            const values = {
                name: _pricingRule.name,
                days: selectedWeekdays,
                courtIds: selectedCourtIds,
                startTime,
                endTime,
                role: +_pricingRule.role as PlayerRole,
                amount: _pricingRule.amount,
                season: seasonName,
                guestFeeCheckbox: !!_pricingRule.guestFee,
                guestFee: _pricingRule.guestFee,
            };
            return values;
        }
        return {
            name: '',
            days: [],
            courtIds: [],
            startTime: '',
            endTime: '',
            role: undefined,
            amount: 0,
            season: seasonName,
            guestFeeCheckbox: false,
            guestFee: undefined,
        };
    };

    useEffect(() => {
        setGuestFeeVisibility(form.getFieldValue('guestFeeCheckbox'));
        if (form.getFieldValue('role') === PLAYERROLEMEMBER) {
            setGuestFeeCheckboxVisibility(true);
        }
    }, []);

    return (
        <div className="pricing-rule-modal__modal-container" ref={containerRef as any}>
            <h1 className="pricing-rule-modal__title">
                {pricingRule
                    ? translate('prices-page_modal_edit-title')
                    : translate('prices-page_modal_create-title')}
            </h1>
            <Form
                form={form}
                requiredMark="optional"
                layout="vertical"
                name="basic"
                initialValues={getInitialFormValues(pricingRule)}
                onFinish={values => onProceed(values)}
                onFieldsChange={() => setError(null)}
            >
                <Form.Item
                    label={(
                        <label className="pricing-rule-modal__form-label">
                            {translate('prices-page_modal_season-label')}
                        </label>
                    )}
                    name="season"
                    required
                >
                    <Input
                        disabled
                        className="pricing-rule-modal__form-item"
                    />
                </Form.Item>
                <Form.Item
                    label={(
                        <label className="pricing-rule-modal__form-label">
                            {translate('prices-page_modal_name-label')}
                        </label>
                    )}
                    name="name"
                    rules={[{
                        required: true,
                        message: translate('prices-page_modal_name-required'),
                    }]}
                >
                    <Input
                        className="pricing-rule-modal__form-item"
                    />
                </Form.Item>
                <FormCheckboxGroup
                    required
                    formItem={{
                        name: 'days',
                        labelText: translate('prices-page_modal_days-label'),
                        errorText: translate('prices-page_modal_days-required'),
                    }}
                    checkboxes={days.map(dayNumber => (
                        { value: dayNumber, name: moment.weekdays(dayNumber) }
                    ))}
                />
                <FormCheckboxGroup
                    required
                    formItem={{
                        name: 'courtIds',
                        labelText: translate('prices-page_modal_courts-label'),
                        errorText: translate('prices-page_modal_courts-required'),
                    }}
                    checkboxes={courts.map(court => ({ value: court.id, name: court.name }))}
                />
                <div className="pricing-rule-modal__time-container">
                    <Form.Item
                        label={(
                            <label className="pricing-rule-modal__form-label">
                                {translate('prices-page_modal_starting-label')}
                            </label>
                        )}
                        name="startTime"
                        rules={[{
                            required: true,
                            message: translate('prices-page_modal_starting-required'),
                        },
                        getTimeRuleValidator(
                            'endTime',
                            false,
                            'prices-page_modal_starting-before-ending',
                        ),
                        ]}
                    >
                        <TimePicker
                            name="startTime"
                            className="pricing-rule-modal__time-picker"
                            minuteStep={30}
                            showSecond={false}
                            format="HH:mm"
                            getPopupContainer={() => containerRef.current as HTMLElement}
                        />
                    </Form.Item>
                    <hr className="pricing-rule-modal__time-divider" />
                    <Form.Item
                        label={(
                            <label className="pricing-rule-modal__form-label">
                                {translate('prices-page_modal_ending-label')}
                            </label>
                        )}
                        name="endTime"
                        rules={[{
                            required: true,
                            message: translate('prices-page_modal_ending-required'),
                        },
                        getTimeRuleValidator(
                            'startTime',
                            true,
                            'prices-page_modal_ending-before-starting',
                        ),
                        ]}
                    >
                        <TimePicker
                            name="endTime"
                            className="pricing-rule-modal__time-picker"
                            minuteStep={30}
                            showSecond={false}
                            format="HH:mm"
                            getPopupContainer={() => containerRef.current as HTMLElement}
                        />
                    </Form.Item>
                </div>
                <div className="pricing-rule-modal__form-grid">
                    <Form.Item
                        name="role"
                        label={(
                            <label htmlFor="role" className="pricing-rule-modal__form-label">
                                {translate('prices-page_modal_role-label')}
                            </label>
                        )}
                        rules={[{ required: true, message: translate('role-is-required') }]}
                    >
                        <Select
                            size="large"
                            className="pricing-rule-modal__form-item-select"
                            getPopupContainer={() => containerRef.current as HTMLElement}
                            onChange={value => onRoleChange(
                                value,
                                form,
                                setGuestFeeCheckboxVisibility,
                                setGuestFeeVisibility,
                            )}
                        >
                            <Select.Option value={PLAYERROLEMEMBER}>
                                {translate(`role_${PLAYERROLEMEMBER}`)}
                            </Select.Option>
                            <Select.Option value={PLAYERROLECLIENT}>
                                {translate(`role_${PLAYERROLECLIENT}`)}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={(
                            <label className="pricing-rule-modal__form-label">
                                {translate('prices-page_modal_price-label')}
                            </label>
                        )}
                        name="amount"
                        rules={[{
                            required: true,
                            message: translate('prices-page_modal_price-required'),
                        }]}
                    >
                        <InputNumber
                            size="large"
                            min={0}
                            decimalSeparator={getDecimalSeparatorFromLocale(antdLocale)}
                            className="pricing-rule-modal__form-item"
                        />
                    </Form.Item>
                </div>
                {guestFeeCheckboxVisibility && (
                    <div className="pricing-rule-modal__form-grid">
                        <Form.Item
                            name="guestFeeCheckbox"
                            rules={[{
                                transform: value => (value || undefined),
                                type: 'boolean',

                            }]}
                            valuePropName="checked"
                        >
                            <Checkbox
                                onChange={e => onGuesFeeChexboxChange(
                                    e.target.checked,
                                    form,
                                    setGuestFeeVisibility,
                                )}
                            >
                                <p className="pricing-rule-modal__form-label">
                                    {translate('prices-page_modal_guest-fee-visibility-label')}
                                </p>
                            </Checkbox>
                        </Form.Item>
                        {guestFeeVisibility && (
                            <Form.Item
                                label={(
                                    <label className="pricing-rule-modal__form-label">
                                        {translate('prices-page_modal_guest-fee-label')}
                                    </label>
                                )}
                                name="guestFee"
                                rules={[{
                                    required: true,
                                    message: translate('prices-page_modal_guest-fee-required'),
                                }]}
                            >
                                <InputNumber
                                    size="large"
                                    min={0}
                                    decimalSeparator={getDecimalSeparatorFromLocale(antdLocale)}
                                    className="pricing-rule-modal__form-item"
                                />
                            </Form.Item>
                        )}
                    </div>
                )}

                <div className="pricing-rule-modal__overlapping-error">
                    {error && (
                        <PricingRuleOverlappingErrorMessage id={error.id} name={error.name} season={error.season} />
                    )}
                </div>

                <div className="pricing-rule-modal__buttons-container">
                    <Button
                        type="default"
                        className="pricing-rule-modal__button pricing-rule-modal__button--gray"
                        onClick={() => onCancel(false)}
                    >
                        {translate('prices-page_modal_cancel-button')}
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="pricing-rule-modal__button"
                        disabled={isSpinnerVisible}
                    >
                        {(pricingRule && pricingRule.id)
                            ? translate('prices-page_modal_edit-button')
                            : translate('prices-page_modal_create-button')}
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export const PricingRuleModalContent = React.forwardRef(PricingRuleModalContentRefless);
