import React from 'react';
import { LOGO_BLACK } from 'config/constants';
import styles from './BlurredBackground.module.scss';

interface IProps {
    children: React.ReactNode
  }

export const BlurredBackground = ({ children }: IProps): JSX.Element => (
    <div className={styles.background}>
        <div className={styles.logo}>
            <img src={LOGO_BLACK} alt="Court Hero Logo" />
        </div>
        {children}
    </div>
);
