import { Rule } from 'antd/lib/form';
import { NamePath } from 'antd/lib/form/interface';
import moment, { Moment } from 'moment';
import { translate } from 'translations/translate';

export const getTimeRuleValidator = (
    fieldName: string,
    fieldNameBeforeValue: boolean,
    messageKey: string,
) => ({ getFieldValue }: { getFieldValue: (name: NamePath) => any}) => ({
    validator(_: Rule, value: Moment) {
        const fieldValue = getFieldValue(fieldName);

        if (!fieldValue) {
            return Promise.resolve();
        }

        const preparedFieldValue = moment(fieldValue.local().format('HH:mm'), 'HH:mm');
        const preparedValue = moment(value.local().format('HH:mm'), 'HH:mm');

        const validationCheck = fieldNameBeforeValue ? preparedFieldValue.isBefore(preparedValue)
            : preparedFieldValue.isAfter(preparedValue);

        if (!preparedFieldValue.isValid() || validationCheck) {
            return Promise.resolve();
        }

        return Promise.reject(new Error(translate(messageKey)));
    },
});
