import {
    Button, Card, Form,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { translate } from 'translations/translate';
import './BookingExtraInformation.scss';

export interface IBookingExtraInformation {
    bookingExtraInformation: string | null,
}
interface IProps {
    bookingExtraInformation?: string,
    onFinish: (bookingExtraInformation: any) => void,
}

export const BookingExtraInformation = ({ bookingExtraInformation, onFinish }: IProps) => (
    <Card className="booking-extra-information-card">
        <h1 className="booking-extra-information-card__title">{translate('booking-extra-information-card_title')}</h1>

        <p className="booking-extra-information-card__text">
            {translate('booking-extra-information-card_description-label')}
        </p>
        <Form
            layout="vertical"
            name="basic"
            initialValues={{
                bookingExtraInformation,
            }}
            onFinish={onFinish}
        >
            <Form.Item
                name="bookingExtraInformation"
            >
                <TextArea
                    className="booking-extra-information-card__description"
                    data-testid="booking-extra-information-card_description"
                    placeholder={translate('booking-extra-information-card_description-placeholder')}
                />
            </Form.Item>

            <Button
                type="primary"
                htmlType="submit"
                className="booking-extra-information-card__button"
            >
                {translate('booking-extra-information-card_save-changes')}
            </Button>
        </Form>
    </Card>
);
