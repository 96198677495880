/* eslint-disable max-len */
import {
    BkgPriceEvent, CalendarViewEnum, PLAYERROLECLIENT, PLAYERROLEMEMBER,
} from 'types';

export const buildEmptyBkgPricingEvents = (
    bkgPriceEvents: BkgPriceEvent[],
    calendarView: CalendarViewEnum,
): BkgPriceEvent[] => {
    const allEvents = bkgPriceEvents
        .reduce((acc: BkgPriceEvent[], current: BkgPriceEvent) => {
            const oppositePriceEvents = bkgPriceEvents.filter(bkgPriceEvent => (
                bkgPriceEvent.start === current.start
                && bkgPriceEvent.end === current.end
                && bkgPriceEvent.resourceIds.some(resourceId => current.resourceIds.includes(resourceId)
                && bkgPriceEvent.role !== current.role)
            ));
            const emptyEvent = {
                start: current.start,
                end: current.end,
                display: 'background',
                color: 'transparent',
                resourceIds: current.resourceIds,
                role: current.role === PLAYERROLEMEMBER ? PLAYERROLECLIENT : PLAYERROLEMEMBER,
                isAdmin: current.isAdmin,
            } as BkgPriceEvent;
            if (oppositePriceEvents.length > 0) {
                if (calendarView === CalendarViewEnum.DAYVIEW) {
                    const emptyOppositePriceEvents = oppositePriceEvents
                        .filter(priceEvent => priceEvent.resourceIds
                            .some(resource => !current.resourceIds.includes(resource)))
                        .map(priceEvent => {
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            const { slotPrice, ...oppositeEmptyEvent } = emptyEvent;
                            return {
                                ...oppositeEmptyEvent,
                                role: priceEvent.role === PLAYERROLEMEMBER ? PLAYERROLECLIENT : PLAYERROLEMEMBER,
                                resourceIds: priceEvent.resourceIds.filter(resource => !current.resourceIds.includes(resource)),
                            } as BkgPriceEvent;
                        });
                    return [...acc, current, ...emptyOppositePriceEvents];
                }
                return [...acc, current];
            }
            return [
                ...acc,
                current,
                emptyEvent,
            ];
        }, []) as BkgPriceEvent[];
    return allEvents;
};
