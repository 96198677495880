import { FormInstance } from 'antd';
import { PlayerRole, PLAYERROLEMEMBER } from 'types';

export const onGuesFeeChexboxChange = (
    checkboxValue: boolean,
    form: FormInstance,
    setGuestFeeVisibility: Function,
): void => {
    const role = form.getFieldValue('role') as PlayerRole;
    if (role === PLAYERROLEMEMBER) {
        setGuestFeeVisibility(checkboxValue);
        return;
    }
    setGuestFeeVisibility(false);
};
