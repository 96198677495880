export const enableReservationsCalendarScrollbar = (): void => {
    const scrollPosition = 0;
    const body = document.querySelector('body') as HTMLElement;
    const calendar = document.getElementsByClassName('calendar-container')[0] as HTMLElement;
    const disableBodyScrollingClass = 'body--scroll-disabled';
    const disableCalendarScrollingClass = 'reservations-calendar--calendar-scroll-disable';

    body.classList.remove(disableBodyScrollingClass);
    calendar.classList.remove(disableCalendarScrollingClass);
    body.style.removeProperty('top');

    window.scrollTo(0, scrollPosition);
};
