import React from 'react';
import { Button, DatePicker } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment/moment';
import { useAppContext } from 'context/AppContext';
import { CHDateTime } from 'helpers/DateTime/CHDateTime';
import { formatWeekDate } from './formatWeekDate';
import { handleWeekClick } from './handleWeekClick';
import './TopRowFilters.scss';

interface IProps {
    dayDate: CHDateTime,
    onWeekChange: (date: CHDateTime) => void,
}

export const WeekSelector = ({ dayDate, onWeekChange }: IProps) => {
    const { antdLocaleObject } = useAppContext();
    const { locale } = antdLocaleObject;

    return (
        <div className="filter-selector">
            <Button
                icon={<LeftOutlined className="icon-bold" />}
                className="filter-selector__button filter-selector__button--margin-right"
                onClick={() => handleWeekClick(dayDate, -1, onWeekChange)}
            />
            <div className="filter-selector__filter-container">
                <DatePicker
                    picker="week"
                    bordered={false}
                    defaultValue={moment(dayDate.toNativeDate())}
                    format={date => formatWeekDate(date, locale)}
                    value={moment(dayDate.toNativeDate())}
                    onChange={_value => onWeekChange(CHDateTime.fromNativeDate(_value!.toDate()))}
                    className="filter-selector__date-picker-input"
                    dropdownClassName="filter-selector__date-picker-dropdown"
                    suffixIcon={false}
                    allowClear={false}
                />
            </div>
            <Button
                icon={<RightOutlined className="icon-bold" />}
                className="filter-selector__button filter-selector__button--margin-left"
                onClick={() => handleWeekClick(dayDate, +1, onWeekChange)}
            />
        </div>
    );
};
