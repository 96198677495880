import { IClub } from 'usecases/types/club';
import { CLUBS, PLACEHOLDERS } from 'config/endpoints';
import { getWrapper } from 'services/httpService/httpServiceWrapper';

interface Response {
    link: string;
}

export const getPaymentProviderOnboardingLink = async (club: IClub): Promise<Response> => {
    const url = CLUBS.PAYMENT_PROVIDER_ONBOARDING_LINK.replace(PLACEHOLDERS.CLUB_ID, club.id);
    const { data } = await getWrapper(url);
    return data;
};
