import { PLACEHOLDERS, INVOICES } from 'config/endpoints';
import { putWrapper } from 'services/httpService/httpServiceWrapper';
import { Invoice } from 'types';
import { adaptInvoiceResponse } from './adaptInvoiceResponse';

export const cancelInvoices = async ({
    invoiceIds,
    clubId,
}:{
    invoiceIds: string[],
    clubId: string
}): Promise<{invoices: Invoice[], errors: string[]}> => {
    const url = INVOICES.LIST.replace(PLACEHOLDERS.CLUB_ID, clubId);

    const payload = {
        invoiceIds,
        status: 'CANCELLED',
    };

    const { data } = await putWrapper(url, payload);

    return {
        invoices: adaptInvoiceResponse(data.invoices),
        errors: data.errors,
    };
};
