import React from 'react';
import { LayoutPage } from 'pages/common/Layout/LayoutPage';
import { ROUTES } from 'routing/routes.enum';
import { customHistory } from 'routing/customHistory';
import { adminRoutes } from 'routing/adminRoutes';
import { Location } from 'history';
import { NotFoundPage } from 'pages/common/NotFound/NotFoundPage';
import { IRoute } from 'types';
import { AdminLayoutMenu } from './AdminLayoutMenu';
import { AdminNotice } from './AdminNotice';

export const AdminLayoutPage = ({ guard, location }:
    { guard: () => boolean, location: Location }): JSX.Element => {
    if (!guard()) {
        customHistory.push(ROUTES.LOGIN);
        return <></>;
    }

    const isAdminPath = adminRoutes.some((route: IRoute) => route.path === location.pathname);
    if (!isAdminPath) {
        return <NotFoundPage />;
    }

    return (
        <LayoutPage
            routes={adminRoutes}
            menu={<AdminLayoutMenu />}
            notice={<AdminNotice />}
        />
    );
};
