import { Switch } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'translations/translate';
import { changePaymentMethodsStatus } from 'usecases/club/changePaymentMethodsStatus';
import { getClubFromStorage, getUserFromStorage, setClubInStorage } from 'services/functionalStorage';
import { useApi } from 'hooks/useApi';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { getOnlinePaymentEnabledDataLayer } from 'services/gtm/gtmDatalayers';

export const OnlinePaymentMethodStepThreeFour = () => {
    const club = getClubFromStorage();
    const callApi = useApi();
    const [checked, setChecked] = useState<boolean>(club.onlinePaymentsEnabled);

    const onChange = (toggleValue: boolean) => {
        callApi(changePaymentMethodsStatus(club, { online: toggleValue })).then(() => {
            setClubInStorage({ ...getClubFromStorage(), onlinePaymentsEnabled: toggleValue });
            setChecked(toggleValue);

            if (toggleValue) {
                const user = getUserFromStorage();
                pushGtmDatalayer(getOnlinePaymentEnabledDataLayer(user.id, club.id));
            }
        });
    };

    return (
        <div className="payment-methods__container payment-methods__container--step-three">
            <p className="payment-methods__text">
                {checked ? translate('club_settings-payments_step_four')
                    : translate('club_settings-payments_step_three')}
            </p>

            <div className="payment-methods__status payment-methods__status--approved">
                {translate('club_settings-payments_approved')}
            </div>

            <div className="payment-methods__toggle-container">
                <Switch
                    className="payment-methods__toggle"
                    onChange={onChange}
                    checked={checked}
                />
                <p className="payment-methods__text">{translate('club_settings-payments_toggle')}</p>
            </div>

            <Link
                className="payment-methods__link"
                to={{ pathname: 'https://dashboard.stripe.com/login' }}
                target="_blank"
            >
                {translate('club_settings-payments_go_to_account')}
            </Link>

        </div>

    );
};
