import React from 'react';
import {
    Button, Card, Form, Input, notification,
} from 'antd';
import { translate } from 'translations/translate';
import { useAppContext } from 'context/AppContext';
import { passwordChange } from 'usecases/users/PasswordChange';
import './PasswordForm.scss';

export const PasswordForm = ({ onSuccessfulChange }: {onSuccessfulChange: Function}): JSX.Element => {
    const { isSpinnerVisible, setIsSpinnerVisible } = useAppContext();
    const [form] = Form.useForm();

    const onResetPassword = async (values: any) => {
        setIsSpinnerVisible(true);

        try {
            const { newPassword, oldPassword, newPasswordConfirmation } = values;
            await passwordChange.execute({
                oldPassword,
                newPassword,
                newPasswordConfirmation,
            });
            onSuccessfulChange();
            form.resetFields();
        } catch (error:any) {
            notification.error({
                message: translate('error'),
                description: error.message,
            });
            form.resetFields();
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    return (
        <Card className="password-form">
            <h1 className="password-form__title">{translate('profile-password-card_title')}</h1>
            <Form
                form={form}
                requiredMark="optional"
                layout="vertical"
                name="password"
                onFinish={onResetPassword}
            >
                <Form.Item
                    label={(
                        <label className="password-form__form-label">
                            {translate('current-password')}
                        </label>
                    )}
                    name="oldPassword"
                    rules={[{ required: true, message: translate('password-is-required') }]}
                >
                    <Input type="password" className="password-form__form-item" />
                </Form.Item>

                <Form.Item
                    label={(
                        <label className="password-form__form-label">
                            {translate('new-password')}
                        </label>
                    )}
                    name="newPassword"
                    rules={[
                        {
                            required: true,
                            message: translate('new-password-is-required'),
                        },
                    ]}
                >
                    <Input type="password" className="password-form__form-item" />
                </Form.Item>

                <Form.Item
                    label={(
                        <label className="password-form__form-label">
                            {translate('repeat-new-password')}
                        </label>
                    )}
                    name="newPasswordConfirmation"
                    rules={[
                        {
                            required: true,
                            message: translate('repeat-password-is-required'),
                        },
                    ]}
                >
                    <Input type="password" className="password-form__form-item" />
                </Form.Item>

                <Button
                    type="default"
                    htmlType="submit"
                    size="large"
                    className="password-form__delete-button"
                    disabled={isSpinnerVisible}
                >
                    {translate('reset-password')}
                </Button>
            </Form>
        </Card>
    );
};
