import { Address } from 'types';

export const getAddressFromParameters = (
    country?: string,
    city?: string,
    street?: string,
    number?: string,
    zipCode?: string,
): Address | null => {
    if (!country || !city || !street || !number || !zipCode) {
        return null;
    }
    return {
        country,
        city,
        street,
        number,
        zipCode,
    };
};
