/* eslint-disable react/no-unescaped-entities */
import { getProfileLanguage } from 'translations/getProfilelanguage';
import React from 'react';
import './SettlementExplanation.scss';

export const SettlementExplanation = () => {
    const profileLanguage = getProfileLanguage();
    const germanExplanation = () => (
        <>
            <h1 className="settlement-explanation__title">Abrechnung</h1>
            <div className="settlement-explanation__text-container">
                <p className="settlement-explanation__text">
                    Die Funktion „Abrechnung“ ermöglicht es dir Abrechnungen für Spieler durchzuführen,
                    welche ihre Buchungen nicht bereits online bezahlt haben.
                    Bitte rechne diese Buchungen immer rückwirkend ab
                    (z.B. am 01.02.2022 für den Zeitraum 01.01.2022 bis 31.01.2022).
                </p>
                <p className="settlement-explanation__text">
                    Um eine Liste von Spielern zur Abrechnung angezeigt zu bekommen,
                    musst du zuerst einen Zeitraum mit Start- und Enddatum auswählen.
                    Sobald du für beide Felder das Datum ausgewählt hast,
                    wird dir eine Liste von Spielern angezeigt.
                </p>
                <p className="settlement-explanation__text">
                    Die Liste enthält nun alle Spieler für den ausgewählten Zeitraum,
                    für welche du noch keine Abrechnung durchgeführt hast.
                    Du kannst die Liste zusätzlich noch nach der Rolle des Spielers filtern.
                </p>
                <p className="settlement-explanation__text">
                    Zudem kannst du in der angezeigten Liste auch nach einzelnen Spielern suchen.
                    Die Liste wird nur auf diesen Spieler reduziert,
                    falls der Spieler in der Liste enthalten sein sollte.
                </p>
                <p className="settlement-explanation__text">
                    Des Weiteren kannst du die Liste nach den Spalten Vorname,
                    Nachname und Gesamtbetrag auf- und absteigend sortieren.
                </p>
                <p className="settlement-explanation__text">
                    Auf Basis deiner Filter stehen dir für die Liste 2
                    verschiedene Abrechnungsmöglichkeiten zur Verfügung:
                </p>
                <p className="settlement-explanation__text">1) Abrechnung eines Spielers</p>
                <p className="settlement-explanation__text">
                    2) Abrechnung mehrerer oder aller Spieler
                </p>
                <p className="settlement-explanation__text">
                    Wenn du einen einzelnen Spieler abrechnen möchtest,
                    dann klickst du auf den grünen Button „Abrechnen“ am Ende der Zeile dieses Spielers.
                    Durch das Klicken auf den Button wird direkt eine Rechnung erstellt,
                    welche du anschließend unter dem Menüpunkt „Rechnungen“ findest.
                </p>
                <p className="settlement-explanation__text">
                    Wenn du mehrere oder alle Spieler aus der angezeigten Liste auf einmal abrechnen möchtest,
                    dann markiere diese durch Anklicken der einzelnen Checkboxen oder markiere die eine Checkbox
                    in der Tabellenüberschrift ganz links.
                    Klicke dann auf den grünen Button „Ausgewählte Spieler abrechnen“
                    oberhalb der Liste neben dem grauen Erklärungsbutton.
                    Hierdurch werden direkt alle Rechnungen erstellt.
                </p>
                <p className="settlement-explanation__text">
                    <b>Benötigst du weitere Hilfe? </b>
                    Dann schreibe eine E-Mail an
                    {' '}
                    <a
                        className="seasons-explanation__link"
                        href="mailto:courthero@tennis-point.de"
                    >
                        courthero@tennis-point.de
                    </a>
                </p>
            </div>
        </>
    );
    const englishExplanation = () => (
        <>
            <h1 className="settlement-explanation__title">Settlement</h1>
            <div className="settlement-explanation__text-container">
                <p className="settlement-explanation__text">
                    The "Settlement" function enables you to settle bookings for players who have
                    not already paid for their bookings online.
                    Please always settle these bookings retroactively
                    (e.g. on 01.02.2022 for the period 01.01.2022 to 31.01.2022).
                </p>
                <p className="settlement-explanation__text">
                    In order to have a list of players displayed for settlement,
                    you must first select a period with a start and end date.
                    As soon as you have selected the date for both fields, a list of players will be displayed.
                </p>
                <p className="settlement-explanation__text">
                    The list now contains all players for the selected
                    period for which you have not yet made a settlement.
                    However, you can filter the list according to the role of the player.
                </p>
                <p className="settlement-explanation__text">
                    You can also search for individual players in the list displayed.
                    The list will only show the player if the player is included in the list.
                </p>
                <p className="settlement-explanation__text">
                    Furthermore, you can sort the list in ascending and descending order according to the columns name,
                    last name and total amount.
                </p>
                <p className="settlement-explanation__text">
                    Based on your filters, you have 2 different settlement options for the list:
                </p>
                <p className="settlement-explanation__text">1) Settlement of a player</p>
                <p className="settlement-explanation__text">
                    2) Settlement of several or all players
                </p>
                <p className="settlement-explanation__text">
                    If you want to settle a single player,
                    click on the green button "Settle" at the end of the line of this player.
                    By clicking on the button, an invoice is created directly,
                    which you can then find under the menu item "Invoices".
                </p>
                <p className="settlement-explanation__text">
                    If you want to invoice several or all players from the displayed list at once,
                    then mark them by clicking on the individual checkboxes or mark the checkbox
                    in the table header on the left side.
                    Then click on the green button "Settle selected players"
                    above the list next to the grey explanation button.
                    This will directly create all invoices.
                </p>
                <p className="settlement-explanation__text">
                    <b>Need more help? </b>
                    Then send an e-mail to
                    {' '}
                    <a
                        className="seasons-explanation__link"
                        href="mailto:courthero@tennis-point.de"
                    >
                        courthero@tennis-point.de
                    </a>
                </p>
            </div>
        </>
    );
    return (
        <div className="club-members-explanation">
            {profileLanguage === 'english'
                ? englishExplanation()
                : germanExplanation()}
        </div>

    );
};
