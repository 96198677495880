import React from 'react';
import { IListTableRowField } from 'types';

export const adaptRowFields = (rowFields: IListTableRowField[] | string[] | null[]): IListTableRowField[] => rowFields
    .map((rowField: any) => {
        if (!rowField) {
            return { value: '', isLarge: false };
        }

        if (React.isValidElement(rowField)) {
            return { value: rowField, isLarge: false };
        }

        if (typeof rowField === 'string') {
            return { value: rowField, isLarge: false };
        }

        const addaptedIsLargeRowField = rowField.isLarge ? rowField : { ...rowField, isLarge: false };

        return addaptedIsLargeRowField;
    });
