import { ISeason } from 'usecases/types/season';
import { PLACEHOLDERS, CLUBS } from 'config/endpoints';
import { deleteWrapper } from 'services/httpService/httpServiceWrapper';

export const deleteSeason = (clubId: string, season: ISeason): Promise<any> => {
    const url = CLUBS.SEASON
        .replace(PLACEHOLDERS.CLUB_ID, clubId)
        .replace(PLACEHOLDERS.SEASON_ID, season.id);

    return deleteWrapper(url);
};
