import { DEFAULT_BOOOKING_DURATION } from 'config/constants';
import { isLoggedUserAdmin } from 'services/functionalStorage';
import { IClub } from 'usecases/types/club';
import { ICourt } from 'usecases/types/court';
import { IReservation } from 'usecases/types/reservations';
import { IUser } from 'types';
import { CHDateTime, TimeUnits } from 'helpers/DateTime/CHDateTime';
import { BookingType } from 'usecases/types/enums/booking_type.enum';
import { PaymentTypeEnum } from 'usecases/types/enums/payment_type.enum';

export const getInitialEvent = (
    utcDateSelected: CHDateTime,
    courtSelected: ICourt,
    loggedUser: IUser,
    club: IClub,
): IReservation => {
    const owner = isLoggedUserAdmin()
        ? '' as unknown as IUser
        : loggedUser;
    return ({
        id: '',
        owner,
        companions: [] as IUser[],
        guests: [],
        club,
        court: courtSelected,
        startTime: utcDateSelected.toString(),
        endTime: utcDateSelected.add(DEFAULT_BOOOKING_DURATION, TimeUnits.MINUTE).toString(),
        price: null,
        paymentType: PaymentTypeEnum.CASH,
        bookingType: BookingType.SINGLE,
    });
};
