import { PLACEHOLDERS, INVOICES } from 'config/endpoints';
import { postWrapper } from 'services/httpService/httpServiceWrapper';
import { ICreateInvoiceInput } from 'usecases/types/dto/dto';
import { Invoice } from 'types';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { getSettleBulkPlayersDataLayer, getSettlePlayerDataLayer } from 'services/gtm/gtmDatalayers';
import { getCurrentUserRole, getUserFromStorage } from 'services/functionalStorage';
import { getRoleName } from 'helpers/getRoleName';

export const createInvoice = async ({
    ownerIds, clubId, startDate, endDate,
}: ICreateInvoiceInput): Promise<{invoices: Invoice[], errors: string[]}> => {
    const url = INVOICES.LIST.replace(PLACEHOLDERS.CLUB_ID, clubId);

    const payload = {
        ownerIds,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
    };

    const { data } = await postWrapper(url, payload);

    const user = getUserFromStorage();
    const userRole = getCurrentUserRole();

    if (ownerIds.length === 1) {
        pushGtmDatalayer(getSettlePlayerDataLayer(
            user.id,
            clubId,
            data.invoices[0].bookings,
            data.invoices[0].amount,
            getRoleName(userRole),
        ));
    } else {
        let bookings: Array<string> = [];
        let amount = 0;
        data.invoices.forEach((invoice: Invoice) => {
            bookings = [...bookings, ...invoice.bookings];
            amount += invoice.amount;
        });
        pushGtmDatalayer(getSettleBulkPlayersDataLayer(
            user.id,
            clubId,
            bookings,
            amount,
            getRoleName(userRole),
        ));
    }

    return data;
};
