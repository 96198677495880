import { Invoice } from 'types';
import { INVOICES, PLACEHOLDERS } from 'config/endpoints';
import { getFileWrapper } from 'services/httpService/httpServiceWrapper';
import { getUserMembership } from 'helpers/getUserMembership';
import { getClubFromStorage } from 'services/functionalStorage';

export const getInvoicePdf = (invoice: Invoice): Promise<Blob> => {
    const url = INVOICES.SINGLE
        .replace(PLACEHOLDERS.CLUB_ID, getUserMembership(invoice.user, getClubFromStorage())!.clubId)
        .replace(PLACEHOLDERS.INVOICE_NUMBER, invoice.invoiceNumber);
    return getFileWrapper(url);
};
