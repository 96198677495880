import moment from 'moment';

export const buildStartAndEndDateTimesPerDate = (
    date: string,
    startTime: string,
    endTime: string,
): {startDateTime: string, endDateTime: string} => {
    const startDate = moment(date + startTime, 'YYYY-MM-DD HH:mmZ');
    const endDate = moment(date + endTime, 'YYYY-MM-DD HH:mmZ');

    /**
     * Due to timezones, depending on the hours selected, it could happen that the startTime is greater than the endTime
     * For example, if the user sets a pricingRule that goes from 00:00 to 10:00 in GMT+1 this would be stored as
     * 23:00 to 09:00 in UTC
     */
    if (startDate >= endDate) {
        startDate.subtract(1, 'd');
    }

    return {
        startDateTime: startDate.toISOString(),
        endDateTime: endDate.toISOString(),
    };
};
