import React from 'react';
import { ClubVerification } from 'usecases/types/enums/club_verification.enum';
import { getClubFromStorage, isLoggedUserOwner } from 'services/functionalStorage';
import { NoticeHeader } from 'components/NoticeHeader/NoticeHeader';
import { translate } from 'translations/translate';

export const AdminNotice = () => {
    if (!isLoggedUserOwner()) {
        return <></>;
    }
    const { verified = ClubVerification.PENDING, active } = getClubFromStorage();

    if (verified === ClubVerification.REVOKED) {
        return (
            <NoticeHeader type="warning">
                {translate('header_notice-unverified_facility-message')}
            </NoticeHeader>
        );
    }

    if (!active) {
        return (
            <NoticeHeader type="warning">
                {translate('header_notice-deactivated_facility-message')}
            </NoticeHeader>
        );
    }

    return <></>;
};
