/* eslint-disable react/jsx-indent */
import { notification, Select } from 'antd';
import { ActionElements } from 'components/ActionElements/ActionElements';
import { ListTable } from 'components/ListTable/ListTable';
import { PricingRuleModalContent } from 'components/PricingRuleModalContent/PricingRuleModalContent';
import { FACILITY_ICON_PRICE } from 'config/constants';
import { useAppContext } from 'context/AppContext';
import { useModalContext } from 'context/ModalContext';
import React, { useEffect, useRef, useState } from 'react';
import { getClubFromStorage } from 'services/functionalStorage';
import { translate } from 'translations/translate';
import {
    IListTableHeaderField, IPriceForm, PlayerRole,
} from 'types';
import { createPricingRule } from 'usecases/club/createPricingRule';
import { getPricingRulesByClub } from 'usecases/club/getPricingRulesByClub';
import { getCourts } from 'usecases/court/getCourts';
import { ICourt } from 'usecases/types/court';
import { IPricingRule } from 'usecases/types/price';
import { ListPageHeader } from 'components/ListPageHeader/ListPageHeader';
import { ListPageButton } from 'components/ListPageButton/ListPageButton';
import { ISeason } from 'usecases/types/season';
import { getClubSeasons } from 'usecases/club/getClubSeasons';
import moment from 'moment';
import { sortItemsBy } from 'adapters/sortItemsBy';
import { InfoBox } from 'components/InfoBox/InfoBox';
import { getWeekdayShortname } from 'adapters/getWeekdayShortname';
import { PricesExplanation } from 'components/PricesExplanation/PricesExplanation';
import { InfoModalContent } from 'components/InfoModalContent/InfoModalContent';
import { deletePricingRule } from 'usecases/pricingRule/deletePricingRule';
import { AlertModalContent } from 'components/AlertModalContent/AlertModalContent';
import { updatePricingRule } from 'usecases/pricingRule/updatePricingRule';
import { formatNumberAsLocaleTwoDigits } from 'adapters/formatNumberAsLocaleTwoDigits';
import { arrayToMapByAttribute } from 'adapters/arrayToMapByAttribute';
import { MobileFooter } from 'components/MobileFooter/MobileFooter';
import { DeleteModalContent } from 'components/DeleteModalContent/DeleteModalContent';
import { customHistory } from 'routing/customHistory';
import { ListRoleItem } from 'components/ListRoleItem/ListRoleItem';
import { useQuery } from 'hooks/useQuery';
import { useMobileMediaQuery } from 'hooks/useMobileMediaQuery';
import './PricesPage.scss';

export const PricesPage = (): JSX.Element | null => {
    const { setIsSpinnerVisible } = useAppContext();
    const { setModalContentProps, setModalContent } = useModalContext();
    const { isMobile } = useMobileMediaQuery();
    const [pricingRules, setPricingRules] = useState<IPricingRule[]>([]);
    const [courts, setCourts] = useState<ICourt[]>([]);
    const [seasons, setSeasons] = useState<ISeason[]>([]);
    const [selectedSeason, setSelectedSeason] = useState<ISeason | undefined>(undefined);
    const [seasonsFetched, setSeasonsFetched] = useState<boolean>(false);
    const pricingRuleModalContentRef = useRef<{ [key: string]: Function }>({});
    const urlParams = useQuery();

    const headers: IListTableHeaderField[] = [
        {
            field: '',
            value: '',
        },
        {
            field: 'name',
            value: translate('prices-page_table-header-name'),
            sortable: true,
        },
        {
            field: 'days',
            value: translate('prices-page_table-header-days'),
        },
        {
            field: 'courts',
            value: translate('prices-page_table-header-courts'),
        },
        {
            field: 'startTime',
            value: translate('prices-page_table-header-time'),
        },
        {
            field: 'role',
            value: translate('prices-page_table-header-role'),
        },
        {
            field: 'price',
            value: translate('prices-page_table-header-price'),
        },
        {
            field: 'guest fee',
            value: translate('prices-page_table-header-guest-fee'),
        },
        {
            field: '',
            value: '',
        },
    ];

    const getPriceModalContent = (
        createPriceCb: (...args: any[]) => Promise<void>,
        editPriceCb: (...args: any[]) => Promise<void>,
        pricingRule?: IPricingRule,
    )
        : React.ReactElement => (
        <PricingRuleModalContent
            onProceed={pricingRule
                ? (formParams: any) => editPriceCb(pricingRule, formParams)
                : createPriceCb}
            onCancel={() => {
                setModalContent(null);
            }}
            pricingRule={pricingRule}
            seasonName={selectedSeason!.name}
            courts={courts.filter(court => selectedSeason!.courts.includes(court.id))}
            ref={pricingRuleModalContentRef}
        />
    );

    const fetchPricingRulesFromAPI = async (seasonId: string): Promise<void> => {
        try {
            const { id } = getClubFromStorage();
            const currentPricingRules = await getPricingRulesByClub(id, seasonId);
            setPricingRules(currentPricingRules);
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    const onDeletePricingRule = async (priceToDelete: IPricingRule): Promise<void> => {
        try {
            setIsSpinnerVisible(true);
            await deletePricingRule(priceToDelete, getClubFromStorage());
            setPricingRules(pricingRules.filter(pricingRule => pricingRule.id !== priceToDelete.id));
        } catch (error: any) {
            notification.error({
                message: translate('error'),
                description: error.message,
            });
        } finally {
            setIsSpinnerVisible(false);
            setModalContent(null);
        }
    };

    const onCreatePrice = async ({
        name,
        days,
        courtIds,
        startTime,
        endTime,
        role,
        amount,
        guestFee,
    }: IPriceForm): Promise<void> => {
        setIsSpinnerVisible(true);
        try {
            const pricingRuleCreated = await createPricingRule({
                clubId: getClubFromStorage().id,
                name,
                weekdays: days.sort(),
                courts: courtIds,
                startTime: startTime as string,
                endTime: endTime as string,
                role: role as PlayerRole,
                amount: +amount,
                seasonId: selectedSeason!.id,
                guestFee,
            });

            setPricingRules([...pricingRules, pricingRuleCreated]);
            setModalContentProps({
                closable: false,
                onCancel: () => setModalContent(null),
            });
            setModalContent(
                <AlertModalContent
                    type="success"
                    header={translate('prices-page_success-modal_title-create')}
                    onGoBack={() => { setModalContent(null); }}
                    goBackText={translate('prices-page_success-modal_second-button')}
                    seeDetailsText={translate('prices-page_success-modal_first-button')}
                    onSeeDetails={() => {
                        setModalContentProps({
                            closable: false,
                            onCancel: () => setModalContent(null),
                        });
                        setModalContent(
                            // eslint-disable-next-line @typescript-eslint/no-use-before-define
                            getPriceModalContent(onCreatePrice, onEditPrice, pricingRuleCreated),
                        );
                    }}
                />,
            );
        } catch (error: any) {
            if (error instanceof Error) {
                notification.error(
                    {
                        message: translate('error'),
                        description: error.message,
                    },
                );
            } else {
                pricingRuleModalContentRef.current.setError({
                    id: error.data.errorValues.overlaps[0].id,
                    name: error.data.errorValues.overlaps[0].name,
                    season: selectedSeason!.id,
                });
            }
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    const onEditPrice = async (pricingRule: IPricingRule, {
        name,
        days,
        courtIds,
        startTime,
        endTime,
        role,
        amount,
        guestFee,
    }: IPriceForm): Promise<void> => {
        setIsSpinnerVisible(true);

        try {
            const pricingRuleUpdated = await updatePricingRule({
                clubId: getClubFromStorage().id,
                name,
                weekdays: days.sort(),
                courts: courtIds,
                startTime: startTime as string,
                endTime: endTime as string,
                role: role as PlayerRole,
                amount: +amount,
                seasonId: selectedSeason!.id,
                priceId: pricingRule.id,
                guestFee,
            });

            const pricesMappedExtended = {
                ...arrayToMapByAttribute(pricingRules),
                [pricingRuleUpdated.id]: pricingRuleUpdated,
            };

            setPricingRules(Object.values(pricesMappedExtended));

            setModalContentProps({
                closable: false,
                onCancel: () => setModalContent(null),
            });
            setModalContent(
                <AlertModalContent
                    type="success"
                    header={translate('prices-page_success-modal_title-edit')}
                    onGoBack={() => { setModalContent(null); }}
                    goBackText={translate('prices-page_success-modal_second-button')}
                    seeDetailsText={translate('prices-page_success-modal_first-button')}
                    onSeeDetails={() => setModalContent(
                        getPriceModalContent(onCreatePrice, onEditPrice, pricingRuleUpdated),
                    )}
                />,
            );
        } catch (error: any) {
            if (error instanceof Error) {
                notification.error(
                    {
                        message: translate('error'),
                        description: error.message,
                    },
                );
            } else {
                pricingRuleModalContentRef.current.setError({
                    id: error.data.errorValues.overlaps[0].id,
                    name: error.data.errorValues.overlaps[0].name,
                    season: selectedSeason!.id,
                });
            }
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    const showDeleteSuccessMessage = () => {
        setModalContentProps({
            closable: false,
            onCancel: () => setModalContent(null),
        });
        setModalContent(
            <AlertModalContent
                type="success"
                header={translate('prices-page_success-delete-modal_header')}
                onGoBack={() => { setModalContent(null); }}
                goBackText={translate('prices-page_success-modal_second-button')}
            />,
        );
    };

    const renderDeleteModalContent = (pricingRule: IPricingRule): React.ReactElement => (
        <DeleteModalContent
            headerText={translate('prices-page_delete-modal_header')}
            cancelText={translate('prices-page_delete-modal_back-button')}
            proceedText={translate('prices-page_delete-modal_delete-button')}
            onCancel={() => { setModalContent(null); }}
            onProceed={() => onDeletePricingRule(pricingRule).then(showDeleteSuccessMessage)}
        />
    );

    const getListRows = (_pricingRules: IPricingRule[]): any[][] => _pricingRules.map(pricingRule => {
        const priceCourts = courts
            .filter(court => pricingRule.courts && pricingRule.courts.includes(court.id));
        const {
            id, name, weekdays, startTime, endTime, role, amount, guestFee,
        } = pricingRule;
        return [
            id,
            name as string,
            weekdays.map(getWeekdayShortname).join(', '),
            priceCourts.map(court => court.name).join(', '),
            `${moment(startTime, 'HH:mmZ').format('HH:mm')}
            -
            ${moment(endTime, 'HH:mmZ').format('HH:mm')}`,
            <ListRoleItem role={role} />,
            `${formatNumberAsLocaleTwoDigits(amount)} ${translate('pricing-rule-unit')}`,
            guestFee ? `${formatNumberAsLocaleTwoDigits(guestFee)} ${translate('pricing-rule-unit')}` : '',
            <ActionElements
                firstButtonOnClick={() => {
                    setModalContentProps({
                        closable: !!isMobile,
                        onCancel: () => setModalContent(null),
                    });
                    setModalContent(getPriceModalContent(onCreatePrice, onEditPrice, pricingRule));
                }}
                secondButtonOnClick={() => {
                    setModalContentProps({
                        closable: false,
                        onCancel: () => setModalContent(null),
                    });
                    setModalContent(renderDeleteModalContent(pricingRule));
                }}
            />,
        ];
    });

    const getCourtsAndSeasonsFromAPI = async (): Promise<void> => {
        setIsSpinnerVisible(true);
        try {
            const APICourts = (await getCourts(getClubFromStorage()));
            setCourts(APICourts);
            const APISeasons = (await getClubSeasons(getClubFromStorage().id))
                .sort((a, b) => {
                    if (!a.active && b.active) { return 1; }
                    if (a.active && !b.active) { return -1; }
                    return (new Date(b.endDateTime).getTime() - new Date(a.startDateTime).getTime());
                });
            setSeasons(APISeasons);
            const seasonId = urlParams.get('season');
            urlParams.delete('season');
            customHistory.originalReplace({ search: decodeURIComponent(urlParams.toString()) });

            const seasonToOpen = seasonId ? APISeasons.find(season => season.id === seasonId) : APISeasons[0];
            if (seasonToOpen) {
                setSelectedSeason(seasonToOpen);
                await fetchPricingRulesFromAPI(seasonToOpen.id);
            }
        } finally {
            setIsSpinnerVisible(false);
            setSeasonsFetched(true);
        }
    };

    const sortBy = (field: string, order?: 'asc' | 'desc'): void => {
        const sortedPrices = sortItemsBy(field, pricingRules, order);
        setPricingRules([...sortedPrices]);
    };

    useEffect(() => {
        getCourtsAndSeasonsFromAPI();
    }, []);

    useEffect(
        () => {
            const pricingRuleToEditId = urlParams.get('pricingRule');
            const pricingRuleToEdit = pricingRules.find(pricingRule => pricingRule.id === pricingRuleToEditId);
            if (pricingRuleToEdit) {
                setModalContentProps({
                    closable: false,
                    onCancel: () => setModalContent(null),
                });
                setModalContent(getPriceModalContent(onCreatePrice, onEditPrice, pricingRuleToEdit));
                urlParams.delete('pricingRule');
                customHistory.originalReplace({ search: decodeURIComponent(urlParams.toString()) });
            }
        },
        [pricingRules],
    );

    if (!seasonsFetched) {
        return null;
    }

    const Filters = (
        <div className="prices-page__filters-container">
            <Select
                size="large"
                className="club-members-filters__filter"
                placeholder={translate('club_members_filter-season')}
                onChange={seasonId => {
                    setIsSpinnerVisible(true);
                    setSelectedSeason(seasons.find(season => season.id === seasonId));
                    fetchPricingRulesFromAPI(seasonId);
                }}
                value={selectedSeason?.id}
            >
                {seasons.map(season => (
                    <Select.Option
                        key={season.id}
                        value={season.id}
                    >
                        {season.name}
                    </Select.Option>
                ))}

            </Select>
        </div>
    );

    return (
        <div className="prices-page">
            <ListPageHeader header={translate('prices-page_title')} iconUrl={FACILITY_ICON_PRICE}>
                <ListPageButton
                    onClick={() => {
                        setModalContentProps({
                            closable: true,
                            onCancel: () => setModalContent(null),
                        });
                        setModalContent(
                            <InfoModalContent>
                                <PricesExplanation />
                            </InfoModalContent>,
                        );
                    }}
                    grey
                    icon="?"
                    roundedIcon
                >
                    {translate('prices-page_header-first-button')}
                </ListPageButton>
                <ListPageButton
                    icon="+"
                    onClick={() => {
                        setModalContentProps({
                            closable: !!isMobile,
                            onCancel: () => setModalContent(null),
                        });
                        setModalContent(getPriceModalContent(onCreatePrice, onEditPrice));
                    }}
                    disabled={!selectedSeason}
                >
                    {translate('prices-page_header-second-button')}
                </ListPageButton>
            </ListPageHeader>
            {(!isMobile && selectedSeason) && Filters}
            {selectedSeason && pricingRules.length > 0 ? (
                <ListTable
                    rows={getListRows(pricingRules)}
                    headers={headers}
                    getKey={(listItem: any[]) => listItem[0]}
                    sortBy={sortBy}
                    firstColumn={false}
                />
            ) : (
                <InfoBox>
                    <p className="prices-page__no-season-info-message">
                        {translate('prices-page_no-season-info_step-one')}
                    </p>
                    <p className="prices-page__no-season-info-message">
                        {translate('prices-page_no-season-info_step-two')}
                    </p>
                    <p className="prices-page__no-season-info-message">
                        {translate('prices-page_no-season-info_step-three')}
                    </p>
                    <p className="prices-page__no-season-info-message">
                        {translate('prices-page_no-season-info_step-four')}
                    </p>
                </InfoBox>
            )}

            {isMobile && selectedSeason && (
                <MobileFooter
                    mobileFooterOpenkey="prices-page_mobile-filters_open"
                    mobileFooterCloseKey="prices-page_mobile-filters_close"
                    content={Filters}
                />
            )}
        </div>
    );
};
