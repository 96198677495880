import { getUserFromStorage, setUserInStorage } from 'services/functionalStorage';
import { PROFILE } from 'config/endpoints';
import { IUpdateUserProfile } from 'usecases/types/dto/dto';
import { putWrapper } from 'services/httpService/httpServiceWrapper';

export const updateUserProfile = async ({
    name, surname, gender, language,
}: IUpdateUserProfile) => {
    const { address } = getUserFromStorage();

    const response = await putWrapper(
        PROFILE.SINGLE,
        {
            name, surname, gender, language, address,
        },
    );

    setUserInStorage({
        ...getUserFromStorage(),
        name,
        surname,
        gender,
        language,
    });

    return response;
};
