import { IPricingRule } from 'usecases/types/price';
import { ISeason } from 'usecases/types/season';
import { getCurrentUserRole, isLoggedUserAdmin, isUserAuthenticated } from 'services/functionalStorage';
import { BkgPriceEvent, CalendarViewEnum, PLAYERROLECLIENT } from 'types';
import { ICourt } from 'usecases/types/court';
import { CHDateTime } from 'helpers/DateTime/CHDateTime';
import { getDisplayedDateRange } from 'components/Reservations/ReservationsCalendar/getDisplayedDateRange';
import { buildStartAndEndDateTimesPerDate } from './buildStartAndEndDateTimesPerDate';
import { buildBkgPriceEvent } from './buildBkgPriceEvent';
import { getTimeSlotsBetweenTwoDates } from './getTimeSlotsBetweenTwoDates';
import { pickDatesFromPricingRule } from './pickDatesFromPricingRule';
import { filterPricingRulesByCourt } from './filterPricingRulesByCourt';
import { filterPricingRulesPerRole } from './filterPricingRulesPerRole';
import { buildEmptyBkgPricingEvents } from './buildEmptyBkgPricingEvents';

export const mapPricingRulesToBkgEvents = (
    pricingRules: IPricingRule[],
    bookableSeason: ISeason | undefined,
    selectedView: CalendarViewEnum,
    displayedCourt: ICourt | null,
    startingDate: CHDateTime,
) : BkgPriceEvent[] => {
    if (!bookableSeason) {
        return [];
    }
    const isAdmin = isLoggedUserAdmin();
    const loggedUser = isUserAuthenticated();
    const currentUserRole = loggedUser ? getCurrentUserRole() : PLAYERROLECLIENT;
    let applicablePricingRules = pricingRules.filter(pr => pr.season.id === bookableSeason.id);

    if (!isAdmin) {
        applicablePricingRules = filterPricingRulesPerRole(applicablePricingRules, currentUserRole);
    }

    if (selectedView === CalendarViewEnum.WEEKVIEW) {
        applicablePricingRules = filterPricingRulesByCourt(applicablePricingRules, displayedCourt!);
    }

    const displayedDateRange = getDisplayedDateRange(startingDate, selectedView);

    const pricingEvents = applicablePricingRules.reduce((acc, currentPrincingRule) => {
        const datesOfPricingRule = pickDatesFromPricingRule(currentPrincingRule, bookableSeason);
        const datesOfPricingRuleWithinDisplayedDateRange = datesOfPricingRule.filter(dateString => {
            const date = CHDateTime.fromNativeDate(new Date(dateString));
            return displayedDateRange.start.isBeforeOrEqual(date) && displayedDateRange.end.isAfterOrEqual(date);
        });
        const priceOfPricingRule = currentPrincingRule.amount / 2;
        const {
            courts, startTime, endTime, role,
        } = currentPrincingRule;

        const bkgPriceEventsTuplet = datesOfPricingRuleWithinDisplayedDateRange.map(
            date => {
                const { startDateTime, endDateTime } = buildStartAndEndDateTimesPerDate(date, startTime, endTime);

                const timeSlots = getTimeSlotsBetweenTwoDates(startDateTime, endDateTime);

                const bkgPriceEventsBuilt = timeSlots.map(timeSlot => buildBkgPriceEvent(
                    {
                        ...timeSlot,
                        slotPrice: priceOfPricingRule,
                        courts,
                        role: isAdmin ? role : currentUserRole,
                        isAdmin,
                    },
                ));

                return bkgPriceEventsBuilt;
            },
        );
        const bkgPriceEvents = [...bkgPriceEventsTuplet.reduce((list, curr) => [...list, ...curr], [])];
        return [...acc, ...bkgPriceEvents];
    }, [] as BkgPriceEvent[]);
    const allPricingEvents = buildEmptyBkgPricingEvents(pricingEvents, selectedView);
    return allPricingEvents;
};
