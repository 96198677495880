import React, { useEffect, useState } from 'react';
import { DateClickArg } from '@fullcalendar/interaction';
import { notification } from 'antd';
import { translate } from 'translations/translate';
import { isUserActive } from 'helpers/isUserActive';
import { useAppContext } from 'context/AppContext';
import { useModalContext } from 'context/ModalContext';
import { IClub } from 'usecases/types/club';
import { ICourt } from 'usecases/types/court';
import { useQuery } from 'hooks/useQuery';
import { getResponseCodeFromUrl } from 'pages/admin/Club/PaymentMethods/getResponseCodeFromUrl';
import { IReservation } from 'usecases/types/reservations';
import { getCourts } from 'usecases/court/getCourts';
import { getReservations } from 'usecases/reservation/getReservations';
import { IUser, CalendarViewEnum } from 'types';
import { getClubAndSetInStorage } from 'usecases/club/getClub';
import { getClubFromStorage, getCurrentUserRole, isLoggedUserAdmin } from 'services/functionalStorage';
import { getBookingIdFromUrl } from 'pages/admin/Club/PaymentMethods/getBookingIdFromUrl';
import { BookingDialog } from 'components/Reservations/BookingDialog/BookingDialog';
import { getClubSeasons } from 'usecases/club/getClubSeasons';
import { ISeason } from 'usecases/types/season';
import { NoSeasonInfoContent } from 'components/NoSeasonInfoContent/NoSeasonInfoContent';
import { MobileFooter } from 'components/MobileFooter/MobileFooter';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import {
    FOREIGN_BOOKING_DATALAYER,
    getBookingFailureDatalayer,
    getBookingSuccessDatalayer,
} from 'services/gtm/gtmDatalayers';
import { InfoBox } from 'components/InfoBox/InfoBox';
import { getPricingRulesByClub } from 'usecases/club/getPricingRulesByClub';
import { AlertModalContent } from 'components/AlertModalContent/AlertModalContent';
import { IPricingRule } from 'usecases/types/price';
import { CHDateTime, TimeUnits } from 'helpers/DateTime/CHDateTime';
import { useApi } from 'hooks/useApi';
import { formatSingleDayDate } from 'components/Reservations/TopRowFilters/formatSingleDayDate';
import { useCalendar } from 'hooks/useCalendar';
import { useMobileMediaQuery } from 'hooks/useMobileMediaQuery';
import { getBookingById } from 'usecases/reservation/getBookingById';
import { getUserMembership } from 'helpers/getUserMembership';
import { isDateInCurrentSeason } from './isDateInCurrentSeason';
import { DateNotInCurrentSeasonInfo } from '../DateNotInCurrentSeasonInfo/DateNotInCurrentSeasonInfo';
import { isActiveSeason } from './isActiveSeason';
import { areCourtsInSeason } from './areCourtsInSeason';
import { getInitialEvent } from './getInitialEvent';
import { isReservationModifiableByLoggedUser } from './isReservationModifiableByLoggedUser';
import { ReservationsCalendarHeader } from './ReservationsCalendarHeader';
import { getBookableSeasons } from './getBookableSeasons';
import { getBookableCourts } from './getBookableCourts';
import { NoCourtsAvailableInfoContent } from './NoCourtsAvailableInfoContent';
import './ReservationsCalendar.scss';

interface Iprops {
    loggedUser: IUser,
    showWaitingActivationModal?: () => void,
    userActive: boolean
}

export const ReservationsCalendar = ({
    loggedUser,
    showWaitingActivationModal = () => { },
    userActive,
}: Iprops): JSX.Element => {
    const { setIsSpinnerVisible, antdLocaleObject } = useAppContext();
    const { locale: antdLocale } = antdLocaleObject;
    const { setModalContentProps, setModalContent } = useModalContext();
    const callApi = useApi();
    const { isMobile } = useMobileMediaQuery();
    const [club, setClub] = useState<IClub>(getClubFromStorage());
    const [visibleCourts, setVisibleCourts] = useState<ICourt[]>([]);
    const [APICourts, setAPICourts] = useState<ICourt[]>([]);
    const [reservations, setReservations] = useState<IReservation[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [showCreateReservationDialog, setShowCreateReservationDialog] = useState<boolean>(false);
    const [bookableSeasons, setBookableSeasons] = useState<ISeason[]>([]);
    const [APIBookableSeasons, setAPIBookableSeasons] = useState<ISeason[]>([]);
    const [pricingRules, setPricingRules] = useState<IPricingRule[]>([]);
    const [selectedEvent, setSelectedEvent] = useState<IReservation>(getInitialEvent(
        new CHDateTime().toUtc(),
        null as unknown as ICourt,
        loggedUser,
        club,
    ));

    const urlParams = useQuery();

    const onDateClick = (event: DateClickArg): void => {
        if (!isUserActive(loggedUser)) {
            showWaitingActivationModal();
            return;
        }
        if (!bookableSeasons.some(season => isDateInCurrentSeason(season, event))) {
            setModalContentProps({
                closable: false,
                onCancel: () => setModalContent(null),
            });
            setModalContent(<DateNotInCurrentSeasonInfo
                onClose={() => setModalContent(null)}
            />);
            return;
        }
        if (visibleCourts.length === 0) {
            notification.error({
                message: translate('calendar_club-without-courts-notification-title'),
                description: translate('calendar_club-without-courts-notification-body'),
            });
            return;
        }
        setShowCreateReservationDialog(true);
    };

    const onEventClick = (extendedProps: Record<string, any>): void => {
        const { reservation } = extendedProps;
        if (reservation) {
            if (isReservationModifiableByLoggedUser(reservation, loggedUser)) {
                setSelectedEvent(reservation);
                setShowCreateReservationDialog(true);
                return;
            }
            pushGtmDatalayer(FOREIGN_BOOKING_DATALAYER);
        }
    };

    const {
        calendar, filters, displayCourt, view, filterDate, bookingDate, displayedCourt, moveToDate,
    } = useCalendar({
        courts: visibleCourts,
        seasons: bookableSeasons,
        pricingRules,
        bookings: reservations,
        role: getCurrentUserRole(),
        onEventClick,
        onDateClick,
    });

    const getReservationsByCourtAndWeek = (court: ICourt, initialDateString: string): Promise<IReservation[]> => {
        const initialDate = new CHDateTime(initialDateString);
        return callApi(getReservations(
            {
                club,
                court,
                from: initialDate.startOfDay(),
                to: initialDate.add(6, TimeUnits.DAY).endOfDay(),
            },
        ));
    };

    const getReservationsByDate = (dateToFilter: string): Promise<IReservation[]> => {
        const dateInstance = new CHDateTime(dateToFilter);
        return callApi(getReservations(
            {
                club,
                court: undefined,
                from: dateInstance.startOfDay(),
                to: dateInstance.endOfDay(),
            },
        ));
    };

    const setUpdatedReservationsByView = async (
        _view: CalendarViewEnum,
        courtDisplayed: ICourt | null,
        filterDateString: string,
    ): Promise<void> => {
        let updatedReservations: IReservation[] = reservations;
        if (filterDateString && _view === CalendarViewEnum.DAYVIEW) {
            updatedReservations = await getReservationsByDate(filterDateString);
        }
        if (courtDisplayed && _view === CalendarViewEnum.WEEKVIEW) {
            updatedReservations = await getReservationsByCourtAndWeek(courtDisplayed!, filterDateString);
        }
        setReservations(updatedReservations);
    };

    useEffect(() => {
        const bookableCourts = getBookableCourts(
            APICourts,
            APIBookableSeasons,
            view,
            filterDate.toString(),
        );
        setVisibleCourts(bookableCourts);
    }, [view, filterDate, APICourts, bookableSeasons]);

    useEffect(() => {
        setSelectedEvent(getInitialEvent(bookingDate, displayedCourt!, loggedUser, club));
    }, [displayedCourt, bookingDate]);

    useEffect(() => {
        setUpdatedReservationsByView(view, displayedCourt, filterDate.toString());
        setBookableSeasons(getBookableSeasons(APIBookableSeasons, displayedCourt!, view));
    }, [displayedCourt, filterDate, view]);

    useEffect(() => {
        const asyncFuncs = async (): Promise<void> => {
            setIsSpinnerVisible(true);

            const [apiClub, _apiCourts, apiSeasons]: [IClub, ICourt[], ISeason[]] = await Promise.all([
                callApi(getClubAndSetInStorage(club.id)),
                callApi(getCourts(club as unknown as IClub)),
                callApi(getClubSeasons(club.id)),
            ]);
            setClub(apiClub);
            const seasonsBookable = isLoggedUserAdmin()
                ? apiSeasons
                : apiSeasons.filter(season => isActiveSeason(season));
            setAPIBookableSeasons(seasonsBookable);
            const seasonsCourts = _apiCourts
                .filter(court => seasonsBookable
                    .some(season => season.courts.includes(court.id)));
            setAPICourts(seasonsCourts);
            if (seasonsBookable) {
                const pricingRulesBySeasonFromAPI = await Promise.all(
                    seasonsBookable.map(season => callApi(getPricingRulesByClub(club.id, season.id))),
                );
                const pricingRulesFromAPI = pricingRulesBySeasonFromAPI
                    .reduce((acc, current) => [...acc, ...current], []);
                setPricingRules(pricingRulesFromAPI);
            }

            const bookableCourts = getBookableCourts(
                seasonsCourts,
                apiSeasons,
                view,
                filterDate.toString(),
            );
            setVisibleCourts(bookableCourts);
            displayCourt(bookableCourts[0]);
            setBookableSeasons(getBookableSeasons(seasonsBookable, bookableCourts[0], view));
            setUpdatedReservationsByView(view, bookableCourts[0], filterDate.toString());
            setDataLoaded(true);
            setIsSpinnerVisible(false);
        };
        asyncFuncs();
    }, []);

    const loadBookingFromUrlParams = async () => {
        const bookingId = getBookingIdFromUrl(urlParams);
        if (bookingId === null) {
            return undefined;
        }
        return getBookingById(bookingId);
    };

    const showPaidReservationDetails = async () => {
        setModalContent(null);
        setShowCreateReservationDialog(true);
    };

    const onGoBackToCalendarButton = () => {
        setModalContent(null);
    };

    const showSuccessMessage = () => {
        setModalContentProps({ closable: false });
        const subHeader = isLoggedUserAdmin() ? '' : translate('calendar-reservation_dialog-cancellation_policy');
        setModalContent(
            <AlertModalContent
                type="success"
                goBackText={translate('go-back')}
                header={translate('calendar-reservation_dialog-booking_successful')}
                subHeader={subHeader}
                onGoBack={onGoBackToCalendarButton}
                seeDetailsText={translate('calendar_reservation-confirmed-show-booking')}
                onSeeDetails={showPaidReservationDetails}
            />,
        );
    };

    const showFailedPaymentMessage = async () => {
        const onlinePaidBooking = await loadBookingFromUrlParams();

        setModalContentProps({ closable: false });
        setModalContent(
            <AlertModalContent
                type="warning"
                goBackText={translate('go-back')}
                header={translate('error-004028')}
                onGoBack={onGoBackToCalendarButton}
            />,
        );
        const userRole = getUserMembership(loggedUser, club)!.role;

        /**
         * Without the timeout this event is sent before loading the cookie consent preferences of onetrust
         * By delaying it for 2s the preferences are properly loaded before trying to send the event
         */
        setTimeout(() => {
            pushGtmDatalayer(getBookingFailureDatalayer(
                loggedUser.id,
                club.id,
                userRole,
                onlinePaidBooking.price,
                onlinePaidBooking.paymentType,
                onlinePaidBooking.bookingType,
                translate('error-004028'),
            ));
        }, 2000);
    };
    const onRedirectFromStripe = async () => {
        const onlinePaidBooking = await loadBookingFromUrlParams();
        moveToDate(new CHDateTime(onlinePaidBooking.startTime));
        setSelectedEvent(onlinePaidBooking);
        showSuccessMessage();

        const userRole = getUserMembership(loggedUser, club)!.role;

        /**
         * Without the timeout this event is sent before loading the cookie consent preferences of onetrust
         * By delaying it for 2s the preferences are properly loaded before trying to send the event
         */
        setTimeout(() => {
            pushGtmDatalayer(getBookingSuccessDatalayer(
                [onlinePaidBooking],
                loggedUser.id,
                club.id,
                userRole,
                onlinePaidBooking.price,
            ));
        }, 2000);
    };

    useEffect(() => {
        if (dataLoaded) {
            const { statusCode } = getResponseCodeFromUrl(urlParams);
            if (statusCode === '000000') {
                onRedirectFromStripe();
            }
            if (statusCode === '010999') {
                showFailedPaymentMessage();
            }
        }
    }, [dataLoaded]);

    const shouldShowCalendarAndFilters = (
        isLoggedUserAdmin() && !!(bookableSeasons && bookableSeasons.some(season => (areCourtsInSeason(season))))
    )
        || !!(bookableSeasons && bookableSeasons.some(season => (isActiveSeason(season) && areCourtsInSeason(season))));

    const shouldShowBookingDialog = userActive && displayedCourt && showCreateReservationDialog;

    const CalendarWithDialog = (
        <>
            {calendar}
            {isMobile && (
                <MobileFooter
                    content={filters}
                    mobileFooterOpenkey={
                        view === CalendarViewEnum.DAYVIEW
                            ? formatSingleDayDate(filterDate, antdLocale)
                            : displayedCourt?.name
                    }
                />
            )}
            {shouldShowBookingDialog && (
                <BookingDialog
                    setVisible={setShowCreateReservationDialog}
                    onCloseActionsDialog={
                        () => setUpdatedReservationsByView(view, displayedCourt, filterDate.toString())
                    }
                    setReservation={setSelectedEvent}
                    booking={selectedEvent}
                    courts={visibleCourts}
                    selectedView={view}
                    bookableSeasons={bookableSeasons}
                />
            )}
        </>
    );

    if (!dataLoaded) {
        return <></>;
    }

    return (
        <div className="reservations-calendar reservations-calendar--input-height">
            {!isMobile && (
                <ReservationsCalendarHeader
                    clubLogo={club.logo}
                    filtersVisibility={shouldShowCalendarAndFilters}
                >
                    {filters}
                </ReservationsCalendarHeader>
            )}

            {!shouldShowCalendarAndFilters && (
                <InfoBox>
                    <NoSeasonInfoContent season={bookableSeasons.find(season => season.active)} />
                </InfoBox>
            )}
            {shouldShowCalendarAndFilters && CalendarWithDialog}
            <NoCourtsAvailableInfoContent
                courts={visibleCourts}
                shouldShowCalendarAndFilters={shouldShowCalendarAndFilters}
            />
        </div>
    );
};
