import { debounce } from 'adapters/debounce';
import { useAppContext } from 'context/AppContext';
import {
    RefObject, useEffect,
} from 'react';

export const useChevronIcon = <T extends HTMLElement>(containerRef: RefObject<T>): void => {
    const {
        isWindowResizing, setIsWindowResizing, setScrollArrowVisibility,
    } = useAppContext();

    const handleWindowResize = (ref: T): void => {
        if (ref.parentElement?.clientWidth !== ref.offsetWidth) {
            setScrollArrowVisibility(true);
        }
    };
    const handleRefScroll = (ref: T): void => {
        if (ref.offsetWidth + ref.scrollLeft >= ref.scrollWidth) {
            setScrollArrowVisibility(false);
        }
        if (ref.offsetWidth + ref.scrollLeft < ref.scrollWidth) {
            setScrollArrowVisibility(true);
        }
    };

    useEffect(() => {
        const currentRef = containerRef.current as T;
        if (currentRef) {
            handleWindowResize(currentRef);
            currentRef.addEventListener('scroll', debounce(() => handleRefScroll(currentRef), 300));
        }
        return () => {
            if (currentRef) {
                currentRef.removeEventListener('scroll', () => {});
            }
        };
    }, [containerRef.current]);

    useEffect(() => {
        const ref = containerRef.current as T;
        debounce(() => handleWindowResize(ref), 200);
        setIsWindowResizing(false);
    }, [isWindowResizing]);
};
