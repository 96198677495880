import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card } from 'antd';
import { translate } from 'translations/translate';
import { LOGO_RED } from 'config/constants';
import {
    DesktopBlurredBackground,
} from 'components/Reservations/DesktopBlurredBackground/DesktopBlurredBackground';
import 'pages/common/WhiteCard/Whitecard.scss';

export const NotFoundPage = (): JSX.Element => (
    <DesktopBlurredBackground>
        <Card className="whitecard">
            <div className="whitecard__header">
                <img className="whitecard__header-image" src={LOGO_RED} alt="Court Hero Logo" />
            </div>
            <div className="whitecard__legend">
                <h1 className="whitecard__legend-title">{translate('page-not-found')}</h1>
                <p className="whitecard__legend-text">{translate('this-page-does-not-exist')}</p>
            </div>
            <Link to="/">
                <Button
                    type="primary"
                    className="whitecard__confirm-button"
                >
                    {translate('go-back')}
                </Button>
            </Link>
        </Card>
    </DesktopBlurredBackground>
);
