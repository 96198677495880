/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { getProfileLanguage } from 'translations/getProfilelanguage';
import './PricesExplanation.scss';

export const PricesExplanation = () => {
    const profileLanguage = getProfileLanguage();
    const germanExplanation = () => (
        <>
            <h1 className="prices-explanation__title">Preise</h1>
            <div className="prices-explanation__text-container">
                <p className="prices-explanation__text">
                    Die Funktion „Preise“ ermöglicht es dir Preisregeln pro Saison zu erstellen, zu bearbeiten oder zu löschen.
                    Auch wenn du deine Plätze kostenlos nur deinen Mitgliedern zur Verfügung stellen möchtest, musst du mindestens eine Regel dafür erstellen,
                    indem du den Preis pro Stunde auf 0€ setzt.
                </p>
                <p className="prices-explanation__text">
                    In dem Saisonfilter kannst du die Saison auswählen, für welche du die Preisregeln erstellen möchtest.
                    Danach kannst du auf den grünen Button „+ Neue Preisregel“ klicken.
                    Folgende Informationen musst du mindestens angeben, wenn du eine Preisregel erstellst:
                </p>
                <p className="prices-explanation__text">1) Name der Preisregel</p>
                <p className="prices-explanation__text">2) Tag/e </p>
                <p className="prices-explanation__text">3) Platz/Plätze</p>
                <p className="prices-explanation__text">4) Start- und Endzeit</p>
                <p className="prices-explanation__text">5) Rolle des Spielers (Mitglied oder Gast)</p>
                <p className="prices-explanation__text">6) Preis pro Stunde</p>

                <p className="prices-explanation__text">
                    Wenn du die Rolle „Mitglied“ beim Erstellen der Preisregel auswählst, dann erscheint zusätzlich eine Checkbox mit dem Text
                    „Gastgebühr aktivieren (Mitglied spielt mit Gast)“.
                    Wenn du diese Checkbox markierst, dann erscheint ein weiteres Feld namens „Gastgebühr/Std.“.
                    Dort kannst du dann die Gastgebühr eingeben. Die Checkbox solltest du nur markieren und das Feld ausfüllen,
                    wenn du von deinen Mitgliedern eine Gastgebühr erheben möchtest, wenn sie mit einem Nichtmitglied spielen.
                </p>

                <p className="prices-explanation__text">
                    Wenn du zudem in deinem Kalender den Preis einer Buchung manuell im Buchungsfenster änderst,
                    dann wird die Gastgebühr für diese Buchung automatisch auf 0€ gesetzt.
                </p>

                <p className="prices-explanation__text">
                    Die erstellten Regeln werden dir in der Liste der Preisregeln angezeigt.
                    In der Liste der Preisregeln stehen dir folgende Funktionen zur Verfügung:
                </p>

                <p className="prices-explanation__text">1) Nach Saison filtern</p>
                <p className="prices-explanation__text">2) Nach dem Namen der Preisregel sortieren</p>
                <p className="prices-explanation__text">3) Bearbeiten der Preisregel (Stift-Symbol)</p>
                <p className="prices-explanation__text">4) Löschen der Preisregel (Papierkorb-Symbol)</p>

                <p className="prices-explanation__text">
                    Der Spieler sieht die entsprechenden Preise im Kalender.
                    Er wird eine Fehlermeldung erhalten, wenn er auf die Schaltfläche "Jetzt buchen"
                    klickt und keine Preisregel vom dir als Admin definiert wurde.
                </p>

                <p className="prices-explanation__text">
                    Du als Admin siehst in deinem Kalender sowohl den Preis für Mitglieder als auch für Gäste, sofern du eine Preisregel dafür erstellt hast.
                </p>

                <p className="prices-explanation__text">
                    <b>Benötigst du weitere Hilfe? </b>
                    Dann schreibe eine E-Mail an
                    {' '}
                    <a
                        className="prices-explanation__link"
                        href="mailto:courthero@tennis-point.de"
                    >
                        courthero@tennis-point.de
                    </a>
                </p>
            </div>
        </>
    );

    const englishExplanation = () => (
        <>
            <h1 className="prices-explanation__title">Prices</h1>
            <div className="prices-explanation__text-container">
                <p className="prices-explanation__text">
                    The "Prices" function allows you to create, edit or delete price rules per season.
                    Even if you want to make your courts available for free to your members only,
                    you must create at least one rule for it by setting the price per hour to 0€.
                </p>
                <p className="prices-explanation__text">
                    In the season filter you can select the season for which you want to create the price rules.
                    After that you can click on the green button "+ New price rule".
                    You have to fill in the following information at least when you create a price rule:
                </p>
                <p className="prices-explanation__text">1) Name of the price rule</p>
                <p className="prices-explanation__text">2) Day/s</p>
                <p className="prices-explanation__text">3) Court/s</p>
                <p className="prices-explanation__text">4) Start and end time</p>
                <p className="prices-explanation__text">5) Role of the player (member or guest)</p>
                <p className="prices-explanation__text">6) Price per hour</p>

                <p className="prices-explanation__text">
                    If you select the role "Member" when creating the price rule, then an additional checkbox appears with the text "Activate guest fee (member plays with guest)".
                    If you mark this checkbox, another field called "Guest fee/hr." appears.
                    There you can enter the guest fee. You should only tick the checkbox and fill in the field if you want to charge your members a guest fee when they play with a non-member.
                </p>

                <p className="prices-explanation__text">
                    If you also change the price of a booking manually in the booking window in your calendar, the guest fee for this booking is automatically set to 0€.
                </p>

                <p className="prices-explanation__text">
                    The rules you have created are displayed in the list of price rules.
                    The following functions are available in the list of price rules:
                </p>

                <p className="prices-explanation__text">1) Filter by season</p>
                <p className="prices-explanation__text">2) Sort by the name of the price rule</p>
                <p className="prices-explanation__text">3) Edit the price rule (pencil icon)</p>
                <p className="prices-explanation__text">4) Delete the Price rule (trash icon)</p>

                <p className="prices-explanation__text">
                    The player will see the corresponding prices in the calendar.
                    He will also get an error message if he clicks on the "Book now" button and no price rule has been defined by you as an admin.
                </p>

                <p className="prices-explanation__text">
                    You as an admin see the price for members as well as for clients in your calendar, if you have created a price rule for it.
                </p>

                <p className="prices-explanation__text">
                    <b>Need more help? </b>
                    Then send an e-mail to
                    {' '}
                    <a
                        className="prices-explanation__link"
                        href="mailto:courthero@tennis-point.de"
                    >
                        courthero@tennis-point.de
                    </a>
                </p>
            </div>
        </>
    );
    return (
        <div className="prices-explanation">
            {profileLanguage === 'english'
                ? englishExplanation()
                : germanExplanation()}
        </div>

    );
};
