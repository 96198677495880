import React from 'react';
import { InfoBox } from 'components/InfoBox/InfoBox';
import { translate } from 'translations/translate';
import { ICourt } from 'usecases/types/court';

interface IProps {
    courts: ICourt[];
    shouldShowCalendarAndFilters: boolean;
}

export const NoCourtsAvailableInfoContent = ({
    courts,
    shouldShowCalendarAndFilters,
}: IProps): JSX.Element => {
    if (courts.length === 0 && shouldShowCalendarAndFilters) {
        return (
            <InfoBox>
                <p>{translate('calendar_info-no-courts-available')}</p>
            </InfoBox>
        );
    }
    return <></>;
};
