import React from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { formatNumberAsLocaleCurrency } from 'adapters/formatNumberAsLocaleCurrency';
import { Button, Checkbox } from 'antd';
import { ListRoleItem } from 'components/ListRoleItem/ListRoleItem';
import { translate } from 'translations/translate';
import { Invoice, InvoiceStatus } from 'types';
import { getAntdLocaleFromStorage, getClubFromStorage } from 'services/functionalStorage';
import { ActionElement } from 'components/ActionElement/ActionElement';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { getUserMembership } from 'helpers/getUserMembership';
import { canInvoiceBeCancelled } from 'helpers/Invoice/canInvoiceBeCancelled';
import { InvoiceStatusDisplay } from '../InvoiceStatus/InvoiceStatusDisplay';

interface GetInvoiceListRows {
    invoicesSorted: Invoice[],
    onProceed: Function,
    onCancel: Function,
    checkboxesSelected: string[],
    onCheckboxChange: (e: CheckboxChangeEvent) => void,
    onChangeStatus: Function
}

export const getInvoiceListRows = ({
    invoicesSorted,
    onProceed,
    onCancel,
    checkboxesSelected,
    onCheckboxChange,
    onChangeStatus,
}: GetInvoiceListRows): any[][] => {
    const antdLocale = getAntdLocaleFromStorage();
    return invoicesSorted.map(invoice => [
        invoice.user.avatar,
        <Checkbox
            checked={checkboxesSelected.includes(invoice.id)}
            onChange={onCheckboxChange}
            name={invoice.id}
            disabled={!canInvoiceBeCancelled(invoice)}
        />,
        invoice.invoiceNumber,
        invoice.name,
        invoice.surname,
        <ListRoleItem role={getUserMembership(invoice.user, getClubFromStorage())!.role} />,
        invoice.createdAt.formatDateToLocale(antdLocale),
        <InvoiceStatusDisplay status={invoice.status} />,
        invoice.paymentMethod === 'cash'
            ? translate('invoice_CASH')
            : translate('invoice_ONLINE'),
        formatNumberAsLocaleCurrency(invoice.amount),
        <ActionElement icon={<EyeOutlined />} onProceed={() => onProceed(invoice)} />,
        <Button
            type="primary"
            size="large"
            className="settlement-page__settle-btn"
            onClick={() => onCancel(invoice.id)}
            disabled={!canInvoiceBeCancelled(invoice)}
            title={
                invoice.isSubscription && invoice.status !== InvoiceStatus.CANCELLED
                    ? translate('error-008004')
                    : ''
            }
        >
            {translate('invoices-page_cancel-button')}
        </Button>,
        <Button
            type="primary"
            size="large"
            className="settlement-page__settle-btn"
            onClick={() => onChangeStatus(invoice.id, invoice.status)}
            disabled={invoice.status === InvoiceStatus.CANCELLED}
        >
            {
                invoice.status === InvoiceStatus.UNPAID
                    ? translate('invoices-page_pay-button')
                    : translate('invoices-page_unpay-button')
            }
        </Button>,
    ]);
};
