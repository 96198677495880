import { Moment } from 'moment';
import { GenderEnum } from 'usecases/types/enums/gender.enum';
import { LanguageEnum } from 'usecases/types/enums/language.enum';
import { CHDateTime } from 'helpers/DateTime/CHDateTime';
import { Interval } from 'usecases/types/enums/booking_interval.enum';
import { MEMBERSHIP_STATUS } from 'usecases/types/enums/membership_status.enum';
import { IClub } from './usecases/types/club';
import { ICourt } from './usecases/types/court';

export type ComponentType<P = {[k: string]: any}> = React.ComponentClass<P> | React.FunctionComponent<P>;

export type Dict<T> = { [k: string]: T }

export interface StringKeyBooleanValueMap {
    [key: string]: boolean
}

export interface NumberKeyBooleanValueMap {
    [key: number]: () => boolean
}
export interface IMembership {
    clubId: string,
    role: PlayerRole,
    status: MEMBERSHIP_STATUS
}
export interface IUser {
    id: string;
    name: string;
    surname: string;
    email: string;
    avatar: string | null;
    gender: string;
    language: string;
    userLogins: string[];
    verified: boolean;
    address: Address | null;
    registerDate: string;
    memberships: IMembership[];
}

export enum CalendarViewEnum {
    WEEKVIEW = 'timeGridWeek',
    DAYVIEW = 'resourceTimeGridDay'
}

export enum CLUB_SERVICE {
    SHOP = 'SHOP',
    RESTAURANT = 'RESTAURANT',
    BEVERAGES = 'BEVERAGES',
    RACKET_RENTAL = 'RACKET_RENTAL',
    PURCHASING_BALLS = 'PURCHASING_BALLS',
    STRINGING = 'STRINGING',
}

export interface ICalendarBackgroundEvent {
    start: string;
    end: string;
    display: 'background';
    color: string;
}

export interface IClubSettingsForm {
    name: string;
    openingTime?: Moment;
    closingTime?: Moment;
    services?: CLUB_SERVICE[];
}

export interface ReservationForm {
    club: IClub;
    court: ICourt;
    companions: Dict<IUser>;
    guests: IGuest[];
    startTime?: string
    owner?: IUser;
}

export interface ISeasonForm {
    name: string;
    start: Moment;
    end: Moment;
    courtIds: string[],
    active: boolean,
}

export interface ICourtForm {
    name: string;
    openingTime: string;
    closingTime: string;
    surface?: CourtSurfaceEnum;
    location: CourtLocationEnum;
}

export interface IMemberForm {
    name: string;
    surname: string;
    gender: GenderEnum;
    language: LanguageEnum;
    role: PlayerRole,
    street: string;
    number: string;
    zipCode: string;
    city: string;
    country: string;
    email: string | null;
}
export interface IPriceForm {
    name: string;
    days: number[];
    courtIds: string[]
    startTime: string | Moment,
    endTime: string | Moment,
    role: PlayerRole | undefined,
    amount: number;
    season?: string;
    guestFeeCheckbox: boolean;
    guestFee?: number;
}

export interface IGuest {
    name: string;
    surname: string;
}

export interface UserWithReservationsCount extends IUser {
    totalAmountGuestsFee: number;
    totalReservations: number,
    totalReservationsWithGuests: number,
}

export interface IRoute {
    path: string;
    component: any;
}

export interface ILink {
    to?: string;
    icon?: JSX.Element;
    icons?: string[];
    text: string;
    routes?: ILink[];
    run?: (params?: any) => void;
    bottom?: boolean;
    targetBlank?: boolean;
    datalayer?: GTMDataLayer
}

export interface GTMDataLayer {
    event?: string;
    eventCategory?: string;
    eventAction?: string;
    eventLabel?: string;
    eventValue?: string;
}

export interface PlayerInfo {
    image: string;
    name: string
}

export interface Address {
    street: string,
    country: string,
    city: string,
    number: string,
    zipCode: string,
}

export enum InvoiceStatus {
    UNPAID = 'UNPAID',
    PAID = 'PAID',
    CANCELLED = 'CANCELLED',
}

export interface Invoice {
    id: string;
    invoiceNumber: string;
    status: InvoiceStatus;
    name: string,
    surname: string,
    user: IUser;
    amount: number,
    bookings: string[];
    createdAt: CHDateTime;
    isSubscription: boolean;
    paymentMethod?: string;
}
export interface InvoiceDTO {
    id: string;
    status: InvoiceStatus;
    invoiceNumber: string;
    name: string,
    surname: string,
    user: IUser;
    amount: number;
    bookings: string[];
    createdAt: string;
    isSubscription: boolean;
    paymentMethod?: string;
}

export interface IInvoiceData {
    bankName: string,
    iban: string,
    bic: string,
    taxNumber: string,
    creditorId: string
}

export interface IContactData {
    phoneNumber: string,
    contactEmail: string,
    homepage: string
}

export enum SeasonAPIAction {
    create = 'CREATE',
    edit = 'EDIT',
    delete = 'DELETE',
}

export enum ListAPIAction {
    create = 'CREATE',
    edit = 'EDIT',
    delete = 'DELETE',
}

export enum CourtSurfaceEnum {
    CARPET = 'carpet',
    CLAY = 'clay',
    GRASS = 'grass',
    HARD = 'hard',
}

export enum CourtLocationEnum {
    INDOOR = 'indoor',
    OUTDOOR = 'outdoor',
}

export interface IListTableHeaderField {
    field: string;
    value: string | JSX.Element;
    sortable?: boolean;
    isLarge?: boolean;
}
export interface IListTableRowField {
    value: string | JSX.Element,
    className?: string
    isLarge?: boolean;
}

interface ReportError {
    email: string;
    row: number;
    error: ApiError;
}

export interface Report {
    processed: number;
    successful: number;
    errors: ReportError[];
}

export const PLAYERROLEMEMBER = 0;
export const PLAYERROLECLIENT = 1;
export const PLAYERROLEADMIN = 2;
export enum PlayerRole {
    MEMBER = 0,
    CLIENT = 1,
    ADMIN = 2,
}

export interface BkgPriceEvent {
    start: string,
    end: string,
    display: 'background',
    color: string,
    slotPrice?: number,
    resourceIds: string[],
    role: PlayerRole,
    isAdmin: boolean
}

export interface TimeSlot {
    slotStartDate: string,
    slotEndDate: string,
}

export interface BkgTimeSlot extends TimeSlot {
    slotPrice: number,
    courts: string[],
    role: PlayerRole
    isAdmin: boolean
}

export interface ErrorFromServer {
    code: string | number,
    data: {
        errorValues:
        { [key: string]: string | number | any; } | null;
    },
    message?: string
}

// TODO: Check if ErrorFromServer and ApiError are the same thing
export interface ApiError {
    code: string;
    data: {
        message: string;
        errorValues: {
            [key: string]: string | number
        }
    }
}

export interface IHeaders {
    [key: string]: string;
}

export interface IModalTexts {
    successDeleteHeader: string;
    successCreateHeader: string;
    successEditHeader: string;
    firstButtonSuccess: string;
    secondButtonSuccess: string;
    firstButtonDeleteSuccess?: string;
    secondButtonDeleteSuccess: string;
}

export interface IAutocompleteOption {
    label: string;
    value: string;
    key?: string;
}
export interface ICalculatedPriceInput {
    ownerId: string;
    courtId: string;
    hasGuests: boolean;
    startDateTime: string;
    duration: number;
    interval?: Interval;
    endingOn?: string;
}

export interface ICalculatedPrice {
    price: {
        amount: number,
        guestFee: number | null,
    }
}

export type Order = 'asc' | 'desc'

export interface JoinFacilityRequest {
    clubId: string,
    role: PlayerRole
}
export interface IClubRegisterForm {
    name: string
}

export enum BookedBy {
    MEMBERS = 'members',
    CLIENTS = 'clients',
    MIXED = 'mixed'
}

export enum PaymentProviderSetupStatus {
    NOT_INITIATED = 'NOT_INITIATED',
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
}
