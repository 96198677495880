import { isLoggedUserAdmin } from 'services/functionalStorage';
import { IReservation } from 'usecases/types/reservations';
import { IUser } from 'types';

export const isReservationModifiableByLoggedUser = (reservation: IReservation, loggedUser: IUser): boolean => {
    if (!reservation.owner) {
        return false;
    }

    if (isLoggedUserAdmin()) {
        return true;
    }

    return reservation.owner && reservation.owner.id === loggedUser.id;
};
