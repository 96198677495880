import { Card } from 'antd';
import { useEffect, useState } from 'react';
import { ROUTES } from 'routing/routes.enum';
import {
    getUserFromStorage, isLoggedUserAdmin, setClubInStorage,
} from 'services/functionalStorage';
import { translate } from 'translations/translate';
import { IClub } from 'usecases/types/club';
import { customHistory } from 'routing/customHistory';
import { useApi } from 'hooks/useApi';
import { getAllClubs } from 'usecases/club/getAllClubs';
import { logoutUser } from 'services/logoutUser';
import { ALL_VENUES_ICON } from 'config/constants';
import { ListPageHeader } from 'components/ListPageHeader/ListPageHeader';
import { getUserInformationAndSetInStorage } from 'usecases/users/getUserInformationAndSetInStorage';
import { FacilityCard } from './FacilityCard/FacilityCard';
import { AddFacilityCard } from './AddFacilityCard/AddFacilityCard';
import { filterFacilities } from './filterFacilities';
import { CreateFacilityButton } from './AddFacilityCard/CreateFacility/CreateFacilityButton';
import './SelectFacility.scss';

export const SelectFacility = () => {
    const callApi = useApi();
    const [newFacilityCreated, setNewFacilityCreated] = useState<IClub>();

    const currentUser = getUserFromStorage();
    if (currentUser.memberships === undefined) {
        logoutUser();
        return <></>;
    }

    const [facilities, setFacilities] = useState<IClub[]>([]);

    const goToCalendar = (cardFacility: IClub) => {
        setClubInStorage(cardFacility);

        const destination = isLoggedUserAdmin() ? ROUTES.ADMIN.DASHBOARD : ROUTES.PLAYER.DASHBOARD;
        customHistory.push(destination);
    };

    const { joinedFacilites, availableFacilities } = filterFacilities({
        facilities,
        userMemberships: currentUser.memberships,
    });

    const updateFacilityCreated = (facility: IClub) => {
        joinedFacilites.push(facility);
        setNewFacilityCreated(facility);
    };

    useEffect(() => {
        callApi(getUserInformationAndSetInStorage().then(getAllClubs).then(setFacilities));
    }, [newFacilityCreated]);

    return (
        <div>
            <ListPageHeader header={translate('select-facility-page_title')} iconUrl={ALL_VENUES_ICON}>
                <CreateFacilityButton updateFacilityCreated={updateFacilityCreated} />
            </ListPageHeader>
            <Card className="select-facility__card">

                <div className="select-facility__facility-cards">
                    {joinedFacilites.length > 0 && (
                        joinedFacilites.map(joinedFacility => {
                            const faclityMembership = currentUser.memberships
                                .find(membership => membership.clubId === joinedFacility.id)!;

                            return (
                                <FacilityCard
                                    key={joinedFacility.id}
                                    club={joinedFacility}
                                    membership={faclityMembership}
                                    onClick={() => goToCalendar(joinedFacility)}
                                />
                            );
                        })
                    )}

                    <AddFacilityCard
                        availableFacilities={availableFacilities}
                    />
                </div>
            </Card>

        </div>
    );
};
