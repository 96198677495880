/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import { ListTable } from 'components/ListTable/ListTable';
import { FACILITY_ICON_SEASONS } from 'config/constants';
import { translate } from 'translations/translate';
import { getClubFromStorage } from 'services/functionalStorage';
import { ISeason } from 'usecases/types/season';
import { useAppContext } from 'context/AppContext';
import { useModalContext } from 'context/ModalContext';
import { getClubSeasons } from 'usecases/club/getClubSeasons';
import { ActionElements } from 'components/ActionElements/ActionElements';
import { deleteSeason } from 'usecases/club/deleteSeason';
import { createSeason } from 'usecases/club/createSeason';
import { editSeason } from 'usecases/club/editSeason';
import {
    IListTableHeaderField, ISeasonForm, ListAPIAction,
} from 'types';
import { dateFormatter } from 'adapters/dateFormatter';
import { ListPageHeader } from 'components/ListPageHeader/ListPageHeader';
import { ListPageButton } from 'components/ListPageButton/ListPageButton';
import { getCourts } from 'usecases/court/getCourts';
import { InfoContent } from 'components/InfoContent/InfoContent';
import { Link, BrowserRouter, useHistory } from 'react-router-dom';
import { ROUTES } from 'routing/routes.enum';
import { InfoModalContent } from 'components/InfoModalContent/InfoModalContent';
import { arrayToMapByAttribute } from 'adapters/arrayToMapByAttribute';
import { DeleteModalContent } from 'components/DeleteModalContent/DeleteModalContent';
import moment from 'moment';
import { CreateEditSeasonModalContent } from 'components/CreateEditSeasonModalContent/CreateEditSeasonModalContent';
import { ICourt } from 'usecases/types/court';
import { useMobileMediaQuery } from 'hooks/useMobileMediaQuery';
import { useApi } from 'hooks/useApi';
import { SeasonsExplanation } from './SeasonsExplanation';
import { getSuccessModalContent } from './getSuccessModalContent';
import './SeasonsPage.scss';

export const SeasonsPage = () => {
    const { setIsSpinnerVisible, antdLocaleObject } = useAppContext();
    const { setModalContentProps, setModalContent } = useModalContext();
    const { locale: antdLocale } = antdLocaleObject;
    const [seasons, setSeasons] = useState<ISeason[]>([]);
    const [courts, setCourts] = useState<ICourt[]>([]);
    const { isMobile } = useMobileMediaQuery();
    const history = useHistory();
    const callApi = useApi();

    const headers: IListTableHeaderField[] = [
        {
            field: '',
            value: '',
        },
        {
            field: 'name',
            value: translate('seasons-page_table-header-name'),
        },
        {
            field: 'time',
            value: translate('seasons-page_table-header-time'),
        },
        {
            field: 'status',
            value: translate('seasons-page_table-header-status'),

        },
        {
            field: '',
            value: '',

        },
    ];

    const getStatusElement = (status: boolean) => {
        const statusString = status ? 'active' : 'inactive';
        return (
            <div className="seasons__item-status-container">
                <div className={`seasons__item-status-circle seasons__item-status-circle--${statusString}`} />
                <div className={`seasons__item-status-text seasons__item-status-text--${statusString}`}>
                    {translate(`seasons-page_list_status-${statusString}`)}

                </div>
            </div>
        );
    };

    const getListRows = (_seasons: ISeason[]): any[][] => {
        const sortedSeasons = _seasons
            .sort((a, b) => {
                if (!a.active && b.active) { return 1; }
                if (a.active && !b.active) { return -1; }
                return (new Date(b.endDateTime).getTime() - new Date(a.startDateTime).getTime());
            });

        return sortedSeasons.map(season => [
            '',
            season.name as string,
            `${dateFormatter(new Date(season.startDateTime), antdLocale)} 
            - 
            ${dateFormatter(new Date(season.endDateTime), antdLocale)}`,
            getStatusElement(season.active),
            <ActionElements
                firstButtonOnClick={() => {
                    setModalContentProps({
                        closable: !!isMobile,
                        onCancel: () => setModalContent(null),
                    });

                    setModalContent(getCreateEditSeasonModalContent(ListAPIAction.edit, season));
                }}
                secondButtonOnClick={() => {
                    setModalContentProps({
                        closable: false,
                        onCancel: () => setModalContent(null),
                    });

                    setModalContent(getDeleteModalContent(season));
                }}
            />,
        ]);
    };

    const onDeleteSeason = async (season: ISeason): Promise<void> => {
        setIsSpinnerVisible(true);
        try {
            await deleteSeason(getClubFromStorage().id, season);
            setModalContentProps({
                closable: false,
                onCancel: () => setModalContent(null),
            });

            setModalContent(getSuccessModalContent({
                actionFromAPI: ListAPIAction.delete,
                onSeeDetailsCallback: getFirstButtonSuccesModalOnClickCallback(ListAPIAction.delete, season),
                onGoBackCallback: getSecondButtonSuccesModalOnClickCallback(ListAPIAction.delete),
            }));
            const seasonsMap = arrayToMapByAttribute(seasons);
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [season.id]: seasonDeleted, ...restSeasons } = seasonsMap;
            setSeasons(Object.values(restSeasons));
        } catch (error: any) {
            notification.error({
                message: translate('error'),
                description: error.message,
            });
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    const onCreateSeason = async ({
        name, start, end, courtIds, active,
    }: ISeasonForm): Promise<void> => {
        setIsSpinnerVisible(true);
        try {
            const { id } = getClubFromStorage();
            const newSeason = await createSeason({
                clubId: id,
                name,
                startDate: start.format('YYYY-MM-DD'),
                endDate: end.format('YYYY-MM-DD'),
                courts: courtIds,
                active,
            });

            const seasonsExtended = [...seasons, newSeason];
            setSeasons(seasonsExtended);

            setModalContentProps({
                closable: false,
                onCancel: () => setModalContent(null),
            });

            setModalContent(getSuccessModalContent({
                actionFromAPI: ListAPIAction.create,
                onSeeDetailsCallback: getFirstButtonSuccesModalOnClickCallback(ListAPIAction.edit, newSeason),
                onGoBackCallback: getSecondButtonSuccesModalOnClickCallback(ListAPIAction.create),
            }));
        } catch (error: any) {
            notification.error(
                {
                    message: translate('error'),
                    description: error.message,
                },
            );
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    const onEditSeason = async (
        {
            name, start, end, courtIds, active,
        }: ISeasonForm,
        notify: boolean,
        season: ISeason,
    ): Promise<void> => {
        setIsSpinnerVisible(true);
        try {
            const { id } = getClubFromStorage();
            const editedSeason = await editSeason({
                clubId: id,
                seasonId: season.id,
                name,
                startDate: start.format('YYYY-MM-DD'),
                endDate: end.format('YYYY-MM-DD'),
                courts: courtIds,
                notify,
                active,
            });

            setModalContentProps({
                closable: false,
                onCancel: () => setModalContent(null),
            });

            setModalContent(getSuccessModalContent({
                actionFromAPI: ListAPIAction.edit,
                onSeeDetailsCallback: getFirstButtonSuccesModalOnClickCallback(ListAPIAction.edit, editedSeason),
                onGoBackCallback: getSecondButtonSuccesModalOnClickCallback(ListAPIAction.edit),
            }));
            const newSeasons = { ...arrayToMapByAttribute(seasons), [editedSeason.id]: editedSeason };
            setSeasons(Object.values(newSeasons));
        } catch (error: any) {
            notification.error(
                {
                    message: translate('error'),
                    description: error.message,
                },
            );
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    useEffect(() => {
        const setSeasonDataInState = async () => {
            const { id } = getClubFromStorage();
            const clubSeasons = await getClubSeasons(id);
            setSeasons(clubSeasons);
        };

        const getCourtsDataFromAPI = async () => {
            const APICourts = await getCourts(getClubFromStorage());
            setCourts(APICourts);
        };

        callApi(Promise.all([
            setSeasonDataInState(),
            getCourtsDataFromAPI(),
        ]));
    }, []);

    const getFirstButtonSuccesModalOnClickCallback = (action: ListAPIAction | undefined, season: ISeason): Function => {
        if (action === ListAPIAction.create || action === ListAPIAction.edit) {
            return () => {
                setModalContentProps({
                    closable: true,
                    onCancel: () => setModalContent(null),
                });

                setModalContent(getCreateEditSeasonModalContent(action, season));
            };
        }

        return () => { };
    };

    const getSecondButtonSuccesModalOnClickCallback = (action?: ListAPIAction): Function => {
        if (action) {
            return () => {
                setModalContent(null);
            };
        }

        return () => { };
    };

    const handleCourtsLink = () => {
        history.push(ROUTES.ADMIN.COURTS);
        setModalContent(null);
    };

    const InfoContentComponent = (
        <InfoContent
            texts={{
                secondButton: translate('seasons-page_no-courts-modal_first-button'),
            }}
            headerComponent={(
                <div className="info-content__header-container">
                    <p>
                        <span>{translate('seasons-page_no-courts-modal_text1')}</span>
                        <BrowserRouter>
                            <Link
                                className="info-content__link"
                                to={ROUTES.ADMIN.COURTS}
                                onClick={handleCourtsLink}
                            >
                                {translate('seasons-page_no-courts-modal_link-text')}
                            </Link>
                        </BrowserRouter>
                        <span>{translate('seasons-page_no-courts-modal_text2')}</span>
                    </p>
                </div>
            )}
            secondButtonSuccesModalOnClick={() => setModalContent(null)}
        />
    );

    const onFormProceed = (formValues: ISeasonForm, actionFromAPI?: ListAPIAction, season?: ISeason): Promise<void> => {
        if (actionFromAPI === ListAPIAction.edit) {
            const formCourtIds = formValues.courtIds;

            if (season!.courts.some(courtId => !formCourtIds.includes(courtId))) {
                setModalContentProps({
                    closable: false,
                    onCancel: () => setModalContent(null),
                });

                setModalContent(getRemoveCourtWarningContent({ ...formValues }, season!));
                return Promise.resolve();
            }

            if (formValues.end.isBefore(moment(season?.endDateTime))) {
                setModalContentProps({
                    closable: false,
                    onCancel: () => setModalContent(null),
                });

                setModalContent(getMoveEndDateSeasonWarningContent({ ...formValues }, season!));
                return Promise.resolve();
            }

            return onEditSeason({ ...formValues }, false, season!);
        }
        return onCreateSeason({ ...formValues });
    };

    const getDeleteModalContent = (season: ISeason) => (
        <DeleteModalContent
            headerText={translate('seasons-page_delete-modal_header')}
            cancelText={translate('seasons-page_delete-modal_back-button')}
            proceedText={translate('seasons-page_delete-modal_delete-button')}
            requiredText={translate('seasons-page_delete-modal_notify-required')}
            checkboxText={translate('seasons-page_delete-modal_notify-label')}
            onProceed={() => onDeleteSeason(season)}
            onCancel={() => setModalContent(null)}
        />
    );

    const getRemoveCourtWarningContent = ({
        name, start, end, courtIds, active,
    }: ISeasonForm, season: ISeason): JSX.Element => (
        <DeleteModalContent
            headerText={translate('seasons-page_deleted-courts-modal_header')}
            proceedText={translate('seasons-page_deleted-courts-modal_first-button')}
            cancelText={translate('seasons-page_deleted-courts-modal_second-button')}
            checkboxText={translate('seasons-page_deleted-courts-modal_text')}
            onProceed={(notify: boolean) => onEditSeason(
                {
                    name, start, end, courtIds, active,
                },
                notify,
                season,
            )}
            onCancel={() => {
                setModalContentProps({
                    closable: true,
                    onCancel: () => setModalContent(null),
                });

                setModalContent(getCreateEditSeasonModalContent(ListAPIAction.edit, season));
            }}
        />
    );

    const getMoveEndDateSeasonWarningContent = ({
        name, start, end, courtIds, active,
    }: ISeasonForm, season: ISeason): JSX.Element => (
        <DeleteModalContent
            headerText={translate('seasons-page_move-end-date-modal_header')}
            proceedText={translate('seasons-page_move-end-date-modal_first-button')}
            cancelText={translate('seasons-page_move-end-date-modal_second-button')}
            checkboxText={translate('seasons-page_move-end-date-modal_text')}
            onProceed={(notify: boolean) => onEditSeason(
                {
                    name, start, end, courtIds, active,
                },
                notify,
                season,
            )}
            onCancel={() => {
                setModalContentProps({
                    closable: true,
                    onCancel: () => setModalContent(null),
                });

                setModalContent(getCreateEditSeasonModalContent(ListAPIAction.edit, season));
            }}
        />
    );

    const getCreateEditSeasonModalContent = (actionFromAPI: ListAPIAction, season?: ISeason): JSX.Element => (
        <CreateEditSeasonModalContent
            APIAction={actionFromAPI}
            onCancel={() => setModalContent(null)}
            onProceed={(formValues: ISeasonForm) => onFormProceed(formValues, actionFromAPI, season)}
            season={season}
            courts={courts}
        />
    );

    return (
        <>
            <div className="seasons">
                <ListPageHeader header={translate('seasons-page_title')} iconUrl={FACILITY_ICON_SEASONS}>
                    <ListPageButton
                        grey
                        icon="?"
                        roundedIcon
                        onClick={() => {
                            setModalContentProps({
                                closable: true,
                                onCancel: () => setModalContent(null),
                            });
                            setModalContent(
                                <InfoModalContent>
                                    <SeasonsExplanation />
                                </InfoModalContent>,
                            );
                        }}
                    >
                        {translate('seasons-page_header-first-button')}
                    </ListPageButton>
                    <ListPageButton
                        icon="+"
                        onClick={async () => {
                            if (courts.length === 0) {
                                setModalContentProps({
                                    closable: false,
                                });
                                setModalContent(InfoContentComponent);
                                return;
                            }

                            setModalContentProps({
                                closable: !!isMobile,
                                onCancel: () => setModalContent(null),
                            });

                            setModalContent(getCreateEditSeasonModalContent(ListAPIAction.create));
                        }}
                    >
                        {translate('seasons-page_header-second-button')}
                    </ListPageButton>
                </ListPageHeader>
                {seasons.length > 0 && (
                    <ListTable
                        rows={getListRows(seasons)}
                        headers={headers}
                        getKey={(listItem: any[]) => listItem[2]}
                        firstColumn={false}
                    />
                )}
            </div>
        </>
    );
};
