import { Profile } from 'pages/common/Profile/Profile';
import { SelectFacility } from 'pages/common/SelectFacility/SelectFacility';
import { ROUTES } from './routes.enum';

export const multiClubRoutes = [
    {
        path: ROUTES.MULTICLUB.MY_FACILITIES,
        component: SelectFacility,
    },
    {
        path: ROUTES.MULTICLUB.PROFILE_SETTINGS,
        component: Profile,
    },
];
