import {
    COURT_CARPET_ICON, COURT_CLAY_ICON, COURT_DEFAULT_ICON, COURT_GRASS_ICON, COURT_HARD_ICON,
} from 'config/constants';
import { CourtSurfaceEnum } from 'types';

export const getSurfaceIconUrl = (surface?: CourtSurfaceEnum) => {
    switch (surface) {
    case CourtSurfaceEnum.CARPET:
        return COURT_CARPET_ICON;
    case CourtSurfaceEnum.CLAY:
        return COURT_CLAY_ICON;
    case CourtSurfaceEnum.GRASS:
        return COURT_GRASS_ICON;
    case CourtSurfaceEnum.HARD:
        return COURT_HARD_ICON;
    default:
        return COURT_DEFAULT_ICON;
    }
};
