import Icon from '@ant-design/icons';
import { dateFormatter } from 'adapters/dateFormatter';
import {
    DatePicker,
} from 'antd';
import { CALENDAR_ICON_GRAY } from 'config/constants';
import { useAppContext } from 'context/AppContext';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { translate } from 'translations/translate';
import { IReservation } from 'usecases/types/reservations';
import './DateTimePicker.scss';

interface IProps {
    reservation: IReservation;
    dateChange: (day: string) => void;
    isNewReservation: boolean
}

export const DateTimePicker = ({
    reservation,
    dateChange,
    isNewReservation,
}: IProps) => {
    const dateFormat = 'DD-MM-YYYY';
    const timeFormat = 'HH:mm';
    const [date, setDate] = useState<moment.Moment>(moment(reservation.startTime));
    const [time, setTime] = useState<moment.Moment>(moment(reservation.startTime));
    const { antdLocaleObject } = useAppContext();
    const { locale: antdLocale } = antdLocaleObject;
    const containerRef = useRef<HTMLElement>();

    useEffect(() => {
        const dateSrting = moment(date).format(dateFormat).valueOf();
        const timeString = moment(time).format(timeFormat).valueOf();
        const calculatedDate = moment(`${dateSrting} ${timeString}:00`, 'DD/MM/YYYY HH:mm').utc().format();
        dateChange(calculatedDate);
    }, [date, time]);

    const MemoizedIcon = React.useCallback(() => (
        <img
            className="season-modal__calendar-icon"
            src={CALENDAR_ICON_GRAY}
            alt="close_icon_white"
        />
    ), []);

    return (
        <div className="date-time-picker__card-cnt" ref={containerRef as any}>
            <div className="date-time-picker__picker-cnt">
                <p className="date-time-picker__title">
                    {translate('calendar-reservation_dialog-date_selector_label')}
                </p>
                <DatePicker
                    picker="date"
                    defaultValue={date}
                    format={value => dateFormatter(value.toDate(), antdLocale)}
                    onChange={_value => setDate(_value as moment.Moment)}
                    className="date-time-picker__date-picker-input"
                    dropdownClassName="date-time-picker__date-picker-dropdown"
                    allowClear={false}
                    getPopupContainer={() => containerRef.current as HTMLElement}
                    suffixIcon={<Icon component={MemoizedIcon} />}
                    disabled={!isNewReservation}
                />
            </div>
            <div className="date-time-picker__picker-cnt">
                <p className="date-time-picker__title">
                    {translate('calendar-reservation_dialog-time_selector_label')}
                </p>
                <input
                    className={`date-time-picker__time-picker ${isNewReservation ? ''
                        : 'date-time-picker__time-picker--disabled'}`}
                    value={time.format('HH:mm')}
                    onChange={event => setTime(moment(event.target.valueAsNumber))}
                    type="time"
                    disabled={!isNewReservation}
                    data-testid="time-input"
                />
            </div>
        </div>
    );
};
