import moment from 'moment/moment';

export class CHTime {
    private static readonly FORMATS = {
        WITH_TIMEZONE: 'HH:mmZ',
        WITHOUT_TIMEZONE: 'HH:mm',
    };

    private datetime: moment.Moment;

    private constructor(time: string, format: string) {
        this.datetime = moment(time, format);
    }

    public static fromStringWithTimezone(time: string) {
        return new CHTime(time, CHTime.FORMATS.WITH_TIMEZONE);
    }

    public toLocalStringWithoutTimezone() {
        return this.datetime.format(CHTime.FORMATS.WITHOUT_TIMEZONE);
    }
}
