/* eslint-disable */
import React from 'react';
import {
    Card,
} from 'antd';
import { DesktopBlurredBackground } from 'components/Reservations/DesktopBlurredBackground/DesktopBlurredBackground';
import './TermsPage.scss';

export const PrivacyPolicyPage = () => (
    <DesktopBlurredBackground>
        <Card className="user-terms__card">
            <h1 className='user-terms__card__title'>Datenschutzerkl&auml;rung</h1>

            <p><br/><h2>1) Information &uuml;ber die Erhebung personenbezogener Daten und Kontaktdaten des Verantwortlichen</h2></p>
            <p>
                1.1 Wir freuen uns, dass Sie unsere Website besuchen und bedanken uns f&uuml;r Ihr Interesse. Im Folgenden informieren wir Sie &uuml;ber den Umgang mit Ihren personenbezogenen Daten bei der Nutzung unserer Website. Personenbezogene Daten sind hierbei alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
            </p>
            <p>
                <br/>
                1.2 Verantwortlicher f&uuml;r die Datenverarbeitung auf dieser Website im Sinne der Datenschutz-Grundverordnung (DSGVO) ist Tennis-Point GmbH, Hans-B&ouml;ckler-Str. 29-35, 33442 Herzebrock, Deutschland, Tel.: 05245 / 8353 - 200, Fax: 05245 / 8353 - 499, E-Mail: <a href='mailto:datenschutz@tennis-point.de'>datenschutz@tennis-point.de</a>. Der f&uuml;r die Verarbeitung von personenbezogenen Daten Verantwortliche ist diejenige nat&uuml;rliche oder juristische Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.
            </p>
            <p>
                <br/>
                1.3 Der Verantwortliche hat einen Datenschutzbeauftragten bestellt, dieser ist wie folgt zu erreichen: <a href='mailto:datenschutz@tennis-point.de'>datenschutz@tennis-point.de</a>
            </p>
            <br/>

            <p><br/><h2>2) Datenerfassung beim Besuch unserer Website</h2></p>
            <p>
                Bei der bloß informatorischen Nutzung unserer Website, also wenn Sie sich nicht registrieren oder uns anderweitig Informationen &uuml;bermitteln, erheben wir nur solche Daten, die Ihr Browser an unseren Server &uuml;bermittelt (sog. &bdquo;Server-Logfiles&ldquo;). Wenn Sie unsere Website aufrufen, erheben wir die folgenden Daten, die f&uuml;r uns technisch erforderlich sind, um Ihnen die Website anzuzeigen:
            </p>
            <p>
                <br/>
                - Unsere besuchte Website
            </p>
            <p>
                <br/>
                - Datum und Uhrzeit zum Zeitpunkt des Zugriffes
            </p>
            <p>
                <br/>
                - Menge der gesendeten Daten in Byte
            </p>
            <p>
                <br/>
                - Quelle/Verweis, von welchem Sie auf die Seite gelangten
            </p>
            <p>
                <br/>
                - Verwendeter Browser
            </p>
            <p>
                <br/>
                - Verwendetes Betriebssystem
            </p>
            <p>
                <br/>
                - Verwendete IP-Adresse (ggf.: in anonymisierter Form)
            </p>
            <p>
                <br/>
                Die Verarbeitung erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilit&auml;t und Funktionalit&auml;t unserer Website. Eine Weitergabe oder anderweitige Verwendung der Daten findet nicht statt. Wir behalten uns allerdings vor, die Server-Logfiles nachtr&auml;glich zu &uuml;berpr&uuml;fen, sollten konkrete Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.
            </p>
            <br/>

            <p><br/><h2>3) Hosting mit Amazon Web Services (AWS)</h2></p>
            <p>
                Wir hosten unsere Website bei AWS. Anbieter ist die Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, 1855 Luxemburg („AWS“).
            </p>
            <br/>
            <p>
                Wenn Sie unsere Website besuchen, werden Ihre personenbezogenen Daten auf den Servern von AWS verarbeitet. Hierbei können auch personenbezogene Daten an das Mutterunternehmen von AWS in die USA übermittelt werden. Die Datenübertragung in die USA wird auf die EU-Standardvertragsklauseln gestützt. Details finden Sie hier:
            </p>
            <br/>
            <p>
                https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/.
            </p>
            <br/>
            <p>
                Weitere Informationen entnehmen Sie der Datenschutzerklärung von AWS:
            </p>
            <br/>
            <p>
                https://aws.amazon.com/de/privacy/?nc1=f_pr.
            </p>
            <br/>

            <p>
                Die Verwendung von AWS erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer möglichst zuverlässigen Darstellung unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>
            <br/>

            <p>
                Wir haben einen Vertrag über Auftragsverarbeitung mit AWS geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass AWS die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
            </p>
            <br/>


            <p><br/><h2>4) Bei Erstellung eines Nutzerkontos</h2></p>
            <p>
                Um Funktionalit&auml;ten wie die Platzreservierung nutzen zu k&ouml;nnen, bieten wir Ihnen die M&ouml;glichkeit, ein Nutzerkonto zu erstellen. F&uuml;r die Registrierung und die Erstellung des Nutzerkontos ben&ouml;tigen wir folgende Pflichtinformationen:
            </p>
            <p>
                <br/>
                &emsp;- Vorname, Nachname, Geschlecht<br />
                &emsp;- E-Mail-Adresse<br />
                &emsp;- Sprache<br />
                &emsp;- Passwort<br />
            </p>
            <p>
                <br/>
                Die Datenverarbeitung erfolgt auf Ihre Anfrage hin und ist nach Art. 6 Abs. 1 S. 1 lit. b und lit. f DSGVO zu den genannten Zwecken f&uuml;r die Erf&uuml;llung des Vertrages und f&uuml;r sonstige vorvertragliche Ma&szlig;nahmen sowie aufgrund unserer berechtigten Interessen erforderlich.
            </p>
            <p>
                <br/>
                Wir arbeiten mit
            </p>
            <p>
                <br/>
                Sendinblue GmbH, K&ouml;penicker Stra&szlig;e 126, 10179 Berlin, Deutschland
            </p>
            <p>
                <br/>
                als spezialisierter Dienstleister f&uuml;r den Versand von Transaktionsmails wie Best&auml;tigungsmails, Stornierungsmail oder Passwort-vergessen-Mails zusammen, an den wir Ihre Stammdaten (Vor- und Nachname, Geschlecht, E-Mail-Adresse, Sprache) &uuml;bermitteln. Diese Daten werden auf den Servern von Sendinblue in Deutschland gespeichert. Die Datenverarbeitung erfolgt auf Ihre Anfrage hin und ist nach Art. 6 Abs. 1 S. 1 lit. b und lit. f DSGVO zu den genannten Zwecken f&uuml;r die Erf&uuml;llung des Vertrages und f&uuml;r sonstige vorvertragliche Ma&szlig;nahmen sowie aufgrund unserer berechtigten Interessen erforderlich.
            </p>
            <p>
                <br/>
                Die f&uuml;r die Registrierung und Anmeldung von uns erhobenen personenbezogenen Daten speichern wir bis Sie uns gegen&uuml;ber die L&ouml;schung Ihres Kundenkontos beantragen. Im Fall des L&ouml;schbegehrens halten wir die erforderlichen Informationen zu Ihren Bestellungen nur vor, sofern eine dar&uuml;berhinausgehende Speicherung auf Grundlage von Art. 6 Abs. 1 S. 1 lit b DSGVO zur Vertragserf&uuml;llung erforderlich ist oder wir nach Artikel 6 Abs. 1 S. 1 lit. c DSGVO aufgrund von steuer- und handelsrechtlichen Aufbewahrungs- und Dokumentationspflichten (aus HGB, StGB oder AO) zu einer l&auml;ngeren Speicherung verpflichtet sind.
            </p>
            <br/>

            <p><br /><h2>5) Cookies</h2></p>
            <p>
                Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu erm&ouml;glichen, verwenden wir auf verschiedenen Seiten sogenannte Cookies. Hierbei handelt es sich um kleine Textdateien, die auf Ihrem Endger&auml;t abgelegt werden. Einige der von uns verwendeten Cookies werden nach dem Ende der Browser-Sitzung, also nach Schlie&szlig;en Ihres Browsers, wieder gel&ouml;scht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endger&auml;t und erm&ouml;glichen uns oder unseren Partnerunternehmen (Cookies von Drittanbietern), Ihren Browser beim n&auml;chsten Besuch wiederzuerkennen (persistente Cookies). Werden Cookies gesetzt, erheben und verarbeiten diese im individuellen Umfang bestimmte Nutzerinformationen wie Browser- und Standortdaten sowie IP-Adresswerte. Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gel&ouml;scht, die sich je nach Cookie unterscheiden kann.
            </p>
            <p>
                <br/>
                Sofern durch einzelne von uns implementierte Cookies auch personenbezogene Daten verarbeitet werden, erfolgt die Verarbeitung gem&auml;&szlig; Art. 6 Abs. 1 lit. b DSGVO entweder zur Durchf&uuml;hrung des Vertrages oder gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO zur Wahrung unserer berechtigten Interessen an der bestm&ouml;glichen Funktionalit&auml;t der Website sowie einer kundenfreundlichen und effektiven Ausgestaltung des Seitenbesuchs.
            </p>
            <p>
                <br/>
                Wir arbeiten unter Umst&auml;nden mit Werbepartnern zusammen, die uns helfen, unser Internetangebot f&uuml;r Sie interessanter zu gestalten. Zu diesem Zweck werden bei Ihrem Besuch unserer Website auch Cookies von Partnerunternehmen auf Ihrer Festplatte gespeichert (Cookies von Drittanbietern). Wenn wir mit vorbenannten Werbepartnern zusammenarbeiten, werden Sie &uuml;ber den Einsatz derartiger Cookies und den Umfang der jeweils erhobenen Informationen innerhalb der nachstehenden Abs&auml;tze individuell und gesondert informiert.
            </p>
            <p>
                <br/>
                Bitte beachten Sie, dass Sie Ihren Browser so einstellen k&ouml;nnen, dass Sie &uuml;ber das Setzen von Cookies informiert werden und einzeln &uuml;ber deren Annahme entscheiden oder die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder generell ausschlie&szlig;en k&ouml;nnen. Jeder Browser unterscheidet sich in der Art, wie er die Cookie-Einstellungen verwaltet. Diese ist in dem Hilfemen&uuml; jedes Browsers beschrieben, welches Ihnen erl&auml;utert, wie Sie Ihre Cookie-Einstellungen &auml;ndern k&ouml;nnen. Diese finden Sie f&uuml;r die jeweiligen Browser unter den folgenden Links:
            </p>
            <p>
                <br/>
                Internet Explorer: <a href='http://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies'>http://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies</a>
            </p>
            <p>
                <br/>
                Firefox: <a href='https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen'>https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen</a>
            </p>
            <p>
                <br/>
                Chrome: <a href='http://support.google.com/chrome/bin/answer.py?hl=de&hlrm=en&answer=95647'>http://support.google.com/chrome/bin/answer.py?hl=de&amp;hlrm=en&amp;answer=95647</a>
            </p>
            <p>
                <br/>
                Safari: <a href='https://support.apple.com/de-de/guide/safari/sfri11471/mac'>https://support.apple.com/de-de/guide/safari/sfri11471/mac</a>
            </p>
            <p>
                <br/>
                Opera: <a href='http://help.opera.com/Windows/10.20/de/cookies.html'>http://help.opera.com/Windows/10.20/de/cookies.html</a>
            </p>
            <p>
                <br />
                Bitte beachten Sie, dass bei Nichtannahme von Cookies die Funktionalit&auml;t unserer Website eingeschr&auml;nkt sein kann.
            </p>
            <br/>

            <p><br /><h2>6) Kontaktaufnahme</h2></p>
            <p>
                Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle der Nutzung eines Kontaktformulars erhoben werden, ist aus dem jeweiligen Kontaktformular ersichtlich. Diese Daten werden ausschlie&szlig;lich zum Zweck der Beantwortung Ihres Anliegens bzw. f&uuml;r die Kontaktaufnahme und die damit verbundene technische Administration gespeichert und verwendet. Rechtsgrundlage f&uuml;r die Verarbeitung dieser Daten ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO. Zielt Ihre Kontaktierung auf den Abschluss eines Vertrages ab, so ist zus&auml;tzliche Rechtsgrundlage f&uuml;r die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO. Ihre Daten werden nach abschlie&szlig;ender Bearbeitung Ihrer Anfrage gel&ouml;scht. Dies ist der Fall, wenn sich aus den Umst&auml;nden entnehmen l&auml;sst, dass der betroffene Sachverhalt abschlie&szlig;end gekl&auml;rt ist und sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.</p>
            <br/>

            <p><br /><h2>7) Bei Nutzung der Online-Zahlung &uuml;ber Stripe</h2></p>
            <p>
                Wir bieten die M&ouml;glichkeit, die Platzreservierung online &uuml;ber den Zahlungsdienstleister Stripe Payments Europe Limited, 3 Dublin Landings, North Wall Quay, Dublin 1, Dublin, D01C4E0, Irland (&bdquo;Stripe&ldquo;) abzuwickeln.
            </p>
            <p>
                <br />
                Wenn Sie &uuml;ber unsere Website Pl&auml;tze zur Reservierung anbieten und f&uuml;r die Platzbuchung Online-Zahlung anbieten wollen, werden Sie zur Registrierung bei Stripe von unserer Website zu Stripe weitergeleitet.
            </p>
            <p>
                <br />
                Wenn Sie &uuml;ber unsere Website einen Platz buchen und online zahlen m&ouml;chten, werden Sie zur Zahlungsabwicklung zu Stripe weitergeleitet.
            </p>
            <p>
                <br />
                Dabei geben wir keine personenbezogenen Daten an Stripe weiter. Stripe erh&auml;lt die zur Zahlungsabwicklung ben&ouml;tigten Daten erst, wenn Sie diese bei Stripe eingeben. Weitere Informationen zum Datenschutz der von Ihnen eingegebenen Daten finden Sie in der Datenschutzerkl&auml;rung von Stripe unter: <a href='https://stripe.com/de/privacy'>https://stripe.com/de/privacy</a>
            </p>
            <br/>

            <p><br /><h2>8) Online-Marketing</h2></p>
            <p>
                <h3>8.1 Einsatz von Google Ads Conversion-Tracking</h3>
            </p>
            <p>
                Diese Website nutzt das Online-Werbeprogramm &quot;Google Ads&quot; und im Rahmen von Google Ads das Conversion-Tracking der Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland (&bdquo;Google&ldquo;). Wir nutzen das Angebot von Google Ads, um mit Hilfe von Werbemitteln (sogenannten Google Adwords) auf externen Webseiten auf unsere attraktiven Angebote aufmerksam zu machen. Wir k&ouml;nnen in Bezug zu den Daten der Werbekampagnen ermitteln, wie erfolgreich die einzelnen Werbema&szlig;nahmen sind. Wir verfolgen damit das Anliegen, Ihnen Werbung anzuzeigen, die f&uuml;r Sie von Interesse ist, unsere Website f&uuml;r Sie interessanter zu gestalten und eine faire Berechnung der anfallenden Werbekosten zu erreichen.
            </p>
            <p>
                <br />
                Das Cookie f&uuml;r Conversion-Tracking wird gesetzt, wenn ein Nutzer auf eine von Google geschaltete Ads-Anzeige klickt. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrem Endger&auml;t abgelegt werden. Diese Cookies verlieren in der Regel nach 30 Tagen ihre G&uuml;ltigkeit und dienen nicht der pers&ouml;nlichen Identifizierung. Besucht der Nutzer bestimmte Seiten dieser Website und ist das Cookie noch nicht abgelaufen, k&ouml;nnen Google und wir erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde. Jeder Google Ads-Kunde erh&auml;lt ein anderes Cookie. Cookies k&ouml;nnen somit nicht &uuml;ber die Websites von Google Ads-Kunden nachverfolgt werden. Die mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu, Conversion-Statistiken f&uuml;r Google Ads-Kunden zu erstellen, die sich f&uuml;r Conversion-Tracking entschieden haben. Die Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer pers&ouml;nlich identifizieren lassen.
            </p>
            <p>
                <br />
                Details zu den durch Google Ads Conversion Tracking angesto&szlig;enen Verarbeitungen und zum Umgang Googles mit Daten von Websites finden Sie hier: <br />
                <a href='https://policies.google.com/technologies/partner-sites'>https://policies.google.com/technologies/partner-sites</a>
            </p>
            <p>
                <br />
                Wenn Sie nicht am Tracking teilnehmen m&ouml;chten, k&ouml;nnen Sie diese Nutzung blockieren, indem Sie das Cookie des Google Conversion-Trackings &uuml;ber ihren Internet-Browser unter dem Stichwort &bdquo;Nutzereinstellungen&ldquo; deaktivieren. Sie werden sodann nicht in die Conversion-Tracking Statistiken aufgenommen. Wir setzen Google Ads auf Grund unseres berechtigten Interesses an einer zielgerichteten Werbung gem. Art. 6 Abs. 1 lit. f DSGVO ein. Im Rahmen der Nutzung von Google Ads kann es auch zu einer &Uuml;bermittlung von personenbezogenen Daten an die Server der Google LLC. in den USA kommen.
            </p>
            <p>
                <br />
                Unter der nachstehenden Internetadresse erhalten Sie weitere Informationen &uuml;ber die Datenschutzbestimmungen von Google: <a href='https://www.google.de/policies/privacy/'>https://www.google.de/policies/privacy/</a>
            </p>
            <p>
                <br />
                Sie k&ouml;nnen dem Setzen von Cookies durch Google Ads Conversion-Tracking dauerhaft widersprechen, indem Sie das unter folgendem Link verf&uuml;gbare Browser-Plug-in von Google herunterladen und installieren:
            </p>
            <p>
                <br />
                <a href="https://www.google.com/settings/ads/plugin?hl=de">https://www.google.com/settings/ads/plugin?hl=de</a>
            </p>
            <p>
                <br />
                Bitte beachten Sie, dass bestimmte Funktionen dieser Website m&ouml;glicherweise nicht oder nur eingeschr&auml;nkt genutzt werden k&ouml;nnen, wenn Sie die Verwendung von Cookies deaktiviert haben.
            </p>
            <p>
                <br />
                Soweit rechtlich erforderlich, haben wir zur vorstehend dargestellten Verarbeitung Ihrer Daten Ihre Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO eingeholt. Sie k&ouml;nnen Ihre erteilte Einwilligung jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen. Um Ihren Widerruf auszu&uuml;ben, deaktivieren Sie diesen Dienst im auf der Webseite bereitgestellten &quot;Cookie-Consent-Tool&quot; oder befolgen Sie alternativ die vorstehend geschilderte M&ouml;glichkeit zur Vornahme eines Widerspruchs.
            </p>
            <p>
                <br />
                <h3>8.2 Google Marketing Platform</h3>
            </p>
            <p>
                Diese Webseite nutzt das Online Marketing Tool Google Marketing Platform des Betreibers Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland (&quot;GMP&quot;).
            </p>
            <p>
                <br />
                GMP setzt Cookies ein, um f&uuml;r die Nutzer relevante Anzeigen zu schalten, die Berichte zur Kampagnenleistung zu verbessern oder um zu vermeiden, dass ein Nutzer die gleichen Anzeigen mehrmals sieht. &Uuml;ber eine Cookie-ID erfasst Google, welche Anzeigen in welchem Browser geschaltet werden und kann so verhindern, dass diese mehrfach angezeigt werden. Die Verarbeitung erfolgt auf Basis unseres berechtigten Interesses an der optimalen Vermarktung unserer Website gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO.
            </p>
            <p>
                <br />
                Dar&uuml;ber hinaus kann GMP mithilfe von Cookie-IDs sog. Conversions erfassen, die Bezug zu Anzeigenanfragen haben. Das ist etwa der Fall, wenn ein Nutzer eine GMP-Anzeige sieht und sp&auml;ter bei Nutzung des gleichen Browsers die Website des Werbetreibenden aufruft und &uuml;ber diese Website etwas kauft. Laut Google enthalten GMP-Cookies keine personenbezogenen Informationen.
            </p>
            <p>
                <br />
                Aufgrund der eingesetzten Marketing-Tools baut Ihr Browser automatisch eine direkte Verbindung mit dem Server von Google auf. Wir haben keinen Einfluss auf den Umfang und die weitere Verwendung der Daten, die durch den Einsatz dieses Tools durch Google erhoben werden und informieren Sie daher entsprechend unserem Kenntnisstand wie folgt: Durch die Einbindung von GMP erh&auml;lt Google die Information, dass Sie den entsprechenden Teil unseres Internetauftritts aufgerufen oder eine Anzeige von uns angeklickt haben. Sofern Sie bei einem Dienst von Google registriert sind, kann Google den Besuch Ihrem Account zuordnen. Selbst wenn Sie nicht bei Google registriert sind bzw. sich nicht eingeloggt haben, besteht die M&ouml;glichkeit, dass der Anbieter Ihre IP-Adresse in Erfahrung bringt und speichert. Im Rahmen der Nutzung von GMP kann es auch zu einer &Uuml;bermittlung von personenbezogenen Daten an die Server der Google LLC. in den USA kommen.
            </p>
            <p>
                <br />
                Wenn Sie der Teilnahme an diesem Tracking-Verfahren widersprechen m&ouml;chten, k&ouml;nnen Sie Cookies f&uuml;r Conversion-Tracking deaktivieren, indem Sie Ihren Browser so einstellen, dass Cookies von der Domain www.googleadservices.com blockiert werden, (s. <a href='https://www.google.de/settings/ads'>https://www.google.de/settings/ads</a>), wobei diese Einstellung gel&ouml;scht wird, wenn Sie Ihre Cookies deaktivieren. Alternativ k&ouml;nnen Sie sich bei der Digital Advertising Alliance unter der Internetadresse www.aboutads.info &uuml;ber das Setzen von Cookies informieren und Ihre gew&uuml;nschten Einstellungen vornehmen. Schlie&szlig;lich k&ouml;nnen Sie Ihren Browser so einstellen, dass Sie &uuml;ber das Setzen von Cookies informiert werden und einzeln &uuml;ber deren Annahme entscheiden oder die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder generell ausschlie&szlig;en. Bei der Nichtannahme von Cookies kann die Funktionalit&auml;t unserer Website eingeschr&auml;nkt sein.
            </p>
            <p>
                <br />
                Unter der nachstehenden Internetadresse erhalten Sie weitere Informationen &uuml;ber die Datenschutzbestimmungen von GMP by Google: <a href='https://www.google.de/policies/privacy/'>https://www.google.de/policies/privacy/</a>
            </p>
            <p>
                <br />
                Soweit rechtlich erforderlich, haben wir zur vorstehend dargestellten Verarbeitung Ihrer Daten Ihre Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO eingeholt. Sie k&ouml;nnen Ihre erteilte Einwilligung jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen. Um Ihren Widerruf auszu&uuml;ben, befolgen Sie bitte die vorstehend geschilderte M&ouml;glichkeit zur Vornahme eines Widerspruchs.
            </p>
            <br/>

            <p><br /><h2>9) Tools und Sonstiges</h2></p>
            <p>
                Auf unserer Webseite kommt der Zustimmungsverwaltungsdienst OneTrust der 2020 OneTrust, LLC (Dixon House, 1 Lloyd’s Avenue, London EC3N 3DQ, United Kingdom). In diesem Zusammenhang werden Datum und Uhrzeit des Besuchs, Browser-Informationen, Informationen zu Einwilligungen, Ger&auml;teinformation und die IP-Adresse des anfragenden Ger&auml;tes verarbeitet. Rechtsgrundlage ist Art. 6 Abs. 1 S. 1. lit. f DSGVO (berechtigtes Interesse). Die Einholung und Verwaltung von rechtlich erforderlichen Einwilligungen ist als berechtigtes Interesse im Sinne der vorgenannten Vorschrift anzusehen, da der Eingriff in die Rechte der Nutzinnen und Nutzer infolge der Verwendung anonymisierter IP-Adressen und der Einschaltung eines in Deutschland ans&auml;ssigen Dienstleisters sehr gering sind. OneTrust speichert Einwilligungen und Widerrufe in unserem Auftrag und auf unsere Weisung. Die Speicherung erfolgt auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f DSGVO. Der Rechenschaftspflicht gem&auml;&szlig; Art. 5 Abs. 2 DSGVO nachkommen zu k&ouml;nnen, ist ein berechtigtes Interesse. Weitere Informationen zum Datenschutz bei OneTrust finden Sie hier:
            </p>
            <p>
                <a href='https://www.onetrust.de/datenschutzerklaerung/#xd_co_f=ZTg2NzAyYWYtZWI0ZS00ZTJiLWE2MTEtNTUwZTM3ZGRjYjRm~'>https://www.onetrust.de/datenschutzerklaerung/#xd_co_f=ZTg2NzAyYWYtZWI0ZS00ZTJiLWE2MTEtNTUwZTM3ZGRjYjRm~</a>
            </p>
            <br/>

            <p><br /><h2>10) Rechte des Betroffenen</h2></p>
            <p>
                Das geltende Datenschutzrecht gew&auml;hrt Ihnen gegen&uuml;ber dem Verantwortlichen hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten umfassende Betroffenenrechte (Auskunfts- und Interventionsrechte), &uuml;ber die wir Sie nachstehend informieren:
            </p>
            <p>
                <br />
                - Auskunftsrecht gem&auml;&szlig; Art. 15 DSGVO: Sie haben insbesondere ein Recht auf Auskunft &uuml;ber Ihre von uns verarbeiteten personenbezogenen Daten, die Verarbeitungszwecke, die Kategorien der verarbeiteten personenbezogenen Daten, die Empf&auml;nger oder Kategorien von Empf&auml;ngern, gegen&uuml;ber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer bzw. die Kriterien f&uuml;r die Festlegung der Speicherdauer, das Bestehen eines Rechts auf Berichtigung, L&ouml;schung, Einschr&auml;nkung der Verarbeitung, Widerspruch gegen die Verarbeitung, Beschwerde bei einer Aufsichtsbeh&ouml;rde, die Herkunft Ihrer Daten, wenn diese nicht durch uns bei Ihnen erhoben wurden, das Bestehen einer automatisierten Entscheidungsfindung einschlie&szlig;lich Profiling und ggf. aussagekr&auml;ftige Informationen &uuml;ber die involvierte Logik und die Sie betreffende Tragweite und die angestrebten Auswirkungen einer solchen Verarbeitung, sowie Ihr Recht auf Unterrichtung, welche Garantien gem&auml;&szlig; Art. 46 DSGVO bei Weiterleitung Ihrer Daten in Drittl&auml;nder bestehen;
            </p>
            <p>
                <br />
                - Recht auf Berichtigung gem&auml;&szlig; Art. 16 DSGVO: Sie haben ein Recht auf unverz&uuml;gliche Berichtigung Sie betreffender unrichtiger Daten und/oder Vervollst&auml;ndigung Ihrer bei uns gespeicherten unvollst&auml;ndigen Daten;
            </p>
            <p>
                <br />
                - Recht auf L&ouml;schung gem&auml;&szlig; Art. 17 DSGVO: Sie haben das Recht, die L&ouml;schung Ihrer personenbezogenen Daten bei Vorliegen der Voraussetzungen des Art. 17 Abs. 1 DSGVO zu verlangen. Dieses Recht besteht jedoch insbesondere dann nicht, wenn die Verarbeitung zur Aus&uuml;bung des Rechts auf freie Meinungs&auml;u&szlig;erung und Information, zur Erf&uuml;llung einer rechtlichen Verpflichtung, aus Gr&uuml;nden des &ouml;ffentlichen Interesses oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen erforderlich ist;
            </p>
            <p>
                <br />
                - Recht auf Einschr&auml;nkung der Verarbeitung gem&auml;&szlig; Art. 18 DSGVO: Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, solange die von Ihnen bestrittene Richtigkeit Ihrer Daten &uuml;berpr&uuml;ft wird, wenn Sie eine L&ouml;schung Ihrer Daten wegen unzul&auml;ssiger Datenverarbeitung ablehnen und stattdessen die Einschr&auml;nkung der Verarbeitung Ihrer Daten verlangen, wenn Sie Ihre Daten zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen ben&ouml;tigen, nachdem wir diese Daten nach Zweckerreichung nicht mehr ben&ouml;tigen oder wenn Sie Widerspruch aus Gr&uuml;nden Ihrer besonderen Situation eingelegt haben, solange noch nicht feststeht, ob unsere berechtigten Gr&uuml;nde &uuml;berwiegen;
            </p>
            <p>
                <br />
                - Recht auf Unterrichtung gem&auml;&szlig; Art. 19 DSGVO: Haben Sie das Recht auf Berichtigung, L&ouml;schung oder Einschr&auml;nkung der Verarbeitung gegen&uuml;ber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empf&auml;ngern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder L&ouml;schung der Daten oder Einschr&auml;nkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unm&ouml;glich oder ist mit einem unverh&auml;ltnism&auml;&szlig;igen Aufwand verbunden. Ihnen steht das Recht zu, &uuml;ber diese Empf&auml;nger unterrichtet zu werden.
            </p>
            <p>
                <br />
                - Recht auf Daten&uuml;bertragbarkeit gem&auml;&szlig; Art. 20 DSGVO: Sie haben das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, g&auml;ngigen und maschinenlesebaren Format zu erhalten oder die &Uuml;bermittlung an einen anderen Verantwortlichen zu verlangen, soweit dies technisch machbar ist;
            </p>
            <p>
                <br />
                - Recht auf Widerruf erteilter Einwilligungen gem&auml;&szlig; Art. 7 Abs. 3 DSGVO: Sie haben das Recht, eine einmal erteilte Einwilligung in die Verarbeitung von Daten jederzeit mit Wirkung f&uuml;r die Zukunft zu widerrufen. Im Falle des Widerrufs werden wir die betroffenen Daten unverz&uuml;glich l&ouml;schen, sofern eine weitere Verarbeitung nicht auf eine Rechtsgrundlage zur einwilligungslosen Verarbeitung gest&uuml;tzt werden kann. Durch den Widerruf der Einwilligung wird die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht ber&uuml;hrt;
            </p>
            <p>
                <br />
                - Recht auf Beschwerde gem&auml;&szlig; Art. 77 DSGVO: Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verst&ouml;&szlig;t, haben Sie - unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs - das Recht auf Beschwerde bei einer Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsortes, Ihres Arbeitsplatzes oder des Ortes des mutma&szlig;lichen Versto&szlig;es.
            </p>
            <br/>

            <p><br /><h2>11) Widerspruchsrecht</h2></p>
            <p>
                Wenn wir im Rahmen einer Interessenabw&auml;gung Ihre personenbezogenen Daten aufgrund unseres &uuml;berwiegenden berechtigten Interesses verarbeiten, haben Sie das jederzeitige Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, gegen diese Verarbeitung Widerspruch mit Wirkung f&uuml;r die Zukunft einzulegen.
            </p>
            <p>
                <br />
                Machen Sie von Ihrem Widerspruchsrecht Gebrauch, beenden wir die Verarbeitung der betroffenen Daten. Eine Weiterverarbeitung bleibt aber vorbehalten, wenn wir zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen k&ouml;nnen, die Ihre Interessen, Grundrechte und Grundfreiheiten &uuml;berwiegen, oder wenn die Verarbeitung der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen dient.
            </p>
            <p>
                <br />
                Werden Ihre personenbezogenen Daten von uns verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen. Sie k&ouml;nnen den Widerspruch wie oben beschrieben aus&uuml;ben.
            </p>
            <p>
                <br />
                Machen Sie von Ihrem Widerspruchsrecht Gebrauch, beenden wir die Verarbeitung der betroffenen Daten zu Direktwerbezwecken.
            </p>
            <br/>

            <p><br /><h2>12) Dauer der Speicherung personenbezogener Daten</h2></p>
            <p>
                Die Dauer der Speicherung von personenbezogenen Daten bemisst sich anhand der jeweiligen Rechtsgrundlage, am Verarbeitungszweck und – sofern einschl&auml;gig – zus&auml;tzlich anhand der jeweiligen gesetzlichen Aufbewahrungsfrist (z.B. handels- und steuerrechtliche Aufbewahrungsfristen).
            </p>
            <p>
                <br />
                Bei der Verarbeitung von personenbezogenen Daten auf Grundlage einer ausdr&uuml;cklichen Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO werden diese Daten so lange gespeichert, bis der Betroffene seine Einwilligung widerruft.
            </p>
            <p>
                <br />
                Existieren gesetzliche Aufbewahrungsfristen f&uuml;r Daten, die im Rahmen rechtsgesch&auml;ftlicher bzw. rechtsgesch&auml;fts&auml;hnlicher Verpflichtungen auf der Grundlage von Art. 6 Abs. 1 lit. b DSGVO verarbeitet werden, werden diese Daten nach Ablauf der Aufbewahrungsfristen routinem&auml;&szlig;ig gel&ouml;scht, sofern sie nicht mehr zur Vertragserf&uuml;llung oder Vertragsanbahnung erforderlich sind und/oder unsererseits kein berechtigtes Interesse an der Weiterspeicherung fortbesteht.
            </p>
            <p>
                <br />
                Bei der Verarbeitung von personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO aus&uuml;bt, es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen Person &uuml;berwiegen, oder die Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.
            </p>
            <p>
                <br />
                Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der Direktwerbung auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 2 DSGVO aus&uuml;bt.
            </p>
            <p>
                <br />
                Sofern sich aus den sonstigen Informationen dieser Erkl&auml;rung &uuml;ber spezifische Verarbeitungssituationen nichts anderes ergibt, werden gespeicherte personenbezogene Daten im &Uuml;brigen dann gel&ouml;scht, wenn sie f&uuml;r die Zwecke, f&uuml;r die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind.
            </p>
            <br/>

            <p><br /><h2>13) Aktualit&auml;t und Änderung dieser Datenschutz</h2></p>
            <p>
                Diese Datenschutzerkl&auml;rung ist aktuell g&uuml;ltig und hat den Stand M&auml;rz  2023.
            </p>
            <p>
                <br />
                Durch die Weiterentwicklung unserer Website und Angebote dar&uuml;ber oder aufgrund ge&auml;nderter gesetzlicher bzw. beh&ouml;rdlicher Vorgaben kann es notwendig werden, diese Datenschutzerkl&auml;rung zu &auml;ndern. Die jeweils aktuelle Datenschutzerkl&auml;rung kann jederzeit auf der Website unter <a href='https://booking.courthero.com/privacy-policy'>https://booking.courthero.com/privacy-policy</a> von Ihnen abgerufen und ausgedruckt werden.
            </p>
            <br/>
        </Card>
    </DesktopBlurredBackground>
);
