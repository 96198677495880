import React from 'react';
import {
    Button, Card, Form, FormInstance, Input, notification, TimePicker,
} from 'antd';
import { useAppContext } from 'context/AppContext';
import { updateClub } from 'usecases/club/updateClub';
import { translate } from 'translations/translate';
import { IClub } from 'usecases/types/club';
import moment from 'moment';
import { CLUB_SERVICE, IClubSettingsForm } from 'types';
import { FormCheckboxGroup } from 'components/FormCheckboxGroup/FormCheckboxGroup';
import { CopyOutlined } from '@ant-design/icons';
import { ROUTES } from 'routing/routes.enum';
import { useModalContext } from 'context/ModalContext';
import { handleCopyToClipboard } from 'helpers/handleCopyToClipboard';
import { validateOpeningHours } from './validateOpeningHours';
import './ClubSettings.scss';

interface IProps {
    currentClub: IClub,
    onUpdateSuccessfully: () => void,
    disabled: boolean
}

export const ClubSettings = ({ currentClub, onUpdateSuccessfully, disabled }: IProps): JSX.Element => {
    const { isSpinnerVisible, setIsSpinnerVisible } = useAppContext();
    const { setModalContentProps, setModalContent } = useModalContext();

    const onFinish = async ({
        name,
        openingTime,
        closingTime,
        services,
    }: IClubSettingsForm): Promise<void> => {
        setIsSpinnerVisible(true);
        try {
            await updateClub({
                clubId: currentClub.id,
                name,
                openingTime: openingTime ? moment(openingTime).format('HH:mmZ') : '',
                closingTime: closingTime ? moment(closingTime).format('HH:mmZ') : '',
                services: services || [],
                address: currentClub.address,
                invoiceData: currentClub.invoiceData,
                contactData: currentClub.contactData,
                bookingExtraInformation: currentClub.bookingExtraInformation,
            });
            onUpdateSuccessfully();
        } catch (error: any) {
            notification.error(
                {
                    message: translate('error'),
                    description: error.message,
                },
            );
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    const getInitialFormValues = (): IClubSettingsForm => ({
        name: currentClub?.name,
        openingTime: currentClub.openingTime
            ? moment(currentClub.openingTime, 'HH:mmZ')
            : undefined,
        closingTime: currentClub.closingTime
            ? moment(currentClub.closingTime, 'HH:mmZ')
            : undefined,
        services: currentClub?.services,
    });

    const showCopyPublicCalendarModal = () => {
        handleCopyToClipboard(ROUTES.PUBLIC_CALENDAR);
        setModalContentProps({ closable: false });
        setModalContent(
            <div className="club-settings__calendar-link-modal-body">
                <div className="club-settings__calendar-link-modal-text">
                    {translate('club_settings-public_calendar_copied')}
                </div>
                <Button
                    type="primary"
                    className="club-settings__calendar-link-modal-close-button"
                    onClick={() => setModalContent(null)}
                >
                    {translate('club_settings-public_calendar_ok')}
                </Button>
            </div>,
        );
    };

    const isButtonVisible = !disabled;

    return (
        <Card className="club-settings">
            <h1 className="club-settings__title">{translate('club_settings-general')}</h1>
            <Form
                requiredMark={disabled ? false : 'optional'}
                layout="vertical"
                name="basic"
                initialValues={getInitialFormValues()}
                onFinish={onFinish}
                className="club-settings__form"
            >
                <Form.Item
                    label={(
                        <label className="club-settings__form-label">
                            {translate('club_settings-club_name')}
                        </label>
                    )}
                    name="name"
                    rules={[{ required: true, message: translate('club_settings-club_name_required') }]}
                >
                    <Input
                        name="name"
                        className="club-settings__form-item"
                        disabled={disabled}
                    />
                </Form.Item>
                <div className="club-settings__time-container">
                    <Form.Item
                        label={(
                            <label className="club-settings__form-label">
                                {translate('club_settings_opening-hours')}
                            </label>
                        )}
                        name="openingTime"
                        rules={[
                            form => ({
                                validator: () => validateOpeningHours(form as FormInstance, 'openingTime'),
                            }),
                        ]}
                    >
                        <TimePicker
                            name="openingTime"
                            className="club-settings__form-item club-settings__form-item-time"
                            minuteStep={30}
                            showSecond={false}
                            format="HH:mm"
                            disabled={disabled}

                        />
                    </Form.Item>
                    <hr className="season-modal__time-divider" />
                    <Form.Item
                        label=" "
                        data-testid="closing_time"
                        name="closingTime"
                        rules={[
                            form => ({
                                validator: () => validateOpeningHours(form as FormInstance, 'closingtime'),
                            }),
                        ]}
                    >
                        <TimePicker
                            name="closingTime"
                            className="club-settings__form-item club-settings__form-item-time"
                            minuteStep={30}
                            showSecond={false}
                            format="HH:mm"
                            disabled={disabled}

                        />
                    </Form.Item>
                </div>

                <FormCheckboxGroup
                    disabled={disabled}
                    formItem={{
                        name: 'services',
                        labelText: translate('club_settings_services'),
                        errorText: '',
                    }}
                    checkboxes={[
                        {
                            value: CLUB_SERVICE.SHOP,
                            name: translate('club_settings-service-shop'),
                        },
                        {
                            value: CLUB_SERVICE.RESTAURANT,
                            name: translate('club_settings-service-restaurant'),
                        },
                        {
                            value: CLUB_SERVICE.BEVERAGES,
                            name: translate('club_settings-service-beverages'),
                        },
                        {
                            value: CLUB_SERVICE.RACKET_RENTAL,
                            name: translate('club_settings-service-rental'),
                        },
                        {
                            value: CLUB_SERVICE.PURCHASING_BALLS,
                            name: translate('club_settings-service-balls'),
                        },
                        {
                            value: CLUB_SERVICE.STRINGING,
                            name: translate('club_settings-stringing'),
                        },
                    ]}
                />
                {!disabled && (
                    <div className="club-settings__public-link-container">
                        <label className="club-settings__form-label">
                            {translate('club_settings-public_calendar_link')}
                        </label>
                        <button
                            className="club-settings__form-item-link"
                            onClick={showCopyPublicCalendarModal}
                            tabIndex={0}
                            type="button"
                        >
                            {translate('club_settings-public_calendar_copy_it')}
                            {' '}
                            <CopyOutlined />
                        </button>
                    </div>
                )}
                {isButtonVisible && (
                    <Button
                        className="club-settings__button"
                        type="primary"
                        htmlType="submit"
                        disabled={isSpinnerVisible}
                    >
                        {translate('club_settings-save')}
                    </Button>
                )}
            </Form>
        </Card>
    );
};
