import { useState } from 'react';
import { setAntdLocaleInStorage } from 'services/functionalStorage';
import { getAntLocale } from 'translations/getAntLocale';

interface useAntdLocaleReturn {
    antdLocaleObject: object;
    updateAntdLocaleMapFromProfileLanguage: () => void,
}

export const useAntdLocale = (): useAntdLocaleReturn => {
    const [antdLocaleObject, setAntdLocale] = useState(getAntLocale());

    const updateAntdLocaleMapFromProfileLanguage = () => setAntdLocale(getAntLocale());
    setAntdLocaleInStorage(antdLocaleObject.locale);

    return { antdLocaleObject, updateAntdLocaleMapFromProfileLanguage };
};
