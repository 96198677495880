import { USERS } from 'config/endpoints';
import { patchWrapper } from 'services/httpService/httpServiceWrapper';
import { PasswordChangeInput } from '../types/dto/PasswordChangeInput';

class PasswordChange {
    execute(value: PasswordChangeInput): Promise<any> {
        const url = USERS.PASSWORD_CHANGE;
        return patchWrapper(url, value);
    }
}

export const passwordChange = new PasswordChange();
