import { PlayerRole } from 'types';

export const getRoleName = (userRole: number): string => {
    let roleName = '';
    // eslint-disable-next-line default-case
    switch (userRole) {
    case PlayerRole.MEMBER:
        roleName = 'member';
        break;
    case PlayerRole.CLIENT:
        roleName = 'client';
        break;
    case PlayerRole.ADMIN:
        roleName = 'admin';
        break;
    }

    return roleName;
};
