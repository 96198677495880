import React from 'react';
import { Checkbox, Form } from 'antd';
import './FormCheckboxGroup.scss';

interface ICheckbox {
    value: any,
    name: string,
}

interface IProps {
    formItem: {
        labelText: string,
        errorText: string,
        name: string,
    }
    checkboxes: ICheckbox[],
    required?: boolean
    disabled?: boolean
}

export const FormCheckboxGroup = ({
    formItem,
    checkboxes,
    required = false,
    disabled,
}: IProps): JSX.Element => (
    <Form.Item
        label={(
            <label className="form-checkbox-group__form-label">
                {formItem.labelText}
            </label>
        )}
        name={formItem.name}
        rules={[{
            required,
            message: formItem.errorText,
        }]}
    >
        <Checkbox.Group
            className="form-checkbox-group"
            disabled={disabled}
        >
            {checkboxes.map(checkbox => (
                <Checkbox
                    className="form-checkbox"
                    key={typeof checkbox.value !== 'string' ? JSON.stringify(checkbox.value) : checkbox.value}
                    value={checkbox.value}
                >
                    <p>{checkbox.name}</p>
                </Checkbox>
            ))}
        </Checkbox.Group>
    </Form.Item>
);
