import React, { ForwardedRef, forwardRef } from 'react';
import { CalendarViewEnum } from 'types';
import { UpOutlined } from '@ant-design/icons';
import FullCalendar, { EventSourceInput } from '@fullcalendar/react';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import { CalendarEventContent } from 'components/CalendarEventContent/CalendarEventContent';
import { formatWeekDayDate } from 'adapters/formatWeekDayDate';
import { ResourceContentComponent } from 'components/Reservations/ResourceContent/ResourceContentComponent';
import { getDayMinWidth } from 'components/Reservations/ReservationsCalendar/getDayMinWidth';
import { useAppContext } from 'context/AppContext';
import { useChevronIcon } from 'hooks/useChevronIcon';
import { IOpeningHours } from 'components/Reservations/adapters/calculateOpeningHours';
import { FULLCALLENDAR_LICENSE_KEY } from 'config/constants';
import { useMobileMediaQuery } from 'hooks/useMobileMediaQuery';
import { BusinessHoursInput } from '@fullcalendar/common';
import { calculateScrollTime } from './calculateScrollTime';
import './Calendar.scss';

interface IProps {
    view: CalendarViewEnum,
    openingHours: IOpeningHours,
    businessHours: BusinessHoursInput,
    courtsAsResources: any[], // TODO: Properly type this prop
    events: EventSourceInput,
    onDateClick: (event: DateClickArg) => void,
    onEventClick: (extendedProps: Record<string, any>) => void,
}

const CalendarRefless = ({
    view, courtsAsResources, openingHours, businessHours, events, onDateClick, onEventClick,
}: IProps, ref: ForwardedRef<FullCalendar>) => {
    const { isMobile } = useMobileMediaQuery();
    const { antdLocaleObject, scrollArrowVisibility } = useAppContext();
    const { locale } = antdLocaleObject;
    const calendarScrollContainerRef = React.useRef<HTMLElement | null>(null);
    useChevronIcon(calendarScrollContainerRef);

    const shouldBeHidden = courtsAsResources.length === 0;
    if (shouldBeHidden) {
        return null;
    }

    const scrollTime = calculateScrollTime();

    return (
        <div className="calendar-container">
            {!isMobile && scrollArrowVisibility && view === CalendarViewEnum.DAYVIEW && (
                <UpOutlined className="list-table__info-list-body__scroll-arrow" />
            )}
            <FullCalendar
                viewDidMount={fullCalendarView => {
                    const scrollContainer = fullCalendarView.el
                        .querySelectorAll('.fc-scroller-liquid-absolute')[1];
                    if (scrollContainer) {
                        calendarScrollContainerRef.current = scrollContainer as HTMLElement;
                    }
                }}
                eventColor="white"
                eventBorderColor="transparent"
                plugins={[
                    scrollGridPlugin as any,
                    timeGridPlugin,
                    interactionPlugin,
                    resourceTimeGridPlugin,
                ]}
                initialView={view}
                dateClick={onDateClick}
                events={events}
                allDaySlot={false}
                ref={ref}
                headerToolbar={false}
                slotLabelFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: false,
                }}
                slotMinTime={openingHours.openingTime}
                slotMaxTime={openingHours.closingTime}
                scrollTime={scrollTime}
                firstDay={(new Date()).getDay()}
                // TODO: Do not disable this rule, make it work instead
                // eslint-disable-next-line react/no-unstable-nested-components
                eventContent={eventInfo => <CalendarEventContent eventInfo={eventInfo} />}
                eventClick={({ event }) => onEventClick(event.extendedProps)}
                views={{
                    timeGrid: {
                        dayHeaderContent: ({ date }) => formatWeekDayDate(date, locale),
                    },
                }}
                schedulerLicenseKey={FULLCALLENDAR_LICENSE_KEY}
                businessHours={businessHours}
                resources={courtsAsResources}
                // eslint-disable-next-line react/no-unstable-nested-components
                resourceLabelContent={resourceLabel => <ResourceContentComponent {...resourceLabel} />}
                resourceOrder={null as unknown as undefined}
                height="100%"
                contentHeight="100%"
                dayMinWidth={getDayMinWidth(isMobile, view)}
            />
        </div>
    );
};

export const Calendar = forwardRef(CalendarRefless);
