import {
    DatePicker, Select,
} from 'antd';
import Icon from '@ant-design/icons';
import { CALENDAR_ICON_GRAY } from 'config/constants';
import React from 'react';
import { translate } from 'translations/translate';
import { Interval } from 'usecases/types/enums/booking_interval.enum';
import moment from 'moment';
import { ISeason } from 'usecases/types/season';
import { dateFormatter } from 'adapters/dateFormatter';
import { useAppContext } from 'context/AppContext';
import { IReservation } from 'usecases/types/reservations';
import { BookingType } from 'usecases/types/enums/booking_type.enum';
import { CHRadioGroup } from 'components/CHRadioGroup/CHRadioGroup';
import { FormDivider } from '../FormDivider/FormDivider';
import { getDisabledDates } from './getDisabledDates';
import './BookingTypeSelector.scss';

interface IRecurringBooking {
    onChangeBookingType: (bookingType: BookingType) => void,
    onIntervalChange: (interval: Interval) => void,
    onEndingOnChange: (recurringBookingEnding: string) => void,
    bookingSeason: ISeason,
    booking: IReservation
}

export const BookingTypeSelector = ({
    onIntervalChange, onEndingOnChange, bookingSeason, onChangeBookingType, booking,
}: IRecurringBooking) => {
    const { antdLocaleObject } = useAppContext();
    const { locale: antdLocale } = antdLocaleObject;
    const onChangeInterval = (newInterval: Interval) => {
        onIntervalChange(newInterval);
    };

    const isNewBooking = !booking.id;

    const MemoizedIcon = React.useCallback(() => (
        <img
            className="season-modal__calendar-icon"
            src={CALENDAR_ICON_GRAY}
            alt="close_icon_white"
        />
    ), []);

    const handleEndingOnChange = (newEndingOn: moment.Moment) => {
        onEndingOnChange(newEndingOn.toISOString());
    };

    const { bookingType } = booking;

    const radioOptions = [
        { value: BookingType.SINGLE, key: 'calendar-reservation_dialog-single-booking' },
        { value: BookingType.RECURRING, key: 'calendar-reservation_dialog-recurring-booking' },
        { value: BookingType.SUBSCRIPTION, key: 'calendar-reservation_dialog-subscription-booking' },
    ];

    return (
        <div className="booking-type-selector">
            <FormDivider />
            <p className="booking-type-selector__label">
                {translate('calendar-reservation_dialog-booking-type')}
            </p>

            <CHRadioGroup
                name="booking-type"
                direction="vertical"
                value={bookingType}
                onChange={({ target }) => onChangeBookingType(target.value)}
                disabled={!isNewBooking}
                radioOptions={radioOptions}
            />

            {bookingType !== BookingType.SINGLE
            && (
                <div className="booking-type-selector__options">

                    <div className="booking-type-selector__option">
                        <p className="booking-type-selector__label">
                            {translate('calendar-reservation_dialog-recurring-booking-interval')}
                        </p>

                        <Select
                            size="large"
                            className="court-selector__custom-select booking-type-selector__option-input"
                            onChange={onChangeInterval}
                            defaultValue={isNewBooking ? Interval.WEEKLY : booking.recurringBookingInfo?.interval}
                            disabled={!isNewBooking}
                            data-testid="interval-input"
                        >
                            <Select.Option value={Interval.WEEKLY}>
                                {translate('calendar-reservation_dialog-recurring-booking-interval-weekly')}
                            </Select.Option>
                            <Select.Option value={Interval.BIWEEKLY}>
                                {translate('calendar-reservation_dialog-recurring-booking-interval-biweekly')}
                            </Select.Option>
                        </Select>
                    </div>

                    <div className="booking-type-selector__option">
                        <p className="booking-type-selector__label">
                            {translate('calendar-reservation_dialog-recurring-booking-ending')}
                        </p>

                        <DatePicker
                            disabled={!isNewBooking}
                            inputReadOnly
                            onChange={newEndingOn => handleEndingOnChange(newEndingOn!)}
                            className="booking-type-selector__date-picker-input booking-type-selector__option-input"
                            suffixIcon={<Icon component={MemoizedIcon} />}
                            allowClear={false}
                            defaultValue={isNewBooking ? moment(bookingSeason.endDateTime)
                                : moment(booking.recurringBookingInfo?.endingOn)}
                            disabledDate={calendarDate => getDisabledDates({
                                calendarDate,
                                bookingSeasonEndDateTime: bookingSeason.endDateTime,
                                bookingStartTime: booking.startTime,
                            })}
                            format={value => dateFormatter(value.toDate(), antdLocale)}
                            data-testid="ending-on-input"
                        />
                    </div>

                </div>
            )}
        </div>
    );
};
