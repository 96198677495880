import { OUT_SEASON_BG_COLOR } from 'config/constants';
import moment from 'moment';
import { ICalendarBackgroundEvent } from 'types';
import { ISeason } from 'usecases/types/season';

export const getBackgroundEvents = (activeSeasons: ISeason[]): ICalendarBackgroundEvent[] => {
    if (activeSeasons.length) {
        const nestedSeasonsTimes = activeSeasons
            .sort((a, b) => new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime())
            .map(season => [new Date(season.startDateTime).getTime(), new Date(season.endDateTime).getTime()]);
        const initalBkgEvents = [
            {
                start: moment(nestedSeasonsTimes[0][0])
                    .subtract(3, 'years').toISOString(),
                end: moment(nestedSeasonsTimes[0][0]).toISOString(),
                display: 'background',
                color: OUT_SEASON_BG_COLOR,
            },
            {
                start: moment(nestedSeasonsTimes[nestedSeasonsTimes.length - 1][1]).toISOString(),
                end: moment(nestedSeasonsTimes[nestedSeasonsTimes.length - 1][1])
                    .add(3, 'years').toISOString(),
                display: 'background',
                color: OUT_SEASON_BG_COLOR,
            },
        ];
        const bkgEvents = nestedSeasonsTimes.reduce((acc: any, current, index) => {
            const start = current[0];
            const previousSeason = nestedSeasonsTimes[index - 1];
            if (previousSeason) {
                const backgroundEventStart = moment(previousSeason[1]).toISOString();
                const backgroundEventEnd = moment(start - 86400).toISOString();
                return [
                    ...acc,
                    {
                        start: backgroundEventStart,
                        end: backgroundEventEnd,
                        display: 'background',
                        color: OUT_SEASON_BG_COLOR,
                    },
                ];
            }
            return [...acc];
        }, [...initalBkgEvents]) as ICalendarBackgroundEvent[];
        return bkgEvents;
    }
    return [];
};
