import { CHDateTime, TimeUnits } from 'helpers/DateTime/CHDateTime';
import { CalendarViewEnum } from 'types';

interface Range {
    start: CHDateTime,
    end: CHDateTime,
}
export const getDisplayedDateRange = (date: CHDateTime, view: CalendarViewEnum): Range => {
    const start = date.startOfDay();
    if (view === CalendarViewEnum.DAYVIEW) {
        return {
            start: date.startOfDay(),
            end: date.endOfDay(),
        };
    }
    return {
        start,
        end: date.add(6, TimeUnits.DAY).endOfDay(),
    };
};
