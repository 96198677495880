import { getUserFromStorage, isUserAuthenticated } from 'services/functionalStorage';

const getLanguageFromBrowser = () => (/^en\b/.test(navigator.language) ? 'english' : 'german');

export const getProfileLanguage = () => {
    if (isUserAuthenticated()) {
        return getUserFromStorage().language;
    }

    return getLanguageFromBrowser();
};
