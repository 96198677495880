export const MIN_DURATION = 30;
export const MAX_DURATION = 1440;
export const STEP_DURATION = 30;

export const calculateDurationWithBoundaries = (
    duration: number,
): number => {
    if (duration <= MIN_DURATION) {
        return MIN_DURATION;
    }
    if (duration >= MAX_DURATION) {
        return MAX_DURATION;
    }
    return duration;
};
