import { Select } from 'antd';
import React, { useRef } from 'react';
import { translate } from 'translations/translate';
import { ICourt } from 'usecases/types/court';
import './CourtSelector.scss';

interface IProps {
    court: ICourt;
    courts: ICourt[];
    onChange: (court: ICourt) => any;
    isNewReservation: boolean
}

export const CourtSelector = ({
    court,
    courts,
    onChange,
    isNewReservation,
}: IProps): JSX.Element => {
    const containerRef = useRef<HTMLElement>();
    const onSetCourt = (courtId: string) => {
        const foundCourt = courts.find(_court => _court.id === courtId) as ICourt;
        onChange(foundCourt);
    };

    return (
        <div className="court-selector" ref={containerRef as any}>
            <p className="court-selector__title">
                {translate('calendar-reservation_dialog-court_selector_label')}
            </p>
            <Select
                size="large"
                className="court-selector__custom-select"
                placeholder="Change court"
                defaultValue={court.id}
                onChange={value => onSetCourt(value)}
                getPopupContainer={() => containerRef.current as HTMLElement}
                disabled={!isNewReservation}
            >
                {courts.map(_court => (
                    <Select.Option value={_court.id} key={_court.id}>
                        {_court.name}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};
