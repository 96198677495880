import React from 'react';
import { ICourt } from 'usecases/types/court';
import { getSurfaceIconUrl } from 'adapters/getSurfaceIconUrl';
import { translate } from 'translations/translate';
import styles from './ResourceContentComponent.module.scss';

interface IProps {
    resource: {
        extendedProps : {
            court?: ICourt
            [extendedProp: string]: any
        }
    };
}

export const ResourceContentComponent = ({ resource: { extendedProps: { court } } }: IProps) => {
    const surfaceIconUrl = getSurfaceIconUrl(court!.surface);
    return (
        <div className={styles.resource}>
            <div className={styles['resource__resource-row']}>
                <img className={styles.resource__image} src={surfaceIconUrl} alt="court-icon" />
                <div className={styles['resource__resource-row-texts-container']}>
                    <span className={styles['resource__resource-row-name']}>
                        {court!.name}
                    </span>
                    <span className={styles['resource__resource-row-surface-text']}>
                        {court?.surface && translate(court.surface)}
                    </span>
                </div>
            </div>
        </div>
    );
};
