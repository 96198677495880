import { NotFoundPage } from 'pages/common/NotFound/NotFoundPage';
import { Redirect, Route, Switch } from 'react-router-dom';
import { publicRoutes } from './publicRoutes';
import { ROUTES } from './routes.enum';

export const UnauthenticatedApp = () => (
    <Switch>
        <Route
            exact
            key="/"
            path="/"
            render={() => <Redirect to={ROUTES.LOGIN} />}
        />

        {publicRoutes.map(route => (
            <Route
                exact
                key={route.path}
                path={route.path}
                render={() => <route.component />}
            />
        ))}

        <Route
            path="*"
            render={() => <NotFoundPage />}
        />
    </Switch>
);
