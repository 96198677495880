import { PROFILE } from 'config/endpoints';
import { translate } from 'translations/translate';

class UploadAvatar {
    async execute(image: Blob) {
        const formData = new FormData();
        formData.append('avatar', image);
        const response = await fetch(PROFILE.AVATAR, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            method: 'POST',
            body: formData,
        });
        const responseJson = await response.json();
        if (response.status !== 200) {
            throw new Error(translate(`error-${responseJson.code}`, responseJson.data.errorValues));
        }
        return `${responseJson.data.url}?${new Date().getTime()}`;
    }
}

export default new UploadAvatar();
