import { notification } from 'antd';
import { useAppContext } from 'context/AppContext';
import { useCallback } from 'react';
import { translate } from 'translations/translate';

export const useApi = () => {
    const { setIsSpinnerVisible } = useAppContext();

    // eslint-disable-next-line consistent-return
    return useCallback(async (retrieveDataPromise: Promise<any>, notifyError: boolean = true) => {
        setIsSpinnerVisible(true);

        try {
            return await retrieveDataPromise;
        } catch (error: any) {
            if (notifyError) {
                notification.error({
                    key: error.name,
                    message: translate('error'),
                    description: error.message,
                });
                throw error;
            } else {
                throw error;
            }
        } finally {
            setIsSpinnerVisible(false);
        }
    }, []);
};
