import React, { useMemo, useState } from 'react';
import { Invoice, Order } from 'types';
import { ListTable } from 'components/ListTable/ListTable';
import { getInvoicePdf } from 'usecases/invoice/getInvoicePdf';
import { translate } from 'translations/translate';
import { notification } from 'antd';
import { openNewTabAsynchronously } from 'helpers/openNewTab/openNewTabAsynchronously';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { canInvoiceBeCancelled } from 'helpers/Invoice/canInvoiceBeCancelled';
import { sortInvoices } from './sortInvoices';
import { getInvoiceListRows } from './getInvoiceListRows';
import { getInvoiceListHeaders } from './getInvoiceListHeaders';
import './InvoiceList.scss';

interface IProps {
    invoices: Invoice[],
    cancelSingleInvoice: Function,
    itemsSelected: string[],
    onItemSelect: (e: CheckboxChangeEvent) => void,
    onSelectAll: (e: CheckboxChangeEvent) => void,
    changeInvoiceStatus: Function
}

export const InvoiceList = ({
    invoices, cancelSingleInvoice, itemsSelected, onItemSelect, onSelectAll, changeInvoiceStatus,
}: IProps) => {
    const [sorting, setSorting] = useState<{ field: string, order: Order }>(
        { field: 'invoice-number', order: 'desc' },
    );

    const invoicesSorted = useMemo(() => sortInvoices({ invoices, sorting }), [invoices, sorting]);

    const getInvoiceLink = async (invoice: Invoice): Promise<string> => {
        const pdf = await getInvoicePdf(invoice);
        return URL.createObjectURL(pdf);
    };

    const openInvoicePdfInNewTab = (invoice: Invoice) => {
        openNewTabAsynchronously(getInvoiceLink(invoice)).catch(error => {
            notification.error({
                message: translate('error'),
                description: error.message,
            });
        });
    };

    const selectableInvoices = invoicesSorted
        .filter(invoice => canInvoiceBeCancelled(invoice));

    return (
        <ListTable
            rows={getInvoiceListRows({
                invoicesSorted,
                onProceed: openInvoicePdfInNewTab,
                onCancel: cancelSingleInvoice,
                checkboxesSelected: itemsSelected,
                onCheckboxChange: onItemSelect,
                onChangeStatus: changeInvoiceStatus,
            })}
            headers={getInvoiceListHeaders({
                allSelected: Boolean(itemsSelected.length)
                    && selectableInvoices.length === itemsSelected.length,
                onClickGlobalCheck: onSelectAll,
            })}
            sortBy={((field, order) => setSorting({ field, order: order! }))}
        />
    );
};
