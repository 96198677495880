import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'antd';
import { translate } from 'translations/translate';
import { LOGO_RED } from 'config/constants';
import {
    DesktopBlurredBackground,
} from 'components/Reservations/DesktopBlurredBackground/DesktopBlurredBackground';
import { ROUTES } from 'routing/routes.enum';
import 'pages/common/WhiteCard/Whitecard.scss';

export const EmailVerification = (): JSX.Element => (
    <DesktopBlurredBackground>
        <Card className="whitecard">
            <div className="whitecard__header">
                <img className="whitecard__header-image" src={LOGO_RED} alt="Court Hero Logo" />
            </div>
            <div className="whitecard__legend">
                <h1 className="whitecard__legend-title">{translate('we-sent-you-an-email')}</h1>
                <p className="whitecard__legend-text">
                    {translate('you-have-successfully-registered-in-court-hero')}
                </p>

                <p className="whitecard__legend-text">
                    <b>{translate('check-your-inbox-for-verification-link')}</b>
                </p>
            </div>
            <Link to={ROUTES.LOGIN}>
                <button type="button" className="whitecard__confirm-button">{translate('go-to-login')}</button>
            </Link>
        </Card>
    </DesktopBlurredBackground>
);
