import { NavbarActions, NAVBAR_ACTIONS } from 'redux/types';

const initialState = false;
export const navbarVisibility = (
    // eslint-disable-next-line default-param-last
    state: boolean = initialState,
    action: NavbarActions,
): boolean => {
    switch (action.type) {
    case NAVBAR_ACTIONS.SHOW:
        return true;

    case NAVBAR_ACTIONS.CLOSE:
        return false;

    default:
        return state;
    }
};
