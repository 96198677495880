import { DateClickArg } from '@fullcalendar/interaction';
import { ISeason } from 'usecases/types/season';

export const isDateInCurrentSeason = (
    currentSeason: ISeason,
    event: DateClickArg,
): boolean => {
    const eventTimestamp = event.date;
    const startDateTimestamp = new Date(currentSeason.startDateTime);
    const endDateTimeStamp = new Date(currentSeason.endDateTime);

    return eventTimestamp > startDateTimestamp && eventTimestamp < endDateTimeStamp;
};
