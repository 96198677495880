import { CHDateTime } from 'helpers/DateTime/CHDateTime';
import { IReservation } from 'usecases/types/reservations';

export const calculateBookingDurationMinutes = (reservation: IReservation): number => {
    let startTimeDate: CHDateTime;
    let endTimeDate: CHDateTime;

    try {
        const { endTime, startTime } = reservation;
        startTimeDate = new CHDateTime(startTime);
        endTimeDate = new CHDateTime(endTime);
    } catch (error) {
        return 0;
    }

    const newDuration = (endTimeDate.getTimestamp() - startTimeDate.getTimestamp()) / 60000;
    return Math.round(newDuration);
};
