import { DataLayerArgs } from 'react-gtm-module';
import { IReservation } from 'usecases/types/reservations';
import { getRoleName } from 'helpers/getRoleName';
import { CalendarViewEnum, PlayerRole } from 'types';
import { BookingType } from 'usecases/types/enums/booking_type.enum';
import { PaymentTypeEnum } from 'usecases/types/enums/payment_type.enum';
import { getUserMembership } from 'helpers/getUserMembership';
import { getClubFromStorage, getUserFromStorage } from 'services/functionalStorage';
import {
    DATALAYER_EVENT,
    DATALAYER_EVENT_ACTION,
    DATALAYER_EVENT_CATEGORY,
    LOGIN_EVENT_LABEL,
} from './enums';
import { IBookingSubmitDatalayerDTO } from './dto';
import { IBookingSubmitDatalayer } from './types';
import { mapCalendarViewToGtmString } from './mapCalendarViewToGtmString';

const bookingTypeToValueMap = {
    [BookingType.SINGLE]: 'Single booking',
    [BookingType.RECURRING]: 'Recurring booking',
    [BookingType.SUBSCRIPTION]: 'Subscription',
};
const paymentMethodToValueMap = {
    [PaymentTypeEnum.CASH]: 'Cash',
    [PaymentTypeEnum.ONLINE]: 'Online-Payment',
};

export const REGISTER_HEADER_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.NAVIGATION,
    eventAction: 'HEADER',
    eventLabel: 'Registrierungslink für Spieler',
    eventValue: undefined,
} as DataLayerArgs['dataLayer'];

export const MY_FACILITY_CLUB_SIDEBAR_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.NAVIGATION,
    eventAction: DATALAYER_EVENT_ACTION.SIDEBAR,
    eventLabel: 'Mein Verein > Verein',
    eventValue: undefined,
} as DataLayerArgs['dataLayer'];

export const MY_FACILITY_COURTS_SIDEBAR_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.NAVIGATION,
    eventAction: DATALAYER_EVENT_ACTION.SIDEBAR,
    eventLabel: 'Mein Verein > Plätze',
    eventValue: undefined,
} as DataLayerArgs['dataLayer'];

export const MY_FACILITY_MEMBERS_SIDEBAR_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.NAVIGATION,
    eventAction: DATALAYER_EVENT_ACTION.SIDEBAR,
    eventLabel: 'Mein Verein > Spieler',
    eventValue: undefined,
} as DataLayerArgs['dataLayer'];

export const MY_FACILITY_SEASON_SIDEBAR_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.NAVIGATION,
    eventAction: DATALAYER_EVENT_ACTION.SIDEBAR,
    eventLabel: 'Mein Verein > Saisons',
    eventValue: undefined,
} as DataLayerArgs['dataLayer'];

export const MY_FACILITY_PRICES_SIDEBAR_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.NAVIGATION,
    eventAction: DATALAYER_EVENT_ACTION.SIDEBAR,
    eventLabel: 'Mein Verein > Preise',
    eventValue: undefined,
} as DataLayerArgs['dataLayer'];

export const CALENDAR_SIDEBAR_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.NAVIGATION,
    eventAction: DATALAYER_EVENT_ACTION.SIDEBAR,
    eventLabel: 'Kalendar',
    eventValue: undefined,
} as DataLayerArgs['dataLayer'];

export const BOOK_KEEPING_GUEST_FEES_SIDEBAR_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.NAVIGATION,
    eventAction: DATALAYER_EVENT_ACTION.SIDEBAR,
    eventLabel: 'Buchhaltung > Gastgebühren',
    eventValue: undefined,
} as DataLayerArgs['dataLayer'];

export const PROFILE_SETTINGS_SIDEBAR_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.NAVIGATION,
    eventAction: DATALAYER_EVENT_ACTION.SIDEBAR,
    eventLabel: 'Konto > Einstellungen',
    eventValue: undefined,
} as DataLayerArgs['dataLayer'];

export const PROFILE_LOGOUT_SIDEBAR_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.NAVIGATION,
    eventAction: DATALAYER_EVENT_ACTION.SIDEBAR,
    eventLabel: 'Konto > Abmelden',
    eventValue: undefined,
} as DataLayerArgs['dataLayer'];

export const MY_FACILITIES_SIDEBAR_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.NAVIGATION,
    eventAction: DATALAYER_EVENT_ACTION.SIDEBAR,
    eventLabel: 'Konto > Spielorte',
    eventValue: undefined,
} as DataLayerArgs['dataLayer'];

export const IMPRINT_SIDEBAR_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.NAVIGATION,
    eventAction: DATALAYER_EVENT_ACTION.SIDEBAR,
    eventLabel: 'Impressum',
    eventValue: undefined,
} as DataLayerArgs['dataLayer'];

export const getNavigateToPreviousCourtDataLayer = () => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.NAVIGATION,
    eventAction: 'Switch court to <',
    eventLabel: 'Court successfully changed to <',
    eventValue: undefined,
    userId: getUserFromStorage()?.id,
    facilityId: getClubFromStorage()?.id,
} as DataLayerArgs['dataLayer']);

export const getNavigateToNextCourtDataLayer = () => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.NAVIGATION,
    eventAction: 'Switch court to >',
    eventLabel: 'Court successfully changed to >',
    eventValue: undefined,
    userId: getUserFromStorage()?.id,
    facilityId: getClubFromStorage()?.id,
} as DataLayerArgs['dataLayer']);

export const FOREIGN_BOOKING_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.FOREIGN_BOOKING,
    eventAction: undefined,
    eventLabel: undefined,
    eventValue: undefined,
    _clear: true,
} as DataLayerArgs['dataLayer'];

export const IMPRINT_LINK_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.OUTBOUND_LINKS,
    eventAction: 'Open imprint',
    eventLabel: 'Imprint successfully opened',
    eventValue: undefined,
    _clear: true,
} as DataLayerArgs['dataLayer'];

export const ADD_GUEST_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.BOOKING,
    eventAction: DATALAYER_EVENT_ACTION.ADD_GUEST,
    eventLabel: 'Guest player pop-up opened',
    eventValue: undefined,
} as DataLayerArgs['dataLayer'];

export const SAVE_GUEST_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.BOOKING,
    eventAction: DATALAYER_EVENT_ACTION.ADD_GUEST,
    eventLabel: 'Guest player successfully added',
    eventValue: undefined,
    _clear: true,
} as DataLayerArgs['dataLayer'];

export const DELETE_GUEST_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.BOOKING,
    eventAction: DATALAYER_EVENT_ACTION.DELETE_GUEST,
    eventLabel: 'Guest player successfully deleted',
    eventValue: undefined,
} as DataLayerArgs['dataLayer'];

export const ADD_PLAYER_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.BOOKING,
    eventAction: DATALAYER_EVENT_ACTION.ADD_PLAYER,
    eventLabel: 'Player successfully added',
    eventValue: undefined,
} as DataLayerArgs['dataLayer'];

export const DELETE_PLAYER_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.BOOKING,
    eventAction: DATALAYER_EVENT_ACTION.DELETE_PLAYER,
    eventLabel: 'Player successfully deleted',
    eventValue: undefined,
} as DataLayerArgs['dataLayer'];

export const REGISTRATION_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.CUSTOMER_RELATIONS,
    eventAction: 'Register',
    eventLabel: 'Registration successful',
    eventValue: undefined,
    _clear: true,
} as DataLayerArgs['dataLayer'];

export const USER_REGISTRATION_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.CUSTOMER_RELATIONS,
    eventAction: 'Create Account',
    eventLabel: 'UserRegistration',
    eventValue: undefined,
    _clear: true,
} as DataLayerArgs['dataLayer'];

export const ADMIN_REGISTRATION_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.CUSTOMER_RELATIONS,
    eventAction: DATALAYER_EVENT_ACTION.CREATE_ACCOUNT,
    eventLabel: 'ClubRegistration',
    eventValue: undefined,
    _clear: true,
} as DataLayerArgs['dataLayer'];

export const SHOW_BOOKING_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.BOOKING,
    eventAction: DATALAYER_EVENT_ACTION.SHOW_BOOKING_AFTER_BOOKING_SUCCESSFUL,
    eventLabel: 'User successfully returned to booking',
} as DataLayerArgs['dataLayer'];

export const BACK_CALENDAR_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.BOOKING,
    eventAction: DATALAYER_EVENT_ACTION.BACK_TO_CALENDAR_AFTER_BOOKING_SUCCESSFUL,
    eventLabel: 'User successfully returned to calendar',
} as DataLayerArgs['dataLayer'];

export const getWeekPaginationDatalayer = ({ diff }: { diff: number }) => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.NAVIGATION,
    eventAction: DATALAYER_EVENT_ACTION.PAGINATION,
    eventLabel: 'Woche',
    eventValue: diff,
} as DataLayerArgs['dataLayer']);

export const getDayPaginationDatalayer = ({ diff }: { diff: number }) => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.NAVIGATION,
    eventAction: DATALAYER_EVENT_ACTION.PAGINATION,
    eventLabel: 'Tag',
    eventValue: diff,
} as DataLayerArgs['dataLayer']);

export const getSuccessfulUserRegistrationDatalayer = ({
    customerClubName, customerGender, customerLanguage, customerUserId,
}: {
    customerClubName: string
    customerGender: string, customerLanguage: string, customerUserId: string,
}) => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.CUSTOMER_RELATIONS,
    eventAction: DATALAYER_EVENT_ACTION.REGISTRATION_SUBMIT,
    eventLabel: undefined,
    eventValue: undefined,
    customerClubName,
    customerGender,
    customerLanguage,
    customerType: 'user',
    customerUserId,
    _clear: true,
}) as DataLayerArgs['dataLayer'];

export const getBookingSubmitDatalayer = ({
    list,
    courtName,
    courtId,
    reservationDate,
    reservationTime,
}: IBookingSubmitDatalayerDTO): IBookingSubmitDatalayer => ({
    event: DATALAYER_EVENT.EEC_PRODUCT_CLICK,
    ecommerce: {
        currencyCode: 'EUR',
        click: {
            actionField: {
                list: mapCalendarViewToGtmString(list),
            },
            products: [{
                name: courtName,
                id: courtId,
                price: '',
                dimension01: reservationDate,
                dimension02: reservationTime,
            }],
        },
    },
});

export const getLoginDataLayer = ({ eventLabel, userId, facilityId }
    : { eventLabel: LOGIN_EVENT_LABEL, userId?: string, facilityId?: string[] }) => (
    {
        event: DATALAYER_EVENT.GA,
        eventCategory: DATALAYER_EVENT_CATEGORY.CUSTOMER_RELATIONS,
        eventAction: 'Login',
        eventLabel,
        eventValue: undefined,
        userId,
        facilityId,
    } as DataLayerArgs['dataLayer']
);

export const getSuccessfulAdminRegistrationDatalayer = ({
    customerClubName, customerGender, customerLanguage, customerUserId,
}: {
    customerClubName: string
    customerGender: string, customerLanguage: string, customerUserId: string,
}) => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.CUSTOMER_RELATIONS,
    eventAction: DATALAYER_EVENT_ACTION.REGISTRATION_SUBMIT,
    eventLabel: undefined,
    eventValue: undefined,
    customerClubName,
    customerGender,
    customerLanguage,
    customerType: 'admin',
    customerUserId,
    _clear: true,
}) as DataLayerArgs['dataLayer'];

export const getBookingSuccessDatalayer = (
    bookings: IReservation[],
    userId: string,
    facilityId: string,
    userRole: PlayerRole,
    amount: number,
): DataLayerArgs['dataLayer'] => {
    let bookingType = '';
    // eslint-disable-next-line default-case
    switch (bookings[0].bookingType) {
    case BookingType.SINGLE:
        bookingType = 'Single booking';
        break;
    case BookingType.RECURRING:
        bookingType = 'Recurring booking';
        break;
    case BookingType.SUBSCRIPTION:
        bookingType = 'Subscription';
        break;
    }
    const bookingsIds: string[] = [];
    bookings.forEach(booking => {
        bookingsIds.push(booking.id);
    });

    return ({
        event: DATALAYER_EVENT.GA,
        eventCategory: DATALAYER_EVENT_CATEGORY.BOOKING,
        eventAction: DATALAYER_EVENT_ACTION.MAKE_BOOKING,
        eventLabel: 'Booking successfully created',
        eventValue: undefined,
        userId,
        facilityId,
        bookingId: bookingsIds,
        userRole: getRoleName(userRole),
        bookingType,
        paymentMethod: bookings[0].paymentType === PaymentTypeEnum.CASH ? 'Cash' : 'Online-Payment',
        amount,
    });
};

export const getBookingFailureDatalayer = (
    userId: string,
    facilityId: string,
    userRole: PlayerRole,
    amount: number,
    paymentMethod: PaymentTypeEnum,
    bookingType: BookingType,
    reason: string,
): DataLayerArgs['dataLayer'] => {
    let bookingTypeText = '';
    // eslint-disable-next-line default-case
    switch (bookingType) {
    case BookingType.SINGLE:
        bookingTypeText = 'Single booking';
        break;
    case BookingType.RECURRING:
        bookingTypeText = 'Recurring booking';
        break;
    case BookingType.SUBSCRIPTION:
        bookingTypeText = 'Subscription';
        break;
    }

    return ({
        event: DATALAYER_EVENT.GA,
        eventCategory: DATALAYER_EVENT_CATEGORY.BOOKING,
        eventAction: DATALAYER_EVENT_ACTION.MAKE_BOOKING,
        eventLabel: 'Booking not created successfully',
        eventValue: undefined,
        userId,
        facilityId,
        userRole: getRoleName(userRole),
        bookingType: bookingTypeText,
        paymentMethod: paymentMethod === PaymentTypeEnum.CASH ? 'Cash' : 'Online-Payment',
        amount,
        reason,
    });
};

export const getIncreaseTimeDataLayer = () => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.BOOKING,
    eventAction: 'Increase duration',
    eventLabel: 'Duration successfully increased',
    eventValue: undefined,
    _clear: true,
    userId: getUserFromStorage().id,
    facilityId: getClubFromStorage().id,
}) as DataLayerArgs['dataLayer'];

export const getDecreaseTimeDataLayer = () => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.BOOKING,
    eventAction: 'Reduce duration',
    eventLabel: 'Duration successfully decreased',
    eventValue: undefined,
    _clear: true,
    userId: getUserFromStorage().id,
    facilityId: getClubFromStorage().id,
}) as DataLayerArgs['dataLayer'];

export const getFacilityCreationDataLayer = (userId: string, facilityId: string) => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.FACILITY_CREATION,
    eventAction: DATALAYER_EVENT_ACTION.NEW_FACILITY_CREATED,
    eventLabel: 'Facility successfully created',
    eventValue: undefined,
    userId,
    facilityId,
    _clear: true,
}) as DataLayerArgs['dataLayer'];

export const getSuccessfulRegistrationDatalayer = ({
    userId, userLanguage, userGender,
}: {
    userLanguage: string, userId: string, userGender: string,
}) => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.CUSTOMER_RELATIONS,
    eventAction: DATALAYER_EVENT_ACTION.REGISTER,
    eventLabel: 'Registration successful',
    eventValue: undefined,
    userGender,
    userLanguage,
    userId,
    _clear: true,
}) as DataLayerArgs['dataLayer'];

export const getCreateCourtDataLayer = (userId: string, facilityId: string, courtId: string) => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.FACILITY_CREATION,
    eventAction: DATALAYER_EVENT_ACTION.NEW_COURT_CREATED,
    eventLabel: 'Court successfully created',
    eventValue: undefined,
    userId,
    facilityId,
    courtId,
    _clear: true,
}) as DataLayerArgs['dataLayer'];

export const getCreateSeasonDataLayer = (
    userId: string,
    facilityId: string,
    courtIds: Array<string>,
    seasonId: string,
    seasonEnabled: boolean,
) => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.FACILITY_CREATION,
    eventAction: DATALAYER_EVENT_ACTION.NEW_SEASON_CREATED,
    eventLabel: 'Season successfully created',
    eventValue: undefined,
    userId,
    facilityId,
    seasonId,
    courtIds,
    seasonEnabled: seasonEnabled ? 'Season enabled' : 'Season not enabled',
    _clear: true,
}) as DataLayerArgs['dataLayer'];

export const getCreatePricingRuleDataLayer = (
    userId: string,
    facilityId: string,
    courtIds: Array<string>,
    seasonId: string,
    priceRuleId: string,
    userRole: string,
    amount: number,
) => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.FACILITY_CREATION,
    eventAction: DATALAYER_EVENT_ACTION.NEW_PRICE_RULE,
    eventLabel: 'Price rule successfully created',
    eventValue: undefined,
    userId,
    facilityId,
    seasonId,
    courtIds,
    priceRuleId,
    userRole,
    amount,
}) as DataLayerArgs['dataLayer'];

export const getEditSeasonDataLayer = (
    userId: string,
    facilityId: string,
    courtIds: Array<string>,
    seasonId: string,
    seasonEnabled: boolean,
) => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.FACILITY_CREATION,
    eventAction: DATALAYER_EVENT_ACTION.SEASON_EDITED,
    eventLabel: 'Season successfully edited',
    eventValue: undefined,
    userId,
    facilityId,
    seasonId,
    courtIds,
    seasonEnabled: seasonEnabled ? 'Season enabled' : 'Season not enabled',
    _clear: true,
}) as DataLayerArgs['dataLayer'];

export const getOnlinePaymentEnabledDataLayer = (
    userId: string,
    facilityId: string,
) => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.FACILITY_CREATION,
    eventAction: DATALAYER_EVENT_ACTION.ONLINE_PAYMENT_ENABLED,
    eventLabel: 'Online-payment successfully enabled',
    eventValue: undefined,
    userId,
    facilityId,
}) as DataLayerArgs['dataLayer'];

export const getSettlePlayerDataLayer = (
    userId: string,
    facilityId: string,
    bookingId: Array<string>,
    amount: number,
    userRole: string,
) => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.BOOKING,
    eventAction: DATALAYER_EVENT_ACTION.SETTLE_SINGLE_PLAYER,
    eventLabel: 'Invoice for 1 player successfully created',
    eventValue: undefined,
    userId,
    facilityId,
    bookingId,
    paymentMethod: 'Cash',
    amount,
    userRole,
}) as DataLayerArgs['dataLayer'];

export const getSettleBulkPlayersDataLayer = (
    userId: string,
    facilityId: string,
    bookingId: Array<string>,
    amount: number,
    userRole: string,
) => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.BOOKING,
    eventAction: DATALAYER_EVENT_ACTION.SETTLE_BULK_PLAYERS,
    eventLabel: 'Invoice for >1 player successfully created',
    eventValue: undefined,
    userId,
    facilityId,
    bookingId,
    paymentMethod: 'Cash',
    amount,
    userRole,
}) as DataLayerArgs['dataLayer'];

export const getDeactivateFacilityDataLayer = (
    userId: string,
    facilityId: string,
) => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.FACILITY_CREATION,
    eventAction: DATALAYER_EVENT_ACTION.FACILITY_DEACTIVATED,
    eventLabel: 'Facility successfully deactivated',
    eventValue: undefined,
    userId,
    facilityId,
}) as DataLayerArgs['dataLayer'];

export const getJoinFacilityDataLayer = (
    userId: string,
    facilityId: string,
    userRole: string,
) => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.FACILITY_JOINING,
    eventAction: DATALAYER_EVENT_ACTION.JOIN_NEW_FACILITY,
    eventLabel: 'Facility successfully joined',
    eventValue: undefined,
    userId,
    facilityId,
    userRole,
}) as DataLayerArgs['dataLayer'];

export const getPlayerActivatedInFacilityDataLayer = (
    userId: string,
    facilityId: string,
    userRole: string,
) => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.FACILITY_JOINING,
    eventAction: DATALAYER_EVENT_ACTION.ACTIVATE_PLAYER,
    eventLabel: 'Player successfully activated',
    eventValue: undefined,
    userId,
    facilityId,
    userRole,
}) as DataLayerArgs['dataLayer'];

export const getEditPlayerRoleInFacilityDataLayer = (
    userId: string,
    facilityId: string,
    userRole: string,
    newUserRole: string,
) => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.FACILITY_JOINING,
    eventAction: DATALAYER_EVENT_ACTION.EDIT_PLAYER,
    eventLabel: 'Player successfully edited',
    eventValue: undefined,
    userId,
    facilityId,
    userRole,
    newUserRole,
}) as DataLayerArgs['dataLayer'];

export const getBookingCancelledDataLayer = (booking: IReservation, type: BookingType) => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.BOOKING,
    eventAction: 'Cancel a booking',
    eventLabel: 'Booking successfully cancelled',
    eventValue: undefined,
    userId: booking.owner?.id,
    facilityId: booking.club.id,
    bookingId: booking.id,
    bookingType: bookingTypeToValueMap[type],
    paymentMethod: paymentMethodToValueMap[booking.paymentType],
    amount: booking.price,
    userRole: getRoleName(getUserMembership(booking.owner, booking.club)!.role),
} as DataLayerArgs['dataLayer']);

export const getCloseBookingModalDataLayer = (
    userId: string,
    facilityId: string,
    bookingId?: string,
) => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.BOOKING,
    eventAction: DATALAYER_EVENT_ACTION.BOOKING_CANCEL,
    eventLabel: 'Booking successfully closed',
    eventValue: undefined,
    userId,
    facilityId,
    bookingId: bookingId || 'No bookingId',
} as DataLayerArgs['dataLayer']);

const WEEK_DATEVIEW_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.NAVIGATION,
    eventAction: 'Switch to week view',
    eventLabel: 'Change to week view successful',
    eventValue: undefined,
} as DataLayerArgs['dataLayer'];

const DAY_DATEVIEW_DATALAYER = {
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.NAVIGATION,
    eventAction: 'Switch to day view',
    eventLabel: 'Change to day view successful',
    eventValue: undefined,
} as DataLayerArgs['dataLayer'];

export const getCalendarViewChangedDataLayer = (view: CalendarViewEnum) => {
    const baseDataLayer = {
        [CalendarViewEnum.WEEKVIEW]: WEEK_DATEVIEW_DATALAYER,
        [CalendarViewEnum.DAYVIEW]: DAY_DATEVIEW_DATALAYER,
    }[view];
    return {
        ...baseDataLayer,
        userId: getUserFromStorage()?.id,
        facilityId: getClubFromStorage()?.id,
    };
};

export const getLoginUnsuccesfullDataLayer = () => {
    const loginDataLayer = getLoginDataLayer({
        eventLabel: LOGIN_EVENT_LABEL.UNSUCCESSFULLY,
        userId: undefined,
        facilityId: undefined,
    });

    return loginDataLayer;
};

export const getUnsuccessfulCancellationBookingDataLayer = (
    userId: string,
    facilityId: string,
    booking: IReservation,
    userRole: PlayerRole,
    reason: string,
) => ({
    event: DATALAYER_EVENT.GA,
    eventCategory: DATALAYER_EVENT_CATEGORY.BOOKING,
    eventAction: 'Cancel a booking',
    eventLabel: 'Booking not cancelled successfully',
    eventValue: undefined,
    userId,
    facilityId,
    bookingId: booking.id,
    bookingType: bookingTypeToValueMap[booking.bookingType],
    paymentMethod: paymentMethodToValueMap[booking.paymentType],
    amount: booking.price,
    userRole: getRoleName(userRole),
    reason,
} as DataLayerArgs['dataLayer']);

export const getAddGuestPlayerDataLayer = (userId: string, facilityId: string) => ({
    ...ADD_GUEST_DATALAYER,
    userId,
    facilityId,
} as DataLayerArgs['dataLayer']);

export const getSaveGuestPlayerDataLayer = (userId: string, facilityId: string) => ({
    ...SAVE_GUEST_DATALAYER,
    userId,
    facilityId,
} as DataLayerArgs['dataLayer']);

export const getDeleteGuestPlayerDataLayer = (userId: string, facilityId: string) => ({
    ...DELETE_GUEST_DATALAYER,
    userId,
    facilityId,
} as DataLayerArgs['dataLayer']);

export const getAddPlayerToBookingDataLayer = (userId: string, facilityId: string) => ({
    ...ADD_PLAYER_DATALAYER,
    userId,
    facilityId,
} as DataLayerArgs['dataLayer']);

export const getShowBookingAfterSuccessDataLayer = (userId: string, facilityId: string) => ({
    ...SHOW_BOOKING_DATALAYER,
    userId,
    facilityId,
} as DataLayerArgs['dataLayer']);

export const getDeletePlayerToBookingDataLayer = (userId: string, facilityId: string) => ({
    ...DELETE_PLAYER_DATALAYER,
    userId,
    facilityId,
} as DataLayerArgs['dataLayer']);

export const getBackToCalendarAfterSuccessDataLayer = (userId: string, facilityId: string) => ({
    ...BACK_CALENDAR_DATALAYER,
    userId,
    facilityId,
} as DataLayerArgs['dataLayer']);
