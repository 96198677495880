import React from 'react';
import { useMobileMediaQuery } from 'hooks/useMobileMediaQuery';
import { BlurredBackground } from '../BlurredBackground/BlurredBackground';

export const DesktopBlurredBackground = ({ children }: { children:any }) => {
    const { isMobile } = useMobileMediaQuery();

    return isMobile
        ? <>{children}</>
        : <BlurredBackground>{children}</BlurredBackground>;
};
