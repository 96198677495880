import { AutoComplete, Input } from 'antd';
import { ADD_PLAYER_GRAY, ADD_PLAYER_GRAY2 } from 'config/constants';
import React, { useRef, useEffect, useState } from 'react';
import { getAddPlayerToBookingDataLayer } from 'services/gtm/gtmDatalayers';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { translate } from 'translations/translate';
import { Dict, PlayerInfo, IUser } from 'types';
import './AddPlayer.scss';

interface IProps {
    userOptions: Dict<IUser>;
    removeOption: (id: string) => void;
    placeholder: string
    isNewReservation: boolean,
    loggedUserId: string
    facilityId: string,
}

export const AddPlayer = ({
    userOptions,
    removeOption,
    placeholder,
    isNewReservation,
    loggedUserId,
    facilityId,
}: IProps) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const [inputText, setInputText] = useState<string>('');
    const containerRef = useRef<HTMLElement>();

    const addPlayerInfo = {
        image: ADD_PLAYER_GRAY,
        name: translate('calendar-reservation_dialog-add_player_placeholder'),
    };

    const [playerInfo, setPlayerInfo] = useState<PlayerInfo>(addPlayerInfo);

    const filterOption = (inputValue: string, option: any) => option.label
        .toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

    const options = Object.values(userOptions)
        .map(({ name, surname, id } : {name: string, id: string, surname: string}) => (
            { label: `${name} ${surname}`, value: id }));

    const onSelect = (value: string) => {
        removeOption(value);
        setInputText('');
        pushGtmDatalayer(getAddPlayerToBookingDataLayer(
            loggedUserId,
            facilityId,
        ));
    };

    const onChange = (value: string) => {
        setInputText(value);
    };

    useEffect(() => {
        setInputText('');
    }, []);

    useEffect(() => {
        setPlayerInfo({
            ...addPlayerInfo,
            image: isHovered ? ADD_PLAYER_GRAY2 : ADD_PLAYER_GRAY,
        });
    }, [isHovered]);

    return (
        <div
            className={`
                add-player
                ${isHovered ? 'add-player--hover' : ''}
            `}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            ref={containerRef as any}
        >
            <div className={`
                add-player__image-cnt
                ${isHovered ? 'add-player__image-cnt--hover' : ''}
            `}
            >
                <img
                    alt={playerInfo.name}
                    src={playerInfo.image}
                    className="add-player__image"
                />
            </div>

            <AutoComplete
                value={inputText}
                options={options}
                style={{
                    width: 200,
                }}
                filterOption={filterOption}
                onSelect={onSelect}
                onChange={onChange}
                getPopupContainer={() => containerRef.current as HTMLElement}
                disabled={!isNewReservation}
            >

                <Input
                    className={`
                        add-player__input
                        ${isHovered ? 'add-player__input--hover' : ''}
                    `}
                    placeholder={placeholder}
                    bordered={false}
                />
            </AutoComplete>

        </div>
    );
};
