import { ExpiredTokenError } from 'services/errors/ExpiredTokenError';
import { isLoggedUserAdmin } from 'services/functionalStorage';
import { logoutUser } from 'services/logoutUser';
import { translate } from 'translations/translate';
import { ErrorFromServer } from 'types';
import { AccountNotVerified } from 'services/errors/AccountNotVerified';

export const handleErrorFromServer = (error:ErrorFromServer):never => {
    if (error.code) {
        switch (error.code) {
        case '007003':
            throw error;
        case '004018':
            throw new Error(
                translate(
                    isLoggedUserAdmin() ? 'error-004018-admin' : 'error-004018-player',
                    error.data.errorValues,
                ),
            );
        case '001017':
            throw new AccountNotVerified(error);
        case 401:
            logoutUser();
            throw new ExpiredTokenError();
        default:
            throw new Error(translate(`error-${error.code}`, error.data.errorValues));
        }
    } else {
        logoutUser();
        throw new ExpiredTokenError();
    }
};
