import React, { useState } from 'react';
import { Switch } from 'antd';
import { changePaymentMethodsStatus } from 'usecases/club/changePaymentMethodsStatus';
import { getClubFromStorage, setClubInStorage } from 'services/functionalStorage';
import { useApi } from 'hooks/useApi';
import { translate } from 'translations/translate';

export const CashPayment = () => {
    const club = getClubFromStorage();
    const callApi = useApi();
    const [checked, setChecked] = useState<boolean>(club.cashPaymentsEnabled);

    const onChangeCashPaymentsStatus = (toggleValue: boolean) => {
        callApi(changePaymentMethodsStatus(club, { cash: toggleValue })).then(() => {
            setClubInStorage({ ...getClubFromStorage(), cashPaymentsEnabled: toggleValue });
            setChecked(toggleValue);
        });
    };

    return (
        <div className="payment-methods__container">
            <p className="payment-methods__text">
                {translate('club_settings-cash_payments_description')}
                <ul>
                    <li>{translate('club_settings-cash_payments_description_item_1')}</li>
                    <li>{translate('club_settings-cash_payments_description_item_2')}</li>
                </ul>
            </p>

            <div className="payment-methods__toggle-container">
                <Switch
                    className="payment-methods__toggle"
                    onChange={onChangeCashPaymentsStatus}
                    checked={checked}
                />
                <p className="payment-methods__text">{translate('club_settings-cash_payments_toggle')}</p>
            </div>
        </div>
    );
};
