/* eslint-disable max-len */
export const enUS: { [key: string]: string } = {
    success: 'Success',
    error: 'Error',
    x_hours: '__hours__ hours',
    x_hour: '__hours__ hour',
    x_minutes: '__minutes__ minutes',
    x_hours_and_y_minutes: '__hours__ hours and __minutes__ minutes',
    x_hour_and_y_minutes: '__hours__ hour and __minutes__ minutes',
    privacy_policy: 'Privacy Policy',
    terms_and_conditions: 'Terms and conditions',
    imprint: 'Imprint',

    // Error messages
    // Global - 000
    'error-000001': 'Invalid request: __message__',
    // User - 001
    'error-001001': 'Password should be at least __minLength__ characters long',
    'error-001002': 'User with ID __id__ not found in the application',
    'error-001003': 'E-Mail __email__ not found in the application',
    'error-001004': 'Invalid e-mail: __email__',
    'error-001005': 'Gender __gender__ is not valid',
    'error-001006': 'Language __language__ is not supported',
    'error-001007': 'Incorrect password',
    'error-001008': 'Password and confirmation password do not match',
    'error-001009': 'The specified account type is not supported',
    'error-001010': 'Invalid google token',
    'error-001011': 'The refresh token does not match the specified user',
    'error-001012': 'Bad credentials',
    'error-001013': 'User already exists',
    'error-001014': 'All the fields in the address are required. Missing field: __missingField__',
    'error-001015': 'Role __role__ is not supported',
    'error-001016': 'Country __country__ is not supported',
    'error-001017': 'You must first confirm your registration in order to log in. To do so, please click the "Please confirm" button in the welcome e-mail.',
    // Facility - 002
    'error-002001': 'Facility with ID __id__ not found in the application',
    'error-002002': 'Facility name cannot be empty',
    'error-002003': 'Logged user is not the admin of facility __club__',
    'error-002004': 'The file must have less than __limit__ players',
    'error-002005': 'Row __row__ has an invalid format',
    'error-002006': 'To continue, please first enter the settlement data.',
    'error-002008': 'There is already another facility with that name.',
    'error-002009': 'You don\'t have the permission to change the role of the player \'__user__\'.',
    'error-002010': 'Token __token__ is no longer valid.',
    'error-002011': 'Online payment cannot be enabled because the set-up process has not yet been completed.',
    'error-002012': 'To continue, please first enter the settlement data.',
    'error-002013': 'The facility cannot be deactivated because it has future bookings. Please go to the calendar and cancel all future bookings before deactivating the facility.',
    'error-002015': 'At least one of the two payment methods must be enabled.',
    'error-002016': 'To continue, please first enter the settlement data.',
    'error-002017': 'You can\'t remove the settlement data as long as online payments are enabled.',
    'error-002018': 'You can\'t join this facility because it\'s not verified.',
    'error-002019': 'Your facility is unverified. So you can´t create or import players. Please contact courthero@tennis-point.de to verify your facility',
    // Court - 003
    'error-003001': 'Court with ID __id__ not found in the application',
    'error-003002': '__type__ is not a valid court surface',
    'error-003003': '__type__ is not a valid court location',
    // Reservation - 004
    'error-004001': 'Reservation with ID __id__ not found in the application',
    'error-004002': 'User has no permissions to modify this reservation',
    'error-004003': 'The reservation does not belong to the facility __club__',
    'error-004004': 'Past reservations cannot be modified',
    'error-004005': 'Only the admin of the facility can change the owner of a reservation',
    'error-004006': 'Cannot make a booking outside of the opening hours of the court',
    'error-004007': 'Court already booked in the selected time',
    'error-004008': 'Cannot book a court with more than __days__ days in advance',
    'error-004009': 'You can\'t book a court in the past',
    'error-004010': 'The maximum time you can book a court is __minutes__ minutes',
    'error-004011': 'The maximum time you can book a court for up to 2 players is __minutes__ minutes',
    'error-004012': 'You can\'t have more than __maximumActiveBookings__ active reservations at the same time',
    'error-004013': 'You can\'t have more than __maximumActiveBookings__ active booking in the next __minutes__ minutes',
    'error-004014': 'You can\'t book this court as your membership hasn\'t been activated by the facility yet',
    'error-004015': 'Invalid interval of time for the reservation filter',
    'error-004016': 'Invalid interval of time for the reservation',
    'error-004017': 'User __adminName__ has no permissions to create this reservation on behalf of the user __userName__',
    'error-004018-admin': 'This booking period cannot be booked because no price has been set yet.',
    'error-004018-player': 'This booking period cannot be booked because no price has been set yet by the facility admin.',
    'error-004019': 'You cannot book outside of the active season.',
    'error-004020': 'Your booking could not be completed because the price has changed in the meantime. Please reload the page.',
    'error-004021': 'You must add at least one fellow player (either member or guest player) to make a booking.',
    'error-004022': 'You cannot book a court with a player who has had already booked a court in the last __minimumAmountOfMinutesBetweenBookings__ minutes.',
    'error-004023': 'There must be at least __minimumAmountOfMinutesBetweenBookings__ minutes between two bookings. However, you can book an additional 30 minutes from 5 minutes before the end of your active booking.',
    'error-004025': 'At least one of the booking dates is not available.',
    'error-004027': 'Please contact the admin of this facility if you want to cancel the subscription.',
    'error-004028': 'You did not pay for your booking. Please cancel it in the calendar. Otherwise the booking will be cancelled automatically after 30 minutes.',
    'error-004029': 'The booking could not be cancelled because the cancellation period of 6 hours has expired.',
    'error-004033': 'You can\'t pay this booking online because the total amount must be at least €0,50.',
    'error-004034': 'The facility has been deactivated and you can no longer make any bookings.',
    'error-004035': 'Your facility is unverified and bookings are therefore not possible for your facility. Please contact courthero@tennis-point.de to verify your facility.',
    'error-004036': 'Booking is not possible because cash payment has been deactivated as a payment method by the facility owner.',
    'error-004037': 'Booking is not possible because online payment has been removed as a payment method by the facility owner.',

    // Time - 005
    'error-005001': '__time__ is not a valid time format',
    // Season - 006
    'error-006001': 'Season with ID __id__ not found in the application',
    'error-006002': 'Start date __startDate__ or end date __endDate__ have no valid format',
    'error-006003': 'Invalid interval of time for the season',
    'error-006004': 'The specified time period overlaps with season: __seasonName__',
    // Pricing Rule - 007
    'error-007001': 'Court __courtSelected__ is not available in season __season__. Available courts are __courtsAvailable__',
    'error-007002': 'Price rule with ID __id__ not found in the application',
    'error-007003': 'The price rule __pricingRule__ has a time overlap with this price rule. Please adjust one of the two price rules to continue.',
    // Invoice - 008
    'error-008004': 'A subscription invoice cannot be cancelled here. You need to cancel the whole subscription through the calendar to cancel the invoice.',
    // External service - 010
    'error-010001': 'File could not be uploaded',
    'error-010002': 'File exceeds maximum size of 2MB',
    'error-010003': 'File could not be deleted',
    'error-010004': 'E-Mail could not be sent',
    'error-010006': 'The payment setup process was closed unexpectedly. You can resume it in the “Online Payment” section of the Facility Settings page.',
    'error-010999': 'Unexpected Error: __message__',

    'menu-profile': 'Account',
    'menu-settings': 'Settings',
    'menu-logout': 'Logout',
    'menu-facilities': 'Venues',

    // User status
    'user-status_pending': 'PENDING',
    'user-status_active': 'ACTIVE',
    'user-status_inactive': 'INACTIVE',
    'user-status_all': 'ALL',

    or: 'Or',
    'sign-up': 'Sign up',
    'e-mail': 'e-mail',
    password: 'Password',
    'remember-me': 'Remember me',
    'forgot-your-password': 'Forgot your password?',

    'password-changed-successfully': 'Password changed successfully',
    'your-password-has-been-successfully-changed': 'Your password has been successfully changed.',
    'from-now-on-you-can-log-in-to-court-hero-with-your-new-password': 'From now on you can log in to Court Hero with your new password.',
    'go-to-login': 'Go to login',
    'enter-your-new-password': 'Enter your new password',
    'confirm-password': 'Confirm password',
    'change-password': 'Change password',
    'passwords-do-not-match': 'Passwords do not match',

    'we-sent-you-an-email': 'We sent you an e-mail',
    'you-have-successfully-registered-in-court-hero': 'You have successfully registered in Court Hero!',
    'check-your-inbox-for-verification-link': 'Check your inbox for the e-mail verification link',

    'email-verified-successfully-title': 'E-Mail verified successfully!',
    'email-verified-successfully-text': 'As of now, you can log in to Court Hero.',
    'the-email-could-not-be-confirmed': 'The e-mail could not be confirmed. If you copied and pasted the link, make sure that it’s completely copied.',

    'page-not-found': 'Page not found',
    'this-page-does-not-exist': 'This page does not exist',
    'go-back': 'Go back',

    'forgot-password': 'FORGOT PASSWORD?',
    'enter-your-email-to-recover-password': 'Please enter your e-mail to recover your password',
    'an-email-will-be-sent-to-reset-your-password': 'An e-mail will be sent to your address containing a link where you can reset your old password and set a new one',
    send: 'Send',

    'account-settings': 'ACCOUNT SETTINGS',
    'user-profile-updated': 'User profile updated',
    'profile-picture': 'Profile picture',
    'your-profile-picture-is-visible-to-everyone': 'Your profile picture is visible to everyone who is also logged in.',
    'edit-profile-picture': 'Edit profile picture',
    'delete-profile-picture': 'Delete profile picture',
    'first-name': 'First Name',
    'profile_card-first_name': 'FIRST NAME',
    'first-name-is-required': 'First Name is required',
    'last-name': 'Last Name',
    'profile_card-last_name': 'LAST NAME',
    'last-name-is-required': 'Last Name is required',
    'email-is-required': 'E-Mail is required',
    'club-name': 'Facility Name',
    'club-name-is-required': 'Facility name is required',
    'profile_card-gender': 'GENDER',
    gender: 'Gender',
    'gender-is-required': 'Gender is required',
    male: 'Male',
    female: 'Female',
    other: 'Other',
    'profile_card-language': 'LANGUAGE',
    language: 'Language',
    'language-is-required': 'Language is required',
    role: 'Role',
    'role-is-required': 'You must select a role',
    role_0: 'Member',
    role_1: 'Client',
    role_2: 'Admin',
    role_all: 'All',
    'save-changes': 'Save changes',
    save: 'Save',
    delete: 'Delete',
    'select-time': 'Select time',
    'current-password': 'CURRENT PASSWORD',
    'password-is-required': 'Password is required',
    'new-password': 'NEW PASSWORD',
    'new-password-is-required': 'New password is required',
    'repeat-new-password': 'REPEAT NEW PASSWORD',
    'repeat-password-is-required': 'Repeat password is required',
    'terms-is-required': 'Terms are required',
    'privacy-is-required': 'Privacy is required',
    'reset-password': 'Reset password',
    carpet: 'carpet',
    clay: 'clay',
    grass: 'grass',
    hard: 'hard',
    indoor: 'indoor',
    outdoor: 'outdoor',
    'not-specified': 'not specified',

    // Invoice Status
    invoice_UNPAID: 'Unpaid',
    invoice_PAID: 'Paid',
    invoice_CANCELLED: 'Cancelled',

    // Invoice Payment Method
    invoice_CASH: 'Cash',
    invoice_ONLINE: 'Online-Payment',

    // User side menu links
    'user_menu-calendar': 'Calendar',
    'user_menu-my_facility': 'My facility',
    'user_menu-club': 'Facility',

    // Register page
    'register-club_name': 'Name',
    'register-page_title': 'REGISTER',
    'register-page_title_link': 'Log in',
    'register-page_bottom-link': 'Log in',

    // User register page
    'user-register_title': 'REGISTER',
    'user-register_link': 'Log in',
    'user-register_bottom-link': 'Log in',
    'user_register-terms_and_conditions_agreement': 'Yes, I agree to', // Yes, I agree to Terms and conditions
    'user_register-terms_and_conditions_agreement2': ' ',
    'user_register-already-a-member': 'Already a member?',
    'user_register-privacy-policy-agreement': 'I have read and accept the', // I have read and accept the Privacy Policy
    'user_register-privacy-policy-agreement2': ' ',
    'user_register-sign_up': 'Sign up',

    // Calendar page
    'calendar_club-without-courts-notification-title': 'The facility has no courts',
    'calendar_club-without-courts-notification-body': 'Ask the facility administrator to add some courts to start the booking process.',
    calendar_header: 'Court Booking',
    'calendar_filter-today': 'today',
    'calendar_filter-surface': 'Surface',
    'calendar_filter-floodlights': 'Floodlights',
    'calendar_filter-inside-outside': 'Inside / Outside',
    'calendar_display-selector-week': 'Week',
    'calendar_display-selector-day': 'Day',
    'calendar_court-reserved': 'Court reserved',
    'calendar_no-owner-selected-error': 'An owner must be selected',
    calendar_from: 'from', // 07/07/2021 from 10:00
    'calendar_court-feature-carpet': 'Carpet',
    'calendar_court-feature-roofed': 'Roofed',
    'calendar_court-feature-floodlight': 'Floodlighting available',
    'calendar_reservation-confirmed-show-booking': 'Show booking',
    'calendar_reservation-confirmed-back-to-calendar': 'Back to calendar',
    'calendar_info-modal_header': 'The selected time slot is outside a season. Please select a time slot with a white background in the calendar.',
    'calendar_info-modal_second-button': 'Back to calendar',
    'calendar_info-no-courts-available': 'The selected date is outside of a created season.',

    'calendar-reservation_dialog-add_player_placeholder': 'Add player',
    'calendar-reservation_dialog-date_selector_label': 'Date',
    'calendar-reservation_dialog-time_selector_label': 'Time',
    'calendar-reservation_dialog-duration_selector_label': 'Duration',
    'calendar-reservation_dialog-court_selector_label': 'Court',
    'calendar-reservation_dialog-guests_list_title': 'Guests',
    'calendar-reservation_dialog-add_guest_player': 'Add guest player',
    'calendar-reservation_dialog-add_guest_modal_title': 'ADD PLAYER',
    'calendar-reservation_dialog-add_guest_modal_button': 'Save changes',
    'calendar-reservation_dialog-book_now': 'Book now',
    'calendar-reservation_dialog-book_now_free_booking': 'Book now',
    'calendar-reservation_dialog-email_notification_notice': 'You will receive a booking confirmation by e-mail.',
    'calendar-delete_reservation_admin_modal-footer': 'Are you sure you want to cancel the booking? This will cancel any invoices if applicable',
    'calendar-delete_reservation_user_modal-footer': 'Are you sure you want to cancel the booking?',
    'calendar-delete_reservation_user_modal-cancellation_policy': 'Please note that chargable bookings can only be cancelled up to 6 hours before the start.',
    'calendar-cancel_subscription_booking_modal-footer': 'Are you sure you want to cancel the subscription? This will also cancel the invoice.',
    'calendar-reservation_dialog-owner': 'Owner',
    'calendar-reservation_dialog-choose_an_owner': 'Choose an owner',
    'calendar-reservation_dialog-you': 'You',
    'calendar-reservation_dialog-players': 'players',
    'calendar-reservation_dialog-choose_a_player': 'Choose a player',
    'calendar-reservation_dialog-booking_successful': 'Booking successful!',
    'calendar-reservation_dialog-booking_successful-delete': 'Booking successfully cancelled!',
    'calendar-reservation_dialog-subscription_successful-delete': 'Subscription successfully cancelled!',
    'calendar-reservation_dialog-book-modal_total': 'TOTAL AMOUNT',
    'calendar-reservation_dialog-book-modal_fee-text': 'I am aware that there is a guest fee of __fee__',
    'calendar-reservation_dialog-book-modal_fee-text2': 'This will be settled directly with the facility on site',
    'calendar-reservation_dialog-book-modal_fee-notice': 'The total amount includes a guest fee of __feeAmount__.',
    'calendar-reservation_dialog-delete_booking': 'Cancel booking',
    'calendar-booking_dialog-cancel_subscription': 'Cancel subscription',
    'calendar-reservation_dialog-back': 'Back',
    'calendar-reservation_dialog-player-alt-image': 'player_image',
    'calendar-reservation_mobile-filters_open': 'Open filters',
    'calendar-reservation_mobile-filters_close': 'Close filters',
    'calendar-reservation_admin_no-courts-no-active-season-step-one': 'In the first step, please create your courts that players should be able to book.',
    'calendar-reservation_admin_no-courts-no-active-season-step-two': 'In the second step, please create a season and assign the desired courts to the season.',
    'calendar-reservation_admin_no-courts-no-active-season-step-three': 'In the third step, please create your price rules for the previously created season.',
    'calendar-reservation_admin_no-courts-no-active-season-step-four': 'In the last step, please edit your previously created season and enable the season for booking by players.',
    'calendar-reservation_admin_no-courts-in-active-season': 'Please assign the desired courts to the active season.',
    'calendar-reservation_player_no-active-season': 'Unfortunately you can\'t make a booking yet. You can only start booking courts after the facility administrator has created and released a season.',
    'calendar-reservation_dialog-payment_type_title': 'Payment',
    'calendar-reservation_dialog-cash': 'Cash',
    'calendar-reservation_dialog-online': 'Online-Payment',
    'calendar-reservation_dialog-total-amount': 'TOTAL AMOUNT',
    'calendar-reservation_dialog-description': 'DESCRIPTION ',
    'calendar-reservation_dialog-booking-type': 'BOOKING TYPE',
    'calendar-reservation_dialog-description-placeholder': 'Enter description text (optional)',
    'calendar-reservation_dialog-description-error': 'Please use a maximum of 200 characters.',
    'calendar-reservation_dialog-guest-error': 'The guest is already selected',
    'calendar-reservation_dialog-recurring-booking': 'Recurring booking for current season',
    'calendar-reservation_dialog-subscription-booking': 'Subscription for current season',
    'calendar-reservation_dialog-single-booking': 'Single booking',
    'calendar-reservation_dialog-recurring-booking-interval': 'Interval',
    'calendar-reservation_dialog-recurring-booking-interval-weekly': 'weekly',
    'calendar-reservation_dialog-recurring-booking-interval-biweekly': 'biweekly',
    'calendar-reservation_dialog-recurring-booking-ending': 'Ending on',
    'calendar-reservation_dialog-delete_single_booking': 'Cancel selected booking',
    'calendar-reservation_dialog-delete_recurring_booking': 'Cancel all recurring bookings',
    'calendar-reservation_dialog-cancellation_policy': 'Please note that chargable bookings can only be cancelled up to 6 hours before the start.',
    'calendar_reservation-players_roles_members': 'Members',
    'calendar_reservation-players_roles_guests': 'Guests',
    'calendar_reservation-players_roles_mixed': 'Members + Guest players',

    // Membership needs to be activated notice
    'membership_activation_notice-activated-notification-title': 'Congratulations!!',
    'membership_activation_notice-activated-notification-body': 'You are in and have access to the reservations!',
    'membership_activation_notice-still-inactive-notification-title': 'Try again later...',
    'membership_activation_notice-still-inactive-notification-body': 'Your user has not been verified yet.',
    'membership_activation_notice-header': 'Waiting for activation',
    'membership_activation_notice-more-info': 'more info',
    'membership_activation_notice-modal-first-paragraph': 'Only after successful activation by the facility administrator a booking can be made.',
    'membership_activation_notice-modal-second-paragraph': 'We ask for your patience.',
    'membership_activation_notice-modal-refresh-button': 'Click here if your facility has already verified your membership.',

    // Owner Deactivated Facility Notice Header
    'header_notice-deactivated_facility-message': 'Your facility is deactivated. Go to My facility > Facility to reactivate it.',

    // Owner Unverified Facility Notice Header
    'header_notice-unverified_facility-message': 'Your facility is unverified and players cannot join your facility. Also you can´t create or import players and bookings are not possible for your facility. Please contact courthero@tennis-point.de to verify your facility.',

    // Admin facility section
    club_title: 'FACILITY',
    'club_settings-general': 'GENERAL',
    'club_settings-club_logo': 'LOGO',
    'club_settings-edit_logo': 'Edit Logo',
    'club_settings-crop_image_title': 'Edit image',
    'club_settings-delete_logo': 'Delete Logo',
    'club_settings-delete_logo_back-button': 'Cancel',
    'club_settings-delete_logo_title': 'Are you sure you want to delete the facility logo?',
    'club_settings-club_name': 'NAME',
    'club_settings-club_name_required': 'Facility name is required',
    'club_settings_opening-hours': 'OPENING HOURS',
    'club_settings_opening-from': 'from',
    'club_settings_opening-to': 'to',
    club_settings_services: 'ADDITIONAL SERVICES',
    'club_settings-save': 'Save changes',
    'club_settings-service-shop': 'Shop',
    'club_settings-service-restaurant': 'Restaurant',
    'club_settings-service-beverages': 'Beverages',
    'club_settings-service-rental': 'Racket rental',
    'club_settings-service-balls': 'Purchasing balls',
    'club_settings-stringing': 'Stringing Service',
    'club_creation-success_modal_title': 'Facility successfully created!',
    'club_settings-success_modal_title': 'Facility profile updated',
    'club_settings_opening-hours-validation-opening': 'The opening time has to be before the closing time',
    'club_settings_opening-hours-validation-closing': 'The closing time has to be after the opening time',
    'club_settings-public_calendar_link': 'PUBLIC CALENDAR LINK',
    'club_settings-public_calendar_copy_it': 'Copy link',
    'club_settings-public_calendar_copied': 'Copied to clipboard!',
    'club_settings-public_calendar_ok': 'Got it',
    'club_settings-payments_title': 'ONLINE PAYMENT',
    'club_settings-payments_step_one': 'If you want your players to be able to pay for their bookings online, you will need to create an account with our partner Stripe. Click on the following button to start the set-up process:',
    'club_settings-payments_button-step_one': 'Set up online payment',
    'club_settings-payments_button-step_two': 'Continue setup',
    'club_settings-payments_step_two': 'The set-up process has started. If you have already completed it, your account will now be verified by Stripe. If you still need to provide more information to Stripe, click the "Continue setup" button below:',
    'club_settings-payments_pending': 'Pending',
    'club_settings-payments_step_three': 'The set-up process has been successfully completed. You can now activate online payment as a payment method for your players.',
    'club_settings-payments_toggle': 'Online Payment with Stripe',
    'club_settings-payments_approved': 'Approved',
    'club_settings-payments_step_four': 'The set-up process has been successfully completed. Online payment as a payment method is enabled for your players.',
    'club_settings-payments_go_to_account': 'Go to your Stripe Account',
    'club_settings-payments_setup_completed': 'Your online payments setup has started. If you need to enter more information for Stripe, you can resume the setup process in the “Online Payment” section of the Facility Settings page.',
    'club_settings-cash_payments_title': 'CASH PAYMENT',
    'club_settings-cash_payments_description': 'You can use the cash payment method if:',
    'club_settings-cash_payments_description_item_1': 'your members make free of charge bookings',
    'club_settings-cash_payments_description_item_2': 'you want to settle chargeable bookings on your own responsibility on site, through invoice or via SEPA direct debit',
    'club_settings-cash_payments_toggle': 'Cash Payment',
    // Faclity status card
    'club_settings-facility_status-title': 'FACILITY STATUS',
    'club_settings-facility_status-active_text': 'Your facility is active and visible to everyone in the search results. You can deactivate your facility here. If you do so, your facility won´t be accessible by anyone but yourself as an owner. It won´t appear in the search results nor in the venues list of players.',
    'club_settings-facility_status-active_button': 'Deactivate Facility',
    'club_settings-facility_status-deactivation_dialog-header': 'CONFIRM DEACTIVATION',
    'club_settings-facility_status-deactivation_dialog-subheader': 'This facility will be deactivated. Please consider:',
    'club_settings-facility_status-deactivation_dialog-subheader_point-one': 'The facility won´t be accessible by anyone but yourself as the owner.',
    'club_settings-facility_status-deactivation_dialog-subheader_point-two': 'The facility will no longer appear in the search results nor in the venues list of players.',
    'club_settings-facility_status-deactivation_dialog-subheader_point-three': 'The facility cannot be deactivated if it has any future bookings.',
    'club_settings-facility_status-deactivation_dialog-subheader_point-four': 'You can always reactivate your facility.',
    'club_settings-facility_status-deactivation_dialog-subheader_confirmation': 'Confirm by typing DEACTIVATE below:',
    'club_settings-facility_status-deactivation_dialog-confirm_button': 'Deactivate facility',
    'club_settings-facility_status-deactivation_dialog-back_button': 'Cancel',
    'club_settings-facility_status-deactivation_dialog-success_message': 'Your facility is deactivated now. If you change your mind, you can reactivate it any time.',
    'club_settings-facility_status-deactivation_dialog-success_button': 'Got it',
    'club_settings-facility_status-deactivation_dialog-error_message': 'The facility cannot be deactivated because it has future bookings. Please go to the calendar and cancel all future bookings before deactivating the facility.',
    'club_settings-facility_status-deactivation_dialog-error_button': 'Got it',
    'club_settings-facility_status-unactive_text': 'Your facility is deactivated.',
    'club_settings-facility_status-unactive_button': 'Activate facility',
    'club_settings-facility_status-activation_dialog-success_message': 'Your facility is activated now.',
    'club_settings-facility_status-activation_dialog-success_button': 'Got it',
    // Booking extra information card
    'booking-extra-information-card_title': 'E-MAIL COMMUNICATION',
    'booking-extra-information-card_description-label': 'Here you can enter important information that your players should receive with the booking confirmation. This information can be, for example, an access code or any other special information of your facility.',
    'booking-extra-information-card_description-placeholder': 'Please add important information here for the booking confirmation.',
    'booking-extra-information-card_save-changes': 'Save changes',

    // Facility members page
    club_members_title: 'PLAYERS',
    'club_members_header-first-button': 'Explanation',
    'club_members_header-second-button': 'New player',
    'club_members_header-import-button': 'Import players',
    'club_members_filter-season': 'Filter Season',
    'club_members-table_header-avatar': 'AVATAR',
    'club_members-table_header-name': 'NAME',
    'club_members-table_header-surname': 'LAST NAME',
    'club_members-table_header-email': 'E-MAIL',
    'club_members-table_header-role': 'ROLE',
    'club_members-table_header-register-date': 'REGISTER DATE',
    'club_members-table_header-status': 'STATUS',
    'club_members-actions-activate': 'Activate',
    'club_members-user-changed-succesfully': 'User changed correctly',
    'club_members-filter-status': 'Filter status',
    'club_members-filter-name': 'Filter player name',
    'club_members-filter-role': 'Filter role',
    'club_members_modal_edit-title': 'EDIT PLAYER',
    'club_members_modal_create-title': 'CREATE NEW PLAYER',
    club_members_modal_subtitle: 'ADDRESS',
    club_members_modal_name: 'FIRST NAME',
    'club_members_modal_name-required': 'First Name is required',
    club_members_modal_surname: 'LAST NAME',
    'club_members_modal_surname-required': 'Last Name is required',
    club_members_modal_email: 'E-MAIL',
    'club_members_modal_email-required': 'E-Mail is required',
    'club-members-modal_no-email-required': 'No e-mail address',
    club_members_modal_gender: 'GENDER',
    'club_members_modal_gender-required': 'Gender is required',
    club_members_modal_language: 'LANGUAGE',
    'club_members_modal_language-required': 'Language is required',
    club_members_modal_role: 'ROLE',
    'club_members_modal_role-required': 'You must specify a role for the player',
    club_members_modal_street: 'STREET',
    'club_members_modal_street-required': 'Street is required',
    club_members_modal_number: 'NUMBER',
    'club_members_modal_number-required': 'Number is required',
    'club_members_modal_zip-code': 'ZIP CODE',
    'club_members_modal_zip-code-required': 'Zip Code is required',
    club_members_modal_city: 'LOCATION',
    'club_members_modal_city-required': 'Location is required',
    club_members_modal_country: 'COUNTRY',
    'club_members_modal_country-required': 'Country is required',
    'club_members_modal_cancel-button': 'Cancel',
    'club_members_modal_create-button': 'Create player',
    'club_members_modal_edit-button': 'Edit player',
    'club_members_success-modal_title-create': 'Player successfully created!',
    'club_members_success-modal_title-edit': 'Player succesfully edited!',
    'club_members_success-delete-modal_header': 'Player successfully deleted!',
    'club_members_success-modal_first-button': 'Show player',
    'club_members_success-modal_second-button': 'Back to Players',
    'club_members_success-delete-second-button': 'Back to Players',
    'club_members-delete_player_confirmation-header': 'Are you sure you want to delete __playerName__?',
    'club_members-delete_player_confirmation-back_button': 'Cancel',
    'club_members-delete_player_confirmation-delete_button': 'Delete player',

    // Courts management page
    'courts-page_title': 'COURTS',
    'courts-page_header-first-button': 'Explanation',
    'courts-page_header-second-button': 'New court',
    'courts-page_table-header-name': 'NAME',
    'courts-page_table-header-surface': 'SURFACE',
    'courts-page_table-header-location': 'LOCATION',
    'courts-page_table-header-booking-hours': 'BOOKING HOURS',
    'courts-page_modal_create-title': 'CREATE NEW COURT',
    'courts-page_modal_edit-title': 'EDIT COURT',
    'courts-page_modal_court-name-label': 'COURT NAME',
    'courts-page_modal_name-is-required': 'Court name is required',
    'courts-page_modal_opening-label': 'OPENING TIME',
    'courts-page_modal_opening-is-required': 'Opening time is required',
    'courts-page_modal_opening-before-closing': 'The opening time has to be before the closing time',
    'courts-page_modal_closing-label': 'CLOSING TIME',
    'courts-page_modal_closing-is-required': 'Closing time is required',
    'courts-page_modal_closing-after-opening': 'The closing time has to be after the opening time',
    'courts-page_modal_surface-label': 'SURFACE',
    'courts-page_modal_surface-is-required': 'Surface is required',
    'courts-page_modal_location-label': 'LOCATION',
    'courts-page_modal_location-is-required': 'Location time is required',
    'courts-page_modal_cancel-button': 'Cancel',
    'courts-page_modal_create-button': 'Create court',
    'courts-page_modal_edit-button': 'Edit court',
    'courts-page_success-modal_title': 'Court successfully created!',
    'courts-page_success-modal_title-edit': 'Court successfully edited!',
    'courts-page_success-modal_first-button': 'Show court',
    'courts-page_success-modal_second-button': 'Back to Courts',
    'courts-page_delete-modal_header': 'Are you sure you want to delete the court?',
    'courts-page_delete-modal_notify-label': 'Send cancellation notification to affected players',
    'courts-page_delete-modal_back-button': 'Cancel',
    'courts-page_delete-modal_delete-button': 'Delete court',
    'courts-page_success-delete-modal_header': 'Court succesfully deleted!',
    'courts-page_success-delete-modal_second-button': 'Back to Courts',

    // Guest fees page
    'guest_fees-title': 'GUEST FEES',
    'guest_fees_header-first-button': 'Explanation',
    'guest_fees-table_header-avatar': 'AVATAR',
    'guest_fees-table_header-name': 'NAME',
    'guest_fees-table_header-email': 'E-MAIL',
    'guest_fees-table_header-bookings_with_guests': 'BOOKINGS WITH GUESTS',
    'guest_fees-table_header-bookings': 'BOOKINGS',
    'guest_fees-table_header-total_guest_fees': 'TOTAL GUEST FEES',
    'guest_fees-filters-search_by_name_placeholder': 'Search member by name',
    'guest_fees_filter-season': 'Filter Season',
    'guest_fees-filters-status-all': 'All',
    'guest_fees-table_header-total_amount': 'TOTAL AMOUNT',
    'guest_fees-currency_units': ' €',

    // Facility registration/invitation link notice
    'club_invitation_link_notice-text': 'This is the registration link for your facility.',
    'club_invitation_link_notice-copy_it': 'Copy it!',
    'club_invitation_link_notice-copied': 'Copied to clipboard!',
    'club_invitation_link_notice-ok': 'Got it',

    // Admin menu
    'admin_menu-calendar': 'Calendar',
    'admin_menu-club': 'Facility',
    'admin_menu-my_facility': 'My facility',
    'admin_menu-courts': 'Courts',
    'admin_menu-members': 'Players',
    'admin_menu-seasons': 'Seasons',
    'admin_menu-book_keeping': 'Book-keeping',
    'admin_menu-guest_fees': 'Guest fees',
    'admin_menu-prices': 'Prices',
    'admin_menu-settlement': 'Settlement',
    'admin_menu-invoices': 'Invoices',

    // Facility register
    'club_register-register': 'Register',
    'club_register-already_a_member': 'Already a member?',

    // Login page
    'login-page_title': 'LOG IN',
    'login-page_link': 'sign up with your own facility',
    'login-page_registration-link': 'Registration',
    'login-page_login-button-text': 'Log in',
    'login-page_privacy-policy': 'Privacy Policy',
    'login-page_resend-verification-email': 'Resend welcome e-mail',
    'login-page_resend-verification-email-success': 'Welcome e-mail sent!',

    // Common Register page
    'register-page_already-have-an-account': 'Already have an account?',

    // Password recovery page
    'password-recovery_log-in-link': 'Log in',
    'password-recovery_email-is-required': 'You need to specify your e-mail',
    'password-recovery_email-sent': 'Password recovery e-mail sent.',

    // Profile page
    'profile-card_title': 'GENERAL',
    'profile-password-card_title': 'PASSWORD',
    'profile-password-card_password-changed-message': 'Password changed successfully',
    'profile-picture_crop-image-title': 'Edit image',
    'profile-picture_delete_title': 'Are you sure you want to delete the image?',
    'profile-picture_delete-logo_back-button': 'Cancel',
    'profile-picture_delete-logo_delete-button': 'Delete image',

    // Profile page - Address Form
    'address-card_title': 'ADDRESS',
    'address-card_street': 'STREET',
    'address-card_number': 'NUMBER',
    'address-card_zipCode': 'ZIP CODE',
    'address-card_city': 'LOCATION',
    'address-card_country': 'COUNTRY',
    'address-card_street-is-required': 'Street is required',
    'address-card_number-is-required': 'Number is required',
    'address-card_zipCode-is-required': 'ZIP Code is required',
    'address-card_city-is-required': 'Location is required',
    'address-card_country-is-required': 'Country is required',
    'address-card_save-changes': 'Save changes',
    DE: 'Germany',
    FR: 'France',
    GB: 'UK',
    IT: 'Italy',
    NL: 'Netherlands',
    AT: 'Austria',
    CH: 'Switzerland',
    ES: 'Spain',

    // Profile page - Settlement Form
    'club-invoice-card_title': 'SETTLEMENT DATA',
    'club-invoice-card_bank': 'BANK',
    'club-invoice-card_iban': 'IBAN',
    'club-invoice-card_bic': 'BIC',
    'club-invoice-card_taxNumber': 'TAX NUMBER',
    'club-invoice-card_creditorId': 'CREDITOR ID',
    'club-invoice-card_bank-is-required': 'Bank is required',
    'club-invoice-card_iban-is-required': 'IBAN is required',
    'club-invoice-card_bic-is-required': 'BIC is required',
    'club-invoice-card_taxNumber-is-required': 'Tax number is required',
    'club-invoice-card_creditorId-is-required': 'Creditor ID is required',
    'club-invoice-card_save-changes': 'Save changes',

    // Profile page - Contact Data
    'club-contact-card_title': 'CONTACT DATA',
    'club-contact-card_phoneNumber': 'PHONE NUMBER',
    'club-contact-card_email': 'E-MAIL ADDRESS',
    'club-contact-card_homePage': 'HOMEPAGE',
    'club-contact-card_save-changes': 'Save changes',

    // Terms page
    'terms-page_go-back': 'Go back',

    // Seasons page
    'seasons-page_title': 'SEASONS',
    'seasons-page_table-header-name': 'SEASON NAME',
    'seasons-page_table-header-time': 'TIME PERIOD',
    'seasons-page_table-header-status': 'BOOKING ALLOWED',
    'seasons-page_header-first-button': 'Explanation',
    'seasons-page_header-second-button': 'New season',
    'seasons-page_modal_season-name-label': 'SEASON NAME',
    'seasons-page_modal_season-is-required': 'Season name is required',
    'seasons-page_modal_title-create': 'ADD NEW SEASON',
    'seasons-page_modal_title-edit': 'EDIT SEASON',
    'seasons-page_modal_cancel-button': 'Cancel',
    'seasons-page_modal_create-button': 'Create season',
    'seasons-page_modal_edit-button': 'Edit season',
    'seasons-page_modal_starting-from-label': 'STARTING FROM',
    'seasons-page_modal_starting-is-required': 'Starting from is required',
    'seasons-page_modal_starting-before-ending': 'The start date has to be before the end date.',
    'seasons-page_modal_ending-on-label': 'ENDING ON',
    'seasons-page_modal_ending-is-required': 'Ending on is required',
    'seasons-page_modal_ending-before-starting': 'The end date has to be after the start date.',
    'seasons-page_modal_courts-label': 'COURTS',
    'seasons-page_modal_courts-is-required': 'At least one court is required',
    'seasons-page_modal_active-label': 'Enable season for booking by players',
    'seasons-page_succes-modal_title-create': 'Season successfully created!',
    'seasons-page_succes-modal_title-edit': 'Season successfully edited!',
    'seasons-page_succes-modal_show-button': 'Show season',
    'seasons-page_succes-modal_back-button': 'Back to Seasons',
    'seasons-page_list_status-active': 'Yes',
    'seasons-page_list_status-inactive': 'No',
    'seasons-page_delete-modal_header': 'Are you sure you want to delete the season?',
    'seasons-page_delete-modal_notify-label': 'Please note that this will delete all price rules for this season as well as cancel all future bookings. This will also send a cancellation notification to affected players.',
    'seasons-page_delete-modal_back-button': 'Cancel',
    'seasons-page_delete-modal_delete-button': 'Delete season',
    'seasons-page_delete-modal_notify-required': 'Please tick the checkbox to permanently delete the season.',
    'seasons-page_success-delete-modal_header': 'Season succesfully deleted!',
    'seasons-page_success-delete-second-button': 'Back to Seasons',
    'seasons-page_no-courts-modal_link-text': 'courts',
    'seasons-page_no-courts-modal_text1': 'In the first step, please create your ',
    'seasons-page_no-courts-modal_text2': ' that you want your members to be able to book. In the second step, please create an active season and assign the desired courts to the season.',
    'seasons-page_no-courts-modal_first-button': 'Back',
    'seasons-page_deleted-courts-modal_header': 'Are you sure you want to remove the court from the active season?',
    'seasons-page_deleted-courts-modal_text': 'Send cancellation confirmation to affected players',
    'seasons-page_deleted-courts-modal_first-button': 'Remove court',
    'seasons-page_deleted-courts-modal_second-button': 'Back',
    'seasons-page_move-end-date-modal_header': 'Are you sure you want to bring forward the end date of the season? This will cancel any bookings if applicable.',
    'seasons-page_move-end-date-modal_text': 'Send cancellation confirmation to affected players',
    'seasons-page_move-end-date-modal_first-button': 'Change end date',
    'seasons-page_move-end-date-modal_second-button': 'Back',
    'seasons-page_modal_max-ending-date': 'The maximum duration of a season is one year.',

    // Prices page
    'prices-page_title': 'PRICES',
    'prices-page_header-first-button': 'Explanation',
    'prices-page_header-second-button': 'New Price rule',
    'prices-page_table-header-name': 'RULE NAME',
    'prices-page_table-header-days': 'DAYS',
    'prices-page_table-header-courts': 'COURTS',
    'prices-page_table-header-time': 'TIME PERIOD',
    'prices-page_table-header-role': 'ROLE',
    'prices-page_table-header-price': 'PRICE/H',
    'prices-page_table-header-guest-fee': 'GUEST FEE/H',
    'prices-page_table-header-color': 'COLOR',
    'prices-page_modal_create-title': 'CREATE NEW PRICE RULE',
    'prices-page_modal_edit-title': 'EDIT PRICE RULE',
    'prices-page_modal_season-label': 'SELECTED SEASON',
    'prices-page_modal_name-label': 'PRICE RULE NAME',
    'prices-page_modal_name-required': 'Price rule name is required',
    'prices-page_modal_days-label': 'DAYS',
    'prices-page_modal_days-required': 'Days is required',
    'prices-page_modal_starting-label': 'STARTING TIME',
    'prices-page_modal_starting-required': 'Starting time is required',
    'prices-page_modal_starting-before-ending': 'The starting time has to be before the ending time',
    'prices-page_modal_ending-label': 'ENDING TIME',
    'prices-page_modal_ending-required': 'Ending time is required',
    'prices-page_modal_ending-before-starting': 'The ending time has to be after the starting time',
    'prices-page_modal_role-label': 'ROLE',
    'prices-page_modal_role-required': 'Role is required',
    'prices-page_modal_price-label': 'PRICE/H',
    'prices-page_modal_price-required': 'Price is required',
    'prices-page_modal_courts-label': 'COURTS',
    'prices-page_modal_courts-required': 'Courts is required',
    'prices-page_modal_color-label': 'COLOR',
    'prices-page_modal_color-required': 'Color is required',
    'prices-page_modal_cancel-button': 'Cancel',
    'prices-page_modal_create-button': 'Create price rule',
    'prices-page_modal_edit-button': 'Edit price rule',
    'prices-page_modal_guest-fee-visibility-label': 'Activate guest fee (Member plays with guest)',
    'prices-page_modal_guest-fee-label': 'FEE/HR.',
    'prices-page_modal_guest-fee-required': 'Fee/hr is required',
    'prices-page_modal_overlapping-error-message1': 'The price rule',
    'prices-page_modal_overlapping-error-message2': 'has a time overlap with this price rule. Please adjust one of the two price rules to continue.',
    'prices-page_success-modal_title-create': 'Price rule successfully created!',
    'prices-page_success-modal_title-edit': 'Price rule successfully edited!',
    'prices-page_success-delete-modal_header': 'Price rule successfully deleted!',
    'prices-page_success-modal_first-button': 'Show price rule',
    'prices-page_success-modal_second-button': 'Back to Prices',
    'prices-page_no-season-info_step-one': 'In the first step, please create your courts that players should be able to book.',
    'prices-page_no-season-info_step-two': 'In the second step, please create a season and assign the desired courts to the season.',
    'prices-page_no-season-info_step-three': 'In the third step, please create your price rules for the previously created season.',
    'prices-page_no-season-info_step-four': 'In the last step, please edit your previously created season and enable the season for booking by players.',
    'prices-page_delete-modal_header': 'Are you sure you want to delete the price rule?',
    'prices-page_delete-modal_back-button': 'Cancel',
    'prices-page_delete-modal_delete-button': 'Delete price rule',
    'prices-page_mobile-filters_open': 'Select season',
    'prices-page_mobile-filters_close': 'Select season',
    'pricing-rule-unit': '€/hr.',

    // Book Keeping
    'book-keeping-page_no-data': 'Sorry, there are no data available for this time period. Please choose another time period.',

    // Settlement page
    'settlement-page_title': 'SETTLEMENT',
    'settlement-page_header-first-button': 'Explanation',
    'settlement-page_header-second-button': 'Settle selected players',
    'settlement-page_table-header-avatar': 'AVATAR',
    'settlement-page_table-header-name': 'NAME',
    'settlement-page_table-header-surname': 'LAST NAME',
    'settlement-page_table-header-role': 'ROLE',
    'settlement-page_table-header-total-amount': 'TOTAL AMOUNT',
    'settlement-page_table-settlement-button': 'Settle',
    'settlement-page_filter-start': 'from',
    'settlement-page_filter-end': 'to',
    'settlement-page_filter-name': 'Filter player name',
    'settlement-page_filter-role': 'Filter role',
    'settlement-page_success-modal_title-success': 'Players successfully settled!',
    'settlement-page_success-modal_title-with-errors': '__settled__/__total__ players successfully settled. __failed__ have failed.',
    'settlement-page_success-modal_first-button': 'Show invoice',
    'settlement-page_success-modal_second-button': 'Back to Settlement',

    // Invoices page
    'invoices-page_title': 'INVOICES',
    'invoices-page_header-explanation-button': 'Explanation',
    'invoices-page_header-bulk-cancel-button': 'Cancel selection',
    'invoices-page_table-header-avatar': 'Avatar',
    'invoices-page_table-header-invoice-number': 'Invoice No.',
    'invoices-page_table-header-name': 'Name',
    'invoices-page_table-header-surname': 'Last Name',
    'invoices-page_table-header-role': 'Role',
    'invoices-page_table-header-date': 'Invoice Date',
    'invoices-page_table-header-status': 'Status',
    'invoices-page_table-header-total-amount': 'Total Amount',
    'invoices-page_table-header-payment-method': 'Payment Method',
    'invoices-page_cancel-button': 'Cancel',
    'invoices-page_cancel-confirmation-header': 'Are you sure you want to cancel the invoice?',
    'invoices-page_cancel-multiple-confirmation-header': 'Are you sure you want to cancel all selected invoices?',
    'invoices-page_cancel-confirmation-proceed': 'Cancel invoice',
    'invoices-page_cancel-multiple-confirmation-proceed': 'Cancel invoices',
    'invoices-page_cancel-confirmation-back': 'Back',
    'invoices-page_cancel-success-header': 'Invoice successfully cancelled',
    'invoices-page_cancel-multiple-success-header': 'Invoices successfully cancelled',
    'invoices-page_cancel-success-button': 'Back to Invoices',
    'invoices-page_filter-start': 'from',
    'invoices-page_filter-end': 'to',
    'invoices-page_filter-name': 'Filter player name',
    'invoices-page_filter-status': 'Filter status',
    'invoices-page_filter-status_all': 'All',
    'invoices-page_filter-role': 'Filter role',
    'invoices-page_filter-invoice-number': 'Filter invoice number',
    'invoices-page_filter-payment-method_placeholder': 'Filter payment method',
    'invoices-page_unpay-button': 'Mark as unpaid',
    'invoices-page_pay-button': 'Mark as paid',

    // Import players modal
    'import-players-title': 'IMPORT PLAYERS',
    'import-players-modal_download-example-title': 'CSV sample file',
    'import-players-modal_download-example-text': 'The CSV sample file should serve you as a help to fulfill the requirements for the CSV import.',
    'import-players-modal_download-example-button': 'Download',
    'import-players-modal_upload-csv-input': 'Upload CSV file',
    'import-players-modal_import-players-button': 'Import players',
    'import-players-modal_data-rights-agreement': '',
    'import-players-modal_checkbox-text': 'As the responsible person for this facility, I hereby confirm that I am authorised to import and process the player data from the selected CSV file.',
    'import-players-modal_report-title': 'Import status',
    'import-players-modal_report-summary': 'players imported',
    'import-players-modal_report-table_email': 'E-Mail',
    'import-players-modal_report-table_row': 'Row',
    'import-players-modal_report-table_error': 'Error',
    'import-players-modal_download-csv_file': 'CSV sample file',

    // My facilities/select facility page
    'facility-card_go_to_calendar': 'Go to calendar',
    'facility_card-join_facility': 'Join new facility',
    'facility_card-create_facility': 'Create new facility',
    'facility_card-join_facitiy_input': 'FACILITY',
    'facility_card-join_this_facility': 'Join new facility',
    'select-facility-page_title': 'VENUES',
    'create_facility_card-create_new_club': 'Create new facility',
    'create_facility_card-club_name': 'FACILITY',
    'create_facility_card-club_name_required': 'Facility name is required',

    // Errors
    'token-expired-error': 'Token expired, you have to log in again',

    // Mobile Footer
    'mobile-filters_open': 'Open filters',
    'mobile-filters_close': 'Close filters',
};
