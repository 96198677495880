import React, { useState } from 'react';
import {
    Button,
    Card, Form, Input, notification,
} from 'antd';
import { useParams, Link } from 'react-router-dom';
import { translate } from 'translations/translate';
import { LOGO_RED } from 'config/constants';
import { changeForgottenPassword } from 'usecases/users/changeForgottenPassword';
import { ROUTES } from 'routing/routes.enum';
import {
    DesktopBlurredBackground,
} from 'components/Reservations/DesktopBlurredBackground/DesktopBlurredBackground';
import 'pages/common/WhiteCard/Whitecard.scss';
import 'pages/common/WhiteCard/WhiteCardForm.scss';

const PasswordChangedConfirmation = () => (
    <Card className="whitecard">
        <div className="whitecard__header">
            <img className="whitecard__header-image" src={LOGO_RED} alt="Court Hero Logo" />
        </div>
        <div className="whitecard__legend">
            <h1 className="whitecard__legend-title">{translate('password-changed-successfully')}</h1>
            <p className="whitecard__legend-text">
                {translate('your-password-has-been-successfully-changed')}
                {translate('from-now-on-you-can-log-in-to-court-hero-with-your-new-password')}
            </p>
        </div>
        <Link to={ROUTES.LOGIN}>
            <button type="button" className="whitecard__confirm-button">{translate('go-to-login')}</button>
        </Link>
    </Card>
);

export const ChangeForgottenPassword = () => {
    const [passwordChanged, setPasswordChanged] = useState(false);
    const { email, token } = useParams<{ email: string, token: string }>();

    const onFormSubmit = (values: { password: string, passwordConfirmation: string }) => {
        const { password, passwordConfirmation } = values;
        if (password !== passwordConfirmation) {
            notification.error({
                message: translate('error'),
                description: translate('passwords-do-not-match'),
            });
            return;
        }
        changeForgottenPassword(decodeURIComponent(email), token, password)
            .then(() => setPasswordChanged(true))
            .catch(error => {
                notification.error({
                    message: translate('error'),
                    description: error.message,
                });
            });
    };

    return (
        <DesktopBlurredBackground>
            {
                passwordChanged
                    ? <PasswordChangedConfirmation />
                    : (
                        <Card className="whitecard">
                            <div className="whitecard__header">
                                <img
                                    className="whitecard__header-image"
                                    src={LOGO_RED}
                                    alt="Court Hero Logo"
                                />
                            </div>
                            <div className="whitecard__legend">
                                <h1 className="whitecard__legend-title">{translate('enter-your-new-password')}</h1>
                            </div>
                            <Form onFinish={onFormSubmit} requiredMark="optional" name="form" layout="vertical">
                                <div>
                                    <Form.Item
                                        name="password"
                                        label={(
                                            <label htmlFor="password" className="whitecard__label">
                                                {translate('password')}
                                            </label>
                                        )}
                                        rules={[{ required: true }]}
                                    >
                                        <Input className="whitecard-form__custom-input" type="password" />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        name="passwordConfirmation"
                                        label={(
                                            <label
                                                htmlFor="passwordConfirmation"
                                                className="whitecard__label"
                                            >
                                                {translate('confirm-password')}
                                            </label>
                                        )}
                                        rules={[{ required: true }]}
                                    >
                                        <Input className="whitecard-form__custom-input" type="password" />
                                    </Form.Item>
                                </div>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="whitecard__confirm-button"
                                >
                                    {translate('change-password')}
                                </Button>
                            </Form>
                        </Card>
                    )
            }
        </DesktopBlurredBackground>
    );
};
