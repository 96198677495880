import React from 'react';
import { Layout } from 'antd';
import { IRoute } from 'types';
import { Route, Switch } from 'react-router-dom';
import { useMobileMediaQuery } from 'hooks/useMobileMediaQuery';
import { ConnectedSidebarComponent } from './components/Sidebar';
import { ConnectedNavbarComponent } from './components/NavBar';
import './LayoutPage.scss';

const { Content } = Layout;
interface IProps {
    routes: IRoute[];
    notice?: React.ReactNode;
    contentProps?: any;
    menu: JSX.Element
}

export const LayoutPage = ({
    routes, notice, contentProps, menu,
}: IProps) => {
    const { isMobile } = useMobileMediaQuery();

    return (
        <>
            <div className="layout-page__background" />

            <ConnectedNavbarComponent
                menu={menu}
            />

            <Layout style={{ minHeight: '100vh' }} className="layout-page">
                {!isMobile && (
                    <ConnectedSidebarComponent
                        menu={menu}
                    />
                )}

                <Content className="layout-page__content">
                    {notice}
                    <div className="layout-page__main">
                        <Switch>
                            {routes.map((route: IRoute) => (
                                <Route
                                    exact
                                    key={route.path}
                                    path={route.path}
                                    render={routerProps => <route.component {...contentProps} {...routerProps} />}
                                />
                            ))}
                        </Switch>
                    </div>
                </Content>
            </Layout>
        </>
    );
};
