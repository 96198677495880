import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useAppContext } from 'context/AppContext';
import { getClubFromStorage } from 'services/functionalStorage';
import { getCourts } from 'usecases/court/getCourts';
import UpdateCourt from 'usecases/court/UpdateCourt';
import DeleteCourt from 'usecases/court/DeleteCourt';
import CreateCourt from 'usecases/court/CreateCourt';
import { translate } from 'translations/translate';
import { FACILITY_ICON_COURTS } from 'config/constants';
import {
    ICourtForm, IListTableHeaderField, IModalTexts, ListAPIAction,
} from 'types';
import { ListTable } from 'components/ListTable/ListTable';
import { ICourt } from 'usecases/types/court';
import { ActionElements } from 'components/ActionElements/ActionElements';
import { getSuccessModalTexts } from 'adapters/getSuccessModalTexts';
import { getSurfaceIconUrl } from 'adapters/getSurfaceIconUrl';
import { ListPageHeader } from 'components/ListPageHeader/ListPageHeader';
import { ListPageButton } from 'components/ListPageButton/ListPageButton';
import { AlertModalContent } from 'components/AlertModalContent/AlertModalContent';
import { DeleteModalContent } from 'components/DeleteModalContent/DeleteModalContent';
import { InfoModalContent } from 'components/InfoModalContent/InfoModalContent';
import { useModalContext } from 'context/ModalContext';
import { useMobileMediaQuery } from 'hooks/useMobileMediaQuery';
import { sortAscendingByString } from './sortAscendingByString';
import { getCourtModalContent } from './getCourtModalContent';
import { CourtsExplanation } from './CourtsExplanation';
import './CourtsPage.scss';

export const CourtsPage = (): JSX.Element => {
    const { setIsSpinnerVisible } = useAppContext();
    const { setModalContentProps, setModalContent } = useModalContext();
    const [courts, setCourts] = useState<any[]>([]);
    const modalTexts: IModalTexts = {
        successCreateHeader: translate('courts-page_success-modal_title'),
        successEditHeader: translate('courts-page_success-modal_title-edit'),
        successDeleteHeader: translate('courts-page_success-delete-modal_header'),
        firstButtonSuccess: translate('courts-page_success-modal_first-button'),
        secondButtonSuccess: translate('courts-page_success-modal_second-button'),
        secondButtonDeleteSuccess: translate('courts-page_success-delete-modal_second-button'),
    };

    const { isMobile } = useMobileMediaQuery();

    const headers: IListTableHeaderField[] = [
        {
            field: '',
            value: translate('empty'),
        },
        {
            field: 'name',
            value: translate('courts-page_table-header-name'),

        },
        {
            field: 'surface',
            value: translate('courts-page_table-header-surface'),

        },
        {
            field: 'location',
            value: translate('courts-page_table-header-location'),

        },
        {
            field: 'booking-hours',
            value: translate('courts-page_table-header-booking-hours'),

        },
        {
            field: '',
            value: '',

        },
    ];

    const getCourtsDataFromAPI = async () => {
        try {
            const APICourts = await getCourts(getClubFromStorage());
            setCourts(APICourts);
        } catch (error: any) {
            notification.error({
                message: translate('error'),
                description: error.message,
            });
        }
    };

    const getFirstButtonSuccesModalOnClickActions = (action: ListAPIAction, court: ICourt) => {
        if (action === ListAPIAction.create || action === ListAPIAction.edit) {
            return () => {
                getCourtsDataFromAPI();
                setModalContentProps({
                    closable: true,
                    onCancel: () => setModalContent(null),
                });
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                setModalContent(getCourtModalContent({
                    titleKey: 'courts-page_modal_edit-title',
                    court,
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    onProceedCallback: editCourt,
                    onCancelCallback: () => setModalContent(null),
                }));
            };
        }
        return () => { };
    };

    const getSecondButtonSuccesModalOnClickActions = () => () => {
        getCourtsDataFromAPI();
        setModalContent(null);
    };

    const getSuccessModalContent = (apiAction: ListAPIAction, court: ICourt): JSX.Element => {
        const { header, firstButton, secondButton } = getSuccessModalTexts(apiAction, modalTexts);

        return (
            <AlertModalContent
                type="success"
                header={header}
                seeDetailsText={firstButton}
                goBackText={secondButton}
                onSeeDetails={getFirstButtonSuccesModalOnClickActions(apiAction, court)}
                onGoBack={getSecondButtonSuccesModalOnClickActions()}
            />
        );
    };

    const getDeleteModalContent = (court: ICourt) => (
        <DeleteModalContent
            headerText={translate('courts-page_delete-modal_header')}
            proceedText={translate('courts-page_delete-modal_delete-button')}
            cancelText={translate('courts-page_delete-modal_back-button')}
            checkboxText={translate('courts-page_delete-modal_notify-label')}
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            onProceed={(notify: boolean) => deleteCourt(notify, court)}
            onCancel={() => setModalContent(null)}
        />
    );

    const createCourt = async ({
        name,
        openingTime,
        closingTime,
        location,
        surface,
    }: ICourtForm) => {
        setIsSpinnerVisible(true);
        try {
            const newCourt = await CreateCourt.execute({
                clubId: getClubFromStorage().id,
                name,
                openingTime,
                closingTime,
                location,
                surface,
            });
            setModalContentProps({
                closable: false,
            });
            setModalContent(getSuccessModalContent(ListAPIAction.create, newCourt));
        } catch (error: any) {
            notification.error(
                {
                    message: translate('error'),
                    description: error.message,
                },
            );
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    const editCourt = async ({
        name,
        openingTime,
        closingTime,
        location,
        surface,
    }: ICourtForm, court: ICourt): Promise<void> => {
        setIsSpinnerVisible(true);

        try {
            const courtToEdit = {
                id: court.id,
                name,
                openingTime,
                closingTime,
                location,
                surface,
            };

            await UpdateCourt.execute(
                getClubFromStorage(),
                courtToEdit,
            );
            setModalContentProps({
                closable: false,
            });
            setModalContent(getSuccessModalContent(ListAPIAction.edit, courtToEdit));
        } catch (error: any) {
            notification.error(
                {
                    message: translate('error'),
                    description: error.message,
                },
            );
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    const deleteCourt = async (notify: boolean, court: ICourt): Promise<void> => {
        setIsSpinnerVisible(true);

        try {
            await DeleteCourt.execute(getClubFromStorage().id, court.id as string, notify);
            setModalContentProps({
                closable: false,
            });
            setModalContent(getSuccessModalContent(ListAPIAction.delete, court));
        } catch (error: any) {
            notification.error(
                {
                    message: translate('error'),
                    description: error.message,
                },
            );
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    const getListRows = (_courts: ICourt[]): any[][] => {
        const sortedCourts = _courts
            .sort((current, next) => sortAscendingByString(current.name, next.name));

        return sortedCourts.map(court => [
            getSurfaceIconUrl(court.surface!),
            court.name as string,
            translate(court.surface!),
            translate(court.location!),
            `${court.openingTime} - ${court.closingTime}`,
            <ActionElements
                firstButtonOnClick={() => {
                    setModalContentProps({
                        closable: !!isMobile,
                        onCancel: () => setModalContent(null),
                    });
                    setModalContent(getCourtModalContent({
                        titleKey: 'courts-page_modal_edit-title',
                        court,
                        onProceedCallback: editCourt,
                        onCancelCallback: () => setModalContent(null),
                    }));
                }}
                secondButtonOnClick={() => {
                    setModalContentProps({
                        closable: false,
                    });
                    setModalContent(getDeleteModalContent(court));
                }}
            />,
        ]);
    };

    useEffect(() => {
        getCourtsDataFromAPI();
    }, []);

    return (
        <>
            <ListPageHeader header={translate('courts-page_title')} iconUrl={FACILITY_ICON_COURTS}>
                <ListPageButton
                    grey
                    icon="?"
                    roundedIcon
                    onClick={() => {
                        setModalContentProps({
                            closable: true,
                            onCancel: () => setModalContent(null),
                        });
                        setModalContent(
                            <InfoModalContent>
                                <CourtsExplanation />
                            </InfoModalContent>,
                        );
                    }}
                >
                    {translate('courts-page_header-first-button')}
                </ListPageButton>
                <ListPageButton
                    icon="+"
                    onClick={() => {
                        setModalContentProps({
                            closable: !!isMobile,
                            onCancel: () => setModalContent(null),
                        });
                        setModalContent(getCourtModalContent({
                            titleKey: 'courts-page_modal_create-title',
                            onProceedCallback: createCourt,
                            onCancelCallback: () => setModalContent(null),
                        }));
                    }}
                >
                    {translate('courts-page_header-second-button')}
                </ListPageButton>
            </ListPageHeader>
            {courts.length > 0 && (
                <ListTable
                    rows={getListRows(courts)}
                    headers={headers}
                    notBorderedImage
                />
            )}
        </>
    );
};
