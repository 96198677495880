import React from 'react';
import { areCourtsInSeason } from 'components/Reservations/ReservationsCalendar/areCourtsInSeason';
import { isActiveSeason } from 'components/Reservations/ReservationsCalendar/isActiveSeason';
import { isLoggedUserAdmin } from 'services/functionalStorage';
import { translate } from 'translations/translate';
import { ISeason } from 'usecases/types/season';
import './NoSeasonInfoContent.scss';

interface IProps {
    season: ISeason | undefined
}

export const NoSeasonInfoContent = ({ season }: IProps): JSX.Element => {
    if (isLoggedUserAdmin()) {
        if (!isActiveSeason(season)) {
            return (
                <>
                    <p className="no-season-info__message">
                        {translate('calendar-reservation_admin_no-courts-no-active-season-step-one')}
                    </p>
                    <p className="no-season-info__message">
                        {translate('calendar-reservation_admin_no-courts-no-active-season-step-two')}
                    </p>
                    <p className="no-season-info__message">
                        {translate('calendar-reservation_admin_no-courts-no-active-season-step-three')}
                    </p>
                    <p className="no-season-info__message">
                        {translate('calendar-reservation_admin_no-courts-no-active-season-step-four')}
                    </p>
                </>
            );
        }
        if (!areCourtsInSeason(season)) {
            return (
                <>
                    <p className="no-season-info__message">
                        {translate('calendar-reservation_admin_no-courts-in-active-season')}
                    </p>
                </>
            );
        }
    }

    if (!isActiveSeason(season) || !areCourtsInSeason(season)) {
        return (
            <>
                <p className="no-season-info__message">
                    {translate('calendar-reservation_player_no-active-season')}
                </p>
            </>
        );
    }

    return <></>;
};
