import { PLACEHOLDERS, RESERVATIONS } from 'config/endpoints';
import { deleteWrapper } from 'services/httpService/httpServiceWrapper';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { getBookingCancelledDataLayer } from 'services/gtm/gtmDatalayers';
import { BookingType } from 'usecases/types/enums/booking_type.enum';
import { IReservation } from '../types/reservations';

export const deleteReservation = async (reservation: IReservation): Promise<any> => {
    const url = RESERVATIONS.SINGLE
        .replace(PLACEHOLDERS.CLUB_ID, reservation.club.id)
        .replace(PLACEHOLDERS.RESERVATION_ID, reservation.id);
    return deleteWrapper(url)
        .then(response => {
            pushGtmDatalayer(getBookingCancelledDataLayer(reservation, BookingType.SINGLE));
            return response;
        });
};
