import React from 'react';
import { NoticeHeader } from 'components/NoticeHeader/NoticeHeader';
import { translate } from 'translations/translate';
import { UserActiveHeader } from '../UserActiveHeader/UserActiveHeader';
import './UserHeader.scss';

interface Iprops {
    userActive: boolean,
    openModal: () => void,
}

export const UserHeader = ({
    userActive,
    openModal,
}: Iprops) => (
    <NoticeHeader>
        {userActive
            ? <UserActiveHeader />
            : (
                <>
                    <span>{translate('membership_activation_notice-header')}</span>
                    <span
                        className="user-header__link"
                        onClick={openModal}
                        onKeyPress={openModal}
                        role="button"
                        tabIndex={0}
                    >
                        {translate('membership_activation_notice-more-info')}
                    </span>
                </>
            )}
    </NoticeHeader>
);
