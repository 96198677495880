import React from 'react';
import {
    Button, Card, Form, Input, Select,
} from 'antd';
import { translate } from 'translations/translate';
import { Address } from 'types';
import { useAppContext } from 'context/AppContext';
import { getCountryMap } from './getCountryMap';
import './AddressCard.scss';

interface IProps {
    initialValue: Address | null,
    onFinish: (address: Address) => void,
    disabled?: boolean
}

export const AddressCard = ({ initialValue, onFinish, disabled = false }: IProps): JSX.Element => {
    const { isSpinnerVisible } = useAppContext();

    const address = initialValue || { country: '' } as Address;

    const countryMap = getCountryMap();

    const isButtonVisible = !disabled;

    return (
        <Card className="address-card">
            <h1 className="address-card__title">{translate('address-card_title')}</h1>
            <Form
                layout="vertical"
                name="basic"
                initialValues={{
                    street: address.street,
                    number: address.number,
                    city: address.city,
                    zipCode: address.zipCode,
                    country: address.country,
                }}
                onFinish={onFinish}
            >

                <Form.Item
                    label={(
                        <label className="address-card__form-label">
                            {translate('address-card_street')}
                        </label>
                    )}
                    name="street"
                    rules={[{ required: true, message: translate('address-card_street-is-required') }]}
                >
                    <Input
                        className="address-card__form-item"
                        disabled={disabled}
                    />
                </Form.Item>

                <Form.Item
                    label={(
                        <label className="address-card__form-label">
                            {translate('address-card_number')}
                        </label>
                    )}
                    name="number"
                    rules={[{ required: true, message: translate('address-card_number-is-required') }]}
                >
                    <Input
                        className="address-card__form-item"
                        disabled={disabled}
                    />
                </Form.Item>

                <Form.Item
                    label={(
                        <label className="address-card__form-label">
                            {translate('address-card_zipCode')}
                        </label>
                    )}
                    name="zipCode"
                    rules={[{ required: true, message: translate('address-card_zipCode-is-required') }]}
                >
                    <Input
                        className="address-card__form-item"
                        disabled={disabled}
                    />
                </Form.Item>

                <Form.Item
                    label={(
                        <label className="address-card__form-label">
                            {translate('address-card_city')}
                        </label>
                    )}
                    name="city"
                    rules={[{ required: true, message: translate('address-card_city-is-required') }]}
                >
                    <Input
                        className="address-card__form-item"
                        disabled={disabled}
                    />
                </Form.Item>

                <Form.Item
                    label={(
                        <label className="address-card__form-label">
                            {translate('address-card_country')}
                        </label>
                    )}
                    name="country"
                    rules={[{ required: true, message: translate('address-card_country-is-required') }]}
                >
                    <Select
                        size="large"
                        disabled={disabled}
                    >
                        {Object.entries(countryMap)
                            .map(([countryCode, countryName]) => (
                                <Select.Option
                                    key={countryCode}
                                    value={countryCode}
                                >
                                    {countryName}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>

                {isButtonVisible && (
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="address-card__button"
                        disabled={isSpinnerVisible}
                    >
                        {translate('address-card_save-changes')}
                    </Button>
                )}
            </Form>
        </Card>
    );
};
