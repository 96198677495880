import { ClubMembers } from 'pages/admin/ClubMembers/ClubMembers';
import { GuestFeesPage } from 'pages/admin/GuestFees/GuestFeesPage';
import { ClubPage } from 'pages/admin/Club/ClubPage';
import { CourtsPage } from 'pages/admin/Courts/CourtsPage';
import { AdminReservationsPage } from 'pages/admin/Dashboard/AdminReservationsPage';
import { SeasonsPage } from 'pages/admin/Seasons/SeasonsPage';
import { Profile } from 'pages/common/Profile/Profile';
import { PricesPage } from 'pages/admin/PricesPage/PricesPage';
import { SettlementPage } from 'pages/admin/Settlement/SettlementPage';
import { InvoicesPage } from 'pages/admin/Invoices/InvoicesPage';
import { ROUTES } from './routes.enum';

export const adminRoutes = [
    {
        path: ROUTES.ADMIN.DASHBOARD,
        component: AdminReservationsPage,
    },
    {
        path: ROUTES.ADMIN.CLUB,
        component: ClubPage,
    },
    {
        path: ROUTES.ADMIN.COURTS,
        component: CourtsPage,
    },
    {
        path: ROUTES.ADMIN.MEMBERS,
        component: ClubMembers,
    },
    {
        path: ROUTES.ADMIN.GUEST_FEES,
        component: GuestFeesPage,
    },
    {
        path: ROUTES.ADMIN.SEASONS,
        component: SeasonsPage,
    },
    {
        path: ROUTES.ADMIN.PRICES,
        component: PricesPage,
    },
    {
        path: ROUTES.ADMIN.SETTLEMENT,
        component: SettlementPage,
    },
    {
        path: ROUTES.ADMIN.INVOICES,
        component: InvoicesPage,
    },
    {
        path: ROUTES.ADMIN.PROFILE_SETTINGS,
        component: Profile,
    },
];
