import React from 'react';
import { Button, Card } from 'antd';
import { translate } from 'translations/translate';
import { useModalContext } from 'context/ModalContext';
import { AlertModalContent } from 'components/AlertModalContent/AlertModalContent';
import { IClub } from 'usecases/types/club';
import { useApi } from 'hooks/useApi';
import { changeFacilityActivation } from 'usecases/club/changeFacilityActivation';
import { DeactivateConfirmation } from './DeactivateConfirmation';
import './FacilityStatusCard.scss';

interface FacilityStatusCardProps {
    club: IClub,
    updateFacilityState: (newStatus: boolean) => void
}

export const FacilityStatusCard = ({ club, updateFacilityState }: FacilityStatusCardProps) => {
    const { setModalContentProps, setModalContent } = useModalContext();
    const callApi = useApi();

    const { active } = club;

    const onChangeFacilityActivation = async (isActive: boolean, nofify: boolean) => callApi(
        changeFacilityActivation(club.id, isActive).then(() => updateFacilityState(isActive)),
        nofify,
    );

    const deactivateFacility = () => {
        setModalContentProps({ closable: false });
        setModalContent(<DeactivateConfirmation
            onChangeFacilityActivation={onChangeFacilityActivation}
        />);
    };

    const activateFacility = async () => {
        await onChangeFacilityActivation(true, true).then(() => {
            setModalContentProps({ closable: false });
            setModalContent(<AlertModalContent
                type="success"
                header={translate('club_settings-facility_status-activation_dialog-success_message')}
                goBackText={translate('club_settings-facility_status-activation_dialog-success_button')}
                onGoBack={() => setModalContent(null)}
            />);
        });
    };

    return (
        <Card className="facility-status-card">
            <h1 className="facility-status-card__title">{translate('club_settings-facility_status-title')}</h1>

            {active
                ? (
                    <>
                        <p className="facility-status-card__text">
                            {translate('club_settings-facility_status-active_text')}
                        </p>

                        <Button
                            type="default"
                            size="large"
                            className="facility-status-card__button"
                            onClick={deactivateFacility}
                            disabled={false}
                        >
                            {translate('club_settings-facility_status-active_button')}
                        </Button>

                    </>
                ) : (
                    <>
                        <p className="facility-status-card__text">
                            {translate('club_settings-facility_status-unactive_text')}
                        </p>

                        <Button
                            type="primary"
                            size="large"
                            className="facility-status-card__button"
                            onClick={activateFacility}
                            disabled={false}
                        >
                            {translate('club_settings-facility_status-unactive_button')}
                        </Button>
                    </>
                )}
        </Card>
    );
};
