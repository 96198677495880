import { formatNumberAsLocaleCurrency } from 'adapters/formatNumberAsLocaleCurrency';
import React from 'react';
import { PlayerRole } from 'types';
import './CalendarPriceSlot.scss';

interface IProps {
    amount: number | undefined;
    role: PlayerRole;
    isadmin: boolean;
}

export const CalendarPriceSlot = ({ amount, role, isadmin }: IProps): JSX.Element => {
    if (isadmin) {
        return (
            <div className={`calendar-price-slot 
                ${role ? 'calendar-price-slot--client' : 'calendar-price-slot--member'}
                ${!amount && amount !== 0 ? 'calendar-price-slot--placeholder' : ''}
                `}
            >
                {amount || amount === 0 ? formatNumberAsLocaleCurrency(amount) : '-'}
            </div>
        );
    }
    if (amount) {
        return (
            <div className="calendar-price-slot">
                {formatNumberAsLocaleCurrency(amount)}
            </div>
        );
    }
    return <></>;
};
