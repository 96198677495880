import React from 'react';
import { Button } from 'antd';
import { translate } from 'translations/translate';
import './DeleteBookingModalButtons.scss';

interface IProps {
    keyButtonBack: string,
    keyButtonDelete: string,
    onClickButtonBack: () => void,
    onClickButtonDelete: () => void,
    disabled: boolean,
}

export const DeleteBookingModalButtons = ({
    keyButtonBack, keyButtonDelete, onClickButtonBack, onClickButtonDelete, disabled,
}: IProps) => (
    <>
        <Button
            type="default"
            htmlType="submit"
            className="delete-booking-modal-content-button delete-booking-modal-content-button--gray"
            onClick={onClickButtonBack}
        >
            {translate(keyButtonBack)}
        </Button>
        <Button
            danger
            htmlType="submit"
            className="delete-booking-modal-content-button delete-booking-modal-content-button--red"
            onClick={onClickButtonDelete}
            disabled={disabled}
        >
            {translate(keyButtonDelete)}
        </Button>
    </>
);
