import { CLUBS, PLACEHOLDERS } from 'config/endpoints';
import { IUser, Address, PlayerRole } from 'types';
import { postWrapper } from 'services/httpService/httpServiceWrapper';
import { GenderEnum } from 'usecases/types/enums/gender.enum';
import { LanguageEnum } from 'usecases/types/enums/language.enum';

interface Params {
    clubId: string;
    email: string | null;
    name: string;
    surname: string;
    gender: GenderEnum;
    language: LanguageEnum;
    role: PlayerRole,
    address: Address | null;
}

export const createClubPlayer = async ({
    clubId,
    email,
    name,
    surname,
    gender,
    language,
    role,
    address,
}: Params): Promise<IUser> => {
    const payLoad = {
        clubId,
        email: email || null,
        name,
        surname,
        gender,
        language,
        role,
        address,
    };

    const url = CLUBS.PLAYER_LIST.replace(PLACEHOLDERS.CLUB_ID, clubId);
    const { data } = await postWrapper(url, payLoad);

    return data.player;
};
