import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
    Button, Card, notification, Upload,
} from 'antd';
import { ImgCrop } from 'components/ImgCrop/ImgCrop';
import { DeleteModalContent } from 'components/DeleteModalContent/DeleteModalContent';
import { DEFAULT_AVATAR } from 'config/constants';
import { useAppContext } from 'context/AppContext';
import { useModalContext } from 'context/ModalContext';
import React, { useState } from 'react';
import { getUserFromStorage, setUserInStorage } from 'services/functionalStorage';
import { translate } from 'translations/translate';
import { IUser } from 'types';
import { deleteAvatar } from 'usecases/users/deleteAvatar';
import UploadAvatar from 'usecases/users/UploadAvatar';
import './ProfilePicture.scss';

export const ProfilePicture = ({ onSuccessfulChange }: {onSuccessfulChange: Function}): JSX.Element => {
    const { isSpinnerVisible, setIsSpinnerVisible } = useAppContext();
    const { setModalContentProps, setModalContent } = useModalContext();
    const currentUser: IUser = getUserFromStorage();
    const [avatar, setAvatar] = useState<string>(currentUser.avatar || DEFAULT_AVATAR);

    const onAvatarChange = async (values: any) => {
        setIsSpinnerVisible(true);

        try {
            const avatarUrl = await UploadAvatar.execute(values.file as Blob);
            onSuccessfulChange();
            setAvatar(avatarUrl);
            setUserInStorage({ ...currentUser, avatar: avatarUrl });
        } catch (error: any) {
            notification.error({
                message: translate('error'),
                description: error.message,
            });
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    const onDeleteAvatar = async () => {
        setIsSpinnerVisible(true);

        try {
            await deleteAvatar();
            onSuccessfulChange();
            setAvatar(DEFAULT_AVATAR);
        } catch (error: any) {
            notification.error({
                message: translate('error'),
                description: error.message,
            });
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    const getDeleteModalContent = () => (
        <DeleteModalContent
            headerText={translate('profile-picture_delete_title')}
            cancelText={translate('profile-picture_delete-logo_back-button')}
            proceedText={translate('profile-picture_delete-logo_delete-button')}
            onProceed={onDeleteAvatar}
            onCancel={() => setModalContent(null)}
        />
    );

    return (
        <div className="profile-picture">
            <Card className="profile-picture__card">
                <h1 className="profile-picture__title">{translate('profile-picture')}</h1>
                <div className="profile-picture__container">
                    <img
                        className="profile-picture__image"
                        src={avatar}
                        alt={getUserFromStorage().name}
                    />
                    <div className="profile-picture__buttons">
                        <p className="profile-picture__text">
                            {translate('your-profile-picture-is-visible-to-everyone')}
                        </p>
                        <ImgCrop modalTitle={translate('profile-picture_crop-image-title')}>
                            <Upload
                                className="profile-picture__button"
                                maxCount={1}
                                showUploadList={false}
                                name="avatar"
                                listType="picture"
                                customRequest={onAvatarChange}
                            >
                                <Button
                                    type="primary"
                                    size="large"
                                    className="profile-picture__profile-button"
                                    disabled={isSpinnerVisible}
                                >
                                    <EditOutlined />
                                    {' '}
                                    {translate('edit-profile-picture')}
                                </Button>
                            </Upload>
                        </ImgCrop>
                        <Button
                            type="default"
                            size="large"
                            className="profile-picture__profile-button profile-picture__profile-button--delete"
                            onClick={() => {
                                setModalContentProps({
                                    closable: false,
                                });
                                setModalContent(getDeleteModalContent());
                            }}
                            disabled={isSpinnerVisible}
                        >
                            <DeleteOutlined />
                            {' '}
                            {translate('delete-profile-picture')}
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    );
};
