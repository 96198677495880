import { setClubInStorage } from 'services/functionalStorage';
import { PLACEHOLDERS, CLUBS } from 'config/endpoints';
import { getWrapper } from 'services/httpService/httpServiceWrapper';
import { IClub } from 'usecases/types/club';

export const getClub = async (clubId: string) => {
    const url = CLUBS.SINGLE.replace(
        PLACEHOLDERS.CLUB_ID,
        clubId,
    );
    const { data } = await getWrapper(url);
    const { club } = data;

    return club;
};

export const getClubAndSetInStorage = async (clubId: string): Promise<IClub> => {
    const club = await getClub(clubId);

    setClubInStorage(club);

    return club;
};
