/* eslint-disable indent */
import React, { useState } from 'react';
import {
    notification, RadioChangeEvent,
} from 'antd';
import { translate } from 'translations/translate';
import { useAppContext } from 'context/AppContext';
import { IReservation } from 'usecases/types/reservations';
import { deleteReservation } from 'usecases/reservation/deleteReservation';
import { deleteRecurringBooking } from 'usecases/reservation/deleteRecurringBooking';
import { CHRadioGroup } from 'components/CHRadioGroup/CHRadioGroup';
import { BookingType } from 'usecases/types/enums/booking_type.enum';
import { deleteSubscriptionBooking } from 'usecases/reservation/deleteSubscriptionBooking';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { getUnsuccessfulCancellationBookingDataLayer } from 'services/gtm/gtmDatalayers';
import { getUserFromStorage } from 'services/functionalStorage';
import { getUserMembership } from 'helpers/getUserMembership';
import { belongsToRecurringBooking } from '../../belongsToRecurringBooking';
import { DeleteBookingModalButtons } from './DeleteBookingModalButtons/DeleteBookingModalButtons';
import './DeleteReservationModalContent.scss';

interface IProps {
    reservation: IReservation;
    isAdmin: boolean;
    onReservationDeleted: () => void;
    onCancel: () => void
}

export const DeleteReservationModalContent = ({
    reservation,
    onReservationDeleted,
    onCancel,
    isAdmin,
}: IProps) => {
    const { isSpinnerVisible, setIsSpinnerVisible } = useAppContext();
    const [deleteMultiple, setDeleteMultiple] = useState<boolean>(false);
    const showDeleteOptions = belongsToRecurringBooking(reservation);
    const isSubscriptionBooking = reservation.bookingType === BookingType.SUBSCRIPTION;
    const user = getUserFromStorage();
    const currentMembership = getUserMembership(user, reservation.club)!;

    const onChange = (e: RadioChangeEvent) => {
        setDeleteMultiple(Boolean(e.target.value));
    };

    // eslint-disable-next-line no-nested-ternary
    const questionTextKey = isSubscriptionBooking ? 'calendar-cancel_subscription_booking_modal-footer'
        : (isAdmin
            ? 'calendar-delete_reservation_admin_modal-footer'
            : 'calendar-delete_reservation_user_modal-footer');

    const onDeleteReservation = async () => {
        setIsSpinnerVisible(true);

        try {
            if (deleteMultiple) {
                await deleteRecurringBooking(reservation);
            } else {
                await deleteReservation(reservation);
            }
            onReservationDeleted();
        } catch (error: any) {
            notification.error({
                message: translate('error'),
                description: error.message,
            });

            pushGtmDatalayer(getUnsuccessfulCancellationBookingDataLayer(
                user.id,
                reservation.club.id,
                reservation,
                currentMembership.role,
                error.message,
            ));
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    const onDeleteSubscriptionBooking = async () => {
        setIsSpinnerVisible(true);

        try {
            await deleteSubscriptionBooking(reservation);
            onReservationDeleted();
        } catch (error: any) {
            notification.error({
                message: translate('error'),
                description: error.message,
            });

            pushGtmDatalayer(getUnsuccessfulCancellationBookingDataLayer(
                user.id,
                reservation.club.id,
                reservation,
                currentMembership.role,
                error.message,
            ));
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    return (
        <div className="delete-reservation-modal-content">
            <p className="delete-reservation-modal-content__text">
                {translate(questionTextKey)}
            </p>
            {!isAdmin && (
                <p className="delete-reservation-modal-content__text">
                    {translate('calendar-delete_reservation_user_modal-cancellation_policy')}
                </p>
            )}
            {showDeleteOptions
                && isAdmin && (
                    <CHRadioGroup
                        direction="vertical"
                        primarySelectedColor
                        radioOptions={
                            [
                                { value: false, key: 'calendar-reservation_dialog-delete_single_booking' },
                                { value: true, key: 'calendar-reservation_dialog-delete_recurring_booking' },
                            ]
                        }
                        customClassName="delete-reservation-modal-content__options"
                        name="delete-booking"
                        value={deleteMultiple}
                        onChange={onChange}
                    />
                )}

            <DeleteBookingModalButtons
                keyButtonBack="calendar-reservation_dialog-back"
                keyButtonDelete={isSubscriptionBooking
                    ? 'calendar-booking_dialog-cancel_subscription'
                    : 'calendar-reservation_dialog-delete_booking'}
                onClickButtonBack={() => onCancel()}
                onClickButtonDelete={isSubscriptionBooking ? onDeleteSubscriptionBooking : onDeleteReservation}
                disabled={isSpinnerVisible}
            />
        </div>
    );
};
