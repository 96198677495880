import React from 'react';
import { AutoComplete, Input, Select } from 'antd';
import { translate } from 'translations/translate';
import { ISeason } from 'usecases/types/season';
import './GuestFeesFilters.scss';

interface INameFilterOption {
    label: string;
    value: string;
}

interface IProps {
    nameFilterValue : string;
    nameFilterOptions : INameFilterOption[];
    getNameFilterOption : (inputValue: string, option: any) => any;
    onChangeNameFilter : (value: string) => void;
    setSeasonFilterValue : (value: string | '') => void;
    seasonOptions: ISeason[];
}

export const GuestFeesFilters = ({
    nameFilterValue, nameFilterOptions, getNameFilterOption,
    onChangeNameFilter,
    setSeasonFilterValue,
    seasonOptions,
}: IProps): JSX.Element => (
    <div className="guest-fees-filters__filters-container">
        <AutoComplete
            className="guest-fees-filters__filter"
            value={nameFilterValue}
            options={nameFilterOptions}
            filterOption={getNameFilterOption}
            onChange={onChangeNameFilter}
        >

            <Input
                placeholder={translate('guest_fees-filters-search_by_name_placeholder')}
                bordered={false}
            />
        </AutoComplete>
        <Select
            size="large"
            className="guest-fees-filters__filter"
            placeholder={translate('guest_fees_filter-season')}
            onChange={setSeasonFilterValue}
        >
            {seasonOptions.map(season => (
                <Select.Option value={season.id} key={season.id}>{season.name}</Select.Option>
            ))}
            <Select.Option value="">{translate('guest_fees-filters-status-all')}</Select.Option>
        </Select>
    </div>
);
