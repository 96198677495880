import { customHistory } from 'routing/customHistory';

export const getResponseCodeFromUrl = (urlParams: URLSearchParams) => {
    const statusCode = urlParams.get('code');
    urlParams.delete('code');

    customHistory.originalReplace({ search: decodeURIComponent(urlParams.toString()) });

    return { statusCode };
};
