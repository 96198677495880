import React from 'react';
import { dateFormatter } from 'adapters/dateFormatter';
import { useAppContext } from 'context/AppContext';
import { Moment } from 'moment';
import { translate } from 'translations/translate';
import { IReservation } from 'usecases/types/reservations';
import { MIDDLE_DOT } from 'config/constants';
import { getTime } from '../../timeHelpers';
import './ModalHeader.scss';

interface IProps {
    date: Moment,
    icon: JSX.Element,
    reservation: IReservation
}

export const ModalHeader = ({
    date,
    icon,
    reservation,
}: IProps) => {
    const { antdLocaleObject } = useAppContext();
    const { locale: antdLocale } = antdLocaleObject;

    return (
        <>
            <div className="modal-header__container">
                <div>
                    <h2 className="modal-header__title">
                        {dateFormatter(date.toDate(), antdLocale)}
                        {' '}
                        <span className="modal-header__title-text">
                            {translate('calendar_from')}
                        </span>
                        {' '}
                        {getTime(date.format())}
                    </h2>
                    <div
                        className="modal-header__subtitle-container"
                        style={{ display: 'flex' }}
                    >
                        <img
                            className="modal-header__subtitle-img"
                            src="./assets/court-carpet.png"
                            alt="court-carpet"
                        />
                        <p>
                            <span className="modal-header__court-info">
                                <b>{reservation.court.name}</b>
                            </span>
                            {reservation.court.surface && (
                                <>
                                    <span>
                                        <b>
                                            {' '}
                                            {MIDDLE_DOT}
                                            {' '}
                                        </b>
                                    </span>
                                    <span className="modal-header__court-info">
                                        {translate(reservation.court.surface)}
                                    </span>
                                </>
                            )}
                            {reservation.court.location && (
                                <>
                                    <span>
                                        <b>
                                            {' '}
                                            {MIDDLE_DOT}
                                            {' '}
                                        </b>
                                    </span>
                                    <span className="modal-header__court-info">
                                        {translate(reservation.court.location)}
                                    </span>
                                </>
                            )}
                        </p>
                    </div>
                </div>
            </div>
            {icon}
        </>
    );
};
