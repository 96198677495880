import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { translate } from 'translations/translate';
import { Card } from 'antd';
import { DesktopBlurredBackground }
    from 'components/Reservations/DesktopBlurredBackground/DesktopBlurredBackground';
import { verifyEmail } from 'usecases/users/verifyEmail';
import { ROUTES } from 'routing/routes.enum';
import { LOGO_RED } from 'config/constants';
import 'pages/common/WhiteCard/Whitecard.scss';

export const EmailVerify = (): JSX.Element => {
    const { email, token } = useParams<{ email: string, token: string }>();
    const [verified, setVerified] = useState<boolean | undefined>(undefined);

    useEffect(
        () => {
            verifyEmail(decodeURI(email), token).then(() => {
                setVerified(true);
            }).catch(() => {
                setVerified(false);
            });
        },
        [],
    );

    const displayMessage = () => {
        if (verified === undefined) return null;
        if (verified) {
            return (
                <>
                    <h1 className="whitecard__legend-title">{translate('email-verified-successfully-title')}</h1>
                    <p className="whitecard__legend-text">{translate('email-verified-successfully-text')}</p>
                </>
            );
        }
        return <p className="whitecard__legend-text">{translate('the-email-could-not-be-confirmed')}</p>;
    };

    return (
        <DesktopBlurredBackground>
            <Card className="whitecard">
                <div className="whitecard__header">
                    <img className="whitecard__header-image" src={LOGO_RED} alt="Court Hero Logo" />
                </div>
                <div className="whitecard__legend">
                    {displayMessage()}
                </div>
                <Link to={ROUTES.LOGIN}>
                    <button
                        type="button"
                        className="whitecard__confirm-button whitecard__confirm-button--email-verify-card"
                    >
                        {translate('go-to-login')}
                    </button>
                </Link>
            </Card>
        </DesktopBlurredBackground>
    );
};
