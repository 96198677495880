import { IReservation } from 'usecases/types/reservations';
import { PLACEHOLDERS, RESERVATIONS } from 'config/endpoints';
import { deleteWrapper } from 'services/httpService/httpServiceWrapper';
import { pushGtmDatalayer } from 'services/gtm/pushGtmDatalayer';
import { getBookingCancelledDataLayer } from 'services/gtm/gtmDatalayers';
import { BookingType } from 'usecases/types/enums/booking_type.enum';

export const deleteSubscriptionBooking = async (booking: IReservation): Promise<any> => {
    const url = RESERVATIONS.CANCEL_SUBSCRIPTION
        .replace(PLACEHOLDERS.CLUB_ID, booking.club.id)
        .replace(PLACEHOLDERS.RECURRING_ID, booking.recurringId!);
    return deleteWrapper(url)
        .then(response => {
            pushGtmDatalayer(getBookingCancelledDataLayer(booking, BookingType.SUBSCRIPTION));
            return response;
        });
};
