import React, { useEffect, useState } from 'react';
import {
    Router,
} from 'react-router-dom';
import { customHistory } from 'routing/customHistory';
import { useAppContext } from 'context/AppContext';
import { useFeatureFlagContext } from 'context/FeatureFlagContext/FeatureFlagContext';
import { ConfigProvider } from 'antd';
import { SpinnerComponent } from 'components/Spinner/SpinnerComponent';
import { isUserAuthenticated } from 'services/functionalStorage';
import { AuthenticatedApp } from 'routing/AuthenticatedApp';
import { UnauthenticatedApp } from 'routing/UnauthenticatedApp';
import { ModalProvider } from 'context/ModalContext';
import moment from 'moment';
import styles from './App.module.scss';

export const App = (): JSX.Element => {
    const { antdLocaleObject } = useAppContext();
    const [canRenderApp, setCanRenderApp] = useState<boolean>(false);
    const { loaded } = useFeatureFlagContext();

    moment.locale(antdLocaleObject.locale);

    useEffect(() => {
        setCanRenderApp(loaded);
    }, [loaded]);

    if (canRenderApp) {
        return (
            <ConfigProvider locale={antdLocaleObject}>
                <Router history={customHistory}>
                    <ModalProvider>
                        <div className={styles['app-container']}>
                            <SpinnerComponent />
                            {isUserAuthenticated() ? <AuthenticatedApp /> : <UnauthenticatedApp />}
                        </div>
                    </ModalProvider>
                </Router>
            </ConfigProvider>
        );
    }

    return <></>;
};
