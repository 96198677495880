import React, {
    useCallback, useState, useEffect, useRef, useMemo,
} from 'react';
import { Modal, Slider, Upload } from 'antd';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import getCroppedImg, { compressImage } from './cropper';
import './ImgCrop.scss';

type IProps = {
    modalTitle: string
    children: React.ReactComponentElement<typeof Upload>
}

const ZOOM_STEP = 0.1;
const ZOOM_MIN = 1;
const ZOOM_MAX = 3;

export const ImgCrop = ({ modalTitle, children }: IProps) => {
    const resolveRef = useRef<(file: File) => void>();
    const rejectRef = useRef<() => void>();
    const fileNameRef = useRef<string>();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [imagePath, setImagePath] = useState<string>();
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
    const [croppedImage, setCroppedImage] = useState<Blob>();

    const clear = () => {
        setIsModalVisible(false);
        setImagePath(undefined);
        setZoom(1);
        setCrop({ x: 0, y: 0 });
        setCroppedAreaPixels(undefined);
    };

    useEffect(() => {
        if (!resolveRef.current || !fileNameRef.current || !croppedImage) {
            return;
        }

        setIsModalVisible(false);
        resolveRef.current(new File([croppedImage], fileNameRef.current));
        clear();
    }, [croppedImage]);

    useEffect(() => {
        if (!imagePath) {
            return;
        }

        setIsModalVisible(true);
    }, [imagePath]);

    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixelsResult: Area) => {
        setCroppedAreaPixels(croppedAreaPixelsResult);
    }, []);

    const handleOk = async () => {
        if (!imagePath || !croppedAreaPixels) {
            return;
        }

        const croppedImageRes = await getCroppedImg(
            imagePath,
            croppedAreaPixels,
        );
        setCroppedImage(croppedImageRes);
    };

    const handleUpload = async (file: File) => {
        const filePath = await compressImage(file, 1080);
        fileNameRef.current = file.name;
        setImagePath(filePath);
    };

    const uploadComponent = useMemo(() => {
        const upload = Array.isArray(children) ? children[0] : children;
        return {
            ...upload,
            props: {
                ...upload.props,
                accept: 'image/*',
                beforeUpload: (file: File): Promise<File> => {
                    handleUpload(file);

                    return new Promise<File>((resolve, reject) => {
                        resolveRef.current = resolve;
                        rejectRef.current = reject;
                    });
                },
            },
        };
    }, [children]);
    return (
        <div className="img-crop">
            {uploadComponent}
            <Modal title={modalTitle} visible={isModalVisible} onOk={handleOk} onCancel={clear} transitionName="">
                <div className="img-crop__container">
                    <Cropper
                        image={imagePath}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        minZoom={ZOOM_MIN}
                        maxZoom={ZOOM_MAX}
                        showGrid={false}
                    />
                </div>
                <div className="img-crop__controls">
                    <button
                        className="img-crop__controls--button"
                        type="button"
                        onClick={() => setZoom(zoom - ZOOM_STEP)}
                        disabled={zoom - ZOOM_STEP < ZOOM_MIN}
                    >
                        －
                    </button>
                    <Slider
                        className="img-crop__controls--slider"
                        min={ZOOM_MIN}
                        max={ZOOM_MAX}
                        step={ZOOM_STEP}
                        value={zoom}
                        onChange={setZoom}
                    />
                    <button
                        className="img-crop__controls--button"
                        type="button"
                        onClick={() => setZoom(zoom + ZOOM_STEP)}
                        disabled={zoom + ZOOM_STEP > ZOOM_MAX}
                    >
                        ＋
                    </button>
                </div>
            </Modal>
        </div>
    );
};
