import React from 'react';
import {
    Button, Card, Form, Input, notification, Select,
} from 'antd';
import { translate } from 'translations/translate';
import { IUser } from 'types';
import { useAppContext } from 'context/AppContext';
import { getUserFromStorage } from 'services/functionalStorage';
import { GenderEnum } from 'usecases/types/enums/gender.enum';
import { LanguageEnum } from 'usecases/types/enums/language.enum';
import { updateUserProfile } from 'usecases/users/updateUserProfile';
import './ProfileCardComponent.scss';

export const ProfileCardComponent = ({ onSuccessfulChange }: {onSuccessfulChange: Function}): JSX.Element => {
    const {
        updateAntdLocaleMapFromProfileLanguage,
        isSpinnerVisible,
        setIsSpinnerVisible,
    } = useAppContext();

    const {
        name, surname, gender, language,
    }: IUser = getUserFromStorage();

    const onGeneralSettingsChange = async (values: IUser) => {
        setIsSpinnerVisible(true);

        try {
            await updateUserProfile({
                name: values.name,
                surname: values.surname,
                gender: values.gender,
                language: values.language,
            });
            updateAntdLocaleMapFromProfileLanguage();
            onSuccessfulChange();
        } catch (error: any) {
            notification.error({
                message: translate('error'),
                description: error.message,
            });
        } finally {
            setIsSpinnerVisible(false);
        }
    };

    return (

        <Card className="profile-card">
            <h1 className="profile-card__title">{translate('profile-card_title')}</h1>
            <Form
                layout="vertical"
                name="basic"
                initialValues={{
                    name,
                    surname,
                    gender,
                    language,
                }}
                onFinish={onGeneralSettingsChange}
            >
                <Form.Item
                    label={(
                        <label className="profile-card__form-label">
                            {translate('profile_card-first_name')}
                        </label>
                    )}
                    name="name"
                    rules={[{ required: true, message: translate('first-name-is-required') }]}
                >
                    <Input className="profile-card__form-item" />
                </Form.Item>

                <Form.Item
                    label={(
                        <label className="profile-card__form-label">
                            {translate('profile_card-last_name')}
                        </label>
                    )}
                    name="surname"
                    rules={[{ required: true, message: translate('last-name-is-required') }]}
                >
                    <Input className="profile-card__form-item" />
                </Form.Item>

                <Form.Item
                    label={(
                        <label className="profile-card__form-label">
                            {translate('profile_card-gender')}
                        </label>
                    )}
                    name="gender"
                    rules={[{ required: true, message: translate('gender-is-required') }]}
                >
                    <Select size="large">
                        <Select.Option value={GenderEnum.MALE}>{translate('male')}</Select.Option>
                        <Select.Option value={GenderEnum.FEMALE}>{translate('female')}</Select.Option>
                        <Select.Option value={GenderEnum.OTHER}>{translate('other')}</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label={(
                        <label className="profile-card__form-label">
                            {translate('profile_card-language')}
                        </label>
                    )}
                    name="language"
                    rules={[{ required: true, message: translate('language-is-required') }]}
                >
                    <Select size="large">
                        <Select.Option value={LanguageEnum.ENGLISH}>English</Select.Option>
                        <Select.Option value={LanguageEnum.GERMAN}>Deutsch</Select.Option>
                    </Select>
                </Form.Item>

                <Button
                    type="primary"
                    htmlType="submit"
                    className="profile-card__button"
                    disabled={isSpinnerVisible}
                >
                    {translate('save-changes')}
                </Button>
            </Form>
        </Card>
    );
};
